import { ButtonBase, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordInput = forwardRef<HTMLDivElement, Omit<TextFieldProps, 'type'>>(
  (props, ref) => {
    const [type, setType] = useState<TextFieldProps['type']>('password');

    const handleClickShowPassword = () => {
      setType(type === 'password' ? 'text' : 'password');
    };

    return (
      <TextField
        type={type}
        {...props}
        ref={ref}
        InputProps={{
          endAdornment: (
            <ButtonBase onClick={handleClickShowPassword} disableRipple>
              {type === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ButtonBase>
          ),
        }}
      />
    );
  }
);

export default PasswordInput;

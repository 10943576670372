import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { Link, Box, Button, Stack, TextField, TextFieldProps, Typography, IconButton, Autocomplete } from '@mui/material';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteLaboratoryMutation, useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { useGetStaffQuery } from 'entities/staff/api';
import { AddEquipmentModal } from 'pages/equipment/equipment-modal/equipment-modal';
import { EquipmentModalRef } from 'pages/equipment/equipment-modal/types';
import { WarningLabModal } from 'features/warning-lab-modal';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { AddCircle } from '@mui/icons-material';

import { useGetEquipmentQuery } from 'entities/equipment/api';

import { Equipment } from 'entities/equipment/model';
import { useNavigate } from 'react-router-dom';

import LongtextHide from 'shared/ui/long-text-hide';

import { bgColorRule } from 'shared/ui/ui-table/types';
import { Colors } from 'app/constants';
import { WarningEquipModal } from 'features/warning-equip-modal';
import { EnvCondDeleteModalRef } from 'features/types';
import { useGetTestTypesQuery, useGetProtocolsQuery } from 'entities/protocol/api';
import { Protocol } from 'entities/protocol/model';
import LongTextHide from 'shared/ui/long-text-hide';
import moment from 'moment';
import UIDatePicker from 'shared/inputs/date-picker';
import dayjs from 'dayjs';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';


const ProtocolList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<EquipmentModalRef>(null);
  const modalWarningRef = useRef<EnvCondDeleteModalRef>(null);

  const { data: testTypes } = useGetTestTypesQuery({});

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      protocol_nn: '',
      id_test_type: '',
      client_name: '',
      page: 1,
      product_name: '',
      test_program: '',
      date_confirm_from: '',
      date_confirm_to: '',
      date_begin_from: '',
      date_begin_to: '',
      sort: '-confirm_date',
    },
  });

  const { data: records, isFetching } = useGetProtocolsQuery({
    //labId: data.id,
    //sort: '-date_create',
    //'per-page': 3,
    ...filterParams,
  });

  const handleDelete = (id: number) => {
    modalWarningRef.current?.open(id)
  }

  const equipHeaders = [
    {
      label: t('protocol.list.nn'),
      align: "center" as const,
      sortable: true,
      sortField: 'protocol_nn',
    },
    {
      label: t('protocol.list.testType'),
      align: "center" as const,
      sortable: true,
      sortField: 'id_test_type',
    },
    {
      label: t('protocol.list.dateStart'),
      align: "center" as const,
      sortable: true,
      sortField: 'begin_date',
    },
    {
      label: t('protocol.list.client'),
      align: "center" as const,
      sortable: true,
      sortField: 'client_name',
    },
    {
      label: t('protocol.list.product'),
      align: "center" as const,
    },
    {
      label: t('protocol.list.testProg'),
      align: "center" as const,
    },
    {
      label: t('protocol.list.status'),
      align: "center" as const,
    },
    {
      label: t('actions'),
      align: "center" as const,
    },

  ];

  const rules: bgColorRule[] = [
    {
      field: 'date_cancel',
      rule: [
        { value: null, color: Colors.MEDIUM_GREY },
      ]
    },
    {
      field: 'status',
      rule: [
        //{value:0, color: Colors.PURPLE},
        { value: 1, color: Colors.LIGHT_RED },
        { value: 2, color: Colors.PASTEL_ORANGE },
        //{value:3, color: Colors.PALE_BLUE},
      ]
    },
  ];

  /*interface Protocol {
    id: number;
    protocol_nn: string;
    id_test_type: number;
    begin_date: string;
    client_name: string;
    product: string;
    testProg: string;
    status: string;
  }*/

  /*const protocolMockData: Protocol[] = [
    {
      id: 1,
      protocol_nn: "2024031501 от 2024-03-15",
      id_test_type: 1,
      begin_date: '01.01.2021',
      client_name: 'ООО "Рога и копыта"',
      product: 'Печенье "Овсяное"',
      testProg: 'ГОСТ 12345-67',
      status: 'В работе',
    },
    {
      id: 2,
      protocol_nn: "2024031502 от 2024-03-15",
      id_test_type: 2,
      begin_date: '01.01.2021',
      client_name: 'ООО "Рога и копыта"',
      product: 'Печенье "Овсяное"',
      testProg: 'ГОСТ 12345-67',
      status: 'В работе',
    },
    {
      id: 3,
      protocol_nn: "2024031503 от 2024-03-15",
      id_test_type: 3,
      begin_date: '01.01.2021',
      client_name: 'ООО "Рога и копыта"',
      product: 'Печенье "Овсяное"',
      testProg: 'ГОСТ 12345-67',
      status: 'В работе',
    },
    {
      id: 4,
      protocol_nn: "2024031504 от 2024-03-15",
      id_test_type: 4,
      begin_date: '01.01.2021',
      client_name: 'ООО "Рога и копыта"',
      product: 'Печенье "Овсяное"',
      testProg: 'ГОСТ 12345-67',
      status: 'В работе',
    },
    {
      id: 5,
      protocol_nn: "2024031505 от 2024-03-15",
      id_test_type: 5,
      begin_date: '01.01.2021',
      client_name: 'ООО "Рога и копыта"',
      product: 'Печенье "Овсяное"',
      testProg: 'ГОСТ 12345-67',
      status: 'В работе',
    },
  ];*/

  return (
    <Box>
      <Stack direction={'row'} mb={'2rem'}>
        <Typography variant='h2'>{t('protocol.common.protocolTitle')}</Typography>
        <IconButton onClick={() => modalRef.current?.open()} aria-label="add">
          <AddCircle sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <TextField
          label={t('protocol.list.nn')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ protocol_nn: e.target.value })}
        />
        <Autocomplete
          options={testTypes?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
          //@ts-ignore
          onChange={(event, option) => {
            //@ts-ignore
            onChangeFilterParams({ id_test_type: (option && option.id) ? option.id.toString() : '' });

            //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
            //field.onChange(option);
            //@ts-ignore
            //onChange?.(event, option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              //placeholder: t('location')

              label={t('protocol.list.testType')}
            />
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              m: 'auto',
            }, width: 220
          }}
        />
        <TextField
          label={t('protocol.list.client')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ client_name: e.target.value })}
        />
        <TextField
          label={t('protocol.list.product')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ product_name: e.target.value })}
        />
        <TextField
          label={t('protocol.list.testProg')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ test_program: e.target.value })}
        />
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <UIDatePicker
          value={filterParams.date_confirm_from ? dayjs(filterParams.date_confirm_from).toDate() : null}
          TextFieldProps={{ label: t('protocol.list.dateConfirmFrom') }}
          //@ts-ignore
          onChange={(date: Date | null) => onChangeFilterParams({ date_confirm_from: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
        <UIDatePicker
          value={filterParams.date_confirm_to ? dayjs(filterParams.date_confirm_to).toDate() : null}
          TextFieldProps={{ label: t('protocol.list.dateConfirmTo') }}
          //@ts-ignore
          onChange={(date: Date | null) => onChangeFilterParams({ date_confirm_to: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
        <UIDatePicker
          value={filterParams.date_begin_from ? dayjs(filterParams.date_begin_from).toDate() : null}
          TextFieldProps={{ label: t('protocol.list.dateBeginFrom') }}
          //@ts-ignore
          onChange={(date: Date | null) => onChangeFilterParams({ date_begin_from: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
        <UIDatePicker
          value={filterParams.date_begin_to ? dayjs(filterParams.date_begin_to).toDate() : null}
          TextFieldProps={{ label: t('protocol.list.dateBeginTo') }}
          //@ts-ignore
          onChange={(date: Date | null) => onChangeFilterParams({ date_begin_to: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort={onChangeFilterParams}
        orderDirection={'desc'}
        orderByField={'confirm_date'}
        headers={equipHeaders}
        bgColorRules={rules}
        columns={[
          {
            render: (row: Protocol) => {
              return <Typography>
                {`${row.protocol_nn ?? '-'} ${t('from')} ${row.confirm_date ? moment(row.confirm_date).format('DD.MM.YYYY') : '-'} `}
                <Link
                  href={'#'}
                  target="_blank"
                  color="inherit"
                >
                  {t('download')}
                </Link>
              </Typography>;
            }
          },
          {
            render: (row: Protocol) => row.testType.name ?? '-',
          },
          {
            render: (row: Protocol) => row.begin_date ? moment(row.begin_date).format('DD.MM.YYYY') : '-',
          },
          {
            render: (row: Protocol) => (row.client_name ?? row.client?.name ?? '-') + ' ' + (row.client?.inn ?? '-'),
          },
          {
            render: (row: Protocol) => {
              return (<LongTextHide text={row.products ?? '-'} />);
            },
          },
          {
            render: (row: Protocol) => {
              return (<LongTextHide text={row.testProgs ?? '-'} />);
            },
          },
          {
            render: (row: Protocol) => row.status ?? '-',
          },
          {
            render: (row: Protocol) => (
              <Stack direction='row' spacing={2} justifyContent='center'>
                <UIActionButton
                  icon='observe'
                  onClick={() => navigate(`/protocol/view/${row.id}`)}
                />
                {/**<UIActionButton icon='edit' onClick={() => modalRef.current?.open(row, laboratories?.items)} />
                <UIActionButton icon='delete' onClick={() => handleDelete(row.id)} />*/}
              </Stack>
            ),
            nowrap: true,
          },
        ]}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <AddEquipmentModal ref={modalRef} />
      <WarningEquipModal ref={modalWarningRef} />
    </Box>
  );
};

export default ProtocolList;
import { baseApi } from './base-api';

// Reexport baseApi as testProgramApi
export const testProgramApi = baseApi;

export {
  useGetTestProgramsByTenantQuery,
  useGetTestProgramsQuery,
  useGetTestProgramByIdQuery,
  useCreateTestProgramMutation,
  useUpdateTestProgramMutation,
  useDeleteTestProgramMutation,
} from './endpoints/test-program';

export {
  useGetTestProgramFeaturesQuery,
  useCreateTestProgramFeatureMutation,
  useUpdateTestProgramFeatureMutation,
  useDeleteTestProgramFeatureMutation,
} from './endpoints/feature';

export {
  useGetTestProgramFeatureListsQuery,
  useCreateTestProgramFeatureListMutation,
  useUpdateTestProgramFeatureListMutation,
  useDeleteTestProgramFeatureListMutation,
} from './endpoints/feature-list';

export {
  useGetTestProgramResultsQuery,
  useGetTestProgramResultByIdQuery,
  useCreateTestProgramResultMutation,
  useUpdateTestProgramResultMutation,
  useDeleteTestProgramResultMutation,
  useGetTestProgramResultPrimariesQuery,
} from './endpoints/result';

export {
  useGetTestProgramPrimariesQuery,
  useCreateTestProgramPrimaryMutation,
  useUpdateTestProgramPrimaryMutation,
  useDeleteTestProgramPrimaryMutation,
} from './endpoints/primary';

export {
  useGetTestProgramPrimaryPointsQuery,
  useCreateTestProgramPrimaryPointMutation,
  useUpdateTestProgramPrimaryPointMutation,
  useDeleteTestProgramPrimaryPointMutation,
} from './endpoints/primary-point';

export {
  useGetTestProgramSizesQuery,
  useGetTestProgramSizeByIdQuery,
  useCreateTestProgramSizeMutation,
  useUpdateTestProgramSizeMutation,
  useDeleteTestProgramSizeMutation,
  useCombineFeatureListsMutation,
  useClearCombinedSizesMutation,
  useUploadTestProgramSizeExcelMutation,
  useCombineFromParentMutation,
} from './endpoints/size';

export {
  useGetTestProgramConnectQuery,
  useCreateTestProgramConnectMutation,
  useDeleteTestProgramConnectMutation,
  useConfirmTestProgramConnectMutation,
} from './endpoints/connect';

export {
  useGetTestProgramMaterialsQuery,
  useGetTestProgramMaterialByIdQuery,
  useCreateTestProgramMaterialMutation,
  useUpdateTestProgramMaterialMutation,
  useDeleteTestProgramMaterialMutation,
  useCreateMaterialFromMethodMutation,
} from './endpoints/material';

/*import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import {
  DefaultResponse,
  RequestWithPagination,
  ResponseWithPagination,
} from 'app/utils/types';
import {
  TestProgram,
  TestProgramConnect,
  TestProgramFeature,
  TestProgramFeatureList,
  TestProgramMaterialOut,
  TestProgramPrimary,
  TestProgramPrimaryPoint,
  TestProgramResults,
  TestProgramSize, 
} from '../model';
import { TestProgramBody, TestProgramConnectBody, TestProgramFeatureBody, TestProgramFeatureListBody, TestProgramMaterialOutBody, TestProgramPrimaryBody, TestProgramPrimaryPointBody, TestProgramResultsBody, TestProgramSizeBody, TestProgramSizeCombine, TestProgramSizeUploadExcel } from '../model/request-body';
import { createFormData } from 'app/utils';

export const testProgramApi = createApi({
  reducerPath: 'testProgramApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'TestProgramByTenant',
    'TestProgram',
    'TestProgramFeature',
    'TestProgramFeatureList',
    'TestProgramConnect', 
    'TestProgramResult',
    'TestProgramResultPrimary',
    'TestProgramPrimary',
    'TestProgramPrimaryPoint',
    'TestProgramSize',
    'TestProgramMaterialOut',

  ],
  endpoints: (builder) => ({
    // Test programs by tenant except choosen
    getTestProgramsByTenant: builder.query<
      { id: number; name: string }[], 
      { id_tenant: number; id_test_program_cert: number }
    >({
      query: ({ id_tenant, id_test_program_cert }) => ({
        method: 'GET',
        url: `/tenants/${id_tenant}/test-programs?id_test_program_cert=${id_test_program_cert}`,
      }),
      providesTags: ['TestProgramByTenant'],
    }),
    
    // Test program
    getTestPrograms: builder.query<ResponseWithPagination<TestProgram>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/test-programs',
        params
      }),
      providesTags: ['TestProgram'],
    }),
    getTestProgramById: builder.query<TestProgram, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/test-programs/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgram'],
    }),
    createTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    updateTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id}`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    deleteTestProgram: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/test-programs/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['TestProgram'],
    }),

    // Test Program Features
    getTestProgramFeatures: builder.query<
      ResponseWithPagination<TestProgramFeature>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/features`,
        params,
      }),
      providesTags: ['TestProgramFeature'],
    }),
    createTestProgramFeature: builder.mutation<
      DefaultResponse,
      TestProgramFeatureBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/features`,
        body,
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),
    updateTestProgramFeature: builder.mutation<DefaultResponse, TestProgramFeatureBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/features/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),
    deleteTestProgramFeature: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/features/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),

    // Test Program Feature Lists
    getTestProgramFeatureLists: builder.query<
      ResponseWithPagination<TestProgramFeatureList>,
      RequestWithPagination<{ id_test_program: number; id_test_program_feature: number }>
    >({
      query: ({ id_test_program, id_test_program_feature, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/features/${id_test_program_feature}/lists`,
        params,
      }),
      providesTags: ['TestProgramFeatureList'],
    }),
    createTestProgramFeatureList: builder.mutation<
      DefaultResponse,
      TestProgramFeatureListBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/features/${body.id_test_program_feature}/lists`,
        body,
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),
    updateTestProgramFeatureList: builder.mutation<DefaultResponse, TestProgramFeatureListBody & { id: number; id_test_program: number }>({
      query: ({ id, id_test_program, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/features/${body.id_test_program_feature}/lists/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),
    deleteTestProgramFeatureList: builder.mutation({
      query: ({id, id_test_program, id_test_program_feature}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/features/${id_test_program_feature}/lists/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),

    // Test Program Results
    getTestProgramResults: builder.query<
      ResponseWithPagination<TestProgramResults>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results`,
        params,
      }),
      providesTags: ['TestProgramResult'],
    }),
    getTestProgramResultById: builder.query<TestProgramResults, {id: string, id_test_program: string}>({
      query: ({ id, id_test_program }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramResult'],
    }),
    createTestProgramResult: builder.mutation<
      DefaultResponse,
      TestProgramResultsBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/results`,
        body,
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    updateTestProgramResult: builder.mutation<DefaultResponse, TestProgramResultsBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/results/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    deleteTestProgramResult: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    getTestProgramResultPrimaries: builder.query<
      ResponseWithPagination<TestProgramPrimary>,
      RequestWithPagination<{ id_test_program: number; id_test_program_result: number; }>
    >({
      query: ({ id_test_program, id_test_program_result, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_result}/primary-list`,
        params,
      }),
      providesTags: ['TestProgramResultPrimary'],
    }),

    // Test Program Primary
    getTestProgramPrimaries: builder.query<
      ResponseWithPagination<TestProgramPrimary>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/primaries`,
        params,
      }),
      providesTags: ['TestProgramPrimary'],
    }),
    createTestProgramPrimary: builder.mutation<
      DefaultResponse,
      TestProgramPrimaryBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/primaries`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),
    updateTestProgramPrimary: builder.mutation<DefaultResponse, TestProgramPrimaryBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/primaries/${id}`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),
    deleteTestProgramPrimary: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/primaries/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),

    // Test Program Primary Point
    getTestProgramPrimaryPoints: builder.query<
      ResponseWithPagination<TestProgramPrimaryPoint>,
      RequestWithPagination<{ id_test_program: number; id_test_program_primary: number }>
    >({
      query: ({ id_test_program, id_test_program_primary, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/primaries/${id_test_program_primary}/points`,
        params,
      }),
      providesTags: ['TestProgramPrimaryPoint'],
    }),
    createTestProgramPrimaryPoint: builder.mutation<
      DefaultResponse,
      TestProgramPrimaryPointBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/primaries/${body.id_test_program_primary}/points`,
        body,
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),
    updateTestProgramPrimaryPoint: builder.mutation<DefaultResponse, TestProgramPrimaryPointBody & { id: number; id_test_program: number }>({
      query: ({ id, id_test_program, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/primaries/${body.id_test_program_primary}/points/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),
    deleteTestProgramPrimaryPoint: builder.mutation({
      query: ({id, id_test_program, id_test_program_primary}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/primaries/${id_test_program_primary}/points/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),

    // Test Program Size
    getTestProgramSizes: builder.query<
      ResponseWithPagination<TestProgramSize>,
      RequestWithPagination<{ id_test_program: number; id_test_program_results: number }>
    >({
      query: ({ id_test_program, id_test_program_results, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes`,
        params,
      }),
      providesTags: ['TestProgramSize'],
    }),
    getTestProgramSizeById: builder.query<TestProgramSize, {id: string, id_test_program: string, id_test_program_results: string}>({
      query: ({ id, id_test_program, id_test_program_results }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramSize'],
    }),
    createTestProgramSize: builder.mutation<
      DefaultResponse,
      TestProgramSizeBody & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    updateTestProgramSize: builder.mutation<
      DefaultResponse,
      TestProgramSizeBody & { id: number; id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id, id_test_program, id_test_program_results, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    deleteTestProgramSize: builder.mutation({
      query: ({id, id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    combineFeatureLists: builder.mutation<
      DefaultResponse,
      TestProgramSizeCombine & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/combine-variant`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    clearCombinedSizes: builder.mutation({
      query: ({id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/clear-combine-variant`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    uploadTestProgramSizeExcel: builder.mutation<
      DefaultResponse,
      TestProgramSizeUploadExcel & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/upload-excel-template`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    combineFromParent: builder.mutation<
      DefaultResponse,
      TestProgramSizeUploadExcel & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/get-combine-from-parent`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),

    //Connected test programs
    getTestProgramConnect: builder.query<
      ResponseWithPagination<TestProgramConnect>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/connects`,
        params,
      }),
      providesTags: ['TestProgramConnect'],
    }),
    createTestProgramConnect: builder.mutation<
      DefaultResponse,
      TestProgramConnectBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/connects`,
        body,
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),
    deleteTestProgramConnect: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/connects/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),
    confirmTestProgramConnect: builder.mutation<
      DefaultResponse<TestProgramConnect>,
      { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'POST',
        url: `/test-program-connects/${id}/confirm`,
        body,
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),

    // Test Program Materials
    getTestProgramMaterials: builder.query<
      ResponseWithPagination<TestProgramMaterialOut>,
      RequestWithPagination<{ id_test_program: number; id_test_program_results: number }>
    >({
      query: ({ id_test_program, id_test_program_results, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs`,
        params,
      }),
      providesTags: ['TestProgramMaterialOut'],
    }),
    getTestProgramMaterialById: builder.query<TestProgramMaterialOut, {id: string, id_test_program: string, id_test_program_results: string}>({
      query: ({ id, id_test_program, id_test_program_results }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramMaterialOut'],
    }),
    createTestProgramMaterial: builder.mutation<
      DefaultResponse,
      TestProgramMaterialOutBody & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    updateTestProgramMaterial: builder.mutation<
      DefaultResponse,
      TestProgramMaterialOutBody & { id: number; id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id, id_test_program, id_test_program_results, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    deleteTestProgramMaterial: builder.mutation({
      query: ({id, id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    createMaterialFromMethod: builder.mutation<
      DefaultResponse,
      { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-from-method`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),



    
  }),
});

export const {
  useGetTestProgramsByTenantQuery,
  useGetTestProgramsQuery,
  useGetTestProgramByIdQuery,
  useCreateTestProgramMutation,
  useUpdateTestProgramMutation,
  useDeleteTestProgramMutation,
  useGetTestProgramFeaturesQuery,
  useCreateTestProgramFeatureMutation,
  useUpdateTestProgramFeatureMutation,
  useDeleteTestProgramFeatureMutation,
  useGetTestProgramFeatureListsQuery,
  useCreateTestProgramFeatureListMutation,
  useUpdateTestProgramFeatureListMutation,
  useDeleteTestProgramFeatureListMutation,
  useGetTestProgramResultsQuery,
  useGetTestProgramResultByIdQuery,
  useCreateTestProgramResultMutation,
  useUpdateTestProgramResultMutation,
  useDeleteTestProgramResultMutation,
  useGetTestProgramResultPrimariesQuery,
  useGetTestProgramPrimariesQuery,
  useCreateTestProgramPrimaryMutation,
  useUpdateTestProgramPrimaryMutation,
  useDeleteTestProgramPrimaryMutation,
  useGetTestProgramPrimaryPointsQuery,
  useCreateTestProgramPrimaryPointMutation,
  useUpdateTestProgramPrimaryPointMutation,
  useDeleteTestProgramPrimaryPointMutation,
  useGetTestProgramSizesQuery,
  useGetTestProgramSizeByIdQuery,
  useCreateTestProgramSizeMutation,
  useUpdateTestProgramSizeMutation,
  useDeleteTestProgramSizeMutation,
  useCombineFeatureListsMutation,
  useClearCombinedSizesMutation,  
  useUploadTestProgramSizeExcelMutation,
  useCombineFromParentMutation,
  useGetTestProgramConnectQuery,
  useCreateTestProgramConnectMutation,
  useDeleteTestProgramConnectMutation,
  useConfirmTestProgramConnectMutation,
  useGetTestProgramMaterialsQuery,
  useGetTestProgramMaterialByIdQuery,
  useCreateTestProgramMaterialMutation,
  useUpdateTestProgramMaterialMutation,
  useDeleteTestProgramMaterialMutation,
  useCreateMaterialFromMethodMutation,
  
} = testProgramApi;*/

import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell
} from '@mui/material';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

import React from 'react';
import Layout from 'shared/layout';
//import EquipmentContainer from '../ui/equipment-container';
import CollapseCard from '../../../shared/ui/collapse-card';
import './index.css';
import {
  useGetEquipmentIdQuery,
} from 'entities/equipment/api';
import { useParams } from 'react-router-dom';
import { Equipment } from 'entities/equipment/model';
import { ServiceRegistration } from './service-reg';
import { Verification } from './verification';
import { General } from './general';
import { Characteristic } from './characteristic';
import { Shipping } from './shipping';
import { MethodGraphic } from './method-graphic';
import { EnvironmentViolation } from './environment-violation';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'shared/ui/breadcrumb';
//import {  } from 'pages/equipment/verification-modal/types';


const EquipmentViewPage = () => {
  const { viewId } = useParams();
  const { data } = useGetEquipmentIdQuery(viewId ?? '');
  const { t } = useTranslation();

  const editEquipment = () => {
    alert(1);
  }

  const breadcrumbs = [
    { label: t('techMeans'), href: '/equipment' },
    { label: data?.name as string },
  ];


  return (
    <Layout>
      <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
        <Breadcrumb items={breadcrumbs} />
        <Stack gap={'1.5rem'}>
          <Typography variant='h2'>
            {data?.name} 
            <IconButton aria-label="edit" onClick={editEquipment}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="print">
              <PrintIcon />
            </IconButton>
          </Typography>
          {/**<Button variant='contained' color='info' sx={{ width: 'fit-content' }}>
            Печать
          </Button>*/}
        </Stack>
        <General equipment={data as Equipment} />
        <Characteristic equipment={data as Equipment} />
        <Verification equipment={data as Equipment} />
        <ServiceRegistration equipment={data as Equipment} />
        <Shipping equipment={data as Equipment} />
        <MethodGraphic id_equipment={data?.id as number} />
        <EnvironmentViolation id_equipment={data?.id as number} />
      </Box>
    </Layout>
  );
};

export default EquipmentViewPage;

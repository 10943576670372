import { DialogTitle, IconButton, Divider, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface Props {
  title?: string;
  children?: React.ReactNode;
  handleClose?: () => void;
}

const ModalHeader: React.FC<Props> = ({ title, children = title, handleClose }) => {
  const theme = useTheme();
  return (
    <>
    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      {children ?? title}
    </DialogTitle>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: 12,
        top: 12,
        //color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <Divider />
    </>
  );
};

export default ModalHeader;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { CreateEnvRecordBody, Laboratory } from 'entities/laboratories/model';
import { BaseModalRef } from 'app/utils/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import dayjs from 'dayjs';
import {
  useCreateNewEnvironmentRecordMutation,
  useSignEnvironmentRecordByIdMutation,
} from 'entities/laboratories/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { handleResponse } from 'app/utils';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';

interface FormValues {
  date_create: string;
  note: string;
  environmentSubs: {
    value: string;
    envCond: Laboratory['environmentConditions'][number];
  }[];
}

interface Props {
  room: Laboratory;
}

const AddEnviromentConditionModal: React.ForwardRefRenderFunction<BaseModalRef, Props> = (
  { room },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { control, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      date_create: '',
      environmentSubs: [],
    },
  });

  const [createNewEnvRecord] = useCreateNewEnvironmentRecordMutation();
  const [signEnvRecord] = useSignEnvironmentRecordByIdMutation();

  const { fields } = useFieldArray({
    control,
    name: 'environmentSubs',
  });

  useImperativeHandle(ref, () => ({
    open: onOpenHandler,
    close: onCloseHandler,
  }));

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onOpenHandler = () => {
    reset({
      date_create: dayjs().format('HH:mm'),
      note: '',
      environmentSubs: room.environmentConditions.map((el) => ({
        value: '',
        envCond: el,
      })),
    });
    setOpen(true);
  };

  const onSubmit = async (data: FormValues) => {
    const [hrs, mins] = data.date_create.split(':');

    try {
      setIsLoading(true);
      const body: CreateEnvRecordBody = {
        labId: room.id,
        date_create: dayjs()
          .startOf('day')
          .add(Number(hrs), 'hour')
          .add(Number(mins), 'minute')
          .format()
          .split('+')[0],
        note: data.note,
        environmentSubs: data.environmentSubs.map((el) => ({
          id_environment_condition: el.envCond.id,
          value: Number(el.value),
        })),
      };

      const res = await handleResponse(await createNewEnvRecord(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json.replace(/\\\//g, "")));
      await handleResponse(
        await signEnvRecord({ id: res.id, labId: room.id, signature, note: data.note })
      );

    } finally {
      onCloseHandler();
      notify('', 'success');
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '60vw',
          p: 3,
          overflowY: 'visible',
        },
      }}
    >
      <UIModalTitle title={`${t('add_env_cond_title')} "${room.name}"`} />
      <Box
        sx={{
          mt: 7,
          p: 2,
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <FormControl>
                <FormFormatedInput
                  format='##:##'
                  control={control}
                  name='date_create'
                  label={t('time_of_registration')}
                  required
                  rules={{
                    required: true,
                    pattern: {
                      value: /^([01]?[0-9]|2[0-3])?(:[0-5]?[0-9])?$/,
                      message: t('errors:time_format_hh_mm'),
                    },
                  }}
                  InputProps={{
                    endAdornment: <AccessTimeIcon />,
                    placeholder: '12:34',
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{`${t('notion')} (${t('optional')})`}</FormLabel>
                <FormTextInput
                  control={control}
                  name='note'
                  multiline
                  sx={{
                    '& .MuiInputBase-input': {
                      minHeight: '120px',
                    },
                  }}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              {fields.map((field, index) => (
                <FormNumericInput
                  key={field.id}
                  control={control}
                  name={`environmentSubs.${index}.value`}
                  label={t('enter_value')}
                  fullWidth
                  required
                  rules={{
                    required: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography sx={{ whiteSpace: 'nowrap' }}>
                        {field.envCond.name}
                      </Typography>
                    ),
                  }}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <Button color='error' onClick={onCloseHandler}>
          {t('close')}
        </Button>
        <Button color='success' onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(AddEnviromentConditionModal);

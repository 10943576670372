import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, Stack, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AccredSpecificDeleteModalRef } from './types';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { useDeleteSpecificMutation } from 'entities/accreditation/api';
import { handleResponse } from 'app/utils';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';


export interface ErrorStatus {
  error: {
    data: {
      status: boolean,
      error: string
    }
  }
}

export const WarningAccredSpecificModal = forwardRef<AccredSpecificDeleteModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [data, setData] = useState<number>()
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorStatus | null>()
  const [deleteEquip] = useDeleteSpecificMutation()
  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );


  const handleOpen = async (data?: number) => {
    setData(data)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage(null)
  };

  const handleSubmit = async () => {
    try {
      let body
      if (errorMessage && !errorMessage.error.data.status) {
        body = {
          id: data,
          params: {
            'force': 1
          }
        }
      } else {
        body = {
          id: data,
          params: {}
        }
      }
      const res = await deleteEquip(body).then(res => res)
      // @ts-ignore
      setErrorMessage(res)

      if (errorMessage && !errorMessage?.error.data.status) {
        notify('Данные успешно удалены', 'success')
        setOpen(false)
        setErrorMessage(null)
        // @ts-ignore
      } else if (res.error.status !== 422) {
        notify('Данные успешно удалены', 'success')
        setOpen(false)
        setErrorMessage(null)
      }

    }
    catch (err) {
      notify('', 'error')
      setOpen(false)
      setErrorMessage(null)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '26vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={t('toast.warning')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '100px' }} className='scrollbar'>
        <Stack spacing={4} mt={3}>
          <Typography variant='h3' textAlign={'center'}>{errorMessage?.error.data.error ?? t('warning_delete')}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button type='submit' onClick={handleSubmit}>
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

import React, { useRef } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { AccredStandart, AccredStandartEquipment } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import { useCreateStandartEqFromPrimaryMutation, useDeleteStandartEquipmentMutation, useGetStandartEquipmentQuery } from 'entities/accreditation/api';
import { useFilters } from 'app/hooks/use-filters';
import { AddCircle } from '@mui/icons-material';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { AccredStandartEquipmentModalRef } from './modal/types';
import { AccredStandartEquipmentModal } from './modal/standart-eq-modal';
import { useCreateEquipmentMutation } from 'entities/equipment/api';
import notify from 'app/utils/notify';

interface Props {
  data: AccredStandart;
}

interface DeleteRequestBody {
  id: number;
  id_accred_standart: number;
}

export const Equipment: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<AccredStandartEquipmentModalRef>(null);

  const [deleteRec] = useDeleteStandartEquipmentMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_accred_standart: additionalData?.id_accred_standart as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_accred_standart: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_accred_standart });
    }
  };

  const [createStandartEqFromPrimary, { isLoading: isLoadingEqFromPrimary }] = useCreateStandartEqFromPrimaryMutation();

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //sort: '-date_service',
    },
  });

  const { data: records, isFetching } = useGetStandartEquipmentQuery({
    id_accred_standart: data ? data.id : 0,
    ...filterParams,
  });

  const handleFill = async (id_accred_standart: number) => {
    try {
      await createStandartEqFromPrimary(id_accred_standart).unwrap();
      notify(t('addSuccess'), 'success');
    } catch (error) {
      notify(t('addError'), 'error');
    }
  }

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('accred.standart.tabs.equipment.title')}
            <IconButton
              onClick={() => modalRef.current?.open()}
              aria-label="add"
            >
              <AddCircle />
            </IconButton>

          </>
        }
        collapsed={true}
      >
        <Stack
          direction='row'
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Button color='primary' onClick={() => handleFill(data.id)}>
            {t('accred.standart.tabs.equipment.fill')}
          </Button>
        </Stack>
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            {
              label: t('accred.standart.tabs.equipment.name'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.equipment.factoryNum'),
              align: 'center' as const,
            },
            {
              label: t('actions'),
              align: 'center' as const,
            },
          ]}
          columns={[
            {
              render: (row: AccredStandartEquipment) => row.equipment.name ?? '--',
            },
            {
              render: (row: AccredStandartEquipment) => row.equipment.factory_number ?? '--',
            },
            {
              render: (row: AccredStandartEquipment) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='delete'
                    tooltip={t('delete')}
                    onClick={() => openDeleteModal(row.id_equipment, row.id_accred_standart)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
                  />
                </Stack>
              ),
              nowrap: true,
            },

          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <AccredStandartEquipmentModal accredStandart={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  Link,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import ModalHeader from 'shared/ui/modal-header';
import { BaseTestProgramFeatureList, binarityArray, decisionArray, TestProgram, TestProgramFeature, TestProgramResults } from 'entities/test-program/model';
import {
  useGetStandartsQuery,
  useGetSpecificsQuery,
  useGetStandartConnectedQuery,
  useGetStandartPrimaryQuery,
  useGetStandartPrimaryWithConnectedQuery
} from 'entities/accreditation/api';
import {
  useCreateTestProgramResultMutation,
  useUpdateTestProgramResultMutation,
  useGetTestProgramFeaturesQuery,
  useGetTestProgramFeatureListsQuery,
} from 'entities/test-program/api';
import notify from 'app/utils/notify';
import { AccredSpecific, AccredStandart, AccredStandartPrimary } from 'entities/accreditation/model';
import { Units } from 'entities/dicts/model';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import Attention from 'shared/ui/attention';
import { useAppSelector } from 'app/hooks/redux';
import UITable from 'shared/ui/ui-table';

export interface TestProgramResultModalRef {
  open: (data?: TestProgramResults, testProgramId?: number) => void;
  close: () => void;
}

interface FormValues {
  id?: number;
  id_test_program?: number;
  id_accred_specific?: AccredSpecific | null;
  id_accred_standart?: AccredStandart | null;
  id_unit?: Units | null;
  standart_tech_param: string;
  formula: string | null;
  //id_test_program_child?: number;
  specific_name: string | null;
  standart_name: string | null;
  confidence_probability: number | null;
  accuracy: number | null;
  decision_rule: { id: number; name: string } | null;
  binarity: { id: number; name: string } | null;
  //order_nn?: number;
  parent_method?: number | null;
  //testProgramSize: BaseTestProgramFeatureList[][];
  child_standart: {
    check: boolean;
    id_accred_standart: number;
    standart_tech_param: string;
    id_unit: Units;
  }[];
}

const initialState: FormValues = {
  id_accred_specific: null,
  id_accred_standart: null,
  standart_tech_param: '',
  formula: null,
  specific_name: null,
  standart_name: null,
  confidence_probability: null,
  accuracy: null,
  decision_rule: null,
  binarity: null,
  //testProgramSize: [[]],
  child_standart: [],
};

interface Props {
  testProgram: TestProgram;
}

export const TestProgramResultModal = forwardRef<TestProgramResultModalRef, Props>(({ testProgram }, ref) => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [testProgramId, setTestProgramId] = useState<number | undefined>(undefined);
  const { control, handleSubmit, reset, watch, setValue, register } = useForm<FormValues>({
    defaultValues: initialState,
  });
  const [createTestProgramResult, { isLoading: isLoadingCreate }] = useCreateTestProgramResultMutation();
  const [updateTestProgramResult, { isLoading: isLoadingUpdate }] = useUpdateTestProgramResultMutation();

  const { data: accredSpecifics, isFetching: isSpecificsFetching } = useGetSpecificsQuery(
    { id_accred_group: testProgram.id_accred_group, 'per-page': 0 },
    { skip: !testProgram.id_accred_group }
  );
  const watchedAccredSpecific = watch('id_accred_specific');
  const { data: accredStandarts, isFetching: isStandartsFetching } = useGetStandartsQuery(
    { id_accred_specific: watchedAccredSpecific ? watchedAccredSpecific.id : 0, 'per-page': 0 }
  );




  const { data: testProgramFeatures, isFetching: isFeaturesFetching } = useGetTestProgramFeaturesQuery(
    { id_test_program: testProgramId ?? 0 },
    { skip: !testProgramId }
  );

  const watchedAccredStandart = watch('id_accred_standart');
  const { data: connectedStandarts } = useGetStandartConnectedQuery({ id_accred_standart: watchedAccredStandart ? watchedAccredStandart.id : 0 });

  //get primaries for accredStandart
  const { data: accredStandartPrimaries, isFetching: isPrimariesFetching } = useGetStandartPrimaryWithConnectedQuery(
    { id_accred_standart: watchedAccredStandart ? watchedAccredStandart.id : 0 },
    { skip: !watchedAccredStandart }
  );
  
  /*const { fields: sizeFields, append: appendSize, remove: removeSize } = useFieldArray({
    control,
    name: 'testProgramSize',
  });*/

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = (data?: TestProgramResults, testProgramId?: number) => {
    setTestProgramId(testProgramId);
    if (data) {
      setIsEdit(true);
      const decisionRule = data.decision_rule ? decisionArray.find((item) => item.id === data.decision_rule) : null;
      const binarity = data.binarity ? binarityArray.find((item) => item.id === data.binarity) : null;
      reset({
        id: data.id,
        id_test_program: data.id_test_program,
        id_accred_specific: data.accredSpecific,
        id_accred_standart: data.accredStandartData,
        id_unit: data.unit,
        standart_tech_param: data.standart_tech_param,
        formula: data.formula,
        specific_name: data.specific_name,
        standart_name: data.standart_name,
        confidence_probability: data.confidence_probability,
        accuracy: data.accuracy,
        decision_rule: { id: data.decision_rule, name: t(decisionRule?.name as string) },
        binarity: { id: data.binarity, name: t(binarity?.name as string) },
      });
    } else {
      reset({ ...initialState });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    if (!testProgramId||!data.id_accred_specific||!data.id_accred_standart) {
      return;
    }

    console.log(data);
    try {
      const body = {
        ...data,
        id_test_program: testProgramId,
        id_accred_specific: data.id_accred_specific.id,
        id_accred_standart: data.id_accred_standart.id,
        id_unit: data.id_unit ? data.id_unit.id : null,
        decision_rule: data.decision_rule ? data.decision_rule.id : null,
        binarity: data.binarity ? data.binarity.id : null,
        child_standart: data.child_standart.filter((item) => item.check).map((item) => ({
            id_accred_standart: item.id_accred_standart,
            standart_tech_param: item.standart_tech_param,
            id_unit: item.id_unit.id,
          })),
      };
      console.log(body);
      if (isEdit && data.id) {
        await updateTestProgramResult({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgramResult(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }
  };

  /*useEffect(() => {
    if (testProgramFeatures?.items) {
      // Initialize testProgramSize with empty arrays for each feature
      const initialSizes = testProgramFeatures.items.map(() => []);
      setValue('testProgramSize', initialSizes);
    }
  }, [testProgramFeatures, setValue]);*/

  const primaryHeaders = (data: AccredStandart) => [
    {
      label: t('accred.standart.tabs.primary.name'),
      align: "center" as const,
      visible: true,
    },
    {
      label: t('accred.standart.tabs.primary.unit'),
      align: "center" as const,
      visible: data.type !== 2,
    },
    {
      label: t('accred.standart.tabs.primary.var'),
      align: "center" as const,
      visible: true,
    },
    {
      label: t('accred.standart.tabs.primary.eq'),
      align: "center" as const,
      visible: data.type !== 2,
    },
    {
      label: t('accred.standart.tabs.primary.scale'),
      align: "center" as const,
      visible: data.type !== 2,
    },
    {
      label: t('accred.standart.tabs.primary.photo'),
      align: "center" as const,
      visible: true,
    },
  ];

  const primaryColumns = (data: AccredStandart) => [
    {
      render: (row: AccredStandartPrimary) => row.name ?? '--',
      visible: true,
    },
    {
      render: (row: AccredStandartPrimary) => row.unit ?? '--',
      visible: data.type !== 2,
    },
    {
      render: (row: AccredStandartPrimary) => row.variable ?? '--',
      visible: true,
    },
    {
      render: (row: AccredStandartPrimary) => row.equipmentData ? row.equipmentData.name : '--',
      visible: data.type !== 2,
    },
    {
      render: (row: AccredStandartPrimary) => row.scale ? row.scale.name : '--',
      visible: data.type !== 2,
    },
    {
      render: (row: AccredStandartPrimary) => (
        <Stack direction='column' spacing={1} justifyContent='center'>
          {row.photo1_url && <Link href={row.photo1_url} target='_blank'>{t("download")}</Link>}
          {row.photo2_url && <Link href={row.photo2_url} target='_blank'>{t("download")}</Link>}
          {row.photo3_url && <Link href={row.photo3_url} target='_blank'>{t("download")}</Link>}
          {row.photo4_url && <Link href={row.photo4_url} target='_blank'>{t("download")}</Link>}
        </Stack>
      ),
      visible: true,
    },
  ];


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <ModalHeader
        title={isEdit ? t('testProgram.result.edit') : t('testProgram.result.add')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          {tenantType===2 && (
          <Grid item xs={8}>
            <FormControl fullWidth>
              <FormSelectInput
                loading={isSpecificsFetching}
                options={accredSpecifics?.items ?? []}
                control={control}
                name={`id_accred_specific`}
                //label={t('accred_specific')}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={
                  (option, value) => value.id === undefined
                    || String(value.id) === ""
                    || option.id === value.id}
                rules={{ required: true }}
                TextFieldProps={{
                  size: 'small',
                  label: t('testProgram.result.specific')
                }}
                onChange={(event, option) => {
                  setValue('id_accred_standart', undefined);
                  /*if (option){
                    setValue('id_accred_specific', option);
                  }
                  setValue('id_accred_standart', accredStandarts?.items[0]);*/
                }}
              />
            </FormControl>
          </Grid>)}
          {tenantType===1 && (
          <Grid item xs={8}>
            <FormControl fullWidth>
              <FormTextInput
                control={control}
                name="specific_name"
                label={t('testProgram.result.specific')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>)}
          {tenantType===2 && (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <FormSelectInput
                loading={isStandartsFetching}
                control={control}
                name={`id_accred_standart`}
                //label={t('accred_standart')}
                options={accredStandarts?.items ?? []}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                getOptionDisabled={(option) => option.type === 0}
                isOptionEqualToValue={
                  (option, value) => value.id === undefined
                    || String(value.id) === ""
                    || option.id === value.id}
                rules={{ required: true }}
                TextFieldProps={{
                  size: 'small',
                  label: t('testProgram.result.standard')
                }}
                onChange={(event, option) => {
                  setValue('formula', '');
                  if (option?.formula) {
                    setValue('formula', option.formula);
                  }
                  setValue('accuracy', 2.5);
                  if (option?.accuracy) {
                    setValue('accuracy', option.accuracy);
                  }
                  setValue('confidence_probability', 0);
                  if (option?.confidence_probability) {
                    setValue('confidence_probability', option.confidence_probability);
                  }
                  //setValue('standart_tech_param', option?.standart_tech_param);
                  console.log(option);
                }}
              />
            </FormControl>
          </Grid>)}
          {tenantType===1 && (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <FormTextInput
                control={control}
                name="standart_name"
                label={t('testProgram.result.standard')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>)}
          <Grid item xs={5}>
            <FormControl fullWidth>
              <FormTextInput
                control={control}
                name="standart_tech_param"
                label={t('testProgram.result.standartTechParam')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          {tenantType===2 && watchedAccredStandart 
            && (watchedAccredStandart.type === 0 || watchedAccredStandart.type === 1) && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormSelectInput
                control={control}
                name={`id_unit`}
                options={watchedAccredStandart?.accredStandartUnits ?? []}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                TextFieldProps={{
                  size: 'small',
                  label: t('unit')
                }}
              />
            </FormControl>
          </Grid>
          )}
          {tenantType===2 && watchedAccredStandart 
            && (watchedAccredStandart.type === 0 || watchedAccredStandart.type === 1) && (
          <Grid item xs={5}>
            <FormControl fullWidth>
              <FormTextInput
                control={control}
                name="formula"
                label={t('testProgram.result.formula')}
              />
            </FormControl>
          </Grid>
          )}
          {tenantType===2 && accredStandartPrimaries && watchedAccredStandart
            && accredStandartPrimaries.items 
            && accredStandartPrimaries.items.length>0 
            && (
          <Grid item xs={12}>
            <Attention 
              width="80%"
              description={t('testProgram.result.primaryListWarning')}
            />
            <UITable
              headers={primaryHeaders(watchedAccredStandart).filter((header) => header.visible)}
              columns={primaryColumns(watchedAccredStandart).filter((column) => column.visible)}
              data={accredStandartPrimaries.items}
              isLoading={isPrimariesFetching}
            />
            <Attention 
              width="80%"
              description={(<>
                {t('testProgram.result.linkToFixWarning')}
                <Link href={`/accreditation/standart/view/${watchedAccredStandart.id}`} target="_blank">{t('testProgram.result.linkToFix')}</Link>
              </>)}
            />
          </Grid>
          )}
          {tenantType===2 && watchedAccredStandart 
            && (watchedAccredStandart.type === 0 || watchedAccredStandart.type === 1) && (
          <>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormNumericInput
                control={control}
                name="confidence_probability"
                label={t('testProgram.result.confidenceProbability')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormNumericInput
                control={control}
                name="accuracy"
                label={t('testProgram.result.accuracy')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <FormSelectInput
                //loading={isSpecificsFetching}
                options={decisionArray.map((item) => ({ id: item.id, name: t(item.name) }))}
                control={control}
                name={`decision_rule`}
                //label={t('accred_specific')}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={
                  (option, value) => value.id === undefined
                    || String(value.id) === ""
                    || option.id === value.id}
                rules={{ required: true }}
                TextFieldProps={{
                  size: 'small',
                  label: t('testProgram.result.resultRule')
                }}
                onChange={(event, option) => {

                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormSelectInput
                //loading={isSpecificsFetching}
                options={binarityArray.map((item) => ({ id: item.id, name: t(item.name) }))}
                control={control}
                name={`binarity`}
                //label={t('accred_specific')}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={
                  (option, value) => value.id === undefined
                    || String(value.id) === ""
                    || option.id === value.id}
                rules={{ required: true }}
                TextFieldProps={{
                  size: 'small',
                  label: t('testProgram.result.binarity')
                }}
                onChange={(event, option) => {

                }}
              />
            </FormControl>
          </Grid>
          </>
      )}
          {!isEdit && connectedStandarts && connectedStandarts.items && connectedStandarts.items.length > 0 && (
            <Grid item xs={12}>
              <Attention 
                width="70%"
                description={t('testProgram.result.connectWarning')}
              />
              {/**<Typography variant="h6">{t('connected_standarts')}</Typography>*/}
              {connectedStandarts.items.map((standart, standartIndex) => (
                <>
                  <input
                    type="hidden"
                    value={standart.id_accred_standart_child}
                    {...register(`child_standart.${standartIndex}.id_accred_standart`)}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl>
                        <FormCheckboxInput
                          control={control}
                          name={`child_standart.${standartIndex}.check`}
                          label={`${standart.accredStandartChild.accredSpecific.name} ${standart.accredStandartChild.name}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl>
                        <FormTextInput
                          control={control}
                          name={`child_standart.${standartIndex}.standart_tech_param`}
                          label={t('testProgram.result.standartTechParam')}
                          //rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <FormSelectInput
                          control={control}
                          name={`child_standart.${standartIndex}.id_unit`}
                          options={standart.accredStandartChild.accredStandartUnits ?? []}
                          getOptionLabel={(option) => option.name || ""}
                          getOptionKey={(option) => option.id}
                          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                          TextFieldProps={{
                            size: 'small',
                            label: t('unit')
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>



                </>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isLoadingCreate || isLoadingUpdate}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
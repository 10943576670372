import { Staff } from 'entities/staff/model';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Laboratory } from 'entities/laboratories/model';
import { SelectObject } from 'app/utils/types';
import { AccredGroup, AccredSpecific } from 'entities/accreditation/model';
import { Equipment, Scales } from 'entities/equipment/model';





export const methodTypes = [
  {
    label: 'accred.standart.typeCalc',
    value: 1,
  },
  {
    label: 'accred.standart.typeText',
    value: 2,
  },
];

export const resultPrecisions = [
  {
    label: '10.0',
    value: 1,
  },
  {
    label: '5.0',
    value: 2,
  },
  {
    label: '1.0',
    value: 3,
  },
  {
    label: '0',
    value: 4,
  },
  {
    label: '0.5',
    value: 5,
  },
  {
    label: '0.1',
    value: 6,
  },
  {
    label: '0.01',
    value: 7,
  },
  {
    label: '0.001',
    value: 8,
  },
  {
    label: '0.0001',
    value: 9,
  },
  {
    label: '0.00001',
    value: 10,
  },
];

export const resultRules = [
  {
    label: 'accred.standart.view.rule1',
    value: 1,
  },
  {
    label: 'accred.standart.view.rule2',
    value: 2,
  },
  {
    label: 'accred.standart.view.rule3',
    value: 3,
  },
  {
    label: 'accred.standart.view.rule4',
    value: 4,
  },
  {
    label: 'accred.standart.view.rule5',
    value: 5,
  },
  {
    label: 'accred.standart.view.rule6',
    value: 6,
  },
  {
    label: 'accred.standart.view.rule7',
    value: 7,
  },
];



export interface FormValues {
  id?:number;
  name: string;
  unit: string | null;
  variable: string | null;
  id_equipment: Equipment | null;
  id_scale: Scales | null;
  photo1_file: File | null;
  photo2_file: File | null;
  photo3_file: File | null;
  photo4_file: File | null;
}


export const initialState = {
  name: '',
  unit: '',
  variable: '',
  id_equipment: null,
  id_scale: null,
  photo1_file: null,
  photo2_file: null,
  photo3_file: null,
  photo4_file: null,

}
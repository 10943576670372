import notify from "./notify";
import { t } from "i18next";

export async function downloadFile(
  endpointUrl: string,
  fileName: string = 'file.xlsx',
  additionalHeaders: Record<string, string> = {}
): Promise<void> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpointUrl}`, {
      method: 'GET',
      credentials: 'include', // Required to send cookies with the request
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        ...additionalHeaders,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Use the provided file name
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    console.error('There was an error downloading the file:', error);
    notify(t('downloadError'), 'error');
  }
}

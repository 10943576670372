import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  FormControl,
} from '@mui/material';
import { FormValues } from 'pages/equipment/equipment-modal/initialState';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { useGetLaboratoryByIdQuery } from 'entities/laboratories/api';
import { useGetEnvironmentConditionsQuery } from 'entities/dicts/api';

interface Props {
  control: Control<FormValues, any>;
  fields: FieldArrayWithId<FormValues, 'equipmentEnvironmentConditions'>[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, 'equipmentEnvironmentConditions'>;
  //laboratoryId: number;
}

const EnvironmentStep: React.FC<Props> = ({ control, fields, remove, append }) => {
  const { t } = useTranslation();
  const { getValues, register } = useFormContext<FormValues>();

  const [labId] = useState(() => getValues('laboratory')?.id);
  //console.log(labId);

  const { data: laboratoryData } = useGetLaboratoryByIdQuery(labId ? String(labId): '');
  
  const validateCondition = (value: any, condition: any, field: string) => {
    if (field === 'value_from') {
      if (
        condition.checked && !value && !condition.value_to
      ) {
        return t('required_fields');
      }
    }
    if (field === 'value_to') {
      if (
        condition.checked && !value && !condition.value_from
      ) {
        return t('required_fields');
      }
    }
    return true;
  }

  return (
    <Box>
      <Typography mb={'2rem'} variant='h4'>
        {t('operatingConditions')}
      </Typography>
      <Stack
        direction={'row'}
        width={'100%'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        gap={'1.5rem'}
      >
        { (!laboratoryData || laboratoryData?.environmentConditions.length == 0) ? 
          (<Typography sx={{ color:'red' }} mb={'2rem'} variant='h5'>{t('chooseLaboratoryFirst')}</Typography>) : '' }
        { laboratoryData ? laboratoryData?.environmentConditions.map((item, index) => ( //fields.map((item, index) => (
          
          <Stack
            sx={{ flex: '0 0 70%' }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            key={item.id}
          >
            {/**<FormControl>*/}
              <input type="hidden" value={item.id} {...register(`equipmentEnvironmentConditions.${index}.id_environment_condition`)} />
              
            {/**</FormControl>*/}
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={`equipmentEnvironmentConditions.${index}.checked`}
                label={t('envCondition')+' '+(item ? item.name:"")}
              />
            </FormControl>
            <FormControl>
              <FormTextInput
                control={control}
                type='number'
                name={`equipmentEnvironmentConditions.${index}.value_from`}
                label={t('from')}
                variant='standard'
                rules={{
                  //required: true,
                  validate: (value, values) => validateCondition(value, values.equipmentEnvironmentConditions[index], 'value_from'),
                }}
              />
            </FormControl>
            <FormControl>
              <FormTextInput
                control={control}
                type='number'
                name={`equipmentEnvironmentConditions.${index}.value_to`}
                label={t('to')}
                variant='standard'
                rules={{
                  //required: true,
                  validate: (value, values) => validateCondition(value, values.equipmentEnvironmentConditions[index], 'value_to'),
                }}
              />
            </FormControl>
          </Stack>
        )):''}
      </Stack>
    </Box>
  );
};

export default EnvironmentStep;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { CreateEnvRecordBody, Laboratory } from 'entities/laboratories/model';
import { BaseModalRef } from 'app/utils/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import dayjs from 'dayjs';
import {
  useUpdateEquipmentShippingMutation,
  useSignEquipmentShippingRetByIdMutation,
} from 'entities/equipment/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { handleResponse } from 'app/utils';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import { CreateEquipmentServReg, CreateEquipmentShipping, Equipment, EquipmentServReg, EquipmentServiceWork, EquipmentShipping } from 'entities/equipment/model';
import moment from 'moment';
import { ReturnModalRef } from './types';
import FormDatePicker from 'shared/inputs/form/form-date-picker';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { SelectObject } from 'app/utils/types';
import { damageStatuses, workingCapacities } from './dictionaries';

interface FormValues {
  id?: number;
  date_return: string;
  working_capacity: SelectObject;
  damaged: SelectObject;
  note_return: string;
}

interface Props {
  equipment: Equipment;
}

const ReturnModal: React.ForwardRefRenderFunction<ReturnModalRef, Props> = (
  { equipment },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const { control, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      date_return: dayjs().format('YYYY-MM-DD'),
      working_capacity: {
        label: t(workingCapacities[0].label),
        value: workingCapacities[0].value,
      },
      damaged: {
        label: t(damageStatuses[0].label),
        value: damageStatuses[0].value,
      },
      note_return: '',
    },
  });

  const [updateReturn] = useUpdateEquipmentShippingMutation();
  const [signReturn] = useSignEquipmentShippingRetByIdMutation();

  useImperativeHandle(ref, () => ({
    open: onOpenHandler,
    close: onCloseHandler,
  }));

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      date_return: dayjs().format('YYYY-MM-DD'),
      working_capacity: {
        label: t(workingCapacities[0].label),
        value: workingCapacities[0].value,
      },
      damaged: {
        label: t(damageStatuses[0].label),
        value: damageStatuses[0].value,
      },
      note_return: '',
    });
  };

  const onOpenHandler = (data: EquipmentShipping) => {
    reset({
      id: data.id,
      date_return: dayjs().format('YYYY-MM-DD'),
      working_capacity: {
        label: t(workingCapacities[0].label),
        value: workingCapacities[0].value,
      },
      damaged: {
        label: t(damageStatuses[0].label),
        value: damageStatuses[0].value,
      },
      note_return: '',
    });
    setIsEdit(true);
    setOpen(true);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setIsLoading(true);
      const body: CreateEquipmentShipping = {
        id_equipment: equipment.id,
        date_return: moment(data.date_return).format('YYYY-MM-DD'),
        working_capacity: Number(data.working_capacity.value),
        damaged: Number(data.damaged.value),
        note_return: data.note_return,
      };

      /*const res = await handleResponse(await create(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json.replace(/\\\//g, "")));
      await handleResponse(
        await signEnvRecord({ id: res.id, labId: room.id, signature, note: data.note })
      );*/

      const res = await handleResponse(await updateReturn({ ...body, id: data.id as number }));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json_shipping.replace(/\\\//g, "")));
      await handleResponse(
        await signReturn({ id: res.id, id_equipment: equipment.id, signature, note: data.note_return })
      );

    } finally {
      onCloseHandler();
      notify('', 'success');
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose = {onCloseHandler}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '40vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader 
        title={isEdit ? t('edit') : t('add')} 
        handleClose={onCloseHandler} 
      />
      <DialogContent style={{height:'400px'}} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <FormControl>
              <FormDatePicker
                control={control}
                name={`date_return`}
                dateFormat='dd-MM-yyyy'
                TextFieldProps={{ label: t('eqShipping.dateReturn'), variant: 'standard' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <FormControl>
              <FormSelectInput
                options={
                  workingCapacities.map((item) => ({
                    label: t(item.label),
                    value: item.value,
                  }))
                }
                control={control}
                name={`working_capacity`}
                getOptionLabel={(option) => option.label || "" }
                isOptionEqualToValue={(option,value) => value.value === undefined || String(value.value) === "" || option.value === value.value}
                TextFieldProps={{
                  variant: 'standard',
                  label: t('eqShipping.workingCapacity'),
                  size: 'small',
                }}
                rules={{
                  //required: true,
                  //validate: (value, values) => validateWorks(value, values.equipmentServiceWorks[index], 'unit'),
                }}
                sx={{ flex: '0.5', margin: '0' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <FormControl>
              <FormSelectInput
                options={
                  damageStatuses.map((item) => ({
                    label: t(item.label),
                    value: item.value,
                  }))
                }
                control={control}
                name={`damaged`}
                getOptionLabel={(option) => option.label || "" }
                isOptionEqualToValue={(option,value) => value.value === undefined || String(value.value) === "" || option.value === value.value}
                TextFieldProps={{
                  variant: 'standard',
                  label: t('eqShipping.damagedStatus'),
                  size: 'small',
                }}
                rules={{
                  //required: true,
                  //validate: (value, values) => validateWorks(value, values.equipmentServiceWorks[index], 'unit'),
                }}
                sx={{ flex: '0.5', margin: '0' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 2 }}>
          <Grid item xs={10}>
            <FormControl>
                <FormLabel>{`${t('notion')} (${t('optional')})`}</FormLabel>
                <FormTextInput
                  control={control}
                  name='note_return'
                  multiline
                  sx={{
                    '& .MuiInputBase-input': {
                      minHeight: '120px',
                    },
                  }}
                />
            </FormControl>
          </Grid>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onCloseHandler}>
          {t('close')}
        </Button>
        <Button color='success' onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ReturnModal);

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { CreateEnvRecordBody, Laboratory } from 'entities/laboratories/model';
import { BaseModalRef } from 'app/utils/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import dayjs from 'dayjs';
import {
  useCreateEquipmentServRegMutation,
  useCreateEquipmentShippingMutation,
  useGetEquipmentTodayServiceWorkQuery,
  useSignEquipmentShippingShipByIdMutation,
  useUpdateEquipmentServRegMutation,
} from 'entities/equipment/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { handleResponse } from 'app/utils';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import { CreateEquipmentServReg, CreateEquipmentShipping, Equipment, EquipmentServReg, EquipmentServiceWork } from 'entities/equipment/model';
import moment from 'moment';
import FormDatePicker from 'shared/inputs/form/form-date-picker';

interface FormValues {
  id?: number;
  date_shipping: string;
  place_purpose: string;
  note_shipping: string;
}

interface Props {
  equipment: Equipment;
}

const ShippingModal: React.ForwardRefRenderFunction<BaseModalRef, Props> = (
  { equipment },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { control, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      date_shipping: dayjs().format('YYYY-MM-DD'),
      place_purpose: '',
      note_shipping: '',
    },
  });

  const [signShipping] = useSignEquipmentShippingShipByIdMutation();
  const [createShipping] = useCreateEquipmentShippingMutation();

  useImperativeHandle(ref, () => ({
    open: onOpenHandler,
    close: onCloseHandler,
  }));

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      date_shipping: dayjs().format('YYYY-MM-DD'),
      place_purpose: '',
      note_shipping: '',
    });
  };

  const onOpenHandler = () => {
    reset({
      date_shipping: dayjs().format('YYYY-MM-DD'),
      place_purpose: '',
      note_shipping: '',
    });
    setIsEdit(false);
    setOpen(true);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setIsLoading(true);
      const body: CreateEquipmentShipping = {
        id_equipment: equipment.id,
        date_shipping: moment(data.date_shipping).format('YYYY-MM-DD'),
        place_purpose: data.place_purpose,
        note_shipping: data.note_shipping,
        date_return: null,
        check_after_return: null,
        id_staff: null,
        id_staff_return: null,
        working_capacity: null,
        damaged: null,
        note_return: null,
      };

      const res = await handleResponse(await createShipping(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json_shipping.replace(/\\\//g, "")));
      await handleResponse(
        await signShipping({ id: res.id, id_equipment: equipment.id, signature, note: data.note_shipping })
      );

    } finally {
      onCloseHandler();
      notify('', 'success');
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose = {onCloseHandler}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '30vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader 
        title={isEdit ? t('edit') : t('add')} 
        handleClose={onCloseHandler} 
      />
      <DialogContent style={{height:'400px'}} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8}>
            <FormControl>
              <FormDatePicker
                control={control}
                name={`date_shipping`}
                dateFormat='dd-MM-yyyy'
                TextFieldProps={{ label: t('eqShipping.dateShipping'), variant: 'standard' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8}>
            <FormControl>
              <FormTextInput
                control={control}
                name={`place_purpose`}
                label={t('eqShipping.placePurpose')}
                variant='standard'
                rules={{
                  //required: true,
                  //validate: (value, values) => validate(value, values.equipmentServiceRegWorks[index]),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 2 }}>
          <Grid item xs={10}>
            <FormControl>
                <FormLabel>{`${t('notion')} (${t('optional')})`}</FormLabel>
                <FormTextInput
                  control={control}
                  name='note_shipping'
                  multiline
                  sx={{
                    '& .MuiInputBase-input': {
                      minHeight: '120px',
                    },
                  }}
                />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onCloseHandler}>
          {t('close')}
        </Button>
        <Button color='success' onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ShippingModal);

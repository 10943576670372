import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import { DefaultResponse, RequestWithPagination, ResponseWithPagination } from 'app/utils/types';
import {
  CreateEquiment,
  CreateEquipmentShipping,
  CreateEquipmentVerification,
  CreateEquipmentServReg,
  Equipment,
  EquipmentOperatingCondition,
  EquipmentServReg,
  EquipmentShipping,
  EquipmentVerification,
  EquipmentServiceWork,
  SignEqRecordBody,
  EquipmentGraphMethod,
  Scales,
} from '../model';
//import { SignEnvRecordBody } from 'entities/laboratories/model';

export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'Equipment', 
    'EquipmentVerification', 
    'EquipmentServReg', 
    'EquipmentShipping',
    'EquipmentTodayServiceWork',
    'Scale'
  ],
  endpoints: (builder) => ({
    getEquipment: builder.query<ResponseWithPagination<Equipment>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/equipments',
        params,
      }),
      providesTags: ['Equipment'],
    }),
    createEquipment: builder.mutation<DefaultResponse, CreateEquiment>({
      query: (body) => ({
        method: 'POST',
        url: '/equipments',
        body,
      }),
      invalidatesTags: ['Equipment'],
    }),
    createEquipmentVerification: builder.mutation<
      EquipmentVerification,//DefaultResponse,
      CreateEquipmentVerification
    >({
      query: (body) => ({
        method: 'POST',
        url: `/equipments/${body.id_equipment}/verifications`,
        body,
      }),
      invalidatesTags: ['EquipmentVerification'],
    }),
    updateEquipment: builder.mutation<DefaultResponse, CreateEquiment & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/equipments/${id}`,
        body,
      }),
      invalidatesTags: ['Equipment'],
    }),
    updateEquipmentVerification: builder.mutation<DefaultResponse, CreateEquipmentVerification & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/equipments/${body.id_equipment}/verifications/${id}`,
        body,
      }),
      invalidatesTags: ['EquipmentVerification'],
    }),
    deleteEquipmentVerification: builder.mutation({
      query: ({id, id_equipment}) => ({
        method: 'DELETE',
        url: `/equipments/${id_equipment}/verifications/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['EquipmentVerification'],
    }),
    getEquipmentId: builder.query<Equipment, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/equipments/${id}`,
        params: {
          'per-page': 0,
        },
      }),
    }),
    getEquipmentVerification: builder.query<
      ResponseWithPagination<EquipmentVerification>,
      RequestWithPagination<{ id_equipment: number }>
    >({
      query: ({ id_equipment, ...params }) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/verifications`,
        params,
      }),
      providesTags: ['EquipmentVerification'],
    }),
    signEquipmentVerificationById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/verifications/${id}/sign`,
        body,
      }),
      invalidatesTags: ['EquipmentVerification'],
    }),
    cancelEquipmentVerificationById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/verifications/${id}/cancel`,
        body,
      }),
      invalidatesTags: ['EquipmentVerification'],
    }),

    // equipment service registration
    getEquipmentServReg: builder.query<
      ResponseWithPagination<EquipmentServReg>, 
      RequestWithPagination<{ id_equipment: number }>
    >({
      query: ({ id_equipment, ...params }) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/service-works`,
        params,
      }),
      providesTags: ['EquipmentServReg'],
    }),
    createEquipmentServReg: builder.mutation<
      EquipmentServReg,//DefaultResponse,
      CreateEquipmentServReg
    >({
      query: (body) => ({
        method: 'POST',
        url: `/equipments/${body.id_equipment}/service-works`,
        body,
      }),
      invalidatesTags: ['EquipmentServReg', 'EquipmentTodayServiceWork'],
    }),
    updateEquipmentServReg: builder.mutation<DefaultResponse, CreateEquipmentServReg & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/equipments/${body.id_equipment}/service-works/${id}`,
        body,
      }),
      invalidatesTags: ['EquipmentServReg', 'EquipmentTodayServiceWork'],
    }),
    deleteEquipmentServReg: builder.mutation({
      query: ({id, id_equipment}) => ({
        method: 'DELETE',
        url: `/equipments/${id_equipment}/service-works/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['EquipmentServReg', 'EquipmentTodayServiceWork'],
    }),
    signEquipmentServRegById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/service-works/${id}/sign`,
        body,
      }),
      invalidatesTags: ['EquipmentServReg', 'EquipmentTodayServiceWork'],
    }),
    cancelEquipmentServRegById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/service-works/${id}/cancel`,
        body,
      }),
      invalidatesTags: ['EquipmentServReg', 'EquipmentTodayServiceWork'],
    }),

    // equipment shipping
    getEquipmentShipping: builder.query<
      ResponseWithPagination<EquipmentShipping>,
      RequestWithPagination<{ id_equipment: number }>
    >({
      query: ({ id_equipment, ...params }) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/shippings`,
        params,
      }),
      providesTags: ['EquipmentShipping'],
    }),
    createEquipmentShipping: builder.mutation<
      EquipmentShipping,//DefaultResponse,
      CreateEquipmentShipping
    >({
      query: (body) => ({
        method: 'POST',
        url: `/equipments/${body.id_equipment}/shippings`,
        body,
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    updateEquipmentShipping: builder.mutation<
      EquipmentShipping,//DefaultResponse, 
      CreateEquipmentShipping & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/equipments/${body.id_equipment}/shippings/${id}`,
        body,
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    deleteEquipmentShipping: builder.mutation({
      query: ({id, id_equipment}) => ({
        method: 'DELETE',
        url: `/equipments/${id_equipment}/shippings/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    signEquipmentShippingShipById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/shippings/${id}/sign-take-out`,
        body,
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    signEquipmentShippingRetById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/shippings/${id}/sign-return`,
        body,
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    cancelEquipmentShippingById: builder.mutation<{}, SignEqRecordBody>({
      query: ({ id_equipment, id, ...body }) => ({
        method: 'POST',
        url: `/equipments/${id_equipment}/shippings/${id}/cancel`,
        body,
      }),
      invalidatesTags: ['EquipmentShipping'],
    }),
    

    // equipment operating condition
    getEquipmentOperCond: builder.query<
      EquipmentOperatingCondition,
      number
    >({
      query: (id_equipment: number) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/operating-conditions`,
        /*params: {
          'per-page': 0,
        },*/
      }),
    }),
    deleteEquipment: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/equipments/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Equipment'],
    }),
    // equipment service work, current for today
    getEquipmentTodayServiceWork: builder.query<
      EquipmentServiceWork[],
      {id_equipment: string, date_service?: string}
    >({
      query: ({id_equipment, date_service}) => {
        if (date_service) {
          return {
            method: 'GET',
            url: `/equipments/${id_equipment}/today-service-works`,
            params: {
              date_service,
            },
          };
        }else {
          return {
            method: 'GET',
            url: `/equipments/${id_equipment}/today-service-works`,
          };
        }
        /*method: 'GET',
        url: `/equipments/${id_equipment}/today-service-works`,*/
      },
      providesTags: ['EquipmentTodayServiceWork'],
    }),

    // graphs
    getEquipmentGraphMethods: builder.query<
      EquipmentGraphMethod[],
      number
    >({
      query: (id_equipment) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/graphs`,
        //params,
      }),
      //providesTags: ['EquipmentVerification'],
    }),

    // scales
    getEquipmentScale: builder.query<
      ResponseWithPagination<Scales>,
      RequestWithPagination<{ id_equipment: string }>
    >({
      query: ({ id_equipment, ...params }) => ({
        method: 'GET',
        url: `/equipments/${id_equipment}/scales`,
        params,
      }),
      providesTags: ['Scale'],
    }),

    

  }),
});

export const {
  useGetEquipmentQuery,
  useLazyGetEquipmentQuery,
  useGetEquipmentIdQuery,
  useGetEquipmentVerificationQuery,
  useGetEquipmentServRegQuery,
  useGetEquipmentShippingQuery,
  useGetEquipmentOperCondQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
  useCreateEquipmentVerificationMutation,
  useUpdateEquipmentVerificationMutation,
  useDeleteEquipmentVerificationMutation,
  useSignEquipmentVerificationByIdMutation,
  useCancelEquipmentVerificationByIdMutation,
  useCreateEquipmentServRegMutation,
  useUpdateEquipmentServRegMutation,
  useDeleteEquipmentServRegMutation,
  useSignEquipmentServRegByIdMutation,
  useCancelEquipmentServRegByIdMutation,
  useCreateEquipmentShippingMutation,
  useUpdateEquipmentShippingMutation,
  useDeleteEquipmentShippingMutation,
  useSignEquipmentShippingShipByIdMutation,
  useSignEquipmentShippingRetByIdMutation,
  useGetEquipmentTodayServiceWorkQuery,
  useCancelEquipmentShippingByIdMutation,
  useGetEquipmentGraphMethodsQuery,
  useGetEquipmentScaleQuery,
} = equipmentApi;

import { baseApi } from '../base-api';
import { TestProgram } from '../../model';
import { ResponseWithPagination, DefaultResponse } from 'app/utils/types';
import { createFormData } from 'app/utils';
import { TestProgramBody } from '../../model/request-body';

const testProgramApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test programs by tenant except choosen
    getTestProgramsByTenant: builder.query<
      { id: number; name: string }[],
      { id_tenant: number; id_test_program_cert: number }
    >({
      query: ({ id_tenant, id_test_program_cert }) => ({
        method: 'GET',
        url: `/tenants/${id_tenant}/test-programs?id_test_program_cert=${id_test_program_cert}`,
      }),
      providesTags: ['TestProgramByTenant'],
    }),

    // Test program
    getTestPrograms: builder.query<ResponseWithPagination<TestProgram>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/test-programs',
        params
      }),
      providesTags: ['TestProgram'],
    }),
    getTestProgramById: builder.query<TestProgram, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/test-programs/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgram'],
    }),
    createTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    updateTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id}`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    deleteTestProgram: builder.mutation({
      query: ({ id, params }) => ({
        method: 'DELETE',
        url: `/test-programs/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['TestProgram'],
    }),
    copyTestProgram: builder.mutation<
      TestProgram,
      { id: number }
    >({
      query: ({id}) => ({
        method: 'POST',
        url: `/test-programs/${id}/copy`,
        //body,
      }),
      invalidatesTags: ['TestProgram'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramsByTenantQuery,
  useGetTestProgramsQuery,
  useGetTestProgramByIdQuery,
  useCreateTestProgramMutation,
  useUpdateTestProgramMutation,
  useDeleteTestProgramMutation,
  useCopyTestProgramMutation
} = testProgramApi;
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import ModalHeader from 'shared/ui/modal-header';
import { BaseTestProgramFeatureList, TestProgram, TestProgramFeature, TestProgramResults } from 'entities/test-program/model';
import {
  useGetStandartsQuery,
  useGetSpecificsQuery,
  useGetStandartConnectedQuery
} from 'entities/accreditation/api';
import {
  useCreateTestProgramResultMutation,
  useUpdateTestProgramResultMutation,
  useGetTestProgramFeaturesQuery,
  useGetTestProgramFeatureListsQuery,
  useCombineFeatureListsMutation,
} from 'entities/test-program/api';
import notify from 'app/utils/notify';
import { AccredSpecific, AccredStandart } from 'entities/accreditation/model';
import { Units } from 'entities/dicts/model';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';

export interface SizeCombineModalRef {
  open: () => void;
  close: () => void;
}

interface FormValues {
  testProgramSize: BaseTestProgramFeatureList[][];
}

const initialState: FormValues = {
  testProgramSize: [[]],
};

interface Props {
  testProgramResult: TestProgramResults;
}

export const SizeCombineModal = forwardRef<SizeCombineModalRef, Props>(({ testProgramResult }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset, watch, setValue, register } = useForm<FormValues>({
    defaultValues: initialState,
  });
  const [combineFeatureLists, { isLoading: isLoading }] = useCombineFeatureListsMutation();





  const { data: testProgramFeatures, isFetching: isFeaturesFetching } = useGetTestProgramFeaturesQuery(
    { id_test_program: testProgramResult.id_test_program ?? 0 },
    { skip: !testProgramResult }
  );


  const { fields: sizeFields, append: appendSize, remove: removeSize } = useFieldArray({
    control,
    name: 'testProgramSize',
  });

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = () => {
    reset(initialState);
    if (testProgramFeatures?.items) {
      // Initialize testProgramSize with empty arrays for each feature
      const initialSizes = testProgramFeatures.items.map(() => []);
      setValue('testProgramSize', initialSizes);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    //console.log(data.testProgramSize);
    const result = data.testProgramSize.map((sizes, index) => {
      return sizes.map((size) => size.id);
    });
    console.log(result);
    try {
      await combineFeatureLists({
        id_test_program_feature_list: result,
        id_test_program: testProgramResult.id_test_program, 
        id_test_program_results: testProgramResult.id 
      }).unwrap();
      notify(t('addSuccess'), 'success');
      handleClose();
    } catch (error) {
      notify(t('addError'), 'error');
    }
    /*try {
      if (isEdit && data.id) {
        await updateTestProgramResult({ ...data, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgramResult(data).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }*/
  };

  useEffect(() => {
    if (testProgramFeatures?.items) {
      // Initialize testProgramSize with empty arrays for each feature
      const initialSizes = testProgramFeatures.items.map(() => []);
      setValue('testProgramSize', initialSizes);
    }
  }, [testProgramFeatures, setValue]);

  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <ModalHeader
        title={t('testProgram.size.combine')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {testProgramFeatures?.items.map((feature, featureIndex) => (
              <Box key={feature.id} mb={2}>
                <FormControl fullWidth>
                  <FormSelectInput
                    loading={isFeaturesFetching}
                    multiple
                    options={feature?.lists ?? []}
                    control={control}
                    name={`testProgramSize.${featureIndex}`}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionKey={(option) => option.id}
                    //isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id ||
                      (Array.isArray(value) && value.includes(option?.id))
                    }
                    disableCloseOnSelect
                    TextFieldProps={{
                      //variant: 'standard',
                      //placeholder: t('location'),
                      size: 'small',
                      label: feature.name
                    }}
                    rules={{
                      //required: true,
                    }}
                    onChange={(event, values) => {
                      const newSizeArray = [...watch('testProgramSize')];
                      newSizeArray[featureIndex] = values;
                      setValue('testProgramSize', newSizeArray);
                      console.log(newSizeArray);
                    }}
                  />
                </FormControl>
              </Box>
            ))}
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isLoading}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
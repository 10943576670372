import { Staff } from 'entities/staff/model';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Laboratory } from 'entities/laboratories/model';
import { SelectObject } from 'app/utils/types';
import { AccredGroup, AccredSpecific } from 'entities/accreditation/model';

/*export type CharProps = {
  scale: string,
  voltage: string,
  measurement:string,
  rangeOfUnit: 
    {
      from: string,
      to: string,
      accuracy: string,
    }[],
}[]

export type TechProps = {  
  name: string;
  id_unit: string;
  accuracy: string;  
}[]

export interface GeneralInfoProps {
  title:string,
  location:string,
  note:string,
  creator:string,
  zavodNum:string,
  inventoryNum:string,
  dateOfStartfUsage:string,
  metrolTrace:string
}


export interface JobConditionsProps {  
  id_environment_condition: string;
  value_from: string;
  value_to: string;
}*/



export const methodTypes = [
  {
    label: 'accred.standart.typeCalc',
    value: 1,
  },
  {
    label: 'accred.standart.typeText',
    value: 2,
  },
];

export const resultPrecisions = [
  {
    label: '10.0',
    value: 1,
  },
  {
    label: '5.0',
    value: 2,
  },
  {
    label: '1.0',
    value: 3,
  },
  {
    label: '0',
    value: 4,
  },
  {
    label: '0.5',
    value: 5,
  },
  {
    label: '0.1',
    value: 6,
  },
  {
    label: '0.01',
    value: 7,
  },
  {
    label: '0.001',
    value: 8,
  },
  {
    label: '0.0001',
    value: 9,
  },
  {
    label: '0.00001',
    value: 10,
  },
];

export const resultRules = [
  {
    label: 'accred.standart.view.rule1',
    value: 1,
  },
  {
    label: 'accred.standart.view.rule2',
    value: 2,
  },
  {
    label: 'accred.standart.view.rule3',
    value: 3,
  },
  {
    label: 'accred.standart.view.rule4',
    value: 4,
  },
  {
    label: 'accred.standart.view.rule5',
    value: 5,
  },
  {
    label: 'accred.standart.view.rule6',
    value: 6,
  },
  {
    label: 'accred.standart.view.rule7',
    value: 7,
  },
];



export interface FormValues {
  id?:number;
  id_accred_group: AccredGroup | null;
  id_accred_specific: AccredSpecific | null;
  name: string;
  accuracy: number | null;
  id_laboratory: Laboratory | null;
  result_precision: SelectObject;
  measure_count: number | null;
  result_rule: SelectObject;
  conditioning_time: number | null;
  units: Units[];
  formula: string | null;
  confidence_probability: number | null;
  type: SelectObject;
  preliminary_test: boolean;
  parallel_test: boolean;
  repeat_limit: boolean;
  standard_deviation: boolean;

}


export const initialState = {
  id_accred_group: null,
  id_accred_specific: null,
  name: '',
  accuracy: null,
  id_laboratory: null,
  result_precision: {label: '', value: ''},
  measure_count: null,
  result_rule: {label: '', value: ''},
  conditioning_time: null,
  units: [],
  formula: '',
  confidence_probability: null,
  type: {label: '', value: ''},
  preliminary_test: false,
  parallel_test: false,
  repeat_limit: false,
  standard_deviation: false,
  
}
import { baseApi } from '../base-api';
import { TestProgramFeatureList } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramFeatureListBody } from '../../model/request-body';

const testProgramFeatureListApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Feature Lists
    getTestProgramFeatureLists: builder.query<
      ResponseWithPagination<TestProgramFeatureList>,
      RequestWithPagination<{ id_test_program: number; id_test_program_feature: number }>
    >({
      query: ({ id_test_program, id_test_program_feature, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/features/${id_test_program_feature}/lists`,
        params,
      }),
      providesTags: ['TestProgramFeatureList'],
    }),
    createTestProgramFeatureList: builder.mutation<
      DefaultResponse,
      TestProgramFeatureListBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/features/${body.id_test_program_feature}/lists`,
        body,
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),
    updateTestProgramFeatureList: builder.mutation<DefaultResponse, TestProgramFeatureListBody & { id: number; id_test_program: number }>({
      query: ({ id, id_test_program, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/features/${body.id_test_program_feature}/lists/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),
    deleteTestProgramFeatureList: builder.mutation({
      query: ({id, id_test_program, id_test_program_feature}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/features/${id_test_program_feature}/lists/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramFeatureList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramFeatureListsQuery,
  useCreateTestProgramFeatureListMutation,
  useUpdateTestProgramFeatureListMutation,
  useDeleteTestProgramFeatureListMutation,
} = testProgramFeatureListApi;
import { baseApi } from '../base-api';
import { TestProgramConnect } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramConnectBody } from '../../model/request-body';

const testProgramConnectApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    //Connected test programs
    getTestProgramConnect: builder.query<
      ResponseWithPagination<TestProgramConnect>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/connects`,
        params,
      }),
      providesTags: ['TestProgramConnect'],
    }),
    createTestProgramConnect: builder.mutation<
      DefaultResponse,
      TestProgramConnectBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/connects`,
        body,
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),
    deleteTestProgramConnect: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/connects/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),
    confirmTestProgramConnect: builder.mutation<
      DefaultResponse<TestProgramConnect>,
      { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'POST',
        url: `/test-program-connects/${id}/confirm`,
        body,
      }),
      invalidatesTags: ['TestProgramConnect', 'TestProgramByTenant'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramConnectQuery,
  useCreateTestProgramConnectMutation,
  useDeleteTestProgramConnectMutation,
  useConfirmTestProgramConnectMutation,
} = testProgramConnectApi;
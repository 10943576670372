import { baseApi } from '../base-api';
import { TestProgramSize } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { 
  TestProgramSizeBody, 
  TestProgramSizeCombine, 
  TestProgramSizeUploadExcel 
} from '../../model/request-body';
import { createFormData } from 'app/utils';

const testProgramSizeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Size
    getTestProgramSizes: builder.query<
      ResponseWithPagination<TestProgramSize>,
      RequestWithPagination<{ id_test_program: number; id_test_program_results: number }>
    >({
      query: ({ id_test_program, id_test_program_results, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes`,
        params,
      }),
      providesTags: ['TestProgramSize'],
    }),
    getTestProgramSizeById: builder.query<TestProgramSize, {id: string, id_test_program: string, id_test_program_results: string}>({
      query: ({ id, id_test_program, id_test_program_results }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramSize'],
    }),
    createTestProgramSize: builder.mutation<
      DefaultResponse,
      TestProgramSizeBody & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    updateTestProgramSize: builder.mutation<
      DefaultResponse,
      TestProgramSizeBody & { id: number; id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id, id_test_program, id_test_program_results, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    deleteTestProgramSize: builder.mutation({
      query: ({id, id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/sizes/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    combineFeatureLists: builder.mutation<
      DefaultResponse,
      TestProgramSizeCombine & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/combine-variant`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    clearCombinedSizes: builder.mutation({
      query: ({id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/clear-combine-variant`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    uploadTestProgramSizeExcel: builder.mutation<
      DefaultResponse,
      TestProgramSizeUploadExcel & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/upload-excel-template`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
    combineFromParent: builder.mutation<
      DefaultResponse,
      { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/get-combine-from-parent`,
        body,
      }),
      invalidatesTags: ['TestProgramSize'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramSizesQuery,
  useGetTestProgramSizeByIdQuery,
  useCreateTestProgramSizeMutation,
  useUpdateTestProgramSizeMutation,
  useDeleteTestProgramSizeMutation,
  useCombineFeatureListsMutation,
  useClearCombinedSizesMutation,
  useUploadTestProgramSizeExcelMutation,
  useCombineFromParentMutation,
} = testProgramSizeApi;
import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'app/utils/api';

export const baseApi = createApi({
  reducerPath: 'certificationApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'CertRequest',
    /*'TestProgramByTenant',
    'TestProgram',
    'TestProgramFeature',
    'TestProgramFeatureList',
    'TestProgramConnect',
    'TestProgramResult',
    'TestProgramResultPrimary',
    'TestProgramPrimary',
    'TestProgramPrimaryPoint',
    'TestProgramSize',
    'TestProgramMaterialOut',
    'StorageCondition',
    'TestProgramAltPoint',
    'TestProgramEquipment',
    'TestProgramHs',*/
  ],
  endpoints: () => ({}),
});
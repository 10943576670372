import { Box, FormControl, Stack, TextField, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react';
import UIActionButton from 'shared/ui/ui-action-button';
import './index.css';
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayProps,
  FieldArrayWithId,
  UseFieldArrayInsert,
  Control,
  useFormContext,
} from 'react-hook-form';
import { FormValues } from '../initialState';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Equipment } from 'entities/equipment/model';
import { useGetUnitsQuery } from 'entities/dicts/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';

interface ScalePointStep {
  fields: FieldArrayWithId<FormValues, 'scalePoints'>[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, 'scalePoints'>;
  insert: UseFieldArrayInsert<FormValues, 'scalePoints'>;
  control: Control<FormValues, any>;
  equipment: Equipment;
}

/*const voltageOptions = [
  {
    label: 'V',
    value: '1',
  },
  {
    label: 'mV',
    value: '1',
  },
];*/

const ScalePointStep: React.FC<ScalePointStep> = ({
  fields,
  remove,
  append,
  insert,
  control,
  equipment
}) => {
  const {t} = useTranslation();
  const {data: unitsData} = useGetUnitsQuery({});
  //const {data: scales} = useGetSca({});
  const unitList = unitsData?.items.map((el) => ({
    value: el.id,
    label: el.name,
  }));
  //console.log(equipment.scales);

  const addScalePoint = () => {
    append({
      id_scale:null,
      deviation:null,
      expanded_uncertainty:null,
      point:null
    });
  };

  const deleteScalePoint = (index: number) => {
    remove(index);
  };

  /*const addRangeOfUnitHandle = (charIndex: number) => {
    let selectedRange = fields[charIndex].scaleMeaErrors;
    let selectedChar = fields[charIndex];

    insert(charIndex, {
      ...selectedChar,
      scaleMeaErrors: [
        ...selectedRange,
        {
          range_from: null,
          range_to: null,
          coef_a: null,
          coef_b: null
        },
      ],
    });

    remove(charIndex + 1);
  };
  const addCharHandle = () => {
    append({
      accuracy: null,
      id_unit: null,
      name: '',
      scaleMeaErrors: [
        {
          range_from: null,
          range_to: null,
          coef_a: null,
          coef_b: null
        },
      ],
    });
  };
  const deleteCharHandle = (charIndex: number) => {
    remove(charIndex);
  };

  const deleteRangeOfUnitHandle = (charIndex: number, unitIndex: number) => {
    let selectedChar = fields[charIndex];
    let newRange = selectedChar.scaleMeaErrors.filter((item, index) => index !== unitIndex);
    selectedChar.scaleMeaErrors = newRange;
    insert(charIndex, {
      ...selectedChar,
    });
    remove(charIndex + 1);
  };*/



  const validateScale = (value: any, scale: any, field: string) => {
    if(field === 'id_unit'){
      if ((scale.name !== '' && scale.name) && (scale.id_unit === '' || !scale.id_unit)) {
        return false;
      }
    }
    if(field === 'name'){
      if ((scale.id_unit !== '' && scale.id_unit) && (scale.name === '' || !scale.name)) {
        return false;
      }
    }
    return true;
  }

  const validateMeaErrors = (value: any, scale: any, indexMeaError: number, field: string) => {
    const scaleIsFilled = (scale.name !== '' && scale.name) || (scale.id_unit !== '' && scale.id_unit);
    if (scaleIsFilled && (value === '' || !value)) {
      return false;//`Please fill ${field}`;
    }
    return true;
  }


  return (
    <Box>
      <Stack direction={'row'} justifyContent={'flex-start'} gap={'4rem'}>
        <Typography variant='h4' mb={'2rem'} flex={'1'}>
          {t('characteristics')}
        </Typography>
        <Typography variant='h4' mb={'2rem'} flex={'1'}>
        {t('measurementRange')}
        </Typography>
      </Stack>
      <Stack
        height={'30vh'}
        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
        className='scrollbar'
        gap={'1.5rem'}
        width={'100%'}
      >
        {fields.map((anchor, index) => (
          <Stack
            direction={'row'}
            gap={'2rem'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            mb={fields.length - 1 === index ? '3rem' : ''}
            key={anchor.id}
          >
            <FormControl>
              <FormSelectInput
                options={equipment.scales}
                control={control}
                name={`scalePoints.${index}.id_scale`}
                getOptionLabel={(option) => option.name || "" }
                isOptionEqualToValue={(option,value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                  
                TextFieldProps={{
                  variant: 'standard',
                  //placeholder: t('scale'),
                  label: t('scale'),
                  size: 'small',  
                }}
                rules={{
                  required: true,
                }}
                sx={{ flex: '0.5', margin: '0' }}
              />
            </FormControl>
            <FormControl>
              <FormTextInput
                control={control}
                name={`scalePoints.${index}.deviation`}
                placeholder={t('deviation')}
                variant='standard'
                sx={{ flex: '1' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <FormControl>
              <FormNumericInput
                control={control}
                name={`scalePoints.${index}.point`}
                placeholder={t('rangeOfPoint')}
                variant='standard'
                sx={{ flex: '0.5' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <FormControl>
              <FormNumericInput
                control={control}
                name={`scalePoints.${index}.expanded_uncertainty`}
                placeholder={t('uncertainlyExtended')}
                variant='standard'
                sx={{ flex: '0.5' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>

            <Stack direction={'row'} gap={'1rem'} mx={'2rem'}>
              {fields.length > 1 && (
                <IconButton aria-label="delete" onClick={() => deleteScalePoint(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton aria-label="add" onClick={() => addScalePoint()}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default ScalePointStep;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import ModalHeader from 'shared/ui/modal-header';
import { TestProgramFeature, TestProgramFeatureList } from 'entities/test-program/model';
import { useCreateTestProgramFeatureListMutation, useUpdateTestProgramFeatureListMutation } from 'entities/test-program/api';
import notify from 'app/utils/notify';

export interface TestProgramFeatureListModalRef {
  open: (data?: TestProgramFeatureList) => void;
  close: () => void;
}

interface FormValues {
  id?: number;
  id_test_program?: number;
  id_test_program_feature?: number;
  name: string;
}

const initialState: FormValues = {
  name: '',
};

interface Props {
  feature: TestProgramFeature;
};

export const TestProgramFeatureListModal = forwardRef<TestProgramFeatureListModalRef, Props>(({ feature }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: initialState,
  });
  const [createTestProgramFeatureList, { isLoading: isLoadingCreate }] = useCreateTestProgramFeatureListMutation();
  const [updateTestProgramFeatureList, { isLoading: isLoadingUpdate }] = useUpdateTestProgramFeatureListMutation();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = (data?: TestProgramFeatureList) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        id_test_program: data.feature.id_test_program,
        id_test_program_feature: data.id_test_program_feature,
        name: data.name,
      });
    } else {
      reset({ ...initialState, id_test_program: feature.id_test_program, id_test_program_feature: feature.id });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.id_test_program_feature) {
      return;
    }
    if (!data.id_test_program) {
      return;
    }
    try {
      const body = {
        id: data.id,
        id_test_program: data.id_test_program,
        id_test_program_feature: data.id_test_program_feature,
        name: data.name,
      };
      if (isEdit && data.id) {
        await updateTestProgramFeatureList({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgramFeatureList(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <FormTextInput
              control={control}
              name='name'
              label={t('name')}
              rules={{ required: true }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isLoadingCreate || isLoadingUpdate}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
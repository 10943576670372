import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ConnectModalModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetTenantsBySubcontractQuery,
  useGetUnitsQuery,
} from 'entities/dicts/api';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState
} from './initialState';
import { useCreateStandartMutation, useGetGroupsQuery, useGetSpecificsQuery, useUpdateStandartMutation } from 'entities/accreditation/api';
import { AccredStandart } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { useCreateTestProgramConnectMutation, useGetTestProgramsByTenantQuery } from 'entities/test-program/api';
import { TestProgram, TestProgramConnect } from 'entities/test-program/model';
import { idText } from 'typescript';

interface Props {
  testProgram: TestProgram;
}

export const TestProgramConnectModal = forwardRef<ConnectModalModalRef, Props>(({testProgram}, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { 
    control, 
    handleSubmit, 
    reset, 
    //setValue, watch, formState, trigger 
  } = methods;
  const [createTestProgramConnect, { isLoading: isLoadingCreate }] = useCreateTestProgramConnectMutation();
  //const [updateStandart, { isLoading: isLoadingUpdate } ] = useUpdateStandartMutation();

  const { data: tenants, isFetching: isTenantFetching } = useGetTenantsBySubcontractQuery({});
  const [selectedTenant, setSelectedTenant] = useState(0);
  const { data: labTestPrograms, isFetching: isProgramsFetching } = useGetTestProgramsByTenantQuery({
    id_tenant: selectedTenant,
    id_test_program_cert: testProgram.id,
  });//, { skip: !selectedGroup || selectedGroup === '' });
  //const { data: laboratories, isFetching: isLaboratoryFetching } = useGetLaboratoriesQuery({});

  const handleTenantChange = (id_tenant: number) => {
    //console.log(id_tenant);
    setSelectedTenant(id_tenant);
  };

  //const [methodType, setMethodType] = useState(1);
  //const [parallelShow, setParallelShow] = useState(0);

  //const { data: units, isFetching: isUnitFetching } = useGetUnitsQuery({});

  /*const {
    //fields: unitFields,
    append: appendUnit,
    remove: removeUnit,
    //insert: insertUnit,
  } = useFieldArray({
    control,
    name: 'units',
    keyName: 'id',
  });*/

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async () => {
    reset(initialState);
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    
    if (!data.id_test_program_lab) {
      return;
    }
    
    let body = {
      id_test_program: testProgram.id,
      id_test_program_lab: data.id_test_program_lab.id,
    };
    
    try {
      console.log(body);
      await createTestProgramConnect(body).unwrap();
      notify(t('addSuccess'), 'success');
      
      handleClose();
    } catch (error) {
      notify(t('addError'), 'error');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '40vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '160px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isTenantFetching}
                options={tenants?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_tenant`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('testProgram.connect.lab')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, option) => {
                  //@ts-ignore
                  handleTenantChange((option && option.id) ? option.id.toString() : '0');

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isProgramsFetching}
                options={labTestPrograms ?? []}
                control={control}
                name={`id_test_program_lab`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('testProgram.connect.programLab')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

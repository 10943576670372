import { Box, FormControl, Stack, TextField, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react';
import UIActionButton from 'shared/ui/ui-action-button';
import './index.css';
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayProps,
  FieldArrayWithId,
  UseFieldArrayInsert,
  Control,
  useFormContext,
} from 'react-hook-form';
import { FormValues } from 'pages/equipment/equipment-modal/initialState';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { useGetUnitsQuery } from 'entities/dicts/api';

interface SecondStepProps {
  fields: FieldArrayWithId<FormValues, 'scales'>[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, 'scales'>;
  insert: UseFieldArrayInsert<FormValues, 'scales'>;
  control: Control<FormValues, any>;
}

/*const voltageOptions = [
  {
    label: 'V',
    value: '1',
  },
  {
    label: 'mV',
    value: '1',
  },
];*/

const SpecificationStep: React.FC<SecondStepProps> = ({
  fields,
  remove,
  append,
  insert,
  control,
}) => {
  const {t} = useTranslation();
  const {data: unitsData} = useGetUnitsQuery({});
  const unitList = unitsData?.items.map((el) => ({
    value: el.id,
    label: el.name,
  }));
//console.log(unitList);

  const addRangeOfUnitHandle = (charIndex: number) => {
    let selectedRange = fields[charIndex].scaleMeaErrors;
    let selectedChar = fields[charIndex];

    insert(charIndex, {
      ...selectedChar,
      scaleMeaErrors: [
        ...selectedRange,
        {
          range_from: null,
          range_to: null,
          coef_a: null,
          coef_b: null
        },
      ],
    });

    remove(charIndex + 1);
  };
  const addCharHandle = () => {
    append({
      accuracy: null,
      id_unit: null,
      name: '',
      scaleMeaErrors: [
        {
          range_from: null,
          range_to: null,
          coef_a: null,
          coef_b: null
        },
      ],
    });
  };
  const deleteCharHandle = (charIndex: number) => {
    remove(charIndex);
  };

  const deleteRangeOfUnitHandle = (charIndex: number, unitIndex: number) => {
    let selectedChar = fields[charIndex];
    let newRange = selectedChar.scaleMeaErrors.filter((item, index) => index !== unitIndex);
    selectedChar.scaleMeaErrors = newRange;
    insert(charIndex, {
      ...selectedChar,
    });
    remove(charIndex + 1);
  };



  const validateScale = (value: any, scale: any, field: string) => {
    if(field === 'id_unit'){
      if ((scale.name !== '' && scale.name) && (scale.id_unit === '' || !scale.id_unit)) {
        return false;
      }
    }
    if(field === 'name'){
      if ((scale.id_unit !== '' && scale.id_unit) && (scale.name === '' || !scale.name)) {
        return false;
      }
    }
    return true;
  }

  const validateMeaErrors = (value: any, scale: any, indexMeaError: number, field: string) => {
    const scaleIsFilled = (scale.name !== '' && scale.name) || (scale.id_unit !== '' && scale.id_unit);
    if (scaleIsFilled && (value === '' || !value)) {
      return false;//`Please fill ${field}`;
    }
    return true;
  }


  return (
    <Box>
      <Stack direction={'row'} justifyContent={'flex-start'} gap={'4rem'}>
        <Typography variant='h4' mb={'2rem'} flex={'1'}>
          {t('characteristics')}
        </Typography>
        <Typography variant='h4' mb={'2rem'} flex={'1'}>
        {t('measurementRange')}
        </Typography>
      </Stack>
      <Box
        display={'flex'}
        height={'100%'}
        flexDirection={'column'}
        gap={'3rem'}
        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
        className='scrollbar'
      >
        {fields.map((char, indx) => (
          <Stack
            key={char.id}
            direction={'row'}
            gap={'4rem'}
            justifyContent={'space-between'}
            mb={fields.length - 1 === indx ? '4rem' : '0'}
            alignItems={'flex-start'}
          >
            <Stack gap={'1rem'} flex={'1'} direction={'row'} alignItems={'center'}>
              <FormControl>
                <FormTextInput
                  control={control}
                  name={`scales.${indx}.name`}
                  label={t('scale')}
                  variant='standard'
                  rules={{
                    //required: true,
                    validate: (value, values) => validateScale(value, values.scales[indx], 'name'),
                  }}
                />
              </FormControl>
              <FormControl>
                <FormSelectInput
                  options={unitsData?.items ?? []}
                  control={control}
                  getOptionLabel={(option) => option.name || "" }
                  isOptionEqualToValue={(option,value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                  name={`scales.${indx}.id_unit`}
                  
                  TextFieldProps={{
                    variant: 'standard',
                    label: t('measurement'),
                    size: 'small',
                  }}
                  rules={{
                    //required: true,
                    validate: (value, values) => validateScale(value, values.scales[indx], 'id_unit'),
                  }}
                />
              </FormControl>
              <Stack direction={'row'} gap={'1rem'} ml={'1rem'}>
                {fields.length > 1 && (
                  <IconButton aria-label="delete" onClick={() => deleteCharHandle(indx)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                <IconButton aria-label="add" onClick={() => addCharHandle()}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Stack flex={'1'} gap={'1rem'} >
              {char.scaleMeaErrors.map((unit, index) => (
                <Stack
                  key={index}
                  gap={'1rem'}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <FormControl>
                    <FormTextInput
                      control={control}
                      type='number'
                      name={`scales.${indx}.scaleMeaErrors.${index}.range_from`}
                      label={t('from')}
                      variant='standard'
                      rules={{
                        validate: (value, values) => validateMeaErrors(value, values.scales[indx], index, 'range_from'),
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormTextInput
                      control={control}
                      type='number'
                      name={`scales.${indx}.scaleMeaErrors.${index}.range_to`}
                      label={t('to')}
                      variant='standard'
                      rules={{
                        validate: (value, values) => validateMeaErrors(value, values.scales[indx], index, 'range_to'),
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormTextInput
                      control={control}
                      type='number'
                      name={`scales.${indx}.scaleMeaErrors.${index}.coef_a`}
                      label={t('coef_a')}
                      variant='standard'
                      rules={{
                        validate: (value, values) => validateMeaErrors(value, values.scales[indx], index, 'coef_a'),
                      }}                      
                    />
                  </FormControl>
                  <FormControl>
                    <FormTextInput
                      control={control}
                      type='number'
                      name={`scales.${indx}.scaleMeaErrors.${index}.coef_b`}
                      label={t('coef_b')}
                      variant='standard'
                      rules={{
                        validate: (value, values) => validateMeaErrors(value, values.scales[indx], index, 'coef_b'),
                      }}
                    />
                  </FormControl>
                  <Stack direction={'row'} gap={'1rem'} ml={'1rem'} mr={'1rem'}>
                    {char.scaleMeaErrors.length > 1 && (
                      <IconButton aria-label="delete" onClick={() => deleteRangeOfUnitHandle(indx, index)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <IconButton aria-label="add" onClick={() => addRangeOfUnitHandle(indx)}>
                      <AddIcon />
                    </IconButton>
                    
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default SpecificationStep;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { IconButton, Stack, Typography } from '@mui/material';
import { AddCircle, Timeline } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentVerification } from 'entities/equipment/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { useGetEquipmentVerificationQuery } from 'entities/equipment/api';
import { useFilters } from 'app/hooks/use-filters';
import { VerificationModalRef, CancelModalRef } from './types';
import { VerificationModal } from './verification-modal';
import { DeleteVerificationModalRef } from './delete-modal/types';
import { DeleteVerificationModal } from './delete-modal/verification';
import { CancelModal } from './cancel-modal';
import notify from 'app/utils/notify';

interface Props {
  equipment: Equipment;
}

export const Verification: React.FC<Props> = ({ equipment }) => {
  const { t } = useTranslation();

  const modalRef = useRef<VerificationModalRef>(null);
  const modalDeleteRef = useRef<DeleteVerificationModalRef>(null);
  const modalCancelRef = useRef<CancelModalRef>(null);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 2,
      //fromDate: '',
      //toDate: '',
      sort: '-date_service',
    },
  });
  //console.log('equipment', equipment);
  const { data: records, isFetching } = useGetEquipmentVerificationQuery({
    id_equipment: equipment ? equipment.id : 0,
    ...filterParams,
  });

  const verify_result_str = (verify_result: number) => {
    switch (verify_result) {
      case 1:
        return t('valid');
      case 2:
        return t('inValid');
      default:
        return t('inValid');
    }
  }

  const handleCancel = (id : number, id_equipment : number, doc_json:string) => {
    if (doc_json) {
      console.log('doc_json', doc_json);
      modalCancelRef.current?.open({
        id,
        id_equipment,
        doc_json: doc_json.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('eqVerification.title')}
            <IconButton onClick={() => modalRef.current?.open(equipment as Equipment)} aria-label="add">
              <AddCircle />
            </IconButton>
          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            { label: t('date'), align:"center"  as const, },
            { label: t('eqVerification.certificateNumber'), align:"center"  as const, },
            { label: t('eqVerification.conclusion'), align:"center"  as const, },
            { label: t('eqVerification.nextCalibrationDate'), align:"center"  as const, },
            { label: t('eqVerification.scalePoint'), align:"center"  as const, },
            {
              label:t('actions'),
              align:'center'  as const,
            },
          ]}
          columns={[
            {
              render: (row: EquipmentVerification) => row.verify_date ?? '--',
            },
            {
              render: (row: EquipmentVerification) => row.verify_nn ?? '--',
            },
            {
              render: (row: EquipmentVerification) => verify_result_str(row.verify_result) ?? '--',
            },
            {
              render: (row: EquipmentVerification) => row.exp_date ?? '--',
            },
            {
              render: (row: EquipmentVerification) => row.scalePoints.map((point: any) => (
                <Stack gap={'0.5rem'} direction={'row'}>
                  <Timeline /><Typography>{point.point} /</Typography>
                  <Typography>{`${t('deviation')} ${point.deviation} /`}</Typography>
                  <Typography>{`${t('uncertainlyExtended')} ${point.expanded_uncertainty}% /`}</Typography>
                </Stack>
              )),
            },
            {
              render: (row: EquipmentVerification) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  {/**!row.date_sign && (<UIActionButton 
                    icon='edit'
                    tooltip={t('edit')}
                    onClick={() => modalRef.current?.open(equipment as Equipment, row)}
                  />)*/}
                  {/**!row.date_sign && (<UIActionButton 
                    icon='delete'
                    tooltip={t('delete')}
                    onClick={() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
                  />)*/}
                  {/**!row.date_sign && (<UIActionButton 
                    icon='sign'
                    tooltip={t('sign')}
                    //onClick={() => modalDeleteVerifRef.current?.open(row.id, row.id_equipment)}
                  />)*/}
                  {(!row.date_cancel && row.date_sign) && (<UIActionButton 
                    icon='cancel'
                    tooltip={t('cancel')}
                    onClick={() => handleCancel(row.id, row.id_equipment, row.doc_json)}
                  />)}
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
        
      </CollapseCard>
      <VerificationModal ref={modalRef} />
      <DeleteVerificationModal ref={modalDeleteRef} />
      <CancelModal ref={modalCancelRef} />
    </>
  );
}

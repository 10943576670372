import {
  Box,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  FormLabel,
} from '@mui/material';
import { FormValues } from '../initialState';
import React, { useEffect, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormDatePicker from 'shared/inputs/form/form-date-picker';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';

/*const metrologyList = [
  {
    label: 'Калибровка',
    value: '1',
  },
  {
    label: 'ih-house Калибровка',
    value: '2',
  },
  {
    label: 'Поверка',
    value: '3',
  },
  {
    label: 'Не имеет метрологических характеристик',
    value: '4',
  },
];*/

interface Props {
  control: Control<FormValues, any>;
  //onChangeLaboratory: (value: number | null) => void;
}

const GeneralStep: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation();
  const conclusionList = [
    {
      label: t('valid'),
      value: '1',
    },
    {
      label: t('inValid'),
      value: '2',
    },
  ];

  const { data: laboratories } = useGetLaboratoriesQuery({});

  /*const locationList = laboratories?.items.map((el) => ({
    label: el.name,
    value: el.id,
  }));*/
//console.log(locationList);
  return (
    <Box>
      <Typography variant='h4' mb={'2rem'}>
        {t('generalInfo')}
      </Typography>
      <Stack direction={'row'} gap={'4rem'} justifyContent={'space-between'}>
        <Stack gap={'1rem'} flex={'1'}>
          <FormControl>
            {/**<FormTextInput
              control={control}
              type='number'
              name={`verify_nn`}
              placeholder={t('numOfWitness')}
              variant='standard'
              rules={{
                required: true,
              }}
            />*/}
            <FormTextInput
              control={control}
              name={`verify_nn`}
              label={t('numOfWitness')}
              //placeholder={t('name')}
              variant='standard'
              rules={{
                required: true,
              }}
              onInput = {(e) =>{
                // @ts-ignore
                e.target.value = e.target.value.toString().slice(0,256)
            }}
            />
          </FormControl>
          <Controller
            control={control}
            name='note'
            render={({ field }) => (
              <ReactQuill {...field} theme='snow' placeholder={t('notion')} />
            )}
          />
        </Stack>
        <Stack gap={'3rem'} flex={'1'}>
          <FormControl>
            <FormDatePicker
              control={control}
              name={`verify_date`}
              dateFormat='dd-MM-yyyy'
              TextFieldProps={{ label: t('dateOfWitness'), variant: 'standard' }}
              rules={{
                required: true,
              }}
            />
          </FormControl>
          <FormControl>
            <FormDatePicker
              control={control}
              name={`exp_date`}
              dateFormat='dd-MM-yyyy'
              TextFieldProps={{ label: t('dateOfNextCalibration'), variant: 'standard' }}
              rules={{
                required: true,
              }}
            />
          </FormControl>
          <FormControl>
            <FormSelectInput
              options={conclusionList}
              control={control}
              name={`verify_result`}
              getOptionLabel={(option) => option.label || "" }
              isOptionEqualToValue={(option,value) => value.value === undefined || String(value.value) === "" || option.value === value.value}
                
              TextFieldProps={{
                variant: 'standard',
                //placeholder: t('conclusion'),
                label: t('conclusion'),
                size: 'small',
              }}
              rules={{
                required: true,
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GeneralStep;

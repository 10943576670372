import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CollapseCard from 'shared/ui/collapse-card';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentServReg, EquipmentServRegWork, EquipmentServiceWork, EquipmentShipping } from 'entities/equipment/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { useGetEquipmentServRegQuery, useGetEquipmentShippingQuery } from 'entities/equipment/api';
import { useGetRequestProductionsQuery } from 'entities/request/api';
import { useFilters } from 'app/hooks/use-filters';
import ShippingModal from './shipping-modal';
import ReturnModal from './return-modal';
import { CancelModal } from './cancel-modal';
import { ShippingModalRef, ReturnModalRef, CancelModalRef } from './types';
import moment from 'moment';
import { damageStatuses, workingCapacities } from './dictionaries';
import { SelectObject } from 'app/utils/types';
import notify from 'app/utils/notify';
import { Request, RequestFile, RequestProduction } from 'entities/request/model';
import FilePresentIcon from '@mui/icons-material/FilePresent';

interface Props {
  request: Request;
}

export const Production: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  const modalCancelRef = useRef<CancelModalRef>(null);
  const modalShippingRef = useRef<ShippingModalRef>(null);
  const modalReturnRef = useRef<ReturnModalRef>(null);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //fromDate: '',
      //toDate: '',
      //sort: '-date_service',
    },
  });
  //console.log('equipment', equipment);
  const { data: records, isFetching } = useGetRequestProductionsQuery({
    id_request: request ? request.id : 0,
    ...filterParams,
  });

  /*const handleCancel = (id : number, id_equipment : number, doc_json_shipping:string) => {
    if (doc_json_shipping) {
      modalCancelRef.current?.open({
        id,
        id_equipment,
        doc_json_shipping: doc_json_shipping.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }*/
  const headers = [
    { label: t('request.view.productions.accredGroupName'), align:"center"  as const },
    { label: t('request.view.productions.name'), align:"center"  as const },
    { label: t('request.view.productions.unit'), align:"center"  as const },
    { label: t('request.view.productions.quantity'), align:"center"  as const },
    { label: t('request.view.productions.testProgOrg'), align:"center"  as const, visible: request?.id_analysis_select!==null },
    { label: t('request.view.productions.testProg'), align:"center"  as const },
    { label: t('request.view.productions.testProgFile'), align:"center"  as const },
    { label: t('request.view.productions.tuFile'), align:"center"  as const },
    { label: t('request.view.productions.characteristic'), align:"center"  as const },
    { label: t('request.view.productions.samples'), align:"center"  as const },
    {
      label:t('actions'),
      align:'center'  as const,
    },
  ];

  const columns = [
    {
      render: (row: RequestProduction) => row.accredGroup.name ?? '--',
    },
    {
      render: (row: RequestProduction) => row.name ?? '--',
    },
    {
      render: (row: RequestProduction) => row.unit.name ?? '--',
    },
    {
      render: (row: RequestProduction) => row.test_quantity ?? '--',
    },
    {
      render: (row: RequestProduction) => {
        const filesTestProg = (row.test_program_org !== false) ? row.test_program_org as RequestFile[] : null;
        return (
          <Stack spacing={1} justifyContent='center'>
            {filesTestProg && filesTestProg.map((file: RequestFile, index: number) => {
              return (<Typography key={index}><FilePresentIcon />{file.url ? (<Link target="_blank" href={"https://prog.i-test.uz" + file.url as string}>{file.label}</Link>) : file.label}</Typography>);
            })}
          </Stack>
        );
      }, 
      visible: request?.id_analysis_select!==null
    },
    {
      render: (row: RequestProduction) => row.test_program_name ?? '--',
    },
    {
      render: (row: RequestProduction) => row.test_program_url ? (<Link target="_blank" href={"https://prog.i-test.uz" + row.test_program_url as string}>Скачать</Link>): '--',
    },
    {
      render: (row: RequestProduction) => row.tech_standart_url ? (<Link target="_blank" href={"https://prog.i-test.uz" + row.tech_standart_url as string}>Скачать</Link>): '--',
    },
    {
      render: (row: RequestProduction) => row.test_program_variant ?? '--',
    },
    {
      render: (row: RequestProduction) => row.sample_count ?? '--',
    },
    /*{
      render: (row: EquipmentShipping) => moment(row.date_return).format('DD.MM.YYYY') ?? '--',
    },
    {
      render: (row: EquipmentShipping) => row.place_purpose ?? '--',
    },
    {
      render: (row: EquipmentShipping) => {
        const workingCapacity = workingCapacities.find((el:SelectObject)=>el.value == row.working_capacity)?.label;
        const damageStatus = damageStatuses.find((el:SelectObject)=>el.value == row.damaged)?.label;
        return <Stack gap={'0.7rem'}>
          <Typography>{`${t('eqShipping.workingCapacity')}: ${(workingCapacity ? workingCapacity : '-')}`}</Typography>
          <Typography>{`${t('eqShipping.damagedStatus')}: ${(damageStatus ? damageStatus : '-')}`}</Typography>
        </Stack>
      },
    },
    {
      render: (row: EquipmentShipping) => (
        <Stack gap={'0.7rem'}>
          <Typography>{`${t('eqShipping.shippingStaff')}: ${row.staffFio.fio}`}</Typography>
          <Typography>{`${t('eqShipping.returnStaff')}: ${row.returnStaffFio ? row.returnStaffFio.fio : ''}`}</Typography>
          <Typography>{`${t('cancelStaff')}: ${row.cancelStaffFio ? row.cancelStaffFio.fio : ''} ${row.date_cancel ? moment(row.date_cancel).format('DD.MM.YYYY') : ''}`}</Typography>
        </Stack>
      ),
    },*/
    {
      render: (row: RequestProduction) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          {(!request.has_next_steps && !request.date_cancel) && (<UIActionButton 
            icon='edit'
            tooltip={t('edit')}
            onClick={() => alert('Изменить')}
          />)}
          {/**(!row.date_cancel && (row.date_shipping || row.date_return)) && (<UIActionButton 
            icon='cancel'
            tooltip={t('cancel')}
            onClick={() => handleCancel(row.id, row.id_equipment, row.doc_json_shipping)}
          />)*/}
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <>
      <CollapseCard
        title={t('request.view.productions.title')}
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={headers.filter((el) => el.visible===undefined || el.visible)}
          columns={columns.filter((el) => el.visible===undefined || el.visible)}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
        
      </CollapseCard>
      {/*<ShippingModal ref={modalShippingRef} equipment={equipment} />
      <ReturnModal ref={modalReturnRef} equipment={equipment} />
      <CancelModal ref={modalCancelRef} />*/}
    </>
  );
}

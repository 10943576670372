import React from 'react';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { SubNavItem } from '../types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
  data: SubNavItem[];
}

export const SidebarNavPopper: React.FC<Props> = ({ data }) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  return (
    <Box>
      {data.map((el, idx) => {
        const isActive = pathname === el.to;
        return (
          <Box
            key={idx}
            sx={
              {
                // backgroundColor: Colors.DARK,
                // '& :hover': {
                //   backgroundColor: '#51336B',
                // },
              }
            }
          >
            <ButtonBase
              sx={{
                ml: 2,
              }}
            >
              <Stack
                spacing={1.25}
                direction='row'
                alignItems='center'
                component={Link}
                to={el.to}
                sx={{
                  textDecoration: 'none',
                  minWidth: 'max-content',
                  py: 1,
                  pr: 2,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Box component={KeyboardArrowRightIcon} />
                <Typography
                  sx={{
                    // color: isActive ? Colors.TEXT_WHITE : '#8E6EAC',
                    fontWeight: 500,
                  }}
                >
                  {el.name}
                </Typography>
              </Stack>
            </ButtonBase>
          </Box>
        );
      })}
    </Box>
  );
};

import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseControllerInputProps } from 'shared/inputs/types';

export const FormTextInput = <T extends FieldValues>(
  props: BaseControllerInputProps<T, TextFieldProps>
) => {
  const { control, name, onChange, rules, regExp, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...rules,
        required: rules?.required === true ? t('errors:required_field') : rules?.required,
      }}
      render={({ field: { value = '', ...field }, fieldState }) => {
        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value && regExp && !regExp.test(event.target.value)) return;
          onChange?.(event);
          field.onChange(event);
        };

        return (
          <TextField
            value={value}
            {...rest}
            {...field}
            onChange={onChangeHandler}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

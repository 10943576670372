import React from 'react';
import { Box, Grid } from '@mui/material';
import { AccredStandart } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import DetailView from 'shared/ui/detail-view';
import moment from 'moment';

interface Props {
  data: AccredStandart;
}

export const General: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const getResultPrecision = (resultPrecision: number) => {
    switch (resultPrecision) {
      case 1:
        return '10.0';
      case 2:
        return '5.0';
      case 3:
        return '1.0';
      case 4:
        return '0';
      case 5:
        return '0.5';
      case 6:
        return '0.1';
      case 7:
        return '0.01';
      case 8:
        return '0.001';
      case 9:
        return '0.0001';
      case 10:
        return '0.00001';
      default:
        return '-';
    }
  }

  const getResultRuleName = (resultRule: number) => {
    switch (resultRule) {
      case 1:
        return t('accred.standart.view.rule1');
      case 2:
        return t('accred.standart.view.rule2');
      case 3:
        return t('accred.standart.view.rule3');
      case 4:
        return t('accred.standart.view.rule4');
      case 5:
        return t('accred.standart.view.rule5');
      case 6:
        return t('accred.standart.view.rule6');
      case 7:
        return t('accred.standart.view.rule7');
      default:
        return "-";
    }
  }

  return (
    <CollapseCard title={t('accred.standart.tabs.general.title')} collapsed={true}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <DetailView data={
              [
                {
                  label: t('accred.standart.type'),
                  value: data?.type === 0 ? t('accred.standart.typeOld') : data?.type === 1 ? t('accred.standart.typeCalc') : data?.type === 2 ? t('accred.standart.typeText') : ''
                },
                {
                  label: t('accred.standart.view.accuracy'),
                  value: data?.accuracy
                },
                {
                  label: t('accred.standart.view.measureCount'),
                  value: data?.measure_count
                },
                {
                  label: t('accred.standart.view.formula'),
                  value: data?.formula
                },
                {
                  label: t('accred.standart.view.resultRule'),
                  value: getResultRuleName(data?.result_rule)
                },
                {
                  label: t('accred.standart.view.resultPrecision'),
                  value: getResultPrecision(data?.result_precision)
                }
              ]} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <DetailView data={
              [
                {
                  label: t('expiration_date'),
                  value: `${data?.date_begin ? moment(data?.date_begin).format('DD.MM.YYYY') : t('no')} - ${data?.date_end ? moment(data?.date_end).format('DD.MM.YYYY') : t('no')}`
                },
                {
                  label: t('accred.standart.view.laboratory'),
                  value: data?.laboratoryData ? data.laboratoryData.name : ''
                },
                {
                  label: t('accred.standart.view.preliminaryTest'),
                  value: data?.preliminary_test === 1 ? t('yes') : t('no')
                },
                {
                  label: t('accred.standart.view.parallelTest'),
                  value: data?.parallel_test === 1 ? t('yes') : t('no')
                },
                {
                  label: t('accred.standart.view.confidenceProbability'),
                  value: data?.confidence_probability
                },
                {
                  label: t('accred.standart.view.mainMethod'),
                  value: data?.accredStandartMain ? data?.accredStandartMain.name : '-'
                },
                {
                  label: t('accred.standart.view.conditioningTime'),
                  value: data?.conditioning_time
                },
              ]} />
          </Grid>
        </Grid>
      </Box>

    </CollapseCard>


  );
}

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AccredSpecificModalRef } from './types';
import { Equipment } from 'entities/equipment/model';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetEnvironmentConditionsQuery,
  useLazyGetEnvironmentConditionsQuery,
} from 'entities/dicts/api';
import { Colors } from 'app/constants';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { useGetStaffQuery, useLazyGetStaffQuery } from 'entities/staff/api';
import { Staff } from 'entities/staff/model';
import UISelect from 'shared/ui/ui-select';
import UIActionButton from 'shared/ui/ui-action-button';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { handleResponse } from 'app/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';




import ModalHeader from 'shared/ui/modal-header';

import { UseFormHandleSubmit } from 'react-hook-form';
import { initialState, confirmationMethods, periodicyUnits } from './initialState';
import { useAppSelector } from 'app/hooks/redux';
import { CreateEquiment } from 'entities/equipment/model';
import {
  useCreateEquipmentMutation,
  useGetEquipmentIdQuery,
  useUpdateEquipmentMutation,
} from 'entities/equipment/api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
//import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Laboratory } from 'entities/laboratories/model';
import { useGetGroupsQuery, useCreateSpecificMutation, useUpdateSpecificMutation } from 'entities/accreditation/api';
import { AccredGroup, AccredSpecific } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';

export interface FormValues {
  id: number;
  accredGroup: AccredGroup;
  name: string;
}

export const AddAccredSpecificModal = forwardRef<AccredSpecificModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { control, handleSubmit, reset, setValue, watch, formState, trigger } = methods;
  const [createSpecific] = useCreateSpecificMutation();
  const [updateSpecific] = useUpdateSpecificMutation();

  const { data: groups, isFetching } = useGetGroupsQuery({});

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredSpecific | undefined) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        accredGroup: data.accredGroup,
        name: data.name,
      });
    }/*else{
      reset({
        name:'',
      });
    }*/
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      name: '',
      accredGroup: {},
    });
  };

  const onSubmit = async (data: FormValues) => {
    const body = {
      id_accred_group: data.accredGroup.id,
      name: data.name
    };

    try {
      setIsLoading(true);
      if (isEdit && data.id) {
        await handleResponse(await updateSpecific({ ...body, id: data.id }));
      } else {
        await handleResponse(await createSpecific(body));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '40vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('accred.specific.edit') : t('accred.specific.add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '100px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={6}>
            <FormControl>
              <FormSelectInput
                options={groups?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`accredGroup`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.specific.group')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormTextInput
                control={control}
                name='name'
                label={t('name')}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={handleClose}>
          {t('close')}
        </Button>
        <Button color='success' onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

import { Box } from '@mui/material';
import React from 'react';
import Layout from 'shared/layout';
//import SingleRoom from './ui/single-room';
import UITabs from 'shared/ui/ui-tabs';
//import ProtocolList from './list';

import { useTranslation } from 'react-i18next';
import GroupList from './group-list';
import SpecificList from './specific-list';
import StandartList from './standart-list';

const Accreditation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
      <UITabs
        tabs={[
          {
            component: <StandartList />,
            label: t('accred.tabs.standart'),
            href: 'standart',
          },
          {
            component: <GroupList />,
            label: t('accred.tabs.group'),
            href: 'group',
          },
          {
            component: <SpecificList />,
            label: t('accred.tabs.specific'),
            href: 'specific',
          },
        ]}
        name='room'
      />
    </Box>
    </Layout>
  );
};

export default Accreditation;

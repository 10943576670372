import React from 'react';
import Header from '../header';
import Sidebar from '../sidebar';
import Copyright from '../footer';
import { useAppSelector } from 'app/hooks/redux';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  title?: React.ReactNode;
  topContent?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children, title, topContent }) => {
  const { authorized } = useAppSelector((state) => state.auth);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'space-between',
      }}
    >
      {authorized && <Sidebar />}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Box
          sx={{
            flex: 1,
            p: 3,
          }}
        >
          {title && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                mb: 4,
              }}
            >
              <Typography variant='h2'>{title}</Typography>
              {topContent}
            </Stack>
          )}
          {children}
        </Box>
        <Copyright />
      </Box>
    </Box>
  );
};

export default Layout;

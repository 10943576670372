import React, { useEffect } from "react";
//import { BaseModalRef, handleError } from "@/app/helpers";
import { useAppDispatch } from "app/hooks/redux";
import { useLazyLoginWithTokenQuery } from "entities/auth/api";
import { SystemUser } from "entities/auth/model/types";
import { signIn, signOut } from "entities/auth/model/slice";
import { useNavigate } from "react-router-dom";
/*import { setFullscreenLoaderVisibility } from "@/entities/app/model/slice";
import {
  useAuthCustomerMutation,
  useLazyGetOfferContentQuery,
  useSignOfferMutation,
} from "@/entities/auth/api";
import { authCustomer, logout } from "@/entities/auth/model/slice";
import { eimzo } from "@/entities/eimzo";
import { OfferModal } from "@/shared/modals/offer-modal";
import { useEffect, useRef } from "react";

import Cookies from "js-cookie";*/
import Layout from "shared/layout";

export const Auth0Page = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loginWithToken, { data: LoginReturnData }] =
    useLazyLoginWithTokenQuery();

  useEffect(() => {
    Auth();
  }, []);

  const Auth = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const url_token = urlParams.get("token");
    const serialNumber = urlParams.get("serialNumber");
    const url = urlParams.get("url");

    if (!url_token || !serialNumber || !url) return navigate("/");

    try {
      dispatch(signOut());
      //dispatch(setFullscreenLoaderVisibility(true));
      const dataLogin = await loginWithToken(url_token).then((res) => res.data);
      if (!dataLogin) return Promise.reject();
      /*Cookies.set("token", authToken, { expires: 1, secure: true });
      Cookies.set("user-key", token, { expires: 1, secure: true });*/

      const user: SystemUser = {
        ...dataLogin?.user,
        serialNumber: serialNumber,
      };
      dispatch(signIn({ user, token: dataLogin.token }));
      navigate(url);
    } catch (err) {
      console.log(err);
      navigate("/");
    }
  };

  return <Layout>Auth0Page</Layout>;
};

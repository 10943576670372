import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import moment from 'moment';
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Person, CalendarMonth, NextWeek, LocationOn, LocalPhone, Check, AssignmentTurnedIn } from '@mui/icons-material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Protocol } from 'entities/protocol/model';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { InfoGroup } from 'shared/ui/info-group';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  protocol: Protocol;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export const MainInfoCard: React.FC<Props> = ({ protocol }) => {
  const { t } = useTranslation();

  const return_destroy_str = (return_destroy?: number) => {
    switch (return_destroy) {
      case 1:
        return t('protocol.view.destroyed');
      case 2:
        return t('protocol.view.returned');
      default:
        return '-';
    }
  }

  /*const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  
    const filesTestProg= ((request?.files !== undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;

    const files =  (request?.files !== undefined) ? Object.keys(request.files).filter((el) => {
      let file = request.files[el as keyof Request['files']];
      return (file !== false && file !== "" && el !== "testProg");
    }).map(function (el) {
      let file = request.files[el as keyof Request['files']];
      return file as RequestFile;
    }) : [];
*/
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Stack spacing={1}>
          <InfoGroup
            title={`${t('protocol.view.title')} №${protocol.protocol_nn} ${protocol.testType.name}`}
            items={[
              { icon: <CalendarMonth />, text: `${t('protocol.view.beginDate')} ${moment(protocol?.begin_date).format('DD.MM.YYYY')}` },
              { icon: <Check />, text: `${t('protocol.view.confirm')} ${moment(protocol?.confirm_date).format('DD.MM.YYYY')}` },
              { icon: <Person />, text: `${protocol?.confirm_staff_position} ${protocol?.confirm_staff_fio}` },
              { icon: <LocationOn />, text: `${t('protocol.view.placeOfTest')} ${protocol?.place_of_test}` },
            ]}
          />
          <InfoGroup
            title={t('protocol.view.client')}
            items={[
              { icon: <NextWeek />, text: protocol.client_name ? protocol.client_name : protocol.client ? protocol.client.name : '' },
              { icon: <LocationOn />, text: protocol?.client_address ? protocol.client_address : protocol.client ? protocol.client.address : '' },
              { icon: <LocalPhone />, text: protocol?.client_phone ? protocol.client_phone : protocol.clientPhone ? protocol.clientPhone.phone : '' },
              {
                icon: <AssignmentTurnedIn />,
                visible: (protocol.id_contract !== null && protocol.id_contract !== undefined),
                text: `${t('protocol.view.contract')} №${protocol.contract_nn} ${t('from')} ${moment(protocol.contract_date).format('DD.MM.YYYY')}`
              },
            ]}
          />
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack spacing={1} mt={1}>
            <InfoGroup
              title={t('protocol.view.resultRelatesTo')}
              items={[
                { icon: <HomeRepairServiceIcon />, text: protocol?.result_relates_to ? protocol.result_relates_to : '' },
                { icon: <CalendarMonth />, text: `${t('protocol.view.dateGetSample')} ${moment(protocol?.date_get_sample).format('DD.MM.YYYY')}` },
              ]}
            />
            <InfoGroup
              title={t('protocol.view.returnDestroy')}
              items={[
                { icon: <BrokenImageIcon />, text: protocol?.return_destroy !== null ? return_destroy_str(protocol.return_destroy) : '-' },
                { icon: <Person />, text: (protocol?.ret_dest_staff_fio !== null && protocol?.ret_dest_staff_position !== null) ? `${protocol.ret_dest_staff_fio} (${protocol.ret_dest_staff_position})` : '-' },
                { icon: <ThumbUpOffAltIcon />, text: `${t('protocol.view.retDestroyOk')} - ${t('yes')}` },
              ]}
            />
          </Stack>
        </Collapse>
      </CardContent>
      <CardActions disableSpacing>
        <Typography variant="h4" ml={5}>{expanded ? t("less") : t("more")}</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      {/**<Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              ddd
            </CardContent>
          </Collapse>*/}
    </Card>

  );
}

import { StandartSample } from "entities/dicts/model";

export interface FormValues {
  id?: number | null;
  id_standart_sample: StandartSample | null;
  usage_rate: number | null;
};

export const initialState = {
  id_standart_sample: null,
  usage_rate: null,
};
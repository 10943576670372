import React from 'react';
import { Stack, Typography } from '@mui/material';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CheckViewProps } from './types';


const CheckView: React.FC<CheckViewProps> = ({ data }) => {


  return (
    <Stack spacing={1}>
      {data.map((item, index) => {
        if (item.visible === undefined || item.visible !== false) {
          return (
            <Stack justifyContent='center' key={index}>
              <Stack direction="row" alignItems="center" gap={1}>
                {item.value == 1 ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}
                <Typography variant="body1" sx={{ fontSize: 16 }}>{item.title}</Typography>
              </Stack>
              {item.note !== '' ?
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {item.note}
                </Typography> : ''}
            </Stack>
          );
        }

      })}
    </Stack>
  );
};

export default CheckView;
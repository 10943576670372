import React, { useState } from 'react';
import {
  Typography, Paper, Box, Grid, TextField, Button,
  FormControl, InputLabel, Select, MenuItem,
  Stepper, Step, StepLabel
} from '@mui/material';
import Layout from 'shared/layout';
import DetailView from 'shared/ui/detail-view';
import CollapseCard from 'shared/ui/collapse-card';
import UITable from 'shared/ui/ui-table';
import { DetailViewItem } from 'shared/ui/detail-view/types';
import { UITableColumn } from 'shared/ui/ui-table/types';

const CertificationDecisionPage: React.FC = () => {
  const [decision, setDecision] = useState('');

  const stages = [
    'Заявка на сертификацию',
    'Решение по заявке',
    'Обследование производства',
    'Отбор и идентификация',
    'Анализ результатов',
    'Решение по сертификации',
    'Сертификат соответствия',
  ];

  const activeStep = 1;

  const generalInfo: DetailViewItem[] = [
    { label: 'Номер заявки', value: '12345678' },
    { label: 'Дата поступления', value: '2024.03.06' },
    { label: 'Заявитель', value: 'СП ООО "Жопа"' },
    { label: 'Наименование компании (ИНН)', value: 'СП ООО "Жопа" (123456789)' },
    { label: 'Схема сертификации', value: 'Схема 3' },
  ];

  const changeColumns: UITableColumn<any>[] = [
    { key: 'productName', render: (row) => row.productName },
    { key: 'initialProgram', render: (row) => row.initialProgram },
    { key: 'replacedProgram', render: (row) => row.replacedProgram },
    { key: 'stage', render: (row) => row.stage },
  ];

  const changeData = [
    { productName: 'Продукт A', initialProgram: 'Программа 1', replacedProgram: 'Программа 2', stage: 'Отбор / испытания' },
  ];

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Решение по заявке №12345678 от 2024.03.06 СП ООО "Жопа"
        </Typography>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stages.map((label, index) => (
              <Step key={label} completed={index <= activeStep}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>

        <CollapseCard title="Общие сведения" collapsed={false}>
          <DetailView data={generalInfo} />
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title="Решение по заявке на сертификацию" collapsed={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="decision-label">Решение</InputLabel>
                <Select
                  labelId="decision-label"
                  value={decision}
                  label="Решение"
                  onChange={(e) => setDecision(e.target.value)}
                >
                  <MenuItem value="accept">Начать работы по сертификации</MenuItem>
                  <MenuItem value="reject">Отказать в проведении сертификации</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {decision === 'reject' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Причина отказа"
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Орган для проведения отбора продукции"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Комментарий к решению"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title="Задействованные специалисты">
          <Grid container spacing={2}>
            {['Для проведения оценки', 'Для обследования производства', 'Для проведения анализа всей информации и результатов', 'Для принятия решения по сертификации'].map((role, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  label={role}
                  variant="outlined"
                  helperText="ФИО или пишем неприменимо"
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Руководитель органа по сертификации"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title="Регистрация изменений в выбранных НД на продукцию">
          <UITable
            columns={changeColumns}
            data={changeData}
            headers={['Наименование продукции', 'Первоначально выбранная программа', 'Замененная программа', 'Этап, где заменено']}
          />
        </CollapseCard>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary">
            Сохранить решение
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default CertificationDecisionPage;
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import FormDatePicker from 'shared/inputs/form/form-date-picker';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import ModalHeader from 'shared/ui/modal-header';
import { TestProgram } from 'entities/test-program/model';
import { useCreateTestProgramMutation, useUpdateTestProgramMutation } from 'entities/test-program/api';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import notify from 'app/utils/notify';
import { use } from 'i18next';
import { useGetGroupsQuery } from 'entities/accreditation/api';
import moment from 'moment';
import { useAppSelector } from 'app/hooks/redux';
import { FormFileInput } from 'shared/inputs/form/form-file-input';

export interface TestProgramModalRef {
  open: (data?: TestProgram) => void;
  close: () => void;
}

interface FormValues {
  id?: number;
  name: string;
  date_begin: string;
  date_end?: string;
  doc_file?: File | null;
  certification_compliance?: string;
  accredGroup?: { id: number; name: string };
}

const initialState: FormValues = {
  name: '',
  date_begin: '',
  accredGroup: { id: 0, name: '' },
};

export const TestProgramModal = forwardRef<TestProgramModalRef>((_, ref) => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { control, handleSubmit, reset } = methods;
  const [createTestProgram, { isLoading: isLoadingCreate }] = useCreateTestProgramMutation();
  const [updateTestProgram, { isLoading: isLoadingUpdate }] = useUpdateTestProgramMutation();

  const { data: groups, isFetching: isGroupFetching } = useGetGroupsQuery({ 'per-page': 0, });

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = (data?: TestProgram) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        name: data.name,
        date_begin: data.date_begin,
        date_end: data.date_end,
        //doc_file: data.doc_url,
        certification_compliance: data.certification_compliance,
        accredGroup: data.accredGroup,
      });
    } else {
      reset(initialState);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const body = {
        ...data,
        doc_file: data.doc_file ?? null,
        date_begin: moment(data.date_begin).format('YYYY-MM-DD'),
        date_end: data.date_end ? moment(data.date_end).format('YYYY-MM-DD') : null,
        use_method: 1,
        status: 1,
        id_accred_group: data.accredGroup ? data.accredGroup.id : null,
      };
      if (isEdit && data.id) {
        await updateTestProgram({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgram(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          {tenantType === 2 && (
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isGroupFetching}
                options={groups?.items ?? []}
                control={control}
                name='accredGroup'
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                TextFieldProps={{
                  //variant: 'standard',
                  label: t('testProgram.accredGroup'),
                  size: 'small',
                }}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          )}
          <Grid item xs={12}>
            <FormControl>
              <FormTextInput
                control={control}
                name='name'
                label={t('testProgram.name')}
                variant='standard'
                rules={{ required: true }}
                onInput={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.toString().slice(0, 256)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormDatePicker
                control={control}
                name='date_begin'
                dateFormat='dd-MM-yyyy'
                TextFieldProps={{ label: t('testProgram.dateBegin'), variant: 'standard' }}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormDatePicker
                control={control}
                name='date_end'
                dateFormat='dd-MM-yyyy'
                TextFieldProps={{ label: t('testProgram.dateEnd'), variant: 'standard' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>{`${t('testProgram.compliance')}`}</FormLabel>
              <FormTextInput
                control={control}
                name='certification_compliance'
                multiline
                sx={{
                  '& .MuiInputBase-input': {
                    minHeight: '150px',
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormFileInput
                control={control}
                name='doc_file'
                label={t('testProgram.view.file')}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
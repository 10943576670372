import React from 'react';
import { Box, Grid, Link, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DetailView from 'shared/ui/detail-view';
import moment from 'moment';
import { TestProgram } from 'entities/test-program/model';
import { useAppSelector } from 'app/hooks/redux';

interface Props {
  data: TestProgram;
}

export const General: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <DetailView data={
              [
                {
                  label: t('expiration_date'),
                  value: `${data?.date_begin ? moment(data?.date_begin).format('DD.MM.YYYY') : t('no')} - ${data?.date_end ? moment(data?.date_end).format('DD.MM.YYYY') : t('no')}`
                },
                {
                  label: t('testProgram.view.file'),
                  value: data?.doc_url ? <Link href={data.doc_url} target="_blank">{t('download')}</Link> : t('no')
                },
              ]} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <DetailView data={
              [
                {
                  label: t('testProgram.status'),
                  value: data?.status==1 ? t('testProgram.statusMain') : t('testProgram.statusAdditional')
                },
                {
                  label: tenantType === 2 ? t('testProgram.calc') : t('testProgram.compliance'),
                  value: tenantType === 2 ? (data?.use_method==1 ? t('yes') : t('no')) : data?.certification_compliance
                },
              ]} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
    


  );
}

import { Staff } from 'entities/staff/model';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Laboratory } from 'entities/laboratories/model';
import { SelectObject } from 'app/utils/types';
import { AccredGroup, AccredSpecific } from 'entities/accreditation/model';
import { Equipment, Scales } from 'entities/equipment/model';

export interface FormValues {
  id?:number;
  name: string;
  url: string;
}


export const initialState = {
  name: '',
  url: '',
}
import React, { useRef } from 'react';
import { IconButton, Link, Stack } from '@mui/material';
import { AccredStandart, AccredStandartSample } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import { useDeleteStandartSampleMutation, useGetStandartSampleQuery } from 'entities/accreditation/api';
import { useFilters } from 'app/hooks/use-filters';
import { AddCircle } from '@mui/icons-material';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { AccredStandartSampleModalRef } from './modal/types';
import { DeleteModalRef } from 'features/delete-modal/types';
import { AccredStandartSampleModal } from './modal/standart-sample-modal';
import { DeleteModal } from 'features/delete-modal';

interface Props {
  data: AccredStandart;
}

interface DeleteRequestBody {
  id: number;
  id_accred_standart: number;
}

export const Sample: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<AccredStandartSampleModalRef>(null);

  const [deleteRec] = useDeleteStandartSampleMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_accred_standart: additionalData?.id_accred_standart as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_accred_standart: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_accred_standart });
    }
  };

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //sort: '-date_service',
    },
  });

  const { data: records, isFetching } = useGetStandartSampleQuery({
    id_accred_standart: data ? data.id : 0,
    ...filterParams,
  });

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('accred.standart.tabs.standard.title')}
            <IconButton
              onClick={() => modalRef.current?.open()}
              aria-label="add"
            >
              <AddCircle />
            </IconButton>

          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            {
              label: t('accred.standart.tabs.standard.title'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.standard.elements'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.standard.norm'),
              align: 'center' as const,
            },
            {
              label: t('actions'),
              align: 'center' as const,
            },
          ]}
          columns={[
            {
              render: (row: AccredStandartSample) => row.standartSample.name ?? '--',
            },
            {
              render: (row: AccredStandartSample) => (
                <Stack direction='column' spacing={1} justifyContent='center'>
                  {row.standartSample.standartSampleElements.map((el) => {
                    return <Link href={'/standart-sample-element/view/' + el.id} target='_blank'>{el.name}</Link>
                  })}
                </Stack>
              ),
            },
            {
              render: (row: AccredStandartSample) => row.usage_rate ?? '--',
            },
            {
              render: (row: AccredStandartSample) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='edit'
                    tooltip={t('edit')}
                    onClick={() => modalRef.current?.open(row)}
                  />
                  <UIActionButton
                    icon='delete'
                    tooltip={t('delete')}
                    onClick={() => openDeleteModal(row.id, row.id_accred_standart)}
                  />
                </Stack>
              ),
              nowrap: true,
            },

          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <AccredStandartSampleModal accredStandart={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

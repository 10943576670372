import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartAccuracyModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState
} from './initialState';
import { useCreateStandartAccuracyMutation, useUpdateStandartAccuracyMutation } from 'entities/accreditation/api';
import { AccredStandart, AccredStandartAccuracyControl } from 'entities/accreditation/model';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';

interface Props {
  accredStandart: AccredStandart;
}

export const AccredStandartAccuracyModal = forwardRef<AccredStandartAccuracyModalRef, Props>(({ accredStandart }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const {
    control,
    handleSubmit,
    reset,
    //watch,
    //setValue, //formState, trigger 
  } = methods;
  const [createStandartAccuracy, { isLoading: isLoadingCreate }] = useCreateStandartAccuracyMutation();
  const [updateStandartAccuracy, { isLoading: isLoadingUpdate }] = useUpdateStandartAccuracyMutation();

  

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandartAccuracyControl | undefined) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        value_from: data.value_from,
        value_to: data.value_to,
        inlab_stdr_deviation: data.inlab_stdr_deviation,
        interlab_stdr_deviation: data.interlab_stdr_deviation,
        repeat_limit: data.repeat_limit,
        reproduce_limit: data.reproduce_limit,
      });
    } else {
      reset({
        ...initialState,
        //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
      }
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      ...initialState,
      //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
    });
  };

  const onSubmit = async (data: FormValues) => {


    let body = {
      id_accred_standart: accredStandart.id,
      value_from: data.value_from,
      value_to: data.value_to,
      inlab_stdr_deviation: data.inlab_stdr_deviation,
      interlab_stdr_deviation: data.interlab_stdr_deviation,
      repeat_limit: data.repeat_limit,
      reproduce_limit: data.reproduce_limit
    };
    try {
      if (isEdit && data.id) {
        await updateStandartAccuracy({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandartAccuracy(body).unwrap();
        notify(t('addSuccess'), 'success');
      }

      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else {
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '230px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'value_from'}
              label={t('accred.standart.tabs.controlAccuracy.value')+ ' ' + t('from')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'value_to'}
              label={t('accred.standart.tabs.controlAccuracy.value')+ ' ' + t('to')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'inlab_stdr_deviation'}
              label={t('accred.standart.tabs.controlAccuracy.intraLabDeviation')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'interlab_stdr_deviation'}
              label={t('accred.standart.tabs.controlAccuracy.interLabDeviation')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'repeat_limit'}
              label={t('accred.standart.tabs.controlAccuracy.repeatabilityLimit')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumericInput
              control={control}
              name={'reproduce_limit'}
              label={t('accred.standart.tabs.controlAccuracy.reproducibilityLimit')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {'-'}
                  </Typography>
                ),
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface Props extends Omit<BoxProps, 'hidden' | 'id'> {
  value: number;
  index: number;
  name?: string;
}

const UITabPanel: React.FC<Props> = ({ children, value, index, name = '', ...rest }) => {
  return (
    <Box
      component='div'
      hidden={value !== index}
      id={`${name}-panel-${index}`}
      aria-labelledby={`${name}-${index}`}
      {...rest}
      sx={{
        p: 1,
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export default UITabPanel;

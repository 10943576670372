import { Box } from '@mui/material';
import React from 'react';
import Layout from 'shared/layout';
//import SingleRoom from './ui/single-room';
import UITabs from 'shared/ui/ui-tabs';
import CertRequestList from './cert-request/list';

import { useTranslation } from 'react-i18next';
import TestProgramList from '../test-program';

const Certification: React.FC = () => {
  const { t } = useTranslation(['certification', 'common', 'errors']);

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <UITabs
          tabs={[
            {
              component: <CertRequestList />,
              label: t('certRequest.plural'),
              href: 'list',
            },
            {
              component: <TestProgramList />,
              label: t('testProgram.plural'),
              href: 'test-prog',
            },
            {
              component: <Registry />,
              label: t('registry.plural'),
              href: 'registry',
            },
          ]}
          name='cert'
        />
      </Box>
    </Layout>
  );
};



const TestProgram: React.FC = () => {
  return (
      <div>
          <h1>TestProgram</h1>
          {/* Add your content here */}
      </div>
  );
};

const Registry: React.FC = () => {
  return (
      <div>
          <h1>Registry</h1>
          {/* Add your content here */}
      </div>
  );
};

export default Certification;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { Box, Button, Stack, TextField, TextFieldProps, Typography, IconButton, Link } from '@mui/material';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteLaboratoryMutation, useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { useGetStaffQuery } from 'entities/staff/api';
import { AddEquipmentModal } from 'pages/equipment/equipment-modal/equipment-modal';
import { EquipmentModalRef } from 'pages/equipment/equipment-modal/types';
import { WarningLabModal } from 'features/warning-lab-modal';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { AddCircle } from '@mui/icons-material';

import { useGetEquipmentQuery } from 'entities/equipment/api';

import { Equipment } from 'entities/equipment/model';
import { useNavigate } from 'react-router-dom';

import LongtextHide from 'shared/ui/long-text-hide';

import { bgColorRule } from 'shared/ui/ui-table/types';
import { Colors } from 'app/constants';
import { WarningEquipModal } from 'features/warning-equip-modal';
import { EnvCondDeleteModalRef } from 'features/types';
import { Request, RequestFile } from 'entities/request/model';
import { useGetRequestsQuery } from 'entities/request/api';
import moment from 'moment';
import LongTextHide from 'shared/ui/long-text-hide';

import FilePresentIcon from '@mui/icons-material/FilePresent';
import Breadcrumb from 'shared/ui/breadcrumb';
import UIDatePicker from 'shared/inputs/date-picker';
import dayjs from 'dayjs';

const RequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<EquipmentModalRef>(null);
  const modalWarningRef = useRef<EnvCondDeleteModalRef>(null);

  const { data: laboratories } = useGetLaboratoriesQuery({});

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      decision_nn: '',
      client_name: '',
      allproduction: '',
      /*name: '',
      factory_number: '',
      inv_number: '',*/
      page: 1,
      fromDate: '',
      toDate: '',
      sort: '-date_create',
    },
  });

  const { data: records, isFetching } = useGetRequestsQuery({
    //labId: data.id,
    //sort: '-date_create',
    'per-page': 10,
    ...filterParams,
  });

  //console.log(records?.items.map((el) => el.allProductions.map((el) => el.unit.name)));

  const handleDelete = (id: number) => {
    modalWarningRef.current?.open(id)
  }

  const equipHeaders = [
    {
      label: t('request.list.staffFio'),
      align: "center" as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}} />
    },
    {
      label: t('request.list.decisionNumber'),
      align: 'center' as const,
      sortable: true,
      sortField: 'decision_nn',
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.dateCreate'),
      align: 'center' as const,
      sortable: true,
      sortField: 'date_create',
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.requesterName'),
      align: 'center' as const,
      sortable: true,
      sortField: 'client_name',
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.productions'),
      align: 'center' as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.dateGetRejectSamples'),
      align: 'center' as const,
      sortable: true,
      sortField: 'date_samples',
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.files'),
      align: 'center' as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('request.list.actions'),
      align: 'center' as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
  ];

  const rules: bgColorRule[] = [
    /*{
      field: 'date_end',
      rule: [
        { value: null, color: Colors.RED },
      ]
    },*/
    {
      field: 'sort_request',
      rule: [
        //10,15 -> red, 20 -> yellow, 30,35 -> green, 40 -> silver
        //{value:0, color: Colors.PURPLE},
        { value: 10, color: Colors.LIGHT_RED },
        { value: 15, color: Colors.LIGHT_RED },
        { value: 20, color: Colors.PASTEL_ORANGE },
        { value: 30, color: Colors.LIGHT_GREEN },
        { value: 35, color: Colors.LIGHT_GREEN },
        { value: 40, color: Colors.SILVER },
        //{value:3, color: Colors.PALE_BLUE},
      ]
    },
  ];

  const breadcrumbs = [
    { label: t('request.list.title') }
  ];

  return (
    <Layout>
      <Box>
        <Breadcrumb items={breadcrumbs} />
        <Stack direction={'row'} mb={'2rem'}>
          <Typography variant='h2'>{t('request.list.title')}</Typography>
          {/**<IconButton onClick={() => modalRef.current?.open()} aria-label="add">
            <AddCircle />
          </IconButton>*/}
        </Stack>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            my: 2,
          }}
        >
          <TextField
            label={t('request.list.decisionNumber')}
            variant='outlined'
            onChange={(e) => onChangeFilterParams({ decision_nn: e.target.value })}
          />
          <TextField
            label={t('request.list.requesterName')}
            variant='outlined'
            onChange={(e) => onChangeFilterParams({ client_name: e.target.value })}
          />
          <TextField
            label={t('request.list.productions')}
            variant='outlined'
            onChange={(e) => onChangeFilterParams({ allproduction: e.target.value })}
          />
          <UIDatePicker
            value={filterParams.fromDate ? dayjs(filterParams.fromDate).toDate() : null}
            TextFieldProps={{ label: t('dateFrom') }}
            //@ts-ignore
            onChange={(date: Date | null) => onChangeFilterParams({ fromDate: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
            disabled={false}
          />
          <UIDatePicker
            value={filterParams.toDate ? dayjs(filterParams.toDate).toDate() : null}
            TextFieldProps={{ label: t('dateTo') }}
            //@ts-ignore
            onChange={(date: Date | null) => onChangeFilterParams({ toDate: date ? dayjs(date).format('YYYY-MM-DD') : '' })}
            disabled={false}
          />
        </Stack>
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          onSort={onChangeFilterParams}
          orderDirection={'desc'}
          orderByField={'date_create'}
          headers={equipHeaders}
          bgColorRules={rules}
          columns={[
            {
              render: (row: Request) => row.staff.fio ?? '-',
            },
            {
              render: (row: Request) => row.decision_nn ?? '-',
            },
            {
              render: (row: Request) => moment(row.date_create).format('DD.MM.YYYY') ?? '-',
            },
            {
              render: (row: Request) => row.client_name ?? '-',
            },
            {
              render: (row: Request) => {
                const text = row.allProductions.map((el) => el.name + " (" + el.test_quantity + " " + ((el.unit && el.unit.name) ? el.unit.name : "") + ")").join('; ') ?? '-';
                return (<LongTextHide text={text} />);
              },
            },
            {
              render: (row: Request) => row.date_samples ? moment(row.date_samples).format('DD.MM.YYYY') : '-',
            },
            {
              render: (row: Request) => {
                const filesTestProg = (row.files.testProg !== false) ? row.files.testProg as RequestFile[] : null;
                const files = Object.keys(row.files).filter((el) => {
                  let file = row.files[el as keyof Request['files']];
                  return (file !== false && file !== "" && el !== "testProg");
                }).map(function (el) {
                  let file = row.files[el as keyof Request['files']];
                  return file as RequestFile;
                });
                return (
                  <Stack spacing={1} justifyContent='center'>
                    {files.map((file: RequestFile, index: number) => {
                      return (<Typography key={index}><FilePresentIcon />{file.url ? (<Link target="_blank" href={"https://prog.i-test.uz" + file.url as string}>{file.label}</Link>) : file.label}</Typography>);
                    })}
                    {filesTestProg && filesTestProg.map((file: RequestFile, index: number) => {
                      return (<Typography key={index}><FilePresentIcon />{file.url ? (<Link target="_blank" href={"https://prog.i-test.uz" + file.url as string}>{file.label}</Link>) : file.label}</Typography>);
                    })}
                  </Stack>
                );


              },
            },
            /*{
              render: (row: Equipment) => row.inv_number ?? '-',
            },
            {
              render: (row: Equipment) => {
                if (row.equipmentVerificationsOne.length === 0) return '-';
                return row.equipmentVerificationsOne[0].exp_date ?? '-'
              },
            },
            {
              render: (row: Equipment) => {
                const text = row.UsedAccredStandart.map((el) => el.name).join(', ') ?? '-';
                return (<LongtextHide text={text} />);
              },
            },*/
            {
              render: (row: Request) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='observe'
                    onClick={() => navigate(`/request/view/${row.id}`)}
                  />
                  {/**<UIActionButton icon='edit' onClick={() => modalRef.current?.open(row, laboratories?.items)} />
                  <UIActionButton icon='delete' onClick={() => handleDelete(row.id)} />*/}
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
        <AddEquipmentModal ref={modalRef} />
        <WarningEquipModal ref={modalWarningRef} />
      </Box>
    </Layout>
  );
};

export default RequestList;
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ErrorStatus, DeleteModalRef } from './types';
import { useTranslation } from 'react-i18next';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

interface DeleteModalProps<T extends Record<string, any>> {
  //mutationFunction: (body: T) => Promise<any>;
  mutationFunction: MutationTrigger<MutationDefinition<T, any, any, any>>;
  prepareRequestBody: (id: number, additionalData?: Record<string, any>) => T;
  successMessage?: string;
  errorMessage?: string;
}

export const DeleteModal = forwardRef<DeleteModalRef, DeleteModalProps<Record<string, any>>>(
  ({ mutationFunction, prepareRequestBody, successMessage, errorMessage }, ref) => {
    const { t } = useTranslation();
    const [id, setId] = useState<number | null>(null);
    const [additionalData, setAdditionalData] = useState<Record<string, any> | null>(null);
    const [open, setOpen] = useState(false);
    const [errorStatus, setErrorStatus] = useState<ErrorStatus | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        open: handleOpen,
        close: handleClose,
      }),
      []
    );

    const handleOpen = (id: number, additionalData?: Record<string, any>) => {
      setId(id);
      setAdditionalData(additionalData || {});
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setErrorStatus(null);
    };

    const handleSubmit = async () => {
      if (id === null) return;
    
      try {
        const body = prepareRequestBody(id, additionalData || {});
        const res = await mutationFunction(body).unwrap();
    
        notify(successMessage ?? t('deleteSuccess'), 'success');
        setOpen(false);
        setErrorStatus(null);
        console.log('fulfilled', res);
      } catch (error) {
        notify(errorMessage ?? t('deleteError'), 'error');
        setOpen(false);
        setErrorStatus(null);
        console.error('rejected', error);
      }
    };
    
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '1600px',
            width: '26vw',
            //p: 3,
            //overflowY: 'visible',
          },
        }}
      >
        <ModalHeader
          title={t('toast.warning')}
          handleClose={handleClose}
        />
        <DialogContent style={{ height: '100px' }} className='scrollbar'>
          <Stack spacing={4} mt={3}>
            <Typography variant='h3' textAlign={'center'}>
              {errorStatus?.error.data.error ?? t('warning_delete')}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' onClick={handleSubmit}>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog >
    );
  });

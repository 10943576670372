import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import CardActions from '@mui/material/CardActions';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import DetailView from '../detail-view';

import { CollapseCardProps } from './types';


const CollapseCard: React.FC<CollapseCardProps> = ({
  children,
  title,
  collapsed = true
}) => {
  const [open, setOpen] = useState(!collapsed);

  return (    
      <Card sx={{ minWidth: 300, border: "1px solid rgba(211,211,211,0.6)" }}>
        <CardHeader
          title={title} onClick={(e: React.MouseEvent<HTMLElement>) => {
            const tg = e.target as HTMLElement;
            const targetType = tg.tagName.toLowerCase();
            //console.log(targetType!=='button');
            if(targetType!=='button' && targetType!=='svg' && targetType!=='path' && targetType!=='icon' && targetType!=='svg' && targetType!=='input' && targetType!=='label' && targetType!=='select' && targetType!=='option' && targetType!=='textarea' && targetType!=='a'){
              setOpen(!open);
            }
          }}
          action={
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        >
        </CardHeader>
        {/**<div style={{ backgroundColor: 'rgba(211,211,211,0.4)'}}>*/}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent sx={{ paddingTop:0 }}>
            {children}
            {/**<CardActions disableSpacing>
              <IconButton aria-label="add">
                <AddIcon />
              </IconButton>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DetailView data={
                    [
                      {label: 'Наименование оборудования', value: 'Изолирующий трансформатор GL5060', align: 'left'},
                      {label: 'Изготовитель', value: 'КНР, "GWINSTEK"'},
                      {label: 'Инвентарьный номер', value: '30'}]} />
                </Grid>
                <Grid item xs={6}>
                <DetailView data={
                    [
                      {label: 'Наименование оборудования', value: 'Изолирующий трансформатор GL5060', align: 'left'},
                      {label: 'Изготовитель', value: 'КНР, "GWINSTEK"'},
                      {label: 'Инвентарьный номер', value: '30'}]} />
                </Grid>
              </Grid>
            </Box>
            
            <Container sx={{ height: 36, lineHeight: 2 }}>This is the Card Content Area</Container>*/}
          </CardContent>
        </Collapse>
        {/**</div>*/}
      </Card>
    
  );
}

export default CollapseCard;
import { setStoreInEimzo } from 'app/vendors/eimzo';
import { AppStore } from 'app/store';
import { SystemUser } from 'entities/auth/model/types';
import { getUserCertificates, signIn } from 'entities/auth/model/slice';

const authConfig = async (store: AppStore) => {
  setStoreInEimzo(store);
  store.dispatch(getUserCertificates());
  const token = localStorage.getItem('token');
  let user;
  const localUser = localStorage.getItem('user');
  if (localUser) {
    user = JSON.parse(localUser) as SystemUser;
  }
  if (token && user) {
    store.dispatch(signIn({ user, token }));
  }
};

export default authConfig;

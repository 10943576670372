import Layout from 'shared/layout';
import { Button, Card, List, ListItemButton, Stack } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'app/hooks/redux';
import { Navigate } from 'react-router-dom';
import { Container } from '@mui/system';

const HomePage: React.FC = () => {
  const { authorized, userInfo } = useAppSelector((state) => state.auth);

  if (!authorized) {
    return <Navigate to='/auth' />;
  }

  return (
    <Layout>
      {userInfo && (
        <Container>
          <Card>
            <List>
              <ListItemButton>
                {userInfo.fio} ({userInfo.pinfl})
              </ListItemButton>
            </List>
          </Card>
        </Container>
      )}
    </Layout>
  );
};

export default HomePage;

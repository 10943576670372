import { baseApi } from '../base-api';
import { TestProgramMaterialOut } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramMaterialOutBody } from '../../model/request-body';

const testProgramMaterialOutApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Materials
    getTestProgramMaterials: builder.query<
      ResponseWithPagination<TestProgramMaterialOut>,
      RequestWithPagination<{ id_test_program: number; id_test_program_results: number }>
    >({
      query: ({ id_test_program, id_test_program_results, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs`,
        params,
      }),
      providesTags: ['TestProgramMaterialOut'],
    }),
    getTestProgramMaterialById: builder.query<TestProgramMaterialOut, {id: string, id_test_program: string, id_test_program_results: string}>({
      query: ({ id, id_test_program, id_test_program_results }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramMaterialOut'],
    }),
    createTestProgramMaterial: builder.mutation<
      DefaultResponse,
      TestProgramMaterialOutBody & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    updateTestProgramMaterial: builder.mutation<
      DefaultResponse,
      TestProgramMaterialOutBody & { id: number; id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id, id_test_program, id_test_program_results, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    deleteTestProgramMaterial: builder.mutation({
      query: ({id, id_test_program, id_test_program_results}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-outs/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
    createMaterialFromMethod: builder.mutation<
      DefaultResponse,
      { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/material-from-method`,
        body,
      }),
      invalidatesTags: ['TestProgramMaterialOut'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramMaterialsQuery,
  useGetTestProgramMaterialByIdQuery,
  useCreateTestProgramMaterialMutation,
  useUpdateTestProgramMaterialMutation,
  useDeleteTestProgramMaterialMutation,
  useCreateMaterialFromMethodMutation,
} = testProgramMaterialOutApi;
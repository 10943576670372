import { Box, Stack, TextField, FormControl, IconButton } from '@mui/material';
import {
  FormValues,
  periodicyUnits
} from 'pages/equipment/equipment-modal/initialState';

import React from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import UIActionButton from 'shared/ui/ui-action-button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


interface FourthStepProps {
  fields: FieldArrayWithId<FormValues, 'equipmentServiceWorks'>[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, 'equipmentServiceWorks'>;
  control: Control<FormValues, any>;
}

const MaintenanceStep: React.FC<FourthStepProps> = ({ fields, remove, append, control }) => {
  const {t} = useTranslation()

  

  const addCharHandle = () => {
    append({
      name: '',
      periodicy: null,
      unit: {label: '', value: ''},
    });
  };

  const deleteCharHandle = (techIndex: number) => {
    remove(techIndex);
  };

  const validateWorks = (value: any, equipmentServiceWork: any, field: string) => {
    if(field === 'unit'){//console.log(value, equipmentServiceWork);
      if (
        ((equipmentServiceWork.name && equipmentServiceWork.name!=='') 
        || (equipmentServiceWork.periodicy && equipmentServiceWork.periodicy!=='')) 
        && (!value.value || value.value==='')
      ) {//console.log(value);
        return t('required_fields'); 
      }
    }
    if(field === 'periodicy'){
      if (
        ((equipmentServiceWork.name && equipmentServiceWork.name!=='') 
        || (equipmentServiceWork.unit.value && equipmentServiceWork.unit.value!=='')) 
        && (!value || value==='')
      ) {
        return t('required_fields');        
      }
    }
    if(field === 'name'){//console.log(value, equipmentServiceWork.periodicy, equipmentServiceWork.unit);
      if (
        ((equipmentServiceWork.periodicy && equipmentServiceWork.periodicy!='') 
        || (equipmentServiceWork.unit.value && equipmentServiceWork.unit.value!='')) 
        && (!value || value=='')
      ) {//console.log(value, equipmentServiceWork);
        return t('required_fields');        
      }
    }

    return true;
  }

  return (
    <Box>
      <Stack
        height={'100%'}
        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
        className='scrollbar'
        gap={'1.5rem'}
        width={'100%'}
      >
        {fields.map((tech, index) => (
          <Stack
            direction={'row'}
            gap={'2rem'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            mb={fields.length - 1 === index ? '3rem' : ''}
            key={tech.id}
          >
            <FormControl>
              <FormTextInput
                control={control}
                name={`equipmentServiceWorks.${index}.name`}
                label={t('maintenanceDesc')}
                variant='standard'
                sx={{ flex: '1' }}
                rules={{
                  //required: true,
                  validate: (value, values) => validateWorks(value, values.equipmentServiceWorks[index], 'name'),
                }}
              />
            </FormControl>
            <FormControl>
              <FormTextInput
                control={control}
                name={`equipmentServiceWorks.${index}.periodicy`}
                type='number'
                label={t('periodicity')}
                variant='standard'
                sx={{ flex: '0.5' }}
                rules={{
                  //required: true,
                  validate: (value, values) => validateWorks(value, values.equipmentServiceWorks[index], 'periodicy'),
                }}
              />
            </FormControl>
            <FormControl>
              <FormSelectInput
                options={periodicyUnits}
                control={control}
                name={`equipmentServiceWorks.${index}.unit`}
                getOptionLabel={(option) => option.label || "" }
                isOptionEqualToValue={(option,value) => value.value === undefined || String(value.value) === "" || option.value === value.value}
                TextFieldProps={{
                  variant: 'standard',
                  label: t('measurement'),
                  size: 'small',
                }}
                rules={{
                  //required: true,
                  validate: (value, values) => validateWorks(value, values.equipmentServiceWorks[index], 'unit'),
                }}
                sx={{ flex: '0.5', margin: '0' }}
              />
            </FormControl>

            <Stack direction={'row'} gap={'1rem'} mx={'2rem'}>
              {fields.length > 1 && (
                <IconButton aria-label="delete" onClick={() => deleteCharHandle(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton aria-label="add" onClick={() => addCharHandle()}>
                <AddIcon />
              </IconButton>
              
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default MaintenanceStep;

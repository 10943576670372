import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import {
  DefaultResponse,
  RequestWithPagination,
  ResponseWithPagination,
} from 'app/utils/types';
import {
  /*CreateEnvRecordBody,
  CreateEnvResponse,
  EnvironmentRecord,
  Laboratory,
  SignEnvRecordBody,
  UpdateLaboratoryBody,*/
  Protocol, TestType, 
} from '../model';

export const protocolApi = createApi({
  reducerPath: 'protocolApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'TestType', 'Protocol', 'ProtocolObject'
  ],
  endpoints: (builder) => ({
    getTestTypes: builder.query<ResponseWithPagination<TestType>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/test-types',
        params
      }),
      providesTags: ['TestType'],
    }),
    getProtocols: builder.query<ResponseWithPagination<Protocol>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/protocols',
        params
      }),
      providesTags: ['Protocol'],
    }),
    getProtocolById: builder.query<Protocol, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/protocols/${id}`,
        params: {
          'per-page': 0,
        },
      }),
    }),
    /*getProtocolObjects: builder.query<
      ResponseWithPagination<RequestProduction>, 
      RequestWithPagination<{ id_protocol: number }>
    >({
      query: ({ id_protocol, ...params }) => ({
        method: 'GET',
        url: `/protocols/${id_protocol}/objects`,
        params
      }),
      providesTags: ['ProtocolObject'],
    }),*/
    /*getLaboratoryEnvironmentRecords: builder.query<
      ResponseWithPagination<EnvironmentRecord>,
      RequestWithPagination<{ labId: number }>
    >({
      query: ({ labId, ...params }) => ({
        method: 'GET',
        url: `/laboratories/${labId}/environments`,
        params,
      }),
    }),
    createNewEnvironmentRecord: builder.mutation<CreateEnvResponse, CreateEnvRecordBody>({
      query: ({ labId, ...body }) => ({
        method: 'POST',
        url: `/laboratories/${labId}/environments`,
        body,
      }),
      invalidatesTags: ['Laboratory'],
    }),
    signEnvironmentRecordById: builder.mutation<{}, SignEnvRecordBody>({
      query: ({ labId, id, ...body }) => ({
        method: 'POST',
        url: `/laboratories/${labId}/environments/${id}/sign`,
        body,
      }),
      invalidatesTags: ['Laboratory'],
    }),
    cancelEnvironmentRecordById: builder.mutation<{}, SignEnvRecordBody>({
      query: ({ labId, id, ...body }) => ({
        method: 'POST',
        url: `/laboratories/${labId}/environments/${id}/cancel`,
        body,
      }),
      invalidatesTags: ['Laboratory'],
    }),
    updateLaboratory: builder.mutation<
      DefaultResponse,
      UpdateLaboratoryBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/laboratories/${id}`,
        body,
      }),
      invalidatesTags: ['Laboratory'],
    }),
    createLaboratory: builder.mutation<DefaultResponse, UpdateLaboratoryBody>({
      query: (body) => ({
        method: 'POST',
        url: `/laboratories`,
        body,
      }),
      invalidatesTags: ['Laboratory'],
    }),
    deleteLaboratory: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/laboratories/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Laboratory'],
    }),*/
  }),
  //tagTypes: ['Laboratory'],
});

export const {
  useGetTestTypesQuery,
  useGetProtocolsQuery,
  useGetProtocolByIdQuery,
  //useGetProtocolObjectsQuery,
  /*useGetLaboratoriesQuery,
  useGetLaboratoryByIdQuery,
  useGetLaboratoryEnvironmentRecordsQuery,
  useLazyGetLaboratoryEnvironmentRecordsQuery,
  useSignEnvironmentRecordByIdMutation,
  useUpdateLaboratoryMutation,
  useCreateLaboratoryMutation,
  useCreateNewEnvironmentRecordMutation,
  useDeleteLaboratoryMutation,
  useCancelEnvironmentRecordByIdMutation*/
} = protocolApi;

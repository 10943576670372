import React, { useState } from 'react';
import './index.sass';
import { AppBar, Badge, Box, Button, ButtonBase, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountMenu from 'shared/account-menu';
import { HEADER_HEIGHT } from 'app/constants';
import { toggleSidebar } from 'entities/app/model/slice';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const { authorized } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <AppBar position='relative' className='header'>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          height: HEADER_HEIGHT,
          p: '5px 10px',
        }}
      >
        {authorized ? (
          <ButtonBase
            sx={{
              height: '40px',
              width: '40px',
              borderRadius: '50%',
            }}
            onClick={() => dispatch(toggleSidebar())}
          >
            <MenuIcon
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </ButtonBase>
        ) : (
          <Box>I-TEST</Box>
        )}
        <Box>
          {authorized ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <ButtonBase
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
              >
                <Badge
                  variant='standard'
                  badgeContent={Math.floor(Math.random() * 10) + 1}
                  color='error'
                >
                  <EmailIcon />
                </Badge>
              </ButtonBase>
              <ButtonBase
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
              >
                <Badge
                  variant='standard'
                  badgeContent={Math.floor(Math.random() * 10) + 1}
                  color='error'
                >
                  <NotificationsIcon />
                </Badge>
              </ButtonBase>
              <ButtonBase
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
                ref={(node) => {
                  setAnchorEl(node);
                }}
                onClick={() => setOpen(true)}
              >
                <AccountCircleIcon />
              </ButtonBase>
            </Stack>
          ) : (
            <Stack direction='row' spacing={2}>
              <Link to={'/auth'}>
                <Button color='success'>{t('login')}</Button>
              </Link>
              <Link to={'/auth'}>
                <Button color='secondary'>{t('registration')}</Button>
              </Link>
            </Stack>
          )}
        </Box>
        {anchorEl && (
          <AccountMenu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)} />
        )}
      </Stack>
    </AppBar>
  );
};

export default Header;

import { baseApi } from '../base-api';
import { CertRequest } from '../../model';
import { ResponseWithPagination, DefaultResponse } from 'app/utils/types';
import { createFormData } from 'app/utils';
import { CertRequestBody } from '../../model/request-body';

const testProgramApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test program
    getCertRequests: builder.query<ResponseWithPagination<CertRequest>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/cert-requests',
        params
      }),
      providesTags: ['CertRequest'],
    }),
    getCertRequestById: builder.query<CertRequest, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/cert-requests/${id}`,
        /*params: {
          'per-page': 0,
        },*/
      }),
      providesTags: ['CertRequest'],
    }),
    /*createTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    updateTestProgram: builder.mutation<
      DefaultResponse,
      TestProgramBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id}`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgram'],
    }),
    deleteTestProgram: builder.mutation({
      query: ({ id, params }) => ({
        method: 'DELETE',
        url: `/test-programs/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['TestProgram'],
    }),
    copyTestProgram: builder.mutation<
      TestProgram,
      { id: number }
    >({
      query: ({id}) => ({
        method: 'POST',
        url: `/test-programs/${id}/copy`,
        //body,
      }),
      invalidatesTags: ['TestProgram'],
    }),*/
  }),
  overrideExisting: false,
});

export const {
  useGetCertRequestsQuery,
  useGetCertRequestByIdQuery,
  
} = testProgramApi;
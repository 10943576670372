import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AddLaboratoryModalRef } from './types';
import { Laboratory, UpdateLaboratoryBody } from 'entities/laboratories/model';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetEnvironmentConditionsQuery,
  useLazyGetEnvironmentConditionsQuery,
} from 'entities/dicts/api';
import { Colors } from 'app/constants';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { useGetStaffQuery, useLazyGetStaffQuery } from 'entities/staff/api';
import { Staff } from 'entities/staff/model';
import UISelect from 'shared/ui/ui-select';
import UIActionButton from 'shared/ui/ui-action-button';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  useCreateLaboratoryMutation,
  useUpdateLaboratoryMutation,
} from 'entities/laboratories/api';
import { handleResponse } from 'app/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
interface FormValues {
  name: string;
  id?: number;
  laboratoryConditions: boolean[];
  laboratoryConditionStaff: {
    value: Staff & {
      time: string;
    };
  }[];
}

export const AddLaboratoryModal = forwardRef<AddLaboratoryModalRef>((_, ref) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>();
  const {
    fields: staffFields,
    append: staffAppend,
    remove: staffRemove,
    insert: staffInsert,
  } = useFieldArray({
    control,
    name: 'laboratoryConditionStaff',
  });

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const { data: envConds } = useGetEnvironmentConditionsQuery(
    { page: 1 },
    { skip: !open }
  );
  const [getEnvConds] = useLazyGetEnvironmentConditionsQuery();
  const { data: staff } = useGetStaffQuery({}, { skip: !open });
  const [getStaff] = useLazyGetStaffQuery();

  const [updateLab] = useUpdateLaboratoryMutation();
  const [createLab] = useCreateLaboratoryMutation();
  const [staffData, setStaffData] = useState(staff?.items);

  const handleOpen = async (data?: Laboratory) => {
    if (data) {
      setIsEdit(true);
      const [eConds, staff] = await Promise.all([
        getEnvConds({ page: 1 }, true).then((res) => res.data?.items),
        getStaff({}, true).then((res) => res.data?.items),
      ]);
      reset({
        laboratoryConditions: eConds?.map((item) =>
          data.environmentConditions.some((el) => el.id === item.id)
        ),
        name: data?.name,
        laboratoryConditionStaff: data.laboratoryConditionStaff
          .map((item) => {
            const value = staff?.find((el) => el.id === item.id_staff);
            console.log({item})
            return {
              value: {
                ...value,
                time: item.time ?? '',
              },
            };
          })
          .filter((el) => el),
        id: data.id,
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsEdit(false);
      reset();
    }, 300);
  };

  const onStaffAppend = (item: Staff) => {
    staffAppend({
      value: {
        ...item,
        time: '',
      },
    });
  };

  const onStaffInsert = (index:number, item:Staff) => {
    staffInsert(index + 1, {
      value: {
        ...item,
        time:''
      }
    })
  }

  const onStaffRemove = (index: number) => {
    staffRemove(index);
  };

  useEffect(() => {
    setStaffData(staff?.items);
  }, [staff]);

  const onSubmit = async (data: FormValues) => {
    const body: UpdateLaboratoryBody = {
      name: data.name,
      laboratoryConditionStaff: data.laboratoryConditionStaff.map((el) => ({
        id_staff: el.value.id,
        time: el.value.time,
      })),
      laboratoryConditions: data.laboratoryConditions
        .map((el, index) => (el ? envConds?.items[index] : undefined))
        .filter((el) => el)
        .map((el) => ({
          id_environment_condition: el!.id,
        })),
    };

    try {
      setIsLoading(true);
      if (isEdit && data.id) {
        await handleResponse(await updateLab({ ...body, id: data.id }));
      } else {
        await handleResponse(await createLab(body));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <UIModalTitle>{isEdit ? t('edit_laboratory') : t('add_laboratory')}</UIModalTitle>
      <Stack spacing={4} mt={5}>
        <FormControl>
          <FormTextInput
            control={control}
            name='name'
            label={t('lab_name')}
            rules={{
              required: true,
            }}
          />
        </FormControl>
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              ml: -2,
              mt: -2,
            }}
          >
            <Grid item flexGrow={1}>
              <Box
                sx={{
                  border: `1px solid ${Colors.BORDER}`,
                  borderRadius: '8px',
                  p: 2,
                  height: '35vh',
                }}
              >
                <Stack
                  gap={'0.5rem'}
                  sx={{ overflowY: 'scroll', overflowX: 'hidden', height: '30vh' }}
                  className='scrollbar'
                >
                  {envConds?.items.map((item, idx) => (
                    <FormControl key={item.id}>
                      <FormCheckboxInput
                        control={control}
                        name={`laboratoryConditions.${idx}`}
                        label={item.name}
                      />
                    </FormControl>
                  ))}
                </Stack>
              </Box>
            </Grid>
            <Grid item flexGrow={3}>
              <Box
                sx={{
                  border: `1px solid ${Colors.BORDER}`,
                  borderRadius: '8px',
                  p: 2,
                  height: '35vh',
                }}
              >
                <FormControl sx={{ mb: 3 }}>
                  <UISelect
                    options={staffData ?? []}
                    getOptionLabel={(option) => option.fio}
                    value={undefined}
                    onChange={(_, option) => {
                      onStaffAppend(option!);
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        onClick={() => {
                          if (!!staffFields.some((item) => item.value.id === option.id)) {
                            setValue(
                              'laboratoryConditionStaff',
                              staffFields.filter((item) => item.value.id !== option.id)
                            );
                          } else {
                            onStaffAppend(option!);
                          }
                        }}
                      >
                        {!!staffFields.some((item) => item.value.id === option.id) ? (
                          <Stack gap='1rem' flexDirection={'row'}>
                            <CheckBoxIcon fontSize='small' />
                            <li style={{ fontWeight: '700' }}>{option.fio}</li>
                          </Stack>
                        ) : (
                          <li style={{ marginLeft: '2rem' }}>{option.fio}</li>
                        )}
                      </li>
                    )}
                    disableClearable
                    disableCloseOnSelect
                    inputValue=''
                    TextFieldProps={{
                      label: t('choose_staff_from_list'),
                    }}
                  />
                </FormControl>
                <Stack
                  spacing={2}
                  sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
                  className='scrollbar'
                  height={'15rem'}
                  pb={'2rem'}
                >
                  {staffFields?.map((item, index) => (
                    <Stack key={item.id} direction='row' justifyContent='space-between'>
                      {index === 0 ? (
                        <Typography variant='h4'>{item.value.fio}</Typography>
                      ) : staffFields[index].value.id !==
                        staffFields[index - 1].value.id ? (
                        <Typography variant='h4'>{item.value.fio}</Typography>
                      ) : (
                        <Typography> </Typography>
                      )}
                      <Stack direction='row' justifyContent='space-between' spacing={2}>
                        <Stack gap={'1rem'}>
                          <FormControl sx={{ maxWidth: '140px' }}>
                            <FormFormatedInput
                              format='##:##'
                              control={control}
                              name={`laboratoryConditionStaff.${index}.value.time`}
                              rules={{
                                required: true,
                                pattern: {
                                  value: item.value.time? /^/:/^([01]?[0-9]|2[0-3])?(:[0-5]?[0-9])?$/,
                                  message: t('errors:time_format_hh_mm'),
                                },
                              }}
                              InputProps={{
                                endAdornment: <AccessTimeIcon />,
                                placeholder: '12:34',
                              }}
                            />
                          </FormControl>
                          {staffFields[index + 1] ? staffFields[index].value.id !== staffFields[index + 1].value.id && <UIActionButton
                        icon='add'
                        onClick={() => onStaffInsert(index,item.value)}
                        sx={{marginBottom:'2rem'}}
                      /> : <UIActionButton
                        icon='add'
                        onClick={() => onStaffInsert(index,item.value)}
                        sx={{marginBottom:'2rem'}}
                      />}
                        </Stack>
                          <UIActionButton
                          icon='delete'
                          onClick={() => onStaffRemove(index)}
                        />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack spacing={2} mt={3} direction='row' justifyContent='flex-end'>
        <Button color='secondary' onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </Stack>
    </Dialog>
  );
});

import { AccredSpecific, AccredStandart } from 'entities/accreditation/model';

export interface FormValues {
  id?:number;
  id_accred_specific: AccredSpecific | null;
  id_accred_standart_child: AccredStandart | null;
}


export const initialState = {
  id_accred_specific: null,
  id_accred_standart_child: null,
}
import { Box, BoxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavData } from '../lib/get-nav-data';
import { SidebarNavItem } from './sidebar-nav-item';
import { TFunction } from 'i18next';

export const SidebarNav: React.FC<Omit<BoxProps, 'children'>> = (props) => {
  const { t } = useTranslation();
  const NAVDATA = NavData(t as TFunction['$TFunctionBrand']);

  return (
    <Box {...props}>
      {NAVDATA.map((el, idx) => {
        return <SidebarNavItem item={el} key={idx} />;
      })}
    </Box>
  );
};

import React from 'react';
import { MuiFileInput, MuiFileInputProps } from "mui-file-input";
import { Controller, FieldValues } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { BaseControllerInputProps } from 'shared/inputs/types';

export const FormFileInput = <T extends FieldValues>(
    props: BaseControllerInputProps<T, MuiFileInputProps>
  ) => {
    const { control, name, onChange, rules, regExp, ...rest } = props;
    const { t } = useTranslation();
  
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          required: rules?.required === true ? t('errors:required_field') : rules?.required,
        }}
        render={({ field, fieldState }) => {
          return (
            <MuiFileInput
              {...rest}
              {...field}
              //helperText={fieldState.invalid ? "File is invalid" : ""}
              //value={field.value || null}
              //onChange={onChange}
              error={fieldState.invalid}
              helperText={fieldState.error?.message || (fieldState.invalid ? 'File is invalid' : '')}
              //helperText={fieldState.error?.message}
            />)
        }}
      />);
      {/**<Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          required: rules?.required === true ? t('errors:required_field') : rules?.required,
        }}
        render={({ field: { value = '', ...field }, fieldState }) => {
          const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value && regExp && !regExp.test(event.target.value)) return;
            onChange?.(event);
            field.onChange(event);
          };
  
          return (
            <TextField
              value={value}
              {...rest}
              {...field}
              onChange={onChangeHandler}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          );
        }}
      />*/}
    
  };
  

import { Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CancelModalRef } from './types';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { handleResponse } from 'app/utils';
import { useCancelEnvironmentRecordByIdMutation } from 'entities/laboratories/api';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';

export interface CancelData {
  id: number;
  id_lab: number;
  doc_json: string;
}

export const CancelModal = forwardRef<CancelModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [data, setData] = useState<CancelData>();
  const [note, setNote] = useState<string>("");
  const [open, setOpen] = useState(false);

  const [cancelEnv] = useCancelEnvironmentRecordByIdMutation()

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data: CancelData) => {
    setData(data);
    setOpen(true);
    console.log(data.doc_json);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
        const signature = await eimzo.sign(data?.doc_json ?? '');
          await handleResponse(await cancelEnv({ 
            id: data?.id ?? 0,
            labId:data?.id_lab ?? 0,
            signature,
            note
           }));
        }
       finally {
        handleClose();
        notify('', 'success')
      }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <UIModalTitle>{t('toast.warning')}</UIModalTitle>
      <Stack spacing={4} mt={5}>
        <Typography variant='h3' textAlign={'center'}>
          {t('cancel_message')}
        </Typography>
        <TextField
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder={t('notion')}
          multiline
          rows={2}
          maxRows={4}
        />
      </Stack>
      <Stack spacing={2} mt={3} direction='row' justifyContent='flex-end'>
        <Button color='secondary' onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button type='submit' onClick={handleSubmit}>
          {t('sign')}
        </Button>
      </Stack>
    </Dialog>
  );
});

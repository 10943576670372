import { Material, MaterialModel } from 'entities/material/model';

export interface FormValues {
  id?: number | null;
  id_material: Material | null;
  id_material_model: MaterialModel | null;
  quantity: number | null;
}

export const initialState = {
  id_material: null,
  id_material_model: null,
  quantity: null,
}
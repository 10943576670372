import React, { useRef } from 'react';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import UITable from 'shared/ui/ui-table';
import CollapseCard from 'shared/ui/collapse-card';
import {
  useGetTestProgramMaterialsQuery,
  useDeleteTestProgramMaterialMutation,
  useCreateMaterialFromMethodMutation
} from 'entities/test-program/api';
import UIActionButton from 'shared/ui/ui-action-button';
import { useFilters } from 'app/hooks/use-filters';
import {
  TestProgramMaterialOut,
  TestProgramResults
} from 'entities/test-program/model';
import { AddCard, AddCircle } from '@mui/icons-material';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { MaterialOutModal } from './material-out-modal/material-out-modal';
import { MaterialOutModalRef } from './material-out-modal/types';
import notify from 'app/utils/notify';

interface Props {
  result: TestProgramResults;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
  id_test_program_results: number;
}

const MaterialOutList: React.FC<Props> = ({ result }) => {
  const { t } = useTranslation();
  const modalRef = useRef<MaterialOutModalRef>(null);
  
  const [deleteRec] = useDeleteTestProgramMaterialMutation();
  const [createMaterialsFromMethod, { isLoading: isLoadingCreate }] = useCreateMaterialFromMethodMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
    id_test_program_results: additionalData?.id_test_program_results as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number, id_test_program_results: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program, id_test_program_results });
    }
  };

  const { filterParams: materialOutFilterParams, onChangeFilterParams: onChangeMaterialOutFilterParams } = useFilters({
    initialFilterParams: { page: 1, 'per-page': 10 },
  });

  const { data: materialOuts, isFetching: isFetchingMaterialOuts } = useGetTestProgramMaterialsQuery({
    id_test_program: result.id_test_program,
    id_test_program_results: result.id,
    ...materialOutFilterParams,
  });

  const handleCreateMaterialsFromMethod = async () => {
    try {
      await createMaterialsFromMethod({
        id_test_program: result.id_test_program,
        id_test_program_results: result.id,
      }).unwrap();
      notify(t('operationSuccess'), 'success');
    } catch (e) {
      notify(t('operationError'), 'error');
    }
  }

  const materialOutHeaders = [
    { label: t('testProgram.material.name'), align: 'center' as const },
    { label: t('testProgram.material.mark'), align: 'center' as const },
    { label: t('unit'), align: 'center' as const },
    { label: t('testProgram.material.rate'), align: 'center' as const },
    { label: t('actions'), align: 'center' as const },
  ];

  const materialOutColumns = [
    { render: (row: TestProgramMaterialOut) => row.materialModel.material.name ?? '--' },
    { render: (row: TestProgramMaterialOut) => row.materialModel.name ?? '--' },
    { render: (row: TestProgramMaterialOut) => row.materialModel.unit.name ?? '--' },
    { render: (row: TestProgramMaterialOut) => row.quantity ?? '--' },
    {
      render: (row: TestProgramMaterialOut) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <UIActionButton
            icon="edit"
            tooltip={t('edit')}
            onClick={
              () => modalRef.current?.open(row)
            }
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, result.id_test_program as number, row.id_test_program_results)}
          />
        </Stack>
      ),
    },
  ];

  return (
    <>
      <CollapseCard
        title={t('testProgram.material.title')}
      >
        <Box display="flex" justifyContent="flex-begin" sx={{ mb: 1 }}>
          <Stack
            direction='row'
            spacing={1}
            sx={{
              my: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              {t('add')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCard />}
              onClick={() => handleCreateMaterialsFromMethod()}
            >
              {t('testProgram.material.fill')}
            </Button>
          </Stack>
        </Box>

        <UITable
          isLoading={isFetchingMaterialOuts}
          data={materialOuts?.items ?? []}
          headers={materialOutHeaders}
          columns={materialOutColumns}
          PaginationProps={{
            count: materialOuts?._meta.pageCount,
            page: materialOutFilterParams.page,
            onChange: (_, page) => onChangeMaterialOutFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
      <MaterialOutModal testProgramResult={result} ref={modalRef} />
    </>
  );
};

export default MaterialOutList;
import { baseApi } from './base-api';

// Reexport baseApi as testProgramApi
export const certificationApi = baseApi;

export {
  useGetCertRequestsQuery,
  useGetCertRequestByIdQuery,

} from './endpoints/cert-request';



import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CollapseCard from 'shared/ui/collapse-card';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentServReg, EquipmentServRegWork, EquipmentServiceWork, EquipmentShipping } from 'entities/equipment/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { useGetEquipmentServRegQuery, useGetEquipmentShippingQuery } from 'entities/equipment/api';
import { useGetRequestProductionsQuery } from 'entities/request/api';
import { useFilters } from 'app/hooks/use-filters';
import ShippingModal from './shipping-modal';
import ReturnModal from './return-modal';
import { CancelModal } from './cancel-modal';
import { ShippingModalRef, ReturnModalRef, CancelModalRef } from './types';
import moment from 'moment';
import { damageStatuses, workingCapacities } from './dictionaries';
import { SelectObject } from 'app/utils/types';
import notify from 'app/utils/notify';
import { Request, RequestFile, RequestProduction } from 'entities/request/model';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { ProtocolObject, mockProtocolObject, mockProtocolObjects } from 'pages/protocol/types';
import { Protocol } from 'entities/protocol/model';
import { useNavigate } from 'react-router-dom';

interface Props {
  protocol: Protocol;
}

export const Production: React.FC<Props> = ({ protocol }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const modalCancelRef = useRef<CancelModalRef>(null);
  const modalShippingRef = useRef<ShippingModalRef>(null);
  const modalReturnRef = useRef<ReturnModalRef>(null);

  /*const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //fromDate: '',
      //toDate: '',
      //sort: '-date_service',
    },
  });
  //console.log('equipment', equipment);
  const { data: records, isFetching } = useGetRequestProductionsQuery({
    id_request: request ? request.id : 0,
    ...filterParams,
  });*/

  const records = mockProtocolObjects;
  /*const handleCancel = (id : number, id_equipment : number, doc_json_shipping:string) => {
    if (doc_json_shipping) {
      modalCancelRef.current?.open({
        id,
        id_equipment,
        doc_json_shipping: doc_json_shipping.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }*/
  const headers = [
    { label: t('protocol.object.name'), align: "center" as const },
    { label: t('protocol.object.group'), align: "center" as const },
    { label: t('protocol.object.date'), align: "center" as const },
    { label: t('protocol.object.quantity'), align: "center" as const },
    { label: t('protocol.object.unit'), align: "center" as const },
    { label: t('protocol.object.testProg'), align: "center" as const },
    {
      label: t('actions'),
      align: 'center' as const,
    },
  ];

  const columns = [
    {
      render: (row: ProtocolObject) => row.name ?? '--',
    },
    {
      render: (row: ProtocolObject) => row.group ?? '--',
    },
    {
      render: (row: ProtocolObject) => row.date ? moment(row.date).format('DD.MM.YYYY') : '--',
    },
    {
      render: (row: ProtocolObject) => row.quantity ?? '--',
    },
    {
      render: (row: ProtocolObject) => row.unit ?? '--',
    },
    {
      render: (row: ProtocolObject) => row.testProg ?? '--',
    },
    {
      render: (row: ProtocolObject) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='edit'
            tooltip={t('edit')}
            onClick={() => alert('Изменить')}
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => alert('Удалить')}
          />
          <UIActionButton
            icon='observe'
            onClick={() => navigate(`/protocol-object/view/${row.id}`)}
          />
          {/**(!row.date_cancel && (row.date_shipping || row.date_return)) && (<UIActionButton 
            icon='cancel'
            tooltip={t('cancel')}
            onClick={() => handleCancel(row.id, row.id_equipment, row.doc_json_shipping)}
          />)*/}
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <>
      {/**<CollapseCard
        title={t('protocol.object.title')}
        collapsed={true}
      >*/}
      <UITable
        //isLoading={isFetching}
        //data={records?.items ?? []}
        data={records}
        headers={headers}
        columns={columns}
      /*PaginationProps={{
        count: records?._meta.pageCount,
        page: filterParams.page,
        onChange: (_, page) => onChangeFilterParams({ page }),
      }}*/
      />

      {/**</CollapseCard>*/}
      {/*<ShippingModal ref={modalShippingRef} equipment={equipment} />
      <ReturnModal ref={modalReturnRef} equipment={equipment} />
      <CancelModal ref={modalCancelRef} />*/}
    </>
  );
}

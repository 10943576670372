import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteEnvironmentMutation, useGetEnvironmentConditionsQuery, useGetUnitsQuery } from 'entities/dicts/api';
import { AddEnvModal } from 'features/add-environment-modal';
import { AddEnvConditionModalRef, EnvCondDeleteModalRef } from 'features/types';
import { WarningEnvModal } from 'features/warning-env-modal';
import React,{useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';

export const DictsEnvironmentConditionsPage = () => {
  const modalRef = useRef<AddEnvConditionModalRef>(null);
  const modalWarningRef = useRef<EnvCondDeleteModalRef>(null);
  const { t } = useTranslation();
  const {data: units} = useGetUnitsQuery({})

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page':10
    },
  });

  const { data, isFetching } = useGetEnvironmentConditionsQuery({ ...filterParams });

  const handleDelete = (id: number) => {
    modalWarningRef.current?.open(id)
  }
  return (
    <Layout
      title={t('environment_conditions')}
      topContent={
        <Button startIcon={<AddOutlined />} onClick={() => modalRef.current?.open()}>
          {t('create')}
        </Button>
      }
    >
      <Box>
        <UITable
          isLoading={isFetching}
          data={data?.items ?? []}
          headers={['№', t('name'), t('measurement'),t('actions')]}
          columns={[
            {
              render: (_row, idx) =>
                filterParams.page ? (filterParams.page - 1) * 10 + idx + 1 : idx + 1,
              nowrap: true,
            },
            { key: 'name' },
            {
              render: (row) => (
                <Typography>{units?.items.find(item => item.id === row?.id_unit)?.name}</Typography>
              ),
              nowrap: true,
            },
            {
              render: (row) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='edit'
                    onClick={() => modalRef.current?.open(row, units?.items)}
                  />
                  <UIActionButton
                    icon='delete'
                    onClick={() => handleDelete(row.id)}
                  />
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: data?._meta.pageCount,
            page: filterParams.page,
            onChange: (_e, page) => onChangeFilterParams({ page }),
          }}
        />
      </Box>
      <AddEnvModal ref={modalRef} />
      <WarningEnvModal ref={modalWarningRef} />
    </Layout>
  );
};

import { Box } from '@mui/material';
import React from 'react';
import Layout from 'shared/layout';
import SingleRoom from './ui/single-room';
import UITabs from 'shared/ui/ui-tabs';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';

const RoomsPage = () => {
  const { data } = useGetLaboratoriesQuery({});
  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <UITabs
          tabs={(data?.items ?? []).map((el) => ({
            component: <SingleRoom data={el} />,
            label: el.name,
            href: String(el.id),
          }))}
          name='room'
        />
      </Box>
    </Layout>
  );
};

export default RoomsPage;

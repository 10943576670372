import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { BaseControllerInputProps } from 'shared/inputs/types';

type Props = Omit<FormControlLabelProps, 'control'> & {
  CheckBoxProps?: CheckboxProps;
};

const FormCheckboxInput = <T extends FieldValues>(
  props: BaseControllerInputProps<T, Props>
) => {
  const { control, name, CheckBoxProps, onChange: onChangeCallback, ...rest } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = false, onChange } }) => {
        return (
          <FormControlLabel
            checked={value}
            onChange={(event, checked) => {
              //@ts-ignore
              onChange(checked);
              onChangeCallback?.(event, checked);
            }}
            control={<Checkbox {...CheckBoxProps} />}
            {...rest}
          />
        );
      }}
    />
  );
};

export default FormCheckboxInput;

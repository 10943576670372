import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartMaterialModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState,
} from './initialState';
import { useCreateStandartMaterialMutation, useUpdateStandartMaterialMutation } from 'entities/accreditation/api';
import { AccredStandart, AccredStandartMaterial } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { useGetMaterialsQuery, useGetModelsQuery } from 'entities/material/api';

interface Props {
  accredStandart: AccredStandart;
}

export const AccredStandartMaterialModal = forwardRef<AccredStandartMaterialModalRef, Props>(({ accredStandart }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { 
    control, 
    handleSubmit, 
    reset, 
    watch,
    //setValue, //formState, trigger 
  } = methods;
  const [createStandartMaterial, { isLoading: isLoadingCreate }] = useCreateStandartMaterialMutation();
  const [updateStandartMaterial, { isLoading: isLoadingUpdate } ] = useUpdateStandartMaterialMutation();

  const { data: materials, isFetching: isMaterialFetching } = useGetMaterialsQuery({
    'per-page': 0,
  });
  
  //const { data: groups, isFetching: isGroupFetching } = useGetGroupsQuery({});
  const [selectedMaterial, setSelectedMaterial] = useState('0');
  const { data: models, isFetching: isModelFetching } = useGetModelsQuery({
    'per-page': 0,
    id_material: selectedMaterial,
  });//, { skip: !selectedGroup || selectedGroup === '' });
  //const { data: laboratories, isFetching: isLaboratoryFetching } = useGetLaboratoriesQuery({});

  const handleMaterialChange = (id_material: string) => {
    //console.log(id_material);
    setSelectedMaterial(id_material);
  };

  const materialModel = watch('id_material_model');

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandartMaterial | undefined) => {
    if (data) {
      setSelectedMaterial(data.materialModel.material.id.toString());
      setIsEdit(true);
      reset({
        id: data.id,
        id_material: data.materialModel.material,
        id_material_model: data.materialModel,
        quantity: data.quantity ?? 0,
      });
    }else{
      setSelectedMaterial('0');
      reset({...initialState, 
        //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
        }
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({...initialState, 
      //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
    });
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.id_material_model) {
      return;
    }
    
    let body = {
      id_accred_standart: accredStandart.id,
      id_material_model: Number(data.id_material_model.id),
      quantity: Number(data.quantity),
    };
    
    try {
      if (isEdit && data.id) {
        await updateStandartMaterial({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandartMaterial(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      
      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else{
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '26vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '200px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isMaterialFetching}
                options={materials?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_material`}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.tabs.materials.name')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, option) => {
                  //@ts-ignore
                  handleMaterialChange((option && option.id) ? option.id.toString() : '0');

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isModelFetching}
                options={models?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_material_model`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.tabs.materials.mark')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, option) => {
                  
                  //@ts-ignore
                  //handleGroupChange((option && option.id) ? option.id.toString() : '0');

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormNumericInput
              control={control}
              name={'quantity'}
              label={t('accred.standart.tabs.materials.rate')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    { materialModel ? materialModel.unit.name : '-' }
                  </Typography>
                ),
              }}
            />
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

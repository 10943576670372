import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CollapseCard from 'shared/ui/collapse-card';
import { IconButton, Stack, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentServReg, EquipmentServRegWork, EquipmentServiceWork, EquipmentShipping } from 'entities/equipment/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { useGetEquipmentServRegQuery, useGetEquipmentShippingQuery } from 'entities/equipment/api';
import { useFilters } from 'app/hooks/use-filters';
import ShippingModal from './shipping-modal';
import ReturnModal from './return-modal';
import { CancelModal } from './cancel-modal';
import { ShippingModalRef, ReturnModalRef, CancelModalRef } from './types';
import moment from 'moment';
import { damageStatuses, workingCapacities } from './dictionaries';
import { SelectObject } from 'app/utils/types';
import notify from 'app/utils/notify';

interface Props {
  equipment: Equipment;
}

export const Shipping: React.FC<Props> = ({ equipment }) => {
  const { t } = useTranslation();

  const modalCancelRef = useRef<CancelModalRef>(null);
  const modalShippingRef = useRef<ShippingModalRef>(null);
  const modalReturnRef = useRef<ReturnModalRef>(null);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 2,
      //fromDate: '',
      //toDate: '',
      sort: '-date_service',
    },
  });
  //console.log('equipment', equipment);
  const { data: records, isFetching } = useGetEquipmentShippingQuery({
    id_equipment: equipment ? equipment.id : 0,
    ...filterParams,
  });

  const handleCancel = (id : number, id_equipment : number, doc_json_shipping:string) => {
    if (doc_json_shipping) {
      modalCancelRef.current?.open({
        id,
        id_equipment,
        doc_json_shipping: doc_json_shipping.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('eqShipping.title')}
            <UIActionButton
              icon='upload'
              tooltip={t('eqShipping.shipping')}
              onClick={() => modalShippingRef.current?.open()}
            />
          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            { label: t('eqShipping.dateShipping'), align:"center"  as const, },
            { label: t('eqShipping.dateReturn'), align:"center"  as const, },
            { label: t('eqShipping.placePurpose'), align:"center"  as const, },
            { label: t('eqShipping.workingCapacity'), align:"center"  as const, },
            { label: t('eqShipping.staff'), align:"center"  as const, },
            {
              label:t('actions'),
              align:'center'  as const,
            },
          ]}
          columns={[
            {
              render: (row: EquipmentShipping) => moment(row.date_shipping).format('DD.MM.YYYY') ?? '--',
            },
            {
              render: (row: EquipmentShipping) => moment(row.date_return).format('DD.MM.YYYY') ?? '--',
            },
            {
              render: (row: EquipmentShipping) => row.place_purpose ?? '--',
            },
            {
              render: (row: EquipmentShipping) => {
                const workingCapacity = workingCapacities.find((el:SelectObject)=>el.value == row.working_capacity)?.label;
                const damageStatus = damageStatuses.find((el:SelectObject)=>el.value == row.damaged)?.label;
                return <Stack gap={'0.7rem'}>
                  <Typography>{`${t('eqShipping.workingCapacity')}: ${(workingCapacity ? workingCapacity : '-')}`}</Typography>
                  <Typography>{`${t('eqShipping.damagedStatus')}: ${(damageStatus ? damageStatus : '-')}`}</Typography>
                </Stack>
              },
            },
            {
              render: (row: EquipmentShipping) => (
                <Stack gap={'0.7rem'}>
                  <Typography>{`${t('eqShipping.shippingStaff')}: ${row.staffFio.fio}`}</Typography>
                  <Typography>{`${t('eqShipping.returnStaff')}: ${row.returnStaffFio ? row.returnStaffFio.fio : ''}`}</Typography>
                  <Typography>{`${t('cancelStaff')}: ${row.cancelStaffFio ? row.cancelStaffFio.fio : ''} ${row.date_cancel ? moment(row.date_cancel).format('DD.MM.YYYY') : ''}`}</Typography>
                </Stack>
              ),
            },
            {
              render: (row: EquipmentShipping) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  {(!row.date_return && row.date_shipping && !row.date_cancel) && (<UIActionButton 
                    icon='download'
                    tooltip={t('eqShipping.return')}
                    onClick={() => modalReturnRef.current?.open(row)}
                  />)}
                  {(!row.date_cancel && (row.date_shipping || row.date_return)) && (<UIActionButton 
                    icon='cancel'
                    tooltip={t('cancel')}
                    onClick={() => handleCancel(row.id, row.id_equipment, row.doc_json_shipping)}
                  />)}
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
        
      </CollapseCard>
      <ShippingModal ref={modalShippingRef} equipment={equipment} />
      <ReturnModal ref={modalReturnRef} equipment={equipment} />
      <CancelModal ref={modalCancelRef} />
    </>
  );
}

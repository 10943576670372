import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import ModalHeader from 'shared/ui/modal-header';
import { TestProgramFeature } from 'entities/test-program/model';
import { useCreateTestProgramFeatureMutation, useUpdateTestProgramFeatureMutation } from 'entities/test-program/api';
import notify from 'app/utils/notify';

export interface TestProgramFeatureModalRef {
  open: (data?: TestProgramFeature, testProgramId?: number) => void;
  close: () => void;
}

interface FormValues {
  id?: number;
  id_test_program?: number;
  name: string;
}

const initialState: FormValues = {
  name: ''
};

export const TestProgramFeatureModal = forwardRef<TestProgramFeatureModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: initialState,
  });
  const [createTestProgramFeature, { isLoading: isLoadingCreate }] = useCreateTestProgramFeatureMutation();
  const [updateTestProgramFeature, { isLoading: isLoadingUpdate }] = useUpdateTestProgramFeatureMutation();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = (data?: TestProgramFeature, testProgramId?: number) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        id_test_program: data.id_test_program,
        name: data.name
      });
    } else {
      reset({ ...initialState, id_test_program: testProgramId });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      if (isEdit && data.id) {
        await updateTestProgramFeature({ ...data, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgramFeature(data).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormTextInput
              control={control}
              name='name'
              label={t('name')}
              rules={{ required: true }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isLoadingCreate || isLoadingUpdate}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CancelData, CancelModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { handleResponse } from 'app/utils';
import { useCancelEquipmentVerificationByIdMutation } from 'entities/equipment/api';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';

export const CancelModal = forwardRef<CancelModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [data, setData] = useState<CancelData>();
  const [note, setNote] = useState<string>("");
  const [open, setOpen] = useState(false);

  const [cancelEnv] = useCancelEquipmentVerificationByIdMutation()

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data: CancelData) => {
    setData(data);
    setOpen(true);
    console.log(data.doc_json);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
        const signature = await eimzo.sign(data?.doc_json ?? '');
          await handleResponse(await cancelEnv({ 
            id: data?.id ?? 0,
            id_equipment:data?.id_equipment ?? 0,
            signature,
            note
           }));
        }
       finally {
        handleClose();
        notify('', 'success')
      }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '40vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader 
        title={t('toast.warning')} 
        handleClose={handleClose} 
      />
      <DialogContent style={{height:'200px'}} className='scrollbar'>
        <Stack spacing={4} mt={2}>
          <Typography variant='h3' textAlign={'center'}>
            {t('cancel_message')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={11}>
              <FormControl>
                <TextField
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder={t('notion')}
                  multiline
                  rows={2}
                  maxRows={4}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} mt={3} direction='row' justifyContent='flex-end'>
          <Button color='secondary' onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' onClick={handleSubmit}>
            {t('sign')}
          </Button>
        </Stack>
      </DialogActions>
      
    </Dialog>
  );
});

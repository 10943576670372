import React, { useRef } from 'react';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { useFilters } from 'app/hooks/use-filters';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { TestProgram, TestProgramFeature, TestProgramFeatureList, TestProgramPrimary, TestProgramResults } from 'entities/test-program/model';
import { useDeleteTestProgramFeatureMutation, useGetTestProgramFeatureListsQuery, useGetTestProgramFeaturesQuery, useGetTestProgramPrimariesQuery, useGetTestProgramResultPrimariesQuery } from 'entities/test-program/api';
import Attention from 'shared/ui/attention';
/*import { AccredStandartPrimaryModal } from './modal/standart-primary-modal';
import { AccredStandartPrimaryModalRef } from './modal/types';*/

interface Props {
  data: TestProgramResults;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
  id_test_program_feature: number;
}

export const ResultView: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  //const modalRef = useRef<AccredStandartPrimaryModalRef>(null);

  const [deleteRec] = useDeleteTestProgramFeatureMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
    id_test_program_feature: additionalData?.id_test_program_feature as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number, id_test_program_feature: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program, id_test_program_feature });
    }
  };


  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      sort: 'name',
    },
  });

  const { data: records, isFetching } = useGetTestProgramResultPrimariesQuery({
    id_test_program: data ? data.id_test_program : 0,
    id_test_program_result: data ? data.id : 0,
    ...filterParams,
  });

  const headers = [
    {
      label: t('testProgram.primary.name'),
      align: "center" as const,
      visible: true,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.primary.unit'),
      align: "center" as const,
      visible: (data.accredStandartData.type === 1) ? true : false,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.primary.var'),
      align: "center" as const,
      visible: true,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.primary.eq'),
      align: "center" as const,
      visible: (data.accredStandartData.type === 1) ? true : false,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.primary.scale'),
      align: "center" as const,
      visible: (data.accredStandartData.type === 1) ? true : false,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.primary.photo'),
      align: "center" as const,
      visible: true,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('actions'),
      align: 'center' as const,
      visible: true,
    },
  ];

  const columns = [
    {
      render: (row: TestProgramPrimary) => row.name ?? '--',
      visible: true,
    },
    {
      render: (row: TestProgramPrimary) => row.unit ?? '--',
      visible: (data.accredStandartData.type === 1) ? true : false,
    },
    {
      render: (row: TestProgramPrimary) => row.variable ?? '--',
      visible: true,
    },
    {
      render: (row: TestProgramPrimary) => row.equipmentData ? row.equipmentData.name : '--',
      visible: (data.accredStandartData.type === 1) ? true : false,
    },
    {
      render: (row: TestProgramPrimary) => row.scale ? row.scale.name : '--',
      visible: (data.accredStandartData.type === 1) ? true : false,
    },
    {
      render: (row: TestProgramPrimary) => (
        <Stack direction='column' spacing={2} justifyContent='center'>
          {row.photo1_url && (<Link href={row.photo1_url} target='_blank'>
            {t('testProgram.primary.photo')+ " 1"}
          </Link>)}
          {row.photo2_url && (<Link href={row.photo2_url} target='_blank'>
            {t('testProgram.primary.photo')+ " 2"}
          </Link>)}
          {row.photo3_url && (<Link href={row.photo3_url} target='_blank'>
            {t('testProgram.primary.photo')+ " 3"}
          </Link>)}
          {row.photo4_url && (<Link href={row.photo4_url} target='_blank'>
            {t('testProgram.primary.photo')+ " 4"}
          </Link>)}
        </Stack>
      ),
      visible: true,
    },
    {
      render: (row: TestProgramPrimary) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='edit'
            tooltip={t('edit')}
          //onClick={() => modalRef.current?.open(row)}
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, data.id_test_program, 0)}//row.id_test_program_feature)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
        </Stack>
      ),
      visible: true,
      nowrap: true,
    },
  ];

  return (
    <Stack direction='column' spacing={2} justifyContent='left'>
      <Typography variant='h3'>
        {t('testProgram.primary.title')}
        <IconButton
          //onClick={() => modalRef.current?.open()} 
          aria-label="add"
        >
          <AddCircle />
        </IconButton>
      </Typography>
      <Attention 
        width="70%"
        description={t('testProgram.primary.attention')}
      />
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        headers={headers.filter((header) => header.visible)}
        columns={columns.filter((column) => column.visible)}
        onSort={onChangeFilterParams}
        orderDirection={'asc'}
        orderByField={'name'}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />

      {/**<AccredStandartPrimaryModal accredStandart={data} ref={modalRef} />*/}
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </Stack>
  );
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { IconButton, Stack, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentServReg, EquipmentServRegWork, EquipmentServiceWork } from 'entities/equipment/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { useGetEquipmentServRegQuery } from 'entities/equipment/api';
import { useFilters } from 'app/hooks/use-filters';
import { ServiceRegModalRef, CancelModalRef } from './types';
import ServiceRegModal from './service-modal';
import { CancelModal } from './cancel-modal';
import CheckIcon from '@mui/icons-material/Check';
import notify from 'app/utils/notify';

interface Props {
  equipment: Equipment;
}

export const ServiceRegistration: React.FC<Props> = ({ equipment }) => {
  const { t } = useTranslation();

  const modalServiceRegRef = useRef<ServiceRegModalRef>(null);
  const modalCancelRef = useRef<CancelModalRef>(null);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 2,
      //fromDate: '',
      //toDate: '',
      sort: '-date_service',
    },
  });
  //console.log('equipment', equipment);
  const { data: records, isFetching } = useGetEquipmentServRegQuery({
    id_equipment: equipment ? equipment.id : 0,
    ...filterParams,
  });

  const handleCancel = (id : number, id_equipment : number, doc_json:string) => {
    if (doc_json) {
      console.log('doc_json', doc_json);
      modalCancelRef.current?.open({
        id,
        id_equipment,
        doc_json: doc_json.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }

  return (
    <>
      <CollapseCard 
        title={
          <>
            {t('eqServiceReg.title')}
            <IconButton onClick={() => modalServiceRegRef.current?.open()} aria-label="add">
              <AddCircle />
            </IconButton>
          </>
        } 
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            { label: t('eqServiceReg.date'), align:"center"  as const, },
            { label: t('eqServiceReg.staff'), align:"center"  as const, },
            { label: t('eqServiceReg.works'), align:"center"  as const, },
            { label: t('eqServiceReg.dateNext'), align:"center"  as const, },
            {
              label:t('actions'),
              align:'center'  as const,
            },
          ]}
          columns={[
            {
              render: (row: EquipmentServReg) => row.date_service ?? '--',
            },
            {
              render: (row: EquipmentServReg) => row.staffFio.fio ?? '--',
            },
            {
              render: (row: EquipmentServReg) => row.equipmentServiceRegWorks.map((work: EquipmentServRegWork) => {
                const serviceWork = equipment.equipmentServiceWorks.find((item: EquipmentServiceWork) => item.id === work.id_equipment_service_work);
                
                return (
                  <Stack gap={'0.5rem'} direction={'row'}>
                    <CheckIcon fontSize='small' /><Typography>{serviceWork ? serviceWork.name : '-'}</Typography>
                    <Typography>{work.note ? ` (${work.note})` : ''}</Typography>
                  </Stack>
                );
              }),
            },
            {
              render: (row: EquipmentServReg) => row.date_next ?? '--',
            },
            {
              render: (row: EquipmentServReg) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  {/**!row.date_sign && (<UIActionButton 
                    icon='edit'
                    tooltip={t('edit')}
                    onClick={() => modalServiceRegRef.current?.open(row)}
                  />)*/}
                  {/**!row.date_sign && (<UIActionButton 
                    icon='delete'
                    tooltip={t('delete')}
                    //onClick={() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
                  />)*/}
                  {/**!row.date_sign && (<UIActionButton 
                    icon='sign'
                    tooltip={t('sign')}
                    //onClick={() => modalDeleteVerifRef.current?.open(row.id, row.id_equipment)}
                  />)*/}
                  {(!row.date_cancel && row.date_sign) && (<UIActionButton 
                    icon='cancel'
                    tooltip={t('cancel')}
                    onClick={() => handleCancel(row.id, row.id_equipment, row.doc_json)}
                  />)}
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
        
      </CollapseCard>
      <ServiceRegModal ref={modalServiceRegRef} equipment={equipment} />
      <CancelModal ref={modalCancelRef} />
    </>
  );
}

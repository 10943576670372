import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from 'shared/layout';
import DetailView from 'shared/ui/detail-view';
import UITable from 'shared/ui/ui-table';
import CollapseCard from 'shared/ui/collapse-card';
import { 
  Typography, Paper, Box, Alert, Stepper, Step, StepLabel,
  Checkbox, FormControlLabel, Grid, Button,
  Stack
} from '@mui/material';
import { DetailViewItem } from 'shared/ui/detail-view/types';
import { UITableColumn } from 'shared/ui/ui-table/types';
import { useGetCertRequestByIdQuery } from 'entities/certification/api';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import CertificationStepper, { CertificationStep } from 'pages/certification/stepper';
import Breadcrumb from 'shared/ui/breadcrumb';

const ApplicationDetailsPage: React.FC = () => {
  const { t } = useTranslation(['certification', 'common', 'errors']);
  const { id } = useParams<{ id: string }>();
  const { data: certRequest, isLoading, isError } = useGetCertRequestByIdQuery(id || '');

  const generalInfo: DetailViewItem[] = certRequest ? [
    { label: t('certRequest.requestNumber'), value: certRequest.request_nn },
    { label: t('certRequest.requestDate'), value: moment(certRequest.request_date).format('YYYY.MM.DD') },
    { label: t('certRequest.regNumber'), value: certRequest.reg_nn },
    { label: t('certRequest.regDate'), value: moment(certRequest.reg_date).format('YYYY.MM.DD') },
    { label: t('certRequest.applicant'), value: certRequest.clientInfo.name },
    { label: t('certRequest.companyNameInn'), value: `${certRequest.clientInfo.name} (${certRequest.clientInfo.inn})` },
    { label: t('certRequest.scheme'), value: certRequest.scheme.name },
    { label: t('certRequest.contactForNotification'), value: certRequest.id_client_phone?.toString() || '-' },
    { label: t('certRequest.createdBy'), value: certRequest.id_user?.toString() || '-' },
  ] : [];

  const productColumns: UITableColumn<any>[] = [
    { key: 'name', render: (row) => row.name },
    { key: 'unit', render: (row) => row.unit.name },
    { key: 'quantity', render: (row) => row.quantity },
    { key: 'code', render: (row) => row.id_hs_code || '-' },
    { key: 'price', render: (row) => `${row.price} ${t('currency')}` },
  ];

  const testColumns: UITableColumn<any>[] = [
    { key: 'number', render: (_, index) => index + 1 },
    { key: 'productName', render: (row) => row.name },
    { key: 'testProgram', render: () => t('certRequest.standardProgram') },
    { key: 'laboratory', render: () => '-' },
  ];

  if (isLoading) {
    return <Layout><Typography>{t('loading')}</Typography></Layout>;
  }

  if (isError || !certRequest) {
    return <Layout><Typography>{t('error.loadingData')}</Typography></Layout>;
  }

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Breadcrumb 
          items={[
            { label: t('certRequest.plural'), href: '/certification?tab=list' },
            { label: `№${certRequest.request_nn} ${t('from', { ns: 'common' })} ${moment(certRequest.request_date).format('DD.MM.YYYY')}` },
          ]}
        />
        <Typography variant="h4" gutterBottom>
          {t('certRequest.title')} №{certRequest.request_nn} {t('from', { ns: 'common' })} {moment(certRequest.request_date).format('DD.MM.YYYY')} {certRequest.clientInfo.name}
        </Typography>

        {/**<Paper sx={{ p: 3, mb: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stages.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>*/}
        <CertificationStepper activeStep={CertificationStep.CERT_REQUEST} />

        <CollapseCard title={t('certRequest.generalInfo')} collapsed={false}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DetailView data={generalInfo.slice(0, 5)} />
              </Grid>
              <Grid item xs={6}>
                <DetailView data={generalInfo.slice(5)} />
              </Grid>
            </Grid>
          </Box>
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title={t('certRequest.productInfo')}>
          <UITable
            columns={productColumns}
            data={certRequest.products}
            headers={[t('certRequest.productName'), t('certRequest.unit'), t('certRequest.quantity'), t('certRequest.hsCode'), t('certRequest.cost')]}
          />
          {certRequest.products.some(p => p.note) && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <Typography variant="body2">
                {t('certRequest.productSpecificRequirements')}
              </Typography>
            </Alert>
          )}
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title={t('certRequest.laboratoryTestsInfo')}>
          <UITable
            columns={testColumns}
            data={certRequest.products}
            headers={[t('certRequest.number'), t('certRequest.productName'), t('certRequest.testProgram'), t('certRequest.laboratory')]}
          />
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title={t('certRequest.automaticAnalysisInfo')}>
          <Typography variant="subtitle1" gutterBottom>{t('certRequest.resourcesAvailability')}:</Typography>
          <FormControlLabel 
            control={<Checkbox checked />} 
            label={t('certRequest.normativeDocumentsUpToDate')} 
          />
          <Typography variant="caption" display="block" gutterBottom>
            {t('certRequest.normativeDocumentsNote')}
          </Typography>
          <FormControlLabel 
            control={<Checkbox checked />} 
            label={t('certRequest.testProgramDefined')} 
          />
          <Typography variant="caption" display="block" gutterBottom>
            {t('certRequest.noNote')}
          </Typography>
          <FormControlLabel 
            control={<Checkbox checked />} 
            label={t('certRequest.packagingRequirementsDefined')} 
          />
          <Typography variant="caption" display="block" gutterBottom>
            {t('certRequest.packagingRequirementsNote')}
          </Typography>
        </CollapseCard>

        <Box sx={{ my: 2 }} />

        <CollapseCard title={t('certRequest.attachments')}>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" startIcon={<DownloadIcon />}>
              {t('certRequest.downloadCertificate')}
            </Button>
            <Button variant="outlined" startIcon={<DownloadIcon />}>
              {t('certRequest.downloadArchive')}
            </Button>
          </Stack>
        </CollapseCard>
      </Box>
    </Layout>
  );
};

export default ApplicationDetailsPage;
import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek, LocationOn, LocalPhone, Check, AssignmentTurnedIn, DownloadDoneOutlined } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Protocol } from 'entities/protocol/model';
import Download from '@mui/icons-material/Download';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { InfoGroup } from 'shared/ui/info-group';

import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MainInfoCard } from './main-info-card';

interface Props {
  protocol: Protocol;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



export const FileCard: React.FC<Props> = ({ protocol }) => {
  const { t } = useTranslation();

  const return_destroy_str = (return_destroy?: number) => {
    switch (return_destroy) {
      case 1:
        return t('protocol.view.destroyed');
      case 2:
        return t('protocol.view.returned');
      default:
        return '-';
    }
  }

  /*const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  
    const filesTestProg= ((request?.files !== undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;

    const files =  (request?.files !== undefined) ? Object.keys(request.files).filter((el) => {
      let file = request.files[el as keyof Request['files']];
      return (file !== false && file !== "" && el !== "testProg");
    }).map(function (el) {
      let file = request.files[el as keyof Request['files']];
      return file as RequestFile;
    }) : [];
*/
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      {/*<CardHeader
            title="Прикрепленные файлы"
          />
          */}
      <CardContent>
        <Stack spacing={1} justifyContent='center'>
          <Stack justifyContent='center'>
            {/**<Stack direction="row" alignItems="center" gap={1}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {t('protocol.view.primaries')}
                  </Typography>
                </Stack>*/}
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                {t('request.title')}
              </Typography>
              <FilePresentIcon />
              <Link
                href={'#'}
                target="_blank"
                color="inherit"
              >
                <Typography variant="body1" sx={{ fontSize: 16 }}>
                  {t('download')}
                </Typography>
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                {t('protocol.view.travelCertificate')}
              </Typography>
              <FilePresentIcon />
              <Link
                href={'#'}
                target="_blank"
                color="inherit"
              >
                <Typography variant="body1" sx={{ fontSize: 16 }}>
                  {t('download')}
                </Typography>
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                {t('protocol.view.uncertainty')} - {t('yes')}
              </Typography>
              <Button color='success' onClick={() => alert('Меняем')}>
                {t('change')}
              </Button>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                {t('protocol.view.verification')} - {t('yes')}
              </Typography>
              <Button color='success' onClick={() => alert('Меняем')}>
                {t('change')}
              </Button>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                {t('protocol.view.qualityCertificate')} - {t('no')}
              </Typography>
            </Stack>
          </Stack>
          <InfoGroup
            title={(<>
              {`${t('protocol.view.actSelect')} ${(protocol.act_select_nn !== null) && '№' + protocol.act_select_nn}`}
              {(protocol?.act_select_url !== null) && (<Link
                href={'http://prog.i-test.loc/uploads/protocol/' + protocol?.act_select_url}
                target="_blank"
                color="inherit"
                ml={1}
              >
                {t('download')}
              </Link>
              )}
            </>)}
            items={[
              {
                icon: <CalendarMonth />,
                visible: (protocol.act_select_date !== null),
                text: `${moment(protocol?.act_select_date).format('DD.MM.YYYY')}`
              },
              {
                icon: <Person />,
                visible: (protocol.act_select_org_name !== null),
                text: `${protocol?.act_select_org_name}`
              },
            ]}
          />
          <InfoGroup
            title={(<>
              {`${t('protocol.view.decision')} ${(protocol.test_init_nn !== null) && '№' + protocol.test_init_nn}`}
              {(protocol?.test_init_url !== null) && (<Link
                href={'http://prog.i-test.loc/uploads/protocol/' + protocol?.test_init_url}
                target="_blank"
                color="inherit"
                ml={1}
              >
                {t('download')}
              </Link>
              )}
            </>)}
            items={[
              {
                icon: <CalendarMonth />,
                visible: (protocol.test_init_date !== null),
                text: `${moment(protocol?.test_init_date).format('DD.MM.YYYY')}`
              },
              {
                icon: <Person />,
                visible: (protocol.test_init_org_name !== null),
                text: `${protocol?.test_init_org_name}`
              },
            ]}
          />
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack spacing={1} mt={1}>
            <InfoGroup
              title={t('protocol.view.testProg')}
              items={[
                {
                  icon: <FilePresentIcon />,
                  text: (<Link
                    href={'#'}
                    target="_blank"
                    color="inherit"
                  >
                    {t('download')}
                  </Link>)
                },
                {
                  icon: <FilePresentIcon />,
                  text: (<Link
                    href={'#'}
                    target="_blank"
                    color="inherit"
                  >
                    {t('download')}
                  </Link>)
                },
                {
                  icon: <FilePresentIcon />,
                  text: (<Link
                    href={'#'}
                    target="_blank"
                    color="inherit"
                  >
                    {t('download')}
                  </Link>)
                },
              ]}
            />
            <InfoGroup
              title={t('protocol.view.primaries')}
              items={[
                {
                  icon: <FilePresentIcon />,
                  text: (<Link
                    href={'#'}
                    target="_blank"
                    color="inherit"
                  >
                    {t('download')}
                  </Link>)
                },
              ]}
            />
          </Stack>
        </Collapse>
      </CardContent>
      <CardActions disableSpacing>
        <Typography variant="h4" ml={5}>{expanded ? t("less") : t("more")}</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
    </Card>
  );
}

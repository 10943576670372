import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface Props {
  text: string;
  maxLength?: number;
}

const LongTextHide: React.FC<Props> = ({text, maxLength=100}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  
  return (
    <>
      {(text.length>maxLength) ? 
      (<>
        <p>
          {showMore ? text : `${text.substring(0, maxLength)}...`}
        </p>
        <a>
          <Typography onClick={() => setShowMore(!showMore)} paragraph component="span" color="primary" variant="body1">
            {!showMore ? t('longText.show'):t('longText.hide') }
          </Typography>
        </a>
      </>) : (<p>{text}</p>)}
    </>
  );
};

export default LongTextHide;
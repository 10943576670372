import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import {
  DefaultResponse,
  RequestWithPagination,
  ResponseWithPagination,
} from 'app/utils/types';
import {
  Material,
  MaterialModel,
  MaterialModelRequestBody,
  MaterialRequestBody, 
} from '../model';


export const materialApi = createApi({
  reducerPath: 'materialApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'Material',
    'Model',

  ],
  endpoints: (builder) => ({
    getMaterials: builder.query<ResponseWithPagination<Material>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/materials',
        params
      }),
      providesTags: ['Material'],
    }),
    createMaterial: builder.mutation<
      DefaultResponse,
      MaterialRequestBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/materials`,
        body,
      }),
      invalidatesTags: ['Material'],
    }),
    updateMaterial: builder.mutation<
      DefaultResponse,
      MaterialRequestBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/materials/${id}`,
        body,
      }),
      invalidatesTags: ['Material'],
    }),
    deleteMaterial: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/materials/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Material'],
    }),

    // Models
    getModels: builder.query<
      ResponseWithPagination<MaterialModel>,
      RequestWithPagination<{ id_material: string }>
    >({
      query: ({ id_material, ...params }) => ({
        method: 'GET',
        url: `/materials/${id_material}/models`,
        params,
      }),
      providesTags: ['Model'],
    }),
    createModel: builder.mutation<
      DefaultResponse,
      MaterialModelRequestBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/materials/${body.id_material}/models`,
        body,
      }),
      invalidatesTags: ['Model'],
    }),
    updateModel: builder.mutation<
      DefaultResponse, 
      MaterialModelRequestBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/materials/${body.id_material}/models/${id}`,
        body,
      }),
      invalidatesTags: ['Model'],
    }),
    deleteModel: builder.mutation({
      query: ({id, id_material}) => ({
        method: 'DELETE',
        url: `/materials/${id_material}/models/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['Model'],
    }),
  }),
});

export const {
  useGetMaterialsQuery,
  useCreateMaterialMutation,
  useUpdateMaterialMutation,
  useDeleteMaterialMutation,
  useGetModelsQuery,
  useCreateModelMutation,
  useUpdateModelMutation,
  useDeleteModelMutation,  
} = materialApi;

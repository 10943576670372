import { Divider, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import WestIcon from '@mui/icons-material/West';
import CheckIcon from '@mui/icons-material/Check';
import NightlightIcon from '@mui/icons-material/Nightlight';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import { setLang, toggleTheme } from 'entities/app/model/slice';
import { signOut } from 'entities/auth/model/slice';
import { useNavigate } from 'react-router-dom';

interface Props {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
}

interface AccountItem {
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
  to?: string;
  list?: {
    data: {
      value: string;
      label: string;
    }[];
    activeValue: string;
    onClick?: (value: string) => void;
  };
}

const AccountMenu: React.FC<Props> = ({ anchorEl, open, onClose }) => {
  const { t } = useTranslation();
  const { lang, theme } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const ACCOUNT_DATA = useMemo<AccountItem[][]>(
    () => [
      [
        {
          icon: <ManageAccountsIcon />,
          text: t('profile'),
        },
      ],
      [
        {
          icon: <TranslateIcon />,
          text: t('language.title'),
          list: {
            data: [
              {
                value: 'en',
                label: t('language.en'),
              },
              {
                value: 'ru',
                label: t('language.ru'),
              },
              {
                value: 'uz',
                label: t('language.uz'),
              },
            ],
            activeValue: lang,
            onClick: (value) => {
              dispatch(setLang(value));
            },
          },
        },
        {
          icon: theme === 'light' ? <LightModeIcon /> : <NightlightIcon />,
          text: t('theme.title'),
          list: {
            activeValue: theme,
            onClick: () => dispatch(toggleTheme()),
            data: [
              {
                value: 'light',
                label: t('theme.light'),
              },
              {
                value: 'dark',
                label: t('theme.dark'),
              },
            ],
          },
        },
      ],
      [
        {
          icon: <LogoutIcon />,
          text: t('log_out'),
          onClick: () => dispatch(signOut()),
        },
      ],
    ],
    [lang, theme]
  );

  const [activeIndex, setActiveIndex] = React.useState<number[]>([]);
  const activeSubMenu = activeIndex.length
    ? ACCOUNT_DATA[activeIndex[0]][activeIndex[1]]
    : null;

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        sx: {
          minWidth: '250px',
        },
      }}
    >
      {activeSubMenu ? (
        <MenuList>
          <MenuItem onClick={() => setActiveIndex([])}>
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <WestIcon
                sx={{
                  fontSize: '1.8rem',
                }}
              />
              <Typography>{activeSubMenu.text}</Typography>
            </Stack>
          </MenuItem>
          <Divider />
          {activeSubMenu.list?.data.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (item.value !== activeSubMenu.list?.activeValue) {
                  activeSubMenu?.list?.onClick?.(item.value);
                }
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  width: '100%',
                }}
              >
                <Stack direction='row' spacing={1.5} alignItems='center'>
                  <Typography>{item.label}</Typography>
                </Stack>
                {item.value === activeSubMenu.list?.activeValue && (
                  <CheckIcon
                    sx={{
                      fontSize: '1.8rem',
                    }}
                  />
                )}
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      ) : (
        <MenuList>
          {ACCOUNT_DATA.map((item, index) => {
            return item.map((subItem, idx) => (
              <MenuItem
                key={idx}
                divider={index !== ACCOUNT_DATA.length - 1 && idx === item.length - 1}
                sx={{
                  mt: index !== 0 && idx === 0 ? 1 : 0,
                }}
                onClick={() => {
                  if (subItem.onClick) {
                    subItem.onClick();
                  } else if (subItem.list) {
                    setActiveIndex([index, idx]);
                  } else if (subItem.to) {
                    navigate(subItem.to);
                  }
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    width: '100%',
                  }}
                >
                  <Stack direction='row' spacing={1.5} alignItems='center'>
                    {subItem.icon}
                    <Typography>{subItem.text}</Typography>
                  </Stack>
                  {subItem.list && (
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: '1.4rem',
                      }}
                    />
                  )}
                </Stack>
              </MenuItem>
            ));
          })}
        </MenuList>
      )}
      {/* <MenuItem>
          <Stack direction='row' spacing={1.5} alignItems='center'>
            <ManageAccountsIcon
              sx={{
                fontSize: '1.8rem',
              }}
            />
            <Typography>{t('profile')}</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              width: '100%',
            }}
          >
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <TranslateIcon
                sx={{
                  fontSize: '1.8rem',
                }}
              />
              <Typography>{t('language.title')}</Typography>: {t(`language.${lang}`)}
            </Stack>
            <ArrowForwardIosIcon
              sx={{
                fontSize: '1.8rem',
              }}
            />
          </Stack>
        </MenuItem> */}
    </Menu>
  );
};

export default AccountMenu;

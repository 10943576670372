import { SelectObject } from "app/utils/types";

export const workingCapacities: SelectObject[] = [
  {
    label: 'Работоспособен',
    value: 1,
  },
  {
    label: 'Не работоспособен',
    value: 2,
  },
];

export const damageStatuses: SelectObject[] = [
  {
    label: 'Имеются',
    value: 1,
  },
  {
    label: 'Отсутствуют',
    value: 2,
  },
];
import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Grid } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';

interface Props {
  equipment: Equipment;
}

export const General: React.FC<Props> = ({ equipment }) => {
  const { t } = useTranslation();

  const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  return (
    <CollapseCard title="Общие сведения" collapsed={true}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DetailView data={
              [
                {
                  label: t('manufacturer'),
                  value: equipment?.manufactor
                },
                {
                  label: t('factoryNum'),
                  value: equipment?.factory_number
                },
                {
                  label: t('inventoryNum'),
                  value: equipment?.inv_number
                },
                {
                  label: t('metrolTrace'),
                  value: equipment?.confirmation_method && confirmation_method_str(equipment?.confirmation_method)
                }
              ]} />
          </Grid>
          <Grid item xs={6}>
            <DetailView data={
              [
                {
                  label: t('passport'),
                  value: "-//-//-"
                },
                {
                  label: t('dateOfStartfUsage'),
                  value: equipment?.date_begin
                },
                {
                  label: t('location'),
                  value: laboratories?.items.find((item) => item.id == equipment?.id_laboratory)?.name
                },
                {
                  label: t('dateOfEndfUsage'),
                  value: equipment?.date_end ?? '-//-//-'
                }
              ]} />
          </Grid>
        </Grid>
      </Box>

    </CollapseCard>
  );
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { Link, Box, Button, Stack, TextField, TextFieldProps, Typography, IconButton, Autocomplete } from '@mui/material';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteLaboratoryMutation, useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { useGetStaffQuery } from 'entities/staff/api';
import { AddEquipmentModal } from 'pages/equipment/equipment-modal/equipment-modal';
import { EquipmentModalRef } from 'pages/equipment/equipment-modal/types';
import { WarningLabModal } from 'features/warning-lab-modal';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { AddCircle } from '@mui/icons-material';

import { useGetEquipmentQuery } from 'entities/equipment/api';

import { Equipment } from 'entities/equipment/model';
import { useNavigate } from 'react-router-dom';

import LongtextHide from 'shared/ui/long-text-hide';

import { bgColorRule } from 'shared/ui/ui-table/types';
import { Colors } from 'app/constants';
import { WarningEquipModal } from 'features/warning-equip-modal';
import { EnvCondDeleteModalRef } from 'features/types';
import { useGetTestTypesQuery, useGetProtocolsQuery } from 'entities/protocol/api';
import { Protocol } from 'entities/protocol/model';
import LongTextHide from 'shared/ui/long-text-hide';
import moment from 'moment';
import UIDatePicker from 'shared/inputs/date-picker';
import dayjs from 'dayjs';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import { useDeleteTestProgramMutation, useGetTestProgramsQuery } from 'entities/test-program/api';
import { TestProgram, TestProgramConnect } from 'entities/test-program/model';
import { useGetGroupsQuery } from 'entities/accreditation/api';
import { TestProgramModalRef, TestProgramModal } from './modal';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';

interface DeleteRequestBody {
  id: number;
}

const TestProgramList = () => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;
  const navigate = useNavigate();
  const modalRef = useRef<TestProgramModalRef>(null);

  const [deleteRec] = useDeleteTestProgramMutation();

  const prepareRequestBody = (id: number): DeleteRequestBody => ({id});

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id);
    }
  };

  const { data: accredGroups } = useGetGroupsQuery({});

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      name: '',
      use_method: '',
      id_accred_group: '',
      page: 1,
      sort: '',
    },
  });

  const { data: records, isFetching } = useGetTestProgramsQuery({
    //labId: data.id,
    //sort: '-date_create',
    //'per-page': 3,
    ...filterParams,
  });

  const headers = [
    {
      label: t('testProgram.accredGroup'),
      align: "center" as const,
      sortable: true,
      sortField: 'accred_group_name',
      visible: tenantType === 2,
    },
    {
      label: t('testProgram.name'),
      align: "center" as const,
      sortable: true,
      sortField: 'name',
      visible: true,
    },
    {
      label: t('testProgram.date'),
      align: "center" as const,
      visible: true,
    },
    {
      label: t('testProgram.connected'),
      align: "center" as const,
      visible: tenantType === 2,
    },
    {
      label: t('testProgram.status'),
      align: "center" as const,
      sortable: true,
      sortField: 'status',
      visible: true,
    },
    {
      label: t('testProgram.calc'),
      align: "center" as const,
      sortable: true,
      sortField: 'use_method',
      visible: tenantType === 2,
    },
    {
      label: t('actions'),
      align: "center" as const,
      visible: true,
    },
  ];

  const columns = [
    {
      render: (row: TestProgram) => row.accredGroup.name ?? '-',
      visible: tenantType === 2,
    },
    {
      render: (row: TestProgram) => row.name ?? '-',
      visible: true,
    },
    {
      render: (row: TestProgram) => {
        if (row.date_begin === null && row.date_end === null) {
          return '--';
        }
        if (row.date_begin === null) {
          return `${t('to')} ${moment(row.date_end).format('DD.MM.YYYY')}`
        }
        if (row.date_end === null) {
          return `${t('from')} ${moment(row.date_begin).format('DD.MM.YYYY')}`
        }
        return `${t('from')} ${moment(row.date_begin).format('DD.MM.YYYY')} ${t('to')} ${moment(row.date_end).format('DD.MM.YYYY')}`
      },
      visible: true,
    },
    {
      render: (row: TestProgram) => (
        <Stack direction='column' spacing={1} justifyContent='center'>
          {row.testProgramConnects.map((item: TestProgramConnect) => (
            <div key={item.id}>
              {`${item.testProgramCert.name} `}
              {item.testProgramCert.doc_url && <Link href={item.testProgramCert.doc_url} target='_blank'>{t("download")}</Link>}
            </div>
          ))}
        </Stack>
      ),
      visible: tenantType === 2,
    },
    {
      render: (row: TestProgram) => row.status == 1 ? t('testProgram.statusMain') : t('testProgram.statusAdditional'),
      visible: true,
    },
    {
      render: (row: TestProgram) => row.use_method == 1 ? t('yes') : t('no'),
      visible: tenantType === 2,
    },
    {
      render: (row: TestProgram) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='observe'
            onClick={() => navigate(`/test-program/view/${row.id}`)}
          />
          <UIActionButton icon='edit' onClick={() => modalRef.current?.open(row)} />
          <UIActionButton icon='delete' onClick={() => openDeleteModal(row.id)} />
        </Stack>
      ),
      nowrap: true,
      visible: true,
    },
  ];

  return (
    <Box>
      <Stack direction={'row'} mb={'2rem'}>
        <Typography variant='h2'>{t('testProgram.title')}</Typography>
        <IconButton onClick={() => modalRef.current?.open()} aria-label="add">
          <AddCircle sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <TextField
          label={t('testProgram.name')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ name: e.target.value })}
        />
        <Autocomplete
          options={[
            { id: "0", name: t('no') },
            { id: "1", name: t('yes') },
          ]}//{ locationList ?? [] }//{ laboratories?.items ?? []}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
          //@ts-ignore
          onChange={(event, option) => {
            //@ts-ignore
            onChangeFilterParams({ use_method: (option && option.id) ? option.id.toString() : '' });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              //placeholder: t('location')

              label={t('testProgram.calc')}
            />
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              m: 'auto',
            }, width: 220
          }}
        />
        <Autocomplete
          options={accredGroups?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
          //@ts-ignore
          onChange={(event, option) => {
            //@ts-ignore
            onChangeFilterParams({ id_accred_group: (option && option.id) ? option.id.toString() : '' });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              //placeholder: t('location')

              label={t('testProgram.accredGroup')}
            />
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              m: 'auto',
            }, width: 300
          }}
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort={onChangeFilterParams}
        //orderDirection={'desc'}
        //orderByField={'confirm_date'}
        headers={headers.filter((header) => header.visible)}
        //bgColorRules={rules}
        columns={columns.filter((column) => column.visible)}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <TestProgramModal ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </Box>
  );
};

export default TestProgramList;
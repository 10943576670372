import { Fragment, ReactNode } from 'react';
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  IconButton,
  Collapse
} from '@mui/material';
import _ from 'lodash';

import {
  UIRowProps,
  UITableProps
} from './types';
import { useTranslation } from 'react-i18next';
import { Colors } from 'app/constants';
import React from 'react';
import { useAppSelector } from 'app/hooks/redux';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function UIRow<T extends object>(props: UIRowProps<T>) {
  const {
    row,
    headers,
    columns,
    idx,
    color,
    rowCollapsedTitle,
    children,

  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={idx}>
      {/**<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>*/}
      <TableRow
        sx={{
          '&:first-of-type': {
            '& td': {
              ...(!headers && {
                borderTop: `1px solid ${Colors.BORDER}`,

                '&:first-of-type': {
                  borderTopLeftRadius: '8px',
                },

                '&:last-child': {
                  borderTopRightRadius: '8px',
                },
              }),
            },
          },
          '&:last-child': {
            '& td': {
              '&:first-of-type': {
                borderBottomLeftRadius: '8px',
              },
              '&:last-child': {
                borderBottomRightRadius: '8px',
              },
            },
          },
        }}
      >
        <TableCell sx={{ width: 70 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column, colIdx) => (
          <TableCell
            key={`${idx}-${colIdx}`}
            align={column.align ? column.align : 'left'}
            sx={{
              borderRight: `1px solid ${Colors.BORDER}` as any,
              '&:first-of-type': {
                borderLeft: `1px solid ${Colors.BORDER}`,
              },
              borderBottom: `1px solid ${Colors.BORDER}` as any,
              py: 1.25 as any,
              px: 2 as any,
              ...(column?.nowrap && {
                whiteSpace: 'nowrap',
                width: '1%',
              }),
              fontSize: '1rem' as any,
              ...column?.sx,
              // @ts-ignore
              bgcolor: row.date_cancel ? Colors.MEDIUM_GREY : (color ?? ''),
            }}
          >
            {column?.render
              ? column?.render(row, idx)
              : (_.get(row, column.key ? column.key : '') as ReactNode)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow
        sx={{
          '&:first-of-type': {
            '& td.collapse-cell': {
              ...(!headers && {
                borderTop: `1px solid ${Colors.BORDER}`,

                '&:first-of-type': {
                  borderTopLeftRadius: '8px',
                },

                '&:last-child': {
                  borderTopRightRadius: '8px',
                },
              }),
            },
          },
          '&:last-child': {
            '& td.collapse-cell': {
              '&:first-of-type': {
                borderBottomLeftRadius: '8px',
              },
              '&:last-child': {
                borderBottomRightRadius: '8px',
              },
            },
          },
        }}
      >
        <TableCell className='collapse-cell' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headers ? headers?.length+1 : 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {rowCollapsedTitle && (
                <Typography variant="h6" gutterBottom component="div">
                  {rowCollapsedTitle}
                </Typography>
              )}
              {children}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function UITable<T extends object>(props: UITableProps<T>) {
  const { t } = useTranslation();
  const { theme } = useAppSelector(state => state.app)
  const {
    data = [],
    headers,
    columns,
    sx,
    PaginationProps,
    isLoading,
    isError,
    renderRowAfter,
    onSort,
    orderDirection,
    orderByField,
    bgColorRules,
    rowCollapsed,
    rowCollapsedTitle
  } = props;

  const [order, setOrder] = React.useState<'asc' | 'desc' | undefined>(orderDirection);
  const [orderBy, setOrderBy] = React.useState<string | undefined>(orderByField);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (onSort) {
      onSort({ sort: isAsc ? '-' + property : property });
    }
  };

  const createSortHandler =
    (sortField?: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, sortField ? sortField : '');
    };

  /*const bgColor = (row: T) => {
    const findFieldRule = bgColorRules?.find((colorRule) => row[colorRule.field as keyof typeof row]);
    //console.log('findFieldRule', findFieldRule);
    if (findFieldRule) {
      if ((findFieldRule.rule.length === 1) && (!findFieldRule.rule[0].value)) return findFieldRule.rule[0].color;
      const value = row[findFieldRule.field as keyof typeof row];
      console.log('value', value);
      const findRule = findFieldRule.rule.find((rule) => rule.value === value);
      console.log('color', findRule?.color);
      return findRule?.color ?? false;
    }
    return false;
  }*/

  const bgColor = (row: T) => {
    if (!bgColorRules) return false;
  
    for (const colorRule of bgColorRules) {
      const value = row[colorRule.field as keyof typeof row];
      
      for (const rule of colorRule.rule) {
        if (
          (rule.value === null && value === null) ||
          (rule.value === undefined && value === undefined) ||
          rule.value === value
        ) {
          return rule.color;
        }
      }
    }
  
    return false;
  };

  return (
    <Box sx={sx}>
      <TableContainer>
        <Table
          sx={{
            borderCollapse: 'separate',
          }}
        >
          <TableHead>
            {headers && (
              <TableRow
                sx={{
                  backgroundColor: theme === 'light' ? '#F4F8FB' : Colors.TEXT_DARK,
                }}
              >
                {rowCollapsed &&
                  <TableCell
                    sx={{
                      py: 1.1,
                      borderTop: `1px solid ${Colors.BORDER}`,
                      borderRight: `1px solid ${Colors.BORDER}`,
                      borderBottom: `1px solid ${Colors.BORDER}`,
                      '&:first-of-type': {
                        borderRadius: '8px 0 0 0',
                        borderLeft: `1px solid ${Colors.BORDER}`,
                      },
                      '&:last-child': {
                        borderRadius: '0 8px 0 0',
                      },
                      fontSize: '1rem',
                      width: 70
                    }}
                  />}
                {headers
                  .filter((el) => el !== null && typeof el !== 'undefined')
                  .map((el, idx) => (
                    <TableCell
                      key={`header-${idx}`}
                      align={typeof el === 'object' ? el?.align : 'left'}
                      sx={{
                        py: 1.1,
                        borderTop: `1px solid ${Colors.BORDER}`,
                        borderRight: `1px solid ${Colors.BORDER}`,
                        borderBottom: `1px solid ${Colors.BORDER}`,
                        '&:first-of-type': {
                          borderRadius: '8px 0 0 0',
                          borderLeft: `1px solid ${Colors.BORDER}`,
                        },
                        '&:last-child': {
                          borderRadius: '0 8px 0 0',
                        },
                        fontSize: '1rem',
                      }}
                    >
                      <Stack gap={'2rem'}>
                        {(typeof el === 'object' && el.sortable) ? (
                          <Stack direction="row" spacing={2}>
                            <TableSortLabel
                              active={orderBy === el.sortField}
                              direction={orderBy === el.sortField ? order : 'asc'}
                              onClick={createSortHandler(el.sortField)}
                            >
                              <Typography >
                                {typeof el === 'string' ? el : el?.label}
                              </Typography>

                            </TableSortLabel>
                          </Stack>) : (
                          <Typography >
                            {typeof el === 'string' ? el : el?.label}
                          </Typography>
                        )}
                        {typeof el === 'object' && el?.filter}
                      </Stack>
                    </TableCell>
                  ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow sx={{ m: '0 auto' }}>
                <TableCell
                  colSpan={headers?.length}
                  sx={{
                    border: `1px solid ${Colors.BORDER}`,
                    borderTop: 'none',
                    borderRadius: '0 0 8px 8px',
                  }}
                >
                  <Stack alignItems='center' justifyContent='center'>
                    <CircularProgress />
                  </Stack>
                </TableCell>
              </TableRow>
            ) : isError ? (
              <TableRow sx={{ m: '0 auto' }}>
                <TableCell
                  colSpan={headers?.length}
                  sx={{
                    border: `1px solid ${Colors.BORDER}`,
                    borderRadius: '0 0 8px 8px',
                  }}
                >
                  <Typography
                    color='error'
                    sx={{
                      textAlign: 'center',
                      fontWeight: 500,
                    }}
                  >
                    {t('errors:fetch_data_error')}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : data?.length ? (
              data.map((row, idx) =>
                !!rowCollapsed ? (
                  <UIRow
                    key={`row-${idx}`}
                    idx={idx}
                    row={row}
                    headers={headers}
                    columns={columns}
                    color={bgColor(row)}
                    rowCollapsedTitle={rowCollapsedTitle}
                  >
                    {renderRowAfter && renderRowAfter(row, idx)}
                  </UIRow>
                ) : (
                  <Fragment key={idx}>
                    <TableRow
                      sx={{
                        '&:first-of-type': {
                          '& td': {
                            ...(!headers && {
                              borderTop: `1px solid ${Colors.BORDER}`,

                              '&:first-of-type': {
                                borderTopLeftRadius: '8px',
                              },

                              '&:last-child': {
                                borderTopRightRadius: '8px',
                              },
                            }),
                          },
                        },
                        '&:last-child': {
                          '& td': {
                            '&:first-of-type': {
                              borderBottomLeftRadius: '8px',
                            },
                            '&:last-child': {
                              borderBottomRightRadius: '8px',
                            },
                          },
                        },
                      }}
                    >
                      {columns.map((column, colIdx) => (
                        <TableCell
                          key={colIdx}
                          align={column.align ? column.align : 'left'}
                          sx={{
                            borderRight: `1px solid ${Colors.BORDER}` as any,
                            '&:first-of-type': {
                              borderLeft: `1px solid ${Colors.BORDER}`,
                            },
                            borderBottom: `1px solid ${Colors.BORDER}` as any,
                            py: 1.25 as any,
                            px: 2 as any,
                            ...(column?.nowrap && {
                              whiteSpace: 'nowrap',
                              width: '1%',
                            }),
                            fontSize: '1rem' as any,
                            ...column?.sx,
                            // @ts-ignore
                            bgcolor: row.date_cancel ? Colors.MEDIUM_GREY : (bgColor(row) ?? ''),
                          }}
                        >
                          {column?.render
                            ? column?.render(row, idx)
                            : (_.get(row, column.key ? column.key : '') as ReactNode)}
                        </TableCell>
                      ))}
                    </TableRow>
                    {!!renderRowAfter && (
                      <TableRow
                        sx={{
                          '&:first-of-type': {
                            '& td': {
                              ...(!headers && {
                                borderTop: `1px solid ${Colors.BORDER}`,

                                '&:first-of-type': {
                                  borderTopLeftRadius: '8px',
                                },

                                '&:last-child': {
                                  borderTopRightRadius: '8px',
                                },
                              }),
                            },
                          },
                          '&:last-child': {
                            '& td': {
                              '&:first-of-type': {
                                borderBottomLeftRadius: '8px',
                              },
                              '&:last-child': {
                                borderBottomRightRadius: '8px',
                              },
                            },
                          },
                        }}
                      >
                        <TableCell
                          colSpan={columns.length}
                          sx={{
                            borderRight: `1px solid ${Colors.BORDER}`,
                            '&:first-of-type': {
                              borderLeft: `1px solid ${Colors.BORDER}`,
                            },
                            borderBottom: `1px solid ${Colors.BORDER}`,
                            py: 1.25,
                            px: 2,
                          }}
                        >
                          {renderRowAfter(row, idx)}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                )


              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers ? headers.length : 1}
                  sx={{
                    borderRight: `1px solid ${Colors.BORDER}`,
                    borderLeft: `1px solid ${Colors.BORDER}`,
                    '&:last-child': {
                      borderRadius: '0 0 8px 8px',
                    },
                    borderBottom: `1px solid ${Colors.BORDER}`,
                    py: 2.5,
                    px: 2,
                    fontSize: '1rem',
                  }}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {PaginationProps && (
        <Stack
          direction='row'
          justifyContent='center'
          sx={{
            mt: 3,
          }}
        >
          <Pagination {...PaginationProps} />
        </Stack>
      )}
    </Box>
  );
}

export default UITable;

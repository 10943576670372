import React, { useRef } from 'react';
import { IconButton, Link, Stack } from '@mui/material';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { useFilters } from 'app/hooks/use-filters';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { TestProgram, TestProgramConnect } from 'entities/test-program/model';
import { useConfirmTestProgramConnectMutation, useDeleteTestProgramConnectMutation, useGetTestProgramConnectQuery } from 'entities/test-program/api';
import { useAppSelector } from 'app/hooks/redux';
import { TestProgramConnectModal } from './modal/connect-modal';
import { ConnectModalModalRef } from './modal/types';
import notify from 'app/utils/notify';
/*import { AccredStandartPrimaryModal } from './modal/standart-primary-modal';
import { AccredStandartPrimaryModalRef } from './modal/types';*/

interface Props {
  data: TestProgram;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
}

export const ConnectedList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<ConnectModalModalRef>(null);
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;

  const [deleteRec] = useDeleteTestProgramConnectMutation();
  const [ confirmConnect, { isLoading: isLoadingConfirm } ] = useConfirmTestProgramConnectMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program });
    }
  };


  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //sort: 'name',
    },
  });

  const { data: records, isFetching } = useGetTestProgramConnectQuery({
    id_test_program: data ? data.id : 0,
    ...filterParams,
  });

  const handleConfirm = async (id: number) => {
    try {
      await confirmConnect({ id }).unwrap();
      notify(t('confirmSuccess'), 'success');
    } catch (error) {
      notify(t('confirmError'), 'error');
    }
  }

  const headers = [
    {
      label: t('testProgram.connect.certBody'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
      visible: tenantType === 2,
    },
    {
      label: t('testProgram.connect.lab'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
      visible: tenantType === 1,
    },
    {
      label: t('testProgram.connect.programCertBody'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
      visible: true,//tenantType === 1,
    },
    {
      label: t('testProgram.connect.programLab'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
      visible: true,//tenantType === 1,
    },
    {
      label: t('testProgram.connect.confirmed'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
      visible: true,//tenantType === 1,
    },
    {
      label: t('actions'),
      align: 'center' as const,
      visible: true,
    },
  ];

  const columns = [
    {
      render: (row: TestProgramConnect) => row.testProgramCert.certBodyName ?? '--',
      visible: tenantType === 2,
    },
    {
      render: (row: TestProgramConnect) => row.testProgramLab.laboratoryName ?? '--',
      visible: tenantType === 1,
    },
    {
      render: (row: TestProgramConnect) => {
        if (row.testProgramCert) {
          if (row.testProgramCert.doc_url) {
            return (
              <Link
                href={row.testProgramCert.doc_url}
                target='_blank'
              >
                {row.testProgramCert.name}
              </Link>
            );
          } else {
            return row.testProgramCert.name;
          }
        } else {
          return '-- ';
        }
      },
      visible: true,
    },
    {
      render: (row: TestProgramConnect) => row.testProgramLab.name ?? '--',
      visible: true,
    },
    {
      render: (row: TestProgramConnect) => row.is_connected ? t('yes') : t('no'),
      visible: true,
    },
    {
      render: (row: TestProgramConnect) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, row.id_test_program_cert)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
          {((row.is_connected === 0) && (tenantType==2)) && (
            <UIActionButton
              icon='check'
              tooltip={t('testProgram.connect.confirmed')}
              onClick={() => handleConfirm(row.id)}
            />
          )}
        </Stack>
      ),
      nowrap: true,
      visible: true,
    },
  ];

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('testProgram.connect.title')}
            {tenantType==1 && (<IconButton
              onClick={() => modalRef.current?.open()} 
              aria-label="add"
            >
              <AddCircle />
            </IconButton>)}
          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={ headers.filter((header) => header.visible) }
          columns={ columns.filter((column) => column.visible) }
          //onSort={onChangeFilterParams}
          //orderDirection={'asc'}
          //orderByField={'name'}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <TestProgramConnectModal testProgram={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

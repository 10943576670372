import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteStandartMutation, useGetStandartsQuery } from 'entities/accreditation/api';
import { Autocomplete, Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import UITable from 'shared/ui/ui-table';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import { AccredStandart } from 'entities/accreditation/model';
import { useNavigate } from 'react-router-dom';
import { AccredStandartModalRef } from './modal/types';
import { AccredStandartModal } from './modal/standart-modal';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';

interface DeleteRequestBody {
  id: number;
}

const StandartList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<AccredStandartModalRef>(null);

  const [deleteRec] = useDeleteStandartMutation();

  const prepareRequestBody = (id: number): DeleteRequestBody => ({id});

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id);
    }
  };

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      name: '',
      specific_name: '',
      type: '',
      sort: 'id',
    },
  });

  const { data: records, isFetching } = useGetStandartsQuery({
    'per-page': 20,
    ...filterParams,
  });

  return (
    <Box>
      <Stack direction={'row'} mb={'2rem'}>
        <Typography variant='h2'>{t('accred.standart.title')}</Typography>
        <IconButton onClick={() => modalRef.current?.open()} aria-label="add">
          <AddCircle sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <TextField
          label={t('accred.specific.search')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ specific_name: e.target.value })}
        />
        <TextField
          label={t('accred.standart.search')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ name: e.target.value })}
        />
        <Autocomplete
          options={[
            { id: "0", name: t('accred.standart.typeOld') },
            { id: "1", name: t('accred.standart.typeCalc') },
            { id: "2", name: t('accred.standart.typeText') }
          ]}//{ locationList ?? [] }//{ laboratories?.items ?? []}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
          //@ts-ignore
          onChange={(event, option) => {
            //@ts-ignore
            onChangeFilterParams({ type: (option && option.id) ? option.id.toString() : '' });

            //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
            //field.onChange(option);
            //@ts-ignore
            //onChange?.(event, option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              //placeholder: t('location')

              label={t('accred.standart.type')}
            />
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              m: 'auto',
            }, width: 220
          }}
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort={onChangeFilterParams}
        orderDirection={'desc'}
        orderByField={'confirm_date'}
        headers={[
          {
            label: t('accred.standart.group'),
            align: "center" as const,
            //sortable: true,
            //sortField: 'id_accred_group',
          },
          {
            label: t('accred.standart.specific'),
            align: "center" as const,
            sortable: true,
            sortField: 'id_accred_specific',
          },
          {
            label: t('accred.standart.name'),
            align: "center" as const,
            sortable: true,
            sortField: 'name',
          },
          {
            label: t('accred.standart.type'),
            align: "center" as const,
            sortable: true,
            sortField: 'type',
          },
          {
            label: t('actions'),
            align: "center" as const,
          },
        ]}
        columns={[
          {
            render: (row: AccredStandart) => row.accredSpecific.accredGroup.name ?? '-',
          },
          {
            render: (row: AccredStandart) => row.accredSpecific.name ?? '-',
          },
          {
            render: (row: AccredStandart) => row.name ?? '-',
          },
          {
            render: (row: AccredStandart) => row.type===0 ? t('accred.standart.typeOld') : row.type===1 ? t('accred.standart.typeCalc') : row.type===2 ? t('accred.standart.typeText') : '',
          },
          {
            render: (row: AccredStandart) => (
              <Stack direction='row' spacing={2} justifyContent='center'>
                <UIActionButton
                  icon='observe'
                  onClick={() => navigate(`/accreditation/standart/view/${row.id}`)}
                />
                <UIActionButton icon='edit' onClick={() => modalRef.current?.open(row)} />
                <UIActionButton
                  icon='delete'
                  tooltip={t('delete')}
                  onClick={() => openDeleteModal(row.id)}
                />
              </Stack>
            ),
            nowrap: true,
          },
        ]}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <AccredStandartModal ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
      {/**<AddAccredSpecificModal ref={modalRef} />
      <WarningAccredSpecificModal ref={modalWarningRef} />*/}
    </Box>
  );
};

export default StandartList;

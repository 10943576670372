import React, { useRef } from 'react';
import { IconButton, Stack } from '@mui/material';
import { AccredStandart, AccredStandartAccuracyControl } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import { useDeleteStandartAccuracyMutation, useGetStandartAccuracyQuery } from 'entities/accreditation/api';
import { useFilters } from 'app/hooks/use-filters';
import { AddCircle } from '@mui/icons-material';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import Attention from 'shared/ui/attention';
import { DeleteModal } from 'features/delete-modal';
import { AccredStandartAccuracyModal } from './modal/standart-accuracy-modal';
import { AccredStandartAccuracyModalRef } from './modal/types';
import { DeleteModalRef } from 'features/delete-modal/types';

interface Props {
  data: AccredStandart;
}

interface DeleteRequestBody {
  id: number;
  id_accred_standart: number;
}

export const Accuracy: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<AccredStandartAccuracyModalRef>(null);

  const [deleteRec] = useDeleteStandartAccuracyMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_accred_standart: additionalData?.id_accred_standart as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_accred_standart: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_accred_standart });
    }
  };

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //sort: '-date_service',
    },
  });

  const { data: records, isFetching } = useGetStandartAccuracyQuery({
    id_accred_standart: data ? data.id : 0,
    ...filterParams,
  });

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('accred.standart.tabs.controlAccuracy.title')}
            <IconButton
              onClick={() => modalRef.current?.open()}
              aria-label="add"
            >
              <AddCircle />
            </IconButton>

          </>
        }
        collapsed={true}
      >
        {(data.parallel_test === 1 && records?._meta?.totalCount && records._meta.totalCount < 1) && (
          <Attention
            description={t('accred.standart.tabs.controlAccuracy.noData')}
          />
        )}
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={[
            {
              label: t('accred.standart.tabs.controlAccuracy.value'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.controlAccuracy.intraLabDeviation'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.controlAccuracy.interLabDeviation'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.controlAccuracy.repeatabilityLimit'),
              align: 'center' as const,
            },
            {
              label: t('accred.standart.tabs.controlAccuracy.reproducibilityLimit'),
              align: 'center' as const,
            },
            {
              label: t('actions'),
              align: 'center' as const,
            },
          ]}
          columns={[
            {
              render: (row: AccredStandartAccuracyControl) => {
                if (row.value_from === null && row.value_to === null) {
                  return '--';
                }
                if (row.value_from === null) {
                  return `${t('to')} ${row.value_to}`
                }
                if (row.value_to === null) {
                  return `${t('from')} ${row.value_from}`
                }
                return `${t('from')} ${row.value_from} ${t('to')} ${row.value_to}`
              },
            },
            {
              render: (row: AccredStandartAccuracyControl) => row.inlab_stdr_deviation ?? '--',
            },
            {
              render: (row: AccredStandartAccuracyControl) => row.interlab_stdr_deviation ?? '--',
            },
            {
              render: (row: AccredStandartAccuracyControl) => row.repeat_limit ?? '--',
            },
            {
              render: (row: AccredStandartAccuracyControl) => row.reproduce_limit ?? '--',
            },
            {
              render: (row: AccredStandartAccuracyControl) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='edit'
                    tooltip={t('edit')}
                    onClick={() => modalRef.current?.open(row)}
                  />
                  <UIActionButton
                    icon='delete'
                    tooltip={t('delete')}
                    onClick={() => openDeleteModal(row.id, row.id_accred_standart)}
                  />
                </Stack>
              ),
              nowrap: true,
            },

          ]}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <AccredStandartAccuracyModal accredStandart={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

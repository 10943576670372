import { Box, Button, Stack, FormControl } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UITable from 'shared/ui/ui-table';
import AddEnviromentConditionModal from '../add-enviroment-conditions-modal';
import { EnvironmentRecord, Laboratory } from 'entities/laboratories/model';
import { BaseModalRef } from 'app/utils/types';
import { useGetLaboratoryEnvironmentRecordsQuery } from 'entities/laboratories/api';
import { useFilters } from 'app/hooks/use-filters';
import { useGetEnvironmentConditionsQuery } from 'entities/dicts/api';
import { UITableColumn } from 'shared/ui/ui-table/types';
import UIActionButton from 'shared/ui/ui-action-button';
import { CancelModal } from 'features/cancel-modal';
import { CancelModalRef } from 'features/types';
import notify from 'app/utils/notify';

import dayjs from 'dayjs';
import UIDatePicker from 'shared/inputs/date-picker';

interface Props {
  data: Laboratory;
}

const SingleRoom: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();


  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      fromDate: '',
      toDate: '',
      sort: '-date_create',
    },
  });

  const { data: records, isFetching } = useGetLaboratoryEnvironmentRecordsQuery({
    labId: data.id,
    //sort: '-date_create',
    ...filterParams,
  });
  const { data: envConds } = useGetEnvironmentConditionsQuery({ page: 1 });

  const modalRef = useRef<BaseModalRef>(null);
  const cancelModalRef = useRef<CancelModalRef>(null);

  const envHeaders =
    records?.items && records.items.length > 0 ?  records?.items?.[0].environmentSubs.map(
      (el) =>
        envConds?.items.find((item) => item.id === el.id_environment_condition)?.name ??
        ''
    ) : [];

  const envColumns: UITableColumn<EnvironmentRecord>[] =
  records?.items && records.items.length > 0 ? records?.items?.[0].environmentSubs.map((_, idx) => ({
      render: (row) => {
        if (row.environmentSubs[idx]) {
          return row.environmentSubs[idx].value 
        }
        else return '-'
      },
    })) : [];

  const handleCancel = (id : number, id_lab : number, doc_json:string) => {
    if (doc_json) {
      cancelModalRef.current?.open({
        id,
        id_lab,
        doc_json: doc_json.replace(/\\\//g, "")
      })
    } else {
      notify('Старые данные, нужно отредактировать', 'warning')
    }
  }

  const handleClickAdd = () => {
    modalRef.current?.open();
  }

  return (
    <Box>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <Button color='primary' onClick={handleClickAdd}>
          {t('add')}
        </Button>
        <Button color='success'>{t('report')}</Button>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <UIDatePicker
          value={filterParams.fromDate ? dayjs(filterParams.fromDate).toDate() : null}
          TextFieldProps={{ label: t('dateFrom')}}
          //@ts-ignore
          onChange={(date:Date | null) => onChangeFilterParams({ fromDate:date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
        <UIDatePicker
          value={filterParams.toDate ? dayjs(filterParams.toDate).toDate() : null}
          TextFieldProps={{ label: t('dateTo')}}
          //@ts-ignore
          onChange={(date:Date | null) => onChangeFilterParams({ toDate:date ? dayjs(date).format('YYYY-MM-DD') : '' })}
          disabled={false}
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort = {onChangeFilterParams}
        orderDirection = {'desc'}
        orderByField = {'date_create'}
        headers={['№', 
          {
            label: t('date'),
            align:"center"  as const,
            sortable: true,
            sortField: 'date_create',
          },
          ...envHeaders, t('responsable_person'), t('cancel')
        ]}
        columns={[
          {
            render: (_, index) => (filterParams.page - 1) * 10 + index + 1,
            nowrap: true,
          },
          { render: (row) => row.date_sign, nowrap: true, },
          ...envColumns,
          { key: 'staff.fio' },
          { render: (row) => {
            if (!row.date_cancel) return <Button color='warning' onClick={() => handleCancel(row.id, row.id_laboratory, row.doc_json)}>{t('cancel_sign')}</Button>
            return row.date_cancel + ' ' + (row.staffCancel.fio ?? '')
          }, nowrap: true },
        ]}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <AddEnviromentConditionModal ref={modalRef} room={data} />
      <CancelModal ref={cancelModalRef} />
    </Box>
  );
};

export default SingleRoom;

import React, { useState } from 'react';
import Layout from 'shared/layout';
import DetailView from 'shared/ui/detail-view';
import UITable from 'shared/ui/ui-table';
import CollapseCard from 'shared/ui/collapse-card';
import { 
  Typography, Box, Button, TextField, FormControlLabel, Checkbox,
  Radio, RadioGroup, Grid
} from '@mui/material';
import { DetailViewItem } from 'shared/ui/detail-view/types';
import { UITableColumn } from 'shared/ui/ui-table/types';

const InspectionReportForm: React.FC = () => {
  const renderYesNoNA = (id: string) => (
    <RadioGroup row name={id}>
      <FormControlLabel value="yes" control={<Radio />} label="YES" />
      <FormControlLabel value="no" control={<Radio />} label="NO" />
      <FormControlLabel value="na" control={<Radio />} label="N/A" />
    </RadioGroup>
  );

  const renderTextField = (label: string, multiline: boolean = false, rows: number = 1) => (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      margin="normal"
    />
  );

  const generalInfo: DetailViewItem[] = [
    { label: 'Зарегистрированное имя производителя', value: '' },
    { label: 'Улица и номер дома', value: '' },
    { label: 'Почтовый индекс', value: '' },
    { label: 'Город', value: '' },
    { label: 'Область (провинция)', value: '' },
    { label: 'Страна', value: '' },
    { label: 'GPS – координаты (опция) и QR код на ссылку локации', value: '' },
  ];

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Акт обследования производства №____
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button variant="contained" color="primary">Подпись инспектора</Button>
          <Button variant="contained" color="primary">Подпись эксперта</Button>
          <Button variant="contained" color="secondary">pdf</Button>
          <Button variant="contained">Отправить через inbox</Button>
        </Box>

        <CollapseCard title="1. Общая информация" collapsed={false}>
          <DetailView data={generalInfo} />
        </CollapseCard>

        <CollapseCard title="2. Проверка закупленных компонентов и материалов" collapsed={false}>
          <Typography>
            Материалы, компоненты и узлы проверены производителем в соответствии с соответствующей спецификацией?
          </Typography>
          {renderYesNoNA("2.1")}
          {renderTextField("Примечание", true, 3)}
        </CollapseCard>

        <CollapseCard title="3. Производственный контроль, мониторинг и плановые испытания" collapsed={false}>
          <Typography>
            Ведутся ли записи хотя бы в течение периода между двумя инспекционными визитами?
          </Typography>
          {renderYesNoNA("3.1")}
          {renderTextField("Примечание", true, 3)}
        </CollapseCard>

        <CollapseCard title="4. Функциональная проверка испытательного и измерительного оборудования" collapsed={false}>
          <Typography>
            Есть ли доказательства того, что функциональная проверка оборудования проведена надлежащим образом, даже если сертифицированная продукция не была запущена в производство?
          </Typography>
          {renderYesNoNA("4.1")}
          {renderTextField("Примечание", true, 3)}
        </CollapseCard>

        <CollapseCard title="5. Продукция, увиденная на производстве во время визита" collapsed={false}>
          <FormControlLabel control={<Checkbox />} label="Никакое производство" />
          <FormControlLabel control={<Checkbox />} label="Производство видно для следующего продукта:" />
          {renderTextField("Вид продукта")}
          {renderTextField("Категория товара")}
          {renderTextField("Класс изоляции")}
          {renderTextField("Номер типа")}
          {renderTextField("Сертификационные знаки")}
        </CollapseCard>

        {/* Add more CollapseCard components for sections 6-16 */}

        <CollapseCard title="17. Оценка инспектора" collapsed={false}>
          <Typography variant="h6">Рекомендации инспектора:</Typography>
          <FormControlLabel control={<Checkbox />} label="1. Никаких неудовлетворительных результатов" />
          <FormControlLabel control={<Checkbox />} label="2. Незначительный неудовлетворительный вывод(ы)" />
          <FormControlLabel control={<Checkbox />} label="3. Основной неудовлетворительный вывод(ы)" />
          <FormControlLabel control={<Checkbox />} label="4. Критический неудовлетворительный вывод(ы)" />
          {renderTextField("Продолжительность осмотра, в часах")}
        </CollapseCard>
      </Box>
    </Layout>
  );
};

export default InspectionReportForm;
import React, { useState } from 'react';
import './index.sass';
import Layout from 'shared/layout';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import { useTranslation } from 'react-i18next';
import { Button, Card, FormControl, FormLabel, Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'app/hooks/redux';
import eimzo, { Cert } from 'app/vendors/eimzo';
import { SystemUser } from 'entities/auth/model/types';
import { signIn } from 'entities/auth/model/slice';
import { Controller, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import PasswordInput from 'shared/inputs/password-input';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import EcpSelect from 'shared/inputs/ecp-select';
import { useLazyGenerateGuidQuery, useLoginMutation } from 'entities/auth/api';
import { handleResponse } from 'app/utils';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  cert: Cert | null;
  registry_nn: string;
  password: string;
  rememberMe: boolean;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      cert: null,
      registry_nn: '',
      password: '',
      rememberMe: false,
    },
  });

  const [loading, setLoading] = useState(false);

  const [generateGuid] = useLazyGenerateGuidQuery();
  const [login] = useLoginMutation();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: FormValues) => {
    const { cert, registry_nn, rememberMe, password } = data;
    try {
      setLoading(true);
      const guid = await generateGuid({}).then((res) => res.data);
      if (!guid) return Promise.reject();

      const pkcs7 = await eimzo.sign(guid.guid, cert);
      const data = await handleResponse(
        await login({
          pkcs7,
          rememberMe,
          password,
          registry_nn,
          id_guid: guid.id_guid,
        })
      );
      const user: SystemUser = {
        ...data.user,
        serialNumber: cert?.serialNumber,
      };
      dispatch(signIn({ user, token: data.token }));
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className='auth-page'>
        <div className='auth-page__inner'>
          <Card sx={{ padding: 10 }}>
            <div className='auth-page__header'>
              <div className='auth-page__lock'>
                <LockOpenRoundedIcon className='auth-page__lock--icon' />
              </div>
              <Typography variant='h4'>{t('login_in_cabinet')}</Typography>
            </div>
            <div className='auth-page__inputs'>
              <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                  <FormLabel>{t('chose_key')}</FormLabel>
                  <Controller
                    control={control}
                    name='cert'
                    rules={{
                      required: t('errors:required_field'),
                    }}
                    render={({ field, fieldState }) => (
                      <EcpSelect
                        {...field}
                        invalid={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{t('registry_id')}</FormLabel>
                  <FormTextInput
                    control={control}
                    name='registry_nn'
                    rules={{
                      required: true,
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{t('password')}</FormLabel>
                  <Controller
                    control={control}
                    name='password'
                    rules={{
                      required: t('errors:required_field'),
                    }}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        {...field}
                        helperText={fieldState.error?.message}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormCheckboxInput
                    control={control}
                    name='rememberMe'
                    label={t('remember_me')}
                  />
                </FormControl>

                <Stack direction='row' spacing={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    type='submit'
                    disabled={loading}
                  >
                    {t('login')}
                  </Button>
                </Stack>
              </Stack>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;

import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetUnitsQuery,
} from 'entities/dicts/api';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState,
  methodTypes,
  resultPrecisions,
  resultRules,
} from './initialState';
import { useCreateStandartMutation, useGetGroupsQuery, useGetSpecificsQuery, useUpdateStandartMutation } from 'entities/accreditation/api';
import { AccredStandart } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';

export const AccredStandartModal = forwardRef<AccredStandartModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { 
    control, 
    handleSubmit, 
    reset, 
    //setValue, watch, formState, trigger 
  } = methods;
  const [createStandart, { isLoading: isLoadingCreate }] = useCreateStandartMutation();
  const [updateStandart, { isLoading: isLoadingUpdate } ] = useUpdateStandartMutation();

  const { data: groups, isFetching: isGroupFetching } = useGetGroupsQuery({'per-page': 0,});
  const [selectedGroup, setSelectedGroup] = useState('0');
  const { data: specifics, isFetching: isSpecificFetching } = useGetSpecificsQuery({
    'per-page': 0,
    id_accred_group: selectedGroup,
  });//, { skip: !selectedGroup || selectedGroup === '' });
  const { data: laboratories, isFetching: isLaboratoryFetching } = useGetLaboratoriesQuery({});

  const handleGroupChange = (id_accred_group: string) => {
    console.log(id_accred_group);
    setSelectedGroup(id_accred_group);
  };

  const [methodType, setMethodType] = useState(1);
  const [parallelShow, setParallelShow] = useState(0);

  const { data: units, isFetching: isUnitFetching } = useGetUnitsQuery({});

  const {
    //fields: unitFields,
    append: appendUnit,
    remove: removeUnit,
    //insert: insertUnit,
  } = useFieldArray({
    control,
    name: 'units',
    keyName: 'id',
  });

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandart | undefined) => {
    if (data) {
      setIsEdit(true);
      const result_precision = resultPrecisions.find((item) => item.value === data.result_precision);
      const result_rule = resultRules.find((item) => item.value === data.result_rule);
      const method_type = methodTypes.find((item) => item.value === data.type);
      setMethodType(data.type);
      setParallelShow(data.parallel_test);
      reset({
        id: data.id,
        id_accred_group: data.accredSpecific.accredGroup,
        id_accred_specific: data.accredSpecific,
        name: data.name,
        accuracy: data.accuracy,
        id_laboratory: data.laboratoryData,
        result_precision: result_precision ? {...result_precision, label: t(result_precision.label)}:{label:'', value:''},
        measure_count: data.measure_count,
        result_rule: result_rule ? {...result_rule, label: t(result_rule.label)}:{label:'', value:''},
        conditioning_time: data.conditioning_time,
        units: data.accredStandartUnits,
        formula: data.formula,
        confidence_probability: data.confidence_probability,
        type: method_type ? {...method_type, label: t(method_type.label)}:{label:'', value:''},
        preliminary_test: data.preliminary_test===1,
        parallel_test: data.parallel_test===1,
        repeat_limit: data.repeat_limit===1,
        standard_deviation: data.standard_deviation===1,
      });
    }else{
      reset({...initialState, type: {label: t(methodTypes[0].label), value: methodTypes[0].value}});
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({...initialState, type: {label: t(methodTypes[0].label), value: methodTypes[0].value}});
  };

  const onSubmit = async (data: FormValues) => {
    
    if (!data.id_accred_specific) {
      return;
    }
    
    let body = {
      //id_accred_group: data.id_accred_group ? Number(data.id_accred_group.id) : null,
      id_accred_specific: Number(data.id_accred_specific.id),
      name: data.name,
      accuracy: Number(data.accuracy),
      id_laboratory: data.id_laboratory ? Number(data.id_laboratory.id) : null,
      result_precision: Number(data.result_precision.value),
      measure_count: Number(data.measure_count),
      result_rule: Number(data.result_rule.value),
      conditioning_time: Number(data.conditioning_time),
      units: data.units ? data.units.map((item) => item.id) : [],
      formula: data.formula,
      confidence_probability: Number(data.confidence_probability),
      type: Number(data.type.value),
      preliminary_test: data.preliminary_test ? 1 : 0,
      parallel_test: data.parallel_test ? 1 : 0,
      repeat_limit: data.repeat_limit ? 1 : 0,
      standard_deviation: data.standard_deviation ? 1 : 0,
    };
    
    try {
      if (isEdit && data.id) {
        await updateStandart({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandart(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      
      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else{
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '400px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={6}>
            <FormControl>
              <FormSelectInput
                loading={isGroupFetching}
                options={groups?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_accred_group`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.group')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, option) => {
                  //@ts-ignore
                  handleGroupChange((option && option.id) ? option.id.toString() : '0');

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormSelectInput
                loading={isSpecificFetching}
                options={specifics?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_accred_specific`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.view.specific')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <FormTextInput
                control={control}
                name='name'
                label={t('accred.standart.view.standart')}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormSelectInput
                options={methodTypes.map((item) => ({ label: t(item.label), value: item.value }))}
                control={control}
                name={`type`}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) => value.value === undefined || String(value.value) === "" || option.value === value.value}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.type')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, option) => {
                  //@ts-ignore
                  setMethodType((option && option.value) ? option.value : 1);

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <FormSelectInput
                loading={isLaboratoryFetching}
                options={laboratories?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_laboratory`}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.view.laboratory')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormNumericInput
              control={control}
              name={'conditioning_time'}
              label={t('accred.standart.view.conditioningTime')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {t('accred.standart.view.min')}
                  </Typography>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          flex={'1'}
          sx={{ mt: 0.1, display: methodType === 1 ? 'flex' : 'none' }}
        >
          <Grid item xs={4}>
            <FormControl>
              <FormTextInput
                control={control}
                name='formula'
                label={t('accred.standart.view.formula')}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <FormSelectInput
                options={resultPrecisions.map((item) => ({ label: t(item.label), value: item.value }))}
                control={control}
                name={`result_precision`}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) => value.value === undefined || String(value.value) === "" || option.value === value.value}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.view.resultPrecision')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormSelectInput
                options={resultRules.map((item) => ({ label: t(item.label), value: item.value }))}
                control={control}
                name={`result_rule`}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) => value.value === undefined || String(value.value) === "" || option.value === value.value}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.view.resultRule')
                }}
                rules={{
                  //required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormNumericInput
              //key={field.id}
              control={control}
              name={'confidence_probability'}
              label={t('accred.standart.view.confidenceProbability')}
              fullWidth
              required
              rules={{
                required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {t('unitUnit')}
                  </Typography>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'} sx={{ mt: 0.1 }}>
          <Grid item xs={4}>
            <FormNumericInput
              control={control}
              name={'accuracy'}
              label={t('accred.standart.view.accuracy')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    %
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormNumericInput
              control={control}
              name={'measure_count'}
              label={t('accred.standart.view.measureCount')}
              fullWidth
              //required
              rules={{
                //required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    {t('itemUnit')}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={'preliminary_test'}
                label={t('accred.standart.view.preliminaryTest')}
                onChange={(event, checked) => {
                  //setValue('preliminary_test', checked ? 1 : 0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={'parallel_test'}
                label={t('accred.standart.view.parallelTest')}
                onChange={(event, checked) => {
                  //setValue('parallel_test', checked ? 1 : 0);
                  setParallelShow(checked ? 1 : 0);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ display: parallelShow === 1 ? 'block' : 'none' }}>
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={'repeat_limit'}
                label={t('accred.standart.view.repeatLimit')}
                onChange={(event, checked) => {
                  //setValue('repeat_limit', checked ? 1 : 0);
                }}
              />
            </FormControl>

          </Grid>
          <Grid item xs={6} sx={{ display: parallelShow === 1 ? 'block' : 'none' }}>
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={'standard_deviation'}
                label={t('accred.standart.view.standardDeviation')}
                onChange={(event, checked) => {
                  //setValue('standard_deviation', checked ? 1 : 0);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl>
              <FormSelectInput
                loading={isUnitFetching}
                multiple
                options={units?.items ?? []}
                control={control}
                name={`units`}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
                disableCloseOnSelect
                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('unit')
                }}
                rules={{
                  //required: true,
                }}
                onChange={(event, value) => {
                  //field.onChange(value);
                  // Reset the field array with new selected values
                  removeUnit();
                  value.forEach((v) => appendUnit(v));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

import React, { useRef } from 'react';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { useFilters } from 'app/hooks/use-filters';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { TestProgram, TestProgramFeature, TestProgramFeatureList } from 'entities/test-program/model';
import { useDeleteTestProgramFeatureListMutation, useDeleteTestProgramFeatureMutation, useGetTestProgramFeatureListsQuery, useGetTestProgramFeaturesQuery } from 'entities/test-program/api';
import { TestProgramFeatureListModal, TestProgramFeatureListModalRef } from './feature-list-modal';
/*import { AccredStandartPrimaryModal } from './modal/standart-primary-modal';
import { AccredStandartPrimaryModalRef } from './modal/types';*/

interface Props {
  data: TestProgramFeature;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
  id_test_program_feature: number;
}

export const FeatureList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<TestProgramFeatureListModalRef>(null);

  const [deleteRec] = useDeleteTestProgramFeatureListMutation();
  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
    id_test_program_feature: additionalData?.id_test_program_feature as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number, id_test_program_feature: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program, id_test_program_feature });
    }
  };


  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      sort: 'name',
    },
  });

  const { data: records, isFetching } = useGetTestProgramFeatureListsQuery({
    id_test_program: data ? data.id_test_program : 0,
    id_test_program_feature: data ? data.id : 0,
    ...filterParams,
  });

  const headers = [
    {
      label: t('name'),
      align: "center" as const,
      sortable: true,
      sortField: 'name',
    },
    {
      label: t('actions'),
      align: 'center' as const,
    },
  ];

  const columns = [
    {
      render: (row: TestProgramFeatureList) => row.name ?? '--',
    },
    {
      render: (row: TestProgramFeatureList) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='edit'
            tooltip={t('edit')}
            onClick={() => modalRef.current?.open(row)}
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, data.id_test_program, row.id_test_program_feature)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <Stack direction='column' spacing={2} justifyContent='left'>
      <Typography variant='h3'>
        {t('testProgram.featureLists')}
        <IconButton
          onClick={() => modalRef.current?.open()} 
          aria-label="add"
        >
          <AddCircle />
        </IconButton>
      </Typography>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        headers={headers}
        columns={columns}
        onSort={onChangeFilterParams}
        orderDirection={'asc'}
        orderByField={'name'}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />

      <TestProgramFeatureListModal feature={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </Stack>
  );
}

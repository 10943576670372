import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useFilters } from 'app/hooks/use-filters';
import { useGetSpecificsQuery } from 'entities/accreditation/api';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import UITable from 'shared/ui/ui-table';
import { AccredSpecific } from 'entities/accreditation/model';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import { AccredSpecificModalRef, AccredSpecificDeleteModalRef } from './types';
import { AddAccredSpecificModal } from './add-accred-specific-modal';
import { WarningAccredSpecificModal } from './warning-accred-specific-modal';


const SpecificList: React.FC = () => {
  const { t } = useTranslation();
  
  const modalRef = useRef<AccredSpecificModalRef>(null);
  const modalWarningRef = useRef<AccredSpecificDeleteModalRef>(null);

  const handleDelete = (id: number) => {
    modalWarningRef.current?.open(id)
  }

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      name: '',
      sort: 'id',
    },
  });

  const { data: records, isFetching } = useGetSpecificsQuery({
    'per-page': 20,
    ...filterParams,
  });

  return (
    <Box>
      <Stack direction={'row'} mb={'2rem'}>
        <Typography variant='h2'>{t('accred.specific.title')}</Typography>
        <IconButton onClick={() => modalRef.current?.open()} aria-label="add">
          <AddCircle sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <TextField
          label={t('accred.specific.search')}
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ name: e.target.value })}
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort={onChangeFilterParams}
        orderDirection={'desc'}
        orderByField={'confirm_date'}
        headers={[
          {
            label: t('accred.specific.group'),
            align: "center" as const,
            sortable: true,
            sortField: 'id_accred_group',
          },
          {
            label: t('accred.specific.name'),
            align: "center" as const,
            sortable: true,
            sortField: 'name',
          },
          {
            label: t('actions'),
            align: "center" as const,
          },
        ]}
        columns={[
          {
            render: (row: AccredSpecific) => row.accredGroup.name ?? '-',
          },
          {
            render: (row: AccredSpecific) => row.name ?? '-',
          },
          {
            render: (row: AccredSpecific) => (
              <Stack direction='row' spacing={2} justifyContent='center'>
                <UIActionButton icon='edit' onClick={() => modalRef.current?.open(row)} />
                <UIActionButton icon='delete' onClick={() => handleDelete(row.id)} />
              </Stack>
            ),
            nowrap: true,
          },
        ]}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <AddAccredSpecificModal ref={modalRef} />
      <WarningAccredSpecificModal ref={modalWarningRef} />
    </Box>
  );
};

export default SpecificList;

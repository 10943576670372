import { Box } from '@mui/material';
import React from 'react';
import Layout from 'shared/layout';
//import SingleRoom from './ui/single-room';
import UITabs from 'shared/ui/ui-tabs';
import ProtocolList from './list';

import { useTranslation } from 'react-i18next';
import TestProgramList from '../test-program';

const Protocol: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <UITabs
          tabs={[
            {
              component: <ProtocolList />,
              label: t('protocol.common.protocolTitle'),
              href: 'list',
            },
            {
              component: <TestProgramList />,
              label: t('protocol.common.testProgTitle'),
              href: 'test-prog',
            },
            {
              component: <Registry />,
              label: t('protocol.common.registryTitle'),
              href: 'registry',
            },
          ]}
          name='room'
        />
      </Box>
    </Layout>
  );
};



const TestProgram: React.FC = () => {
  return (
      <div>
          <h1>TestProgram</h1>
          {/* Add your content here */}
      </div>
  );
};

const Registry: React.FC = () => {
  return (
      <div>
          <h1>Registry</h1>
          {/* Add your content here */}
      </div>
  );
};

export default Protocol;

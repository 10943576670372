import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthState, SystemUser } from './types';
import eimzo from 'app/vendors/eimzo';

const initialState: AuthState = {
  uid: '',
  authorized: !!localStorage.getItem('token'),
  serialNumber: '',
  certs: [],
  token: localStorage.getItem('token') ?? '',
};

export const getUserCertificates = createAsyncThunk('getUserCertificates', async () => {
  const certs = await eimzo.getAllCertificates();

  return certs;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, payload: PayloadAction<{ user: SystemUser; token: string }>) => {
      const { token, user } = payload.payload;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      state.authorized = true;
      state.userInfo = user;
      state.token = token;
      state.uid = user.uid || user.pinfl;
    },
    setUid: (state, payload: PayloadAction<string>) => {
      state.uid = payload.payload;
    },
    setAuthorized: (state, payload: PayloadAction<boolean>) => {
      state.authorized = payload.payload;
    },
    setToken: (state, payload: PayloadAction<string>) => {
      state.token = payload.payload;
      localStorage.setItem('token', payload.payload);
    },
    signOut: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.token = '';
      state.authorized = false;
    },
    setCheckingAuth: (state, payload: PayloadAction<boolean>) => {
      state.checkingAuth = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCertificates.fulfilled, (state, action) => {
      state.certs = action.payload;
    });
  },
});

export const { signOut, setAuthorized, setUid, setCheckingAuth, signIn, setToken } =
  authSlice.actions;

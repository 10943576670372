import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import { ResponseWithPagination } from 'app/utils/types';
import { Calibration } from '../model';

export const calibrationApi = createApi({
  reducerPath: 'calibrationApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getCalibrationMonths: builder.query<Calibration, {}>({
      query: () => ({
        method: 'GET',
        url: '/calibration-months',
       
      }),
    }),
  }),
});

export const { useGetCalibrationMonthsQuery, useLazyGetCalibrationMonthsQuery } =
  calibrationApi;

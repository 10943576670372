import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import ModalHeader from 'shared/ui/modal-header';
import { TestProgramSize } from 'entities/test-program/model';
import { useCreateTestProgramSizeMutation, useUpdateTestProgramSizeMutation } from 'entities/test-program/api';
import notify from 'app/utils/notify';

export interface TestProgramSizeModalRef {
  open: (data?: TestProgramSize, idTestProgram?: number, idTestProgramResults?: number) => void;
  close: () => void;
}

interface FormValues {
  id?: number;
  tech_param_plan: string;
  value_from?: number | null;
  value_to?: number | null;
}

const initialState: FormValues = {
  tech_param_plan: '',
  value_from: null,
  value_to: null,
};

export const SizeModal = forwardRef<TestProgramSizeModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [idTestProgram, setIdTestProgram] = useState<number | undefined>();
  const [idTestProgramResults, setIdTestProgramResults] = useState<number | undefined>();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: initialState,
  });

  const [createTestProgramSize, { isLoading: isLoadingCreate }] = useCreateTestProgramSizeMutation();
  const [updateTestProgramSize, { isLoading: isLoadingUpdate }] = useUpdateTestProgramSizeMutation();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = (data?: TestProgramSize, idTestProgram?: number, idTestProgramResults?: number) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        tech_param_plan: data.tech_param_plan,
        value_from: data.value_from,
        value_to: data.value_to,
      });
    } else {
      reset(initialState);
    }
    setIdTestProgram(idTestProgram);
    setIdTestProgramResults(idTestProgramResults);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    if (!idTestProgram || !idTestProgramResults) {
      notify(t('error.missingIds'), 'error');
      return;
    }

    try {
      const body = {
        ...data,
        id_test_program: idTestProgram,
        id_test_program_results: idTestProgramResults,
      };

      if (isEdit && data.id) {
        await updateTestProgramSize({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        await createTestProgramSize(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      handleClose();
    } catch (error) {
      notify(isEdit ? t('editError') : t('addError'), 'error');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormTextInput
                control={control}
                name='tech_param_plan'
                label={t('testProgram.size.tech_param_plan')}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormNumericInput
                control={control}
                name='value_from'
                label={t('testProgram.size.value_from')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormNumericInput
                control={control}
                name='value_to'
                label={t('testProgram.size.value_to')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
          >
            {t('save')}
          </Button>
        
      </DialogActions>
    </Dialog>
  );
});

export default SizeModal;
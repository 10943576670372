import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VerificationModalRef } from '../types';
import { Equipment, EquipmentVerification, Scales } from 'entities/equipment/model';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetEnvironmentConditionsQuery,
  useLazyGetEnvironmentConditionsQuery,
} from 'entities/dicts/api';
import { Colors } from 'app/constants';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { useGetStaffQuery, useLazyGetStaffQuery } from 'entities/staff/api';
import { Staff } from 'entities/staff/model';
import UISelect from 'shared/ui/ui-select';
import UIActionButton from 'shared/ui/ui-action-button';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { handleResponse } from 'app/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import eimzo from 'app/vendors/eimzo';


import GeneralStep from './steps/general';
import ScalePointStep from './steps/scale-point';


import ModalHeader from 'shared/ui/modal-header';

import { UseFormHandleSubmit } from 'react-hook-form';
import { FormValues, initialState } from './initialState';
import { useAppSelector } from 'app/hooks/redux';
import { CreateEquiment } from 'entities/equipment/model';
import {
  useCreateEquipmentVerificationMutation,
  useGetEquipmentIdQuery,
  useSignEquipmentVerificationByIdMutation,
  useUpdateEquipmentVerificationMutation,
} from 'entities/equipment/api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
//import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Laboratory } from 'entities/laboratories/model';
import notify from 'app/utils/notify';



export const VerificationModal = forwardRef<VerificationModalRef>((_, ref) => {
  const { t } = useTranslation();
  //const { viewId } = useParams();
  //const { data: equipmentData } = useGetEquipmentIdQuery(viewId ?? '');
  //console.log(equipmentData);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { control, handleSubmit, reset, setValue, watch, formState, trigger } = methods;
  const [createEquipmentVerification] = useCreateEquipmentVerificationMutation();
  const [updateEquipmentVerification] = useUpdateEquipmentVerificationMutation();
  const [signEquipmentVerification] = useSignEquipmentVerificationByIdMutation();

  const steps = [t('generalInfo'), t('eqVerification.scalePoint')];
  const [activeStep, setActiveStep] = React.useState(0);

  const [equipment, setEquipment] = useState<Equipment>();

  //const { data: laboratories } = useGetLaboratoriesQuery({});

  const {
    fields: scalePointFields,
    append: scalePointAppend,
    remove: scalePointRemove,
    insert: scalePointInsert,
  } = useFieldArray({
    control,
    name: 'scalePoints',
  });
  /*const {
    fields: techFields,
    append: techAppend,
    remove: techRemove,
  } = useFieldArray({
    control,
    name: 'equipmentServiceWorks',
  });
  const {
    fields: envFields,
    append: envAppend,
    remove: envRemove,
  } = useFieldArray({
    control,
    name: 'equipmentEnvironmentConditions',
  });
  const {
    fields: staffFields,
    append: staffAppend,
    remove: staffRemove,
    insert: staffInsert,
  } = useFieldArray({
    control,
    name: 'equipmentStaffs',
  });*/

  /*const laboratory = watch('laboratory');

  useEffect(() => {
    envRemove();
  }
  , [laboratory]);*/

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const verifyResults = [
    {
      label: t('valid'),
      value: 1,
    },
    {
      label: t('inValid'),
      value: 2,
    }
  ];

  const confirmationMethods = [
    {
      label: t('confirmationMethod.calibration'),
      value: 1,
    },
    {
      label: t('confirmationMethod.attestation'),
      value: 2,
    },
    {
      label: t('confirmationMethod.notMeaInstrument'),
      value: 3,
    }
  ];

  const handleOpen = async (equipment: Equipment, data?: EquipmentVerification | undefined) => {
    if (equipment) {
      setEquipment(equipment);
      console.log(equipment);
    
      if (data) {
        setIsEdit(true);

        //const findLaboratory = laboratories?.find((item) => item.id === data.id_laboratory);
        
        const verifyResult = verifyResults.find((item)=>item.value==data.verify_result);
        const confirmationMethod = confirmationMethods.find((item)=>item.value==data.confirmation_method);
        
        reset({
          id:data.id,
          id_equipment: equipment.id,
          verify_date: data.verify_date,
          verify_nn: data.verify_nn,
          verify_result: verifyResult ? verifyResult : {label:'', value:''},
          exp_date: data.exp_date,
          scan: data.scan_url,
          confirmation_method: confirmationMethod ? confirmationMethod : {label:'', value:''},
          note: data.note,
          scalePoints: data.scalePoints.length > 0 ? 
            data.scalePoints.map((item) => ({
              //id: item.id,
              id_scale: item.scale,
              point: item.point,
              expanded_uncertainty: item.expanded_uncertainty,
              deviation: item.deviation,
            })) : [
              {
                id_scale: null,
                point: null,
                expanded_uncertainty: null,
                deviation: null,
              }
            ],
        });
      }else{
        reset({...initialState, id_equipment: equipment.id,});
      }
      setActiveStep(0);
      setOpen(true);
    };
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    //console.log(data.id_laboratory);
    console.log(data);

    //console.log(data.equipmentEnvironmentConditions.filter((item) => item.checked && item.checked!==undefined));

    const scalePoints = data.scalePoints.map((item) => ({
      //id: item.id,
      id_scale: item.id_scale?.id,
      point: item.point,
      expanded_uncertainty: item.expanded_uncertainty,
      deviation: item.deviation,
    })).filter((item) => 
      item.id_scale && item.deviation && item.expanded_uncertainty && item.point) as { id_scale: number; point: number; expanded_uncertainty: number; deviation: number; }[];
    

    let body = {
      //...data,
      //id_tenant: Number(data.id_tenant),
      //type_system: Number(data.type_system),
      id_equipment: data.id_equipment,
      verify_date: moment(data.verify_date).format('YYYY-MM-DD'),
      verify_nn: data.verify_nn,
      verify_result: Number(data.verify_result.value),
      exp_date: moment(data.exp_date).format('YYYY-MM-DD'),
      scan: data.scan,
      confirmation_method: Number(data.confirmation_method.value),
      note: data.note,
      scalePoints: (scalePoints && scalePoints.length > 0) ? scalePoints : []
    };
    console.log(body);
    //console.log(data.id);
    try {
      setIsLoading(true);
      /*if (isEdit && data.id) {
        await handleResponse(await updateEquipmentVerification({ ...body, id: data.id }));
      } else {
        await handleResponse(await createEquipmentVerification(body));
      }*/
      const res = await handleResponse(await createEquipmentVerification(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json.replace(/\\\//g, "")));
      await handleResponse(
        await signEquipmentVerification({ id: res.id, id_equipment: equipment?.id as number, signature, note: data.note })
      );
      
    } finally {
      handleClose();
      notify('', 'success');
      setIsLoading(false);
    }
    /*try {
      setIsLoading(true);
      if (idEquip) { 
        const updateBody = {
          ...body,
          id: Number(idEquip)
        }
        await handleResponse(await updateEquipment(updateBody));
      } else {
        await handleResponse(await createEquipment(body));
      }
    } finally {
      setIsLoading(false);
      //handleClose();
    }*/
  };
    

  
  const handleNext = () => {
    trigger().then((isValid) => {
      if (isValid) {
        setActiveStep(activeStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <GeneralStep 
          control={control} 
        />;
      case 1:
        return <ScalePointStep
          fields={scalePointFields}
          remove={scalePointRemove}
          append={scalePointAppend}
          insert={scalePointInsert}
          equipment={equipment as Equipment} 
          control={control}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader 
        title={isEdit ? t('edit_equipment') : t('add_equipment')} 
        handleClose={handleClose} 
      />
      
      <DialogContent >
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
        
      <DialogContent style={{height:'400px'}} className='scrollbar'>
        <Stack spacing={1} mt={1}>
          <FormProvider {...methods} >
            <React.Fragment>
              {getStepContent(activeStep)}            
            </React.Fragment>
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
              {t('previous_step')}
            </Button>
          )}
          {activeStep === steps.length-1 ? (
            <Button
              variant="contained"
              autoFocus 
              onClick={handleSubmit(onSubmit)} 
              disabled={isLoading}
              sx={{ mt: 3, ml: 1 }}
            >
              {t('save')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {t('next_step')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
});

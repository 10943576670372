import React, { useEffect } from 'react';
import 'normalize.css';
import 'app/assets/styles/main.sass';
import { I18nextProvider } from 'react-i18next';
import i18n from 'app/i18n';
import { ReduxProvider } from 'app/providers/redux-provider';
import { Routing } from 'pages';
import { Toaster } from 'shared/toaster';
import { store } from 'app/store';
import authConfig from 'app/configs/authConfig';
import { appConfig } from 'app/configs/app.config';

appConfig();

const App: React.FC = () => {
  useEffect(() => {
    authConfig(store);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider>
        <Routing />
        <Toaster />
      </ReduxProvider>
    </I18nextProvider>
  );
};

export default App;

import { baseApi } from '../base-api';
import { TestProgramPrimaryPoint } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramPrimaryPointBody } from '../../model/request-body';

const testProgramPrimaryPointApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Primary Point
    getTestProgramPrimaryPoints: builder.query<
      ResponseWithPagination<TestProgramPrimaryPoint>,
      RequestWithPagination<{ id_test_program: number; id_test_program_primary: number }>
    >({
      query: ({ id_test_program, id_test_program_primary, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/primaries/${id_test_program_primary}/points`,
        params,
      }),
      providesTags: ['TestProgramPrimaryPoint'],
    }),
    createTestProgramPrimaryPoint: builder.mutation<
      DefaultResponse,
      TestProgramPrimaryPointBody & { id_test_program: number }
    >({
      query: ({ id_test_program, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/primaries/${body.id_test_program_primary}/points`,
        body,
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),
    updateTestProgramPrimaryPoint: builder.mutation<DefaultResponse, TestProgramPrimaryPointBody & { id: number; id_test_program: number }>({
      query: ({ id, id_test_program, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${id_test_program}/primaries/${body.id_test_program_primary}/points/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),
    deleteTestProgramPrimaryPoint: builder.mutation({
      query: ({id, id_test_program, id_test_program_primary}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/primaries/${id_test_program_primary}/points/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramPrimaryPoint'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramPrimaryPointsQuery,
  useCreateTestProgramPrimaryPointMutation,
  useUpdateTestProgramPrimaryPointMutation,
  useDeleteTestProgramPrimaryPointMutation,
} = testProgramPrimaryPointApi;
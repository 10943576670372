import { Stack, Typography } from '@mui/material';
import React from 'react';

export interface InfoGroupItem {
  icon?: React.ReactNode;
  text: string | React.ReactNode;
  visible?: boolean;
}

export interface InfoGroupProps {
  title?: string | React.ReactNode;
  items?: InfoGroupItem[];
}

export const InfoGroup: React.FC<InfoGroupProps> = ({ title, items }) => {
  return (
    <Stack justifyContent='center'>
      {title && (<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{title}</Typography>)}
      {items?.filter((item) => item.visible !== false).map((item, index) => (
        <Stack direction="row" alignItems="center" gap={1} key={index}>
          {item.icon}
          {(typeof item.text==="string") ? (<Typography variant="body1" sx={{ fontSize: 16 }}>{item.text}</Typography>):item.text}
        </Stack>
      ))}
    </Stack>
  );
}
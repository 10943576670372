import { AccredGroup, AccredSpecific, AccredStandart } from "entities/accreditation/model";
import { HSCode, Units } from "entities/dicts/model";
import { Equipment, Scales } from "entities/equipment/model";
import { MaterialModel } from "entities/material/model";


export interface TestProgram extends BaseTestProgram {
  accredGroup: AccredGroup;
  testProgramConnects: TestProgramConnect[];
}

export interface BaseTestProgram {
  id: number;
  id_tenant: number;
  id_accred_group?: number;
  name: string;
  date_begin: string;
  date_end?: string;
  status: number;
  doc_url?: string;
  use_method: number;
  certification_compliance?: string;
}

export interface TestProgramAltPoint {
  id: number;
  id_test_program_primary_point: number;
  name: string;
}

export interface TestProgramConnect {
  id: number;
  id_test_program_cert: number;
  id_test_program_lab: number;
  is_connected?: number;
  testProgramCert: { certBodyName: string } & BaseTestProgram;
  testProgramLab: { laboratoryName: string } & BaseTestProgram;
}

export interface TestProgramDuration {
  id: number;
  id_test_program_results: number;
  sample_quantity: number;
  duration: number;
}

export interface TestProgramEquipment {
  id: number;
  id_test_program: number;
  id_equipment: number;
  equipmentData: Equipment;
}

export interface BaseTestProgramFeature {
  id: number;
  id_test_program: number;
  name: string;
  order_nn?: number;
}

export interface BaseTestProgramFeatureList {
  id: number;
  id_test_program_feature: number;
  name: string;
}

export interface TestProgramFeature extends BaseTestProgramFeature {
  lists: BaseTestProgramFeatureList[];
}

export interface TestProgramFeatureList extends BaseTestProgramFeatureList {
  feature: TestProgramFeature;
}

export interface TestProgramHs {
  id: number;
  id_test_program: number;
  id_hs_code: number;
  hsCode: HSCode;
}

export interface TestProgramMaterialOut {
  id: number;
  id_test_program_results: number;
  id_material_model: number;
  quantity: number;
  materialModel: MaterialModel;
}

export interface TestProgramPrimary {
  id: number;
  name: string;
  id_test_program: number;
  unit?: string;
  variable?: string;
  variable_type: number;
  id_equipment?: number;
  id_scale?: number;
  photo1_url?: string;
  photo2_url?: string;
  photo3_url?: string;
  photo4_url?: string;
  id_accred_standart_primary?: number;
  scale: Scales;
  equipmentData: Equipment;
}

export interface TestProgramPrimaryPoint {
  id: number;
  id_test_program_primary: number;
  name: string;
}

export interface TestProgramResults {
  id: number;
  id_test_program: number;
  id_accred_specific?: number;
  id_accred_standart?: number;
  id_unit?: number;
  standart_tech_param: string;
  formula?: string;
  id_test_program_child?: number;
  specific_name?: string;
  standart_name?: string;
  confidence_probability?: number;
  accuracy?: number;
  decision_rule?: number;
  binarity?: number;
  order_nn?: number;
  parent_method?: number;
  accredSpecific: AccredSpecific;
  unit: Units;
  accredStandartData: AccredStandart;
}

export interface TestProgramResultPrimary {
  id_test_program_results: number;
  id_test_program_primary: number;
}

export interface TestProgramSize {
  id: number;
  id_test_program_results: number;
  tech_param_plan: string;
  id_test_program_variant?: number | null;
  value_from?: number | null;
  value_to?: number | null;
  features?: string;
}

export interface TestProgramSizeFeatureList {
  id_test_program_size: number;
  id_test_program_feature_list: number;
}

export interface TestProgramVariant {
  id: number;
  id_test_program: number;
  name: string;
}

export interface StorageCondition {
  id: number;
  id_test_program: number;
  name: string | null;
}

export const binarityArray = [
  { id: 1, name: 'testProgram.result.binary' },
  { id: 2, name: 'testProgram.result.nonbinary' },
];

export const decisionArray = [
  { id: 1, name: 'testProgram.result.rule1' },
  { id: 2, name: 'testProgram.result.rule2' },
  { id: 3, name: 'testProgram.result.rule3' },
  { id: 4, name: 'testProgram.result.rule4' },
  { id: 5, name: 'testProgram.result.rule5' },
];
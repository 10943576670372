import { EnvironmentCondition, Units } from "entities/dicts/model";
import { Laboratory } from "entities/laboratories/model";

export interface ProtocolImport {
  id: number;
  id_protocol: number;
  invoice_nn: string | null;
  invoice_date: string | null;
  ttn_nn: string | null;
  ttn_date: string | null;
}

export interface ProtocolAttendedTest {
  id: number;
  id_protocol: number;
  name: string;
  position?: string | null;
  workplace?: string | null;
  phone?: string | null;
}

export interface ProtocolChange {
  id: number;
  id_protocol: number;
  change: string;
}

export interface ProtocolComplaints {
  id: number;
  id_protocol: number;
  id_protocol_results: number;
  complaint_date: string;
  complaint_result?: string | null;
  close_date?: string | null;
  doc_url?: string | null;
  agree?: number | null;
  complaint_content?: string | null;
  doc_json?: string | null;
  date_sign?: string | null;
  note_sign?: string | null;
  doc_pkcs7?: string | null;
}

export interface ProtocolEquipment {
  id: number;
  id_protocol: number;
  id_equipment: number;
  name?: string | null;
  factory_number?: string | null;
  inv_number?: string | null;
  exp_date?: string | null;
  verify_nn?: string | null;
}

export interface ProtocolMaterialOut {
  id: number;
  id_protocol_objects: number;
  id_material_model: number;
  quantity: number;
}

export interface ProtocolObjects {
  id: number;
  id_protocol: number;
  object_name: string;
  id_accred_group: number;
  manufacturing_date?: string | null;
  quantity: number;
  sample_count: number;
  sample_test_count: number;
  unit?: string | null;
  id_test_program?: number | null;
  note_primary?: string | null;
  note_result?: string | null;
  manufactor?: string | null;
  id_country?: number | null;
  lot_nn?: string | null;
  lot_quantity?: number | null;
  manuf_address?: string | null;
  manuf_phone?: string | null;
  manuf_site?: string | null;
  manuf_mail?: string | null;
  manuf_logo_url?: string | null;
  warranty_period?: string | null;
  warranty_expl?: string | null;
  shelf_life?: string | null;
  certified?: number | null;
  iso?: number | null;
  id_act_select_production?: number | null;
  id_test_program_variant?: number | null;
  status_primary_xls: number;
  number_of_calculations: number;
  justification?: string | null;
  test_program_note?: string | null;
}

export interface ProtocolObjectsMsign {
  id: number;
  id_protocol_objects: number;
  id_manipulation_signs: number;
}

export interface ProtocolObjectFeatureList {
  id_protocol_objects: number;
  id_test_program_feature_list: number;
}

export interface ProtocolObjectMethod {
  id: number;
  id_protocol_objects: number;
  id_test_program_results: number;
  group_result_type: number;
  sample_count: number;
  sample_test_count: number;
}

export interface ProtocolObjectMethodPhoto {
  id: number;
  id_protocol_object_method: number;
  photo_url: string;
  comment?: string | null;
}

export interface ProtocolObjectPrimary {
  id: number;
  id_protocol_objects: number;
  id_test_program_primary: number;
  value?: string | null;
  sample_nn: number;
  id_protocol_results_parent?: number | null;
  id_staff?: number | null;
  test_nn?: number | null;
  measure_nn: number;
}

export interface ProtocolObjectPrimaryPoint {
  id: number;
  id_protocol_object_primary: number;
  id_test_program_primary_point: number;
  satisfy: number;
  id_test_program_alt_point?: number | null;
}

export interface ProtocolObjectStandartSample {
  id: number;
  id_protocol_object: number;
  id_standart_sample_element: number;
  measured_content_1?: number | null;
  measured_content_2?: number | null;
  diff_res_parallel?: number | null;
  comparison_cto?: number | null;
  satisfy?: number | null;
}

export interface ProtocolOpinion {
  id: number;
  id_protocol: number;
  id_protocol_objects: number;
  opinion: string;
}

export interface ProtocolPhoto {
  id: number;
  id_protocol: number;
  type: number;
  photo_url: string;
  comment?: string | null;
}

export interface ProtocolResults {
  id: number;
  id_protocol_objects: number;
  id_accred_specific: number;
  id_accred_standart: number;
  id_test_program_results?: number | null;
  id_unit?: number | null;
  standart_tech_param: string;
  tech_param_plan?: string | null;
  tech_param_fact?: string | null;
  satisfy?: number | null;
  satisfy_accuracy?: number | null;
  sample_nn?: number | null;
  id_protocol_results_parent?: number | null;
  id_staff?: number | null;
  test_nn?: number | null;
  uncertainty_json?: string | null;
  notsatisfy_reason?: string | null;
}

export interface ProtocolReview {
  id: number;
  id_protocol: number;
  rating?: number | null;
  review?: string | null;
}

export interface ProtocolTesters {
  id: number;
  id_protocol: number;
  id_staff: number;
  signed: number;
  staff_role?: number | null;
  doc_json?: string | null;
  date_sign?: string | null;
  note_sign?: string | null;
  doc_pkcs7?: string | null;
  staff_fio?: string | null;
  staff_position?: string | null;
  staff_phone?: string | null;
}

export interface Protocol {
  id: number;
  id_tenant: number;
  id_request?: number | null;
  protocol_nn: number;
  id_test_type: number;
  test_type_name?: string | null;
  id_confirm_staff?: number | null;
  confirm_staff_fio?: string | null;
  confirm_staff_position?: string | null;
  confirm_date: string;
  begin_date: string;
  client: {
    id: number;
    name: string;
    address: string;
    phone: string;
    inn: string;
    contact_fio: string;
  } | null;
  id_client?: number | null;
  id_client_phone?: number | null;
  client_name?: string | null;
  client_address?: string | null;
  client_phone?: string | null;
  id_contract?: number | null;
  contract_nn?: string | null;
  contract_date?: string | null;
  place_of_test?: string | null;
  act_select_nn?: string | null;
  act_select_date?: string | null;
  id_act_select_org?: number | null;
  act_select_org_name?: string | null;
  act_select_url?: string | null;
  test_init_nn?: string | null;
  test_init_date?: string | null;
  id_test_init_org?: number | null;
  test_init_org_name?: string | null;
  test_init_url?: string | null;
  id_subcontractor_contracts?: number | null;
  subcontractor_contracts_name?: string | null;
  subc_standart?: string | null;
  subc_standart_nn?: string | null;
  subc_protocol_nn?: string | null;
  subc_protocol_date?: string | null;
  result_relates_to: string;
  access_code?: string | null;
  test_prog_url?: string | null;
  return_destroy?: number | null;
  id_ret_dest_staff?: number | null;
  ret_dest_staff_fio?: string | null;
  ret_dest_staff_position?: string | null;
  return_destroy_ok?: number | null;
  date_get_sample?: string | null;
  rating?: number | null;
  review?: string | null;
  primary_url?: string | null;
  error_inpdf: number;
  quality_cert: number;
  trip_sheet_url?: string | null;
  is_verification: number;
  doc_json?: string | null;
  id_staff_cancel?: number | null;
  staff_cancel_fio?: string | null;
  staff_cancel_position?: string | null;
  date_cancel?: string | null;
  note_cancel?: string | null;
  doc_pkcs7_cancel?: string | null;
  id_protocol_previous?: number | null;
  status?: number | null;
  pdf_url?: string | null;
  primary_pdf_url?: string | null;
  writeoff_pdf_url?: string | null;

  product: string;
  testProg: string;

  protocolImport: ProtocolImport[];
  protocolAttendedTest: ProtocolAttendedTest[];
  protocolChange: ProtocolChange[];
  //protocolComplaints: ProtocolComplaints[];
  protocolEquipment: ProtocolEquipment[];
  protocolMaterialOut: ProtocolMaterialOut[];
  //protocolObjects: ProtocolObjects[];
  //protocolObjectsMsign: ProtocolObjectsMsign[];
  //protocolObjectFeatureList: ProtocolObjectFeatureList[];
  //protocolObjectMethod: ProtocolObjectMethod[];
  //protocolObjectMethodPhoto: ProtocolObjectMethodPhoto[];
  //protocolObjectPrimary: ProtocolObjectPrimary[];
  //protocolObjectPrimaryPoint: ProtocolObjectPrimaryPoint[];
  //protocolObjectStandartSample: ProtocolObjectStandartSample[];
  protocolOpinion: ProtocolOpinion[];
  protocolPhoto: ProtocolPhoto[];
  protocolTesters: ProtocolTesters[];

  anySign?: boolean;
}

export const mockProtocol: Protocol = {
    id: 1,
    id_tenant: 1,
    id_request: 1,
    protocol_nn: 2024031501,
    id_test_type: 1,
    test_type_name: 'сертификационные',
    id_confirm_staff: 1,
    confirm_staff_fio: 'Иванов Иван Иванович',
    confirm_staff_position: 'Директор',
    confirm_date: '2024-03-15',
    begin_date: '2024-03-15',
    client: {
      id: 1,
      name: 'ИП ООО «ALUKABEL PAYRAV»',
      address: 'Ташкентская область, г. Янгиюль, улица Чарикова,18',
      phone: '998946974000',
      inn: '1234567890',
      contact_fio: 'Петров Петр Петрович',
    },
    id_client: 1,
    id_client_phone: 1,
    client_name: 'ИП ООО «ALUKABEL PAYRAV»',
    client_address: 'Ташкентская область, г. Янгиюль, улица Чарикова,18',
    client_phone: '998946974000',
    id_contract: 1,
    contract_nn: '35 (111-139)',
    contract_date: '2024-03-15',
    place_of_test: 'ООО "BELCOR"',
    act_select_nn: '2024031501',
    act_select_date: '2024-03-15',
    id_act_select_org: 1,
    act_select_org_name: 'ООО "Рога и копыта"',
    act_select_url: 'https://www.google.com/',
    test_init_nn: '2024031501',
    test_init_date: '2024-03-15',
    id_test_init_org: 1,
    test_init_org_name: 'ООО "Рога и копыта"',
    test_init_url: 'https://www.google.com/',
    id_subcontractor_contracts: null,
    subcontractor_contracts_name: null,
    subc_standart: null,
    subc_standart_nn: null,
    subc_protocol_nn: null,
    subc_protocol_date: null,
    result_relates_to: 'на предоставленные образцы',
    access_code: null,
    test_prog_url: 'https://www.google.com/',
    return_destroy: 1,
    id_ret_dest_staff: 1,
    ret_dest_staff_fio: 'Петров Петр Петрович',
    ret_dest_staff_position: 'Директор',
    return_destroy_ok: 1,
    date_get_sample: '2024-03-15',
    rating: 5,
    review: 'Отлично',
    primary_url: 'https://www.google.com/',
    error_inpdf: 0,
    quality_cert: 1,
    trip_sheet_url: 'https://www.google.com/',
    is_verification: 1,
    doc_json: 'https://www.google.com/',
    id_staff_cancel: null,
    staff_cancel_fio: null,
    staff_cancel_position: null,
    date_cancel: null,
    note_cancel: null,
    id_protocol_previous: null,
    status: 1,
    pdf_url: 'https://www.google.com/',
    primary_pdf_url: 'https://www.google.com/',
    writeoff_pdf_url: 'https://www.google.com/',
    product: 'Товар',
    testProg: 'Программа испытаний',
    protocolImport: [],
    protocolAttendedTest: [],
    protocolChange: [],
    protocolEquipment: [],
    protocolMaterialOut: [],
    protocolOpinion: [],
    protocolPhoto: [],
    protocolTesters: [],
    anySign: false,
  };

export const mockProtocols: Protocol[] = [
  mockProtocol, mockProtocol, mockProtocol, mockProtocol, mockProtocol
];


export interface ProtocolEnvironment {
  id_laboratory: number;
  laboratory: Laboratory;
  data: {
    environmentCondition: EnvironmentCondition;
    unit: Units;
    min: number | null;
    max: number | null;
  }[]
}

export const mockProtocolEnvironment: ProtocolEnvironment = {
  id_laboratory: 1,
  laboratory: {
    id: 1,
    id_tenant: 1,
    name: '№0-1 (MET)',
    laboratoryConditionStaff: [{
      id: 1,
      id_laboratory: 1,
      id_staff: 1,
      time: '10:10'
    }],
    environmentConditions: [{
      id: 1,
      id_tenant: 1,
      id_unit: 1,
      name: 'Температура воздуха'
    }]
  },
  data: [{
    environmentCondition: {
      id: 1,
      id_tenant: 1,
      name: 'Температура воздуха',
      id_unit: 1
    },
    unit: {
      id: 1,
      name: '°C',
      name_en: '°C'
    },
    min: 20,
    max: 25
  },
  {
    environmentCondition: {
      id: 2,
      id_tenant: 1,
      name: 'Влажность воздуха',
      id_unit: 2
    },
    unit: {
      id: 2,
      name: '%',
      name_en: '%'
    },
    min: 40,
    max: 60
  }]
};

export const mockProtocolsEnvironment: ProtocolEnvironment[] = [
  mockProtocolEnvironment, mockProtocolEnvironment, mockProtocolEnvironment, mockProtocolEnvironment, mockProtocolEnvironment
];

export interface ProtocolObject {
  id: number;
  name: string;
  group: string;
  date: string;
  quantity: number;
  unit: string;
  testProg: string;
}

export const mockProtocolObject: ProtocolObject = {
  id: 1,
  name: 'Товар',
  group: 'Группа',
  date: '2024-03-15',
  quantity: 1,
  unit: 'шт',
  testProg: 'Программа испытаний'
};

export const mockProtocolObject1: ProtocolObject = {
  id: 2,
  name: 'Провода для электрических установок на номинальное напряжение до 450/750V марки ПВС 4х2,5	',
  group: 'Кабели и провода',
  date: '2024-02-10',
  quantity: 15,
  unit: 'm',
  testProg: 'ГОСТ 7399-97 - Провода и шнуры на номинальное напряжение до 450/750 В (с ППР)'
};

export const mockProtocolObject2: ProtocolObject = {
  id: 3,
  name: 'Провода для электрических установок на номинальное напряжение до 450/750V марки ПУГВ 4',
  group: 'Кабели и провода',
  date: '2024-02-10',
  quantity: 15,
  unit: 'm',
  testProg: 'ГОСТ 31947-2012 - Провода и кабели для электрических установок на номинальное напряжение до 450/750 В включительно (с ППР)'
};

export const mockProtocolObjects: ProtocolObject[] = [
  mockProtocolObject, mockProtocolObject1, mockProtocolObject2
];
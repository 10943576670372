import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { UISelectProps } from 'shared/ui/ui-select/types';
import { BaseControllerInputProps } from 'shared/inputs/types';

type Props<
  T extends FieldValues,
  V,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = BaseControllerInputProps<T, UISelectProps<V, Multiple, DisableClearable, FreeSolo>>;

const FormSelectInput = <
  T extends FieldValues,
  V,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  control,
  name,
  rules,
  TextFieldProps,
  onChange,
  ...rest
}: Props<T, V, Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      rules={{
        ...rules,
        required: rules?.required === true ? t('errors:required_field') : rules?.required,
      }}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...rest}
            {...field}
            //@ts-ignore
            value={field.value || null}
            onChange={(event, option) => {
              //@ts-ignore
              field.onChange(option);
              //@ts-ignore
              onChange?.(event, option);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...TextFieldProps}
                error={fieldState.invalid}
                helperText={fieldState?.error?.message}
                variant={TextFieldProps?.variant ?? "outlined"}
              />
            )}
            sx={{
              '& .MuiAutocomplete-popupIndicator': {
                m: 'auto',
              },
            }}
          />
        );
      }}
    />
  );
};

export default FormSelectInput;

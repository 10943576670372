import { startApi } from 'app/vendors/eimzo';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

export const appConfig = () => {
  startApi();
  dayjs.extend(advancedFormat);
  if (process.env.NODE_ENV === 'development') {
    window.dayjs = dayjs;
  }
};

import React from 'react';
import { Stepper, Step, StepLabel, Paper, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export enum CertificationStep {
  CERT_REQUEST = 'certRequest',
  DECISION = 'decision',
  INSPECTION = 'inspection',
  SAMPLING = 'sampling',
  ANALYSIS = 'analysis',
  CERT_DECISION = 'certDecision',
  CERTIFICATE = 'certificate',
}

const stepOrder: CertificationStep[] = [
  CertificationStep.CERT_REQUEST,
  CertificationStep.DECISION,
  CertificationStep.INSPECTION,
  CertificationStep.SAMPLING,
  CertificationStep.ANALYSIS,
  CertificationStep.CERT_DECISION,
  CertificationStep.CERTIFICATE,
];

interface CertificationStepperProps {
  activeStep: CertificationStep;
}

const CertificationStepper: React.FC<CertificationStepperProps> = ({ activeStep }) => {
  const { t } = useTranslation('certification');

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={stepOrder.indexOf(activeStep)} alternativeLabel>
          {stepOrder.map((step) => (
            <Step key={step} completed={stepOrder.indexOf(step) <= stepOrder.indexOf(activeStep)}>
              <StepLabel>{t(`${step}.title`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Paper>
  );
};

export default CertificationStepper;
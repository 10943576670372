export interface FormValues {
  id?: number;
  value_from: number | null;
  value_to: number | null;
  inlab_stdr_deviation: number | null;
  interlab_stdr_deviation: number | null;
  repeat_limit: number | null;
  reproduce_limit: number | null;
}

export const initialState = {
  value_from: null,
  value_to: null,
  inlab_stdr_deviation: null,
  interlab_stdr_deviation: null,
  repeat_limit: null,
  reproduce_limit: null,

}
import React, { useRef } from 'react';
import { IconButton, Link, Stack } from '@mui/material';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import UIActionButton from 'shared/ui/ui-action-button';
import { AddCircle } from '@mui/icons-material';
import UITable from 'shared/ui/ui-table';
import { useFilters } from 'app/hooks/use-filters';
import { DeleteModalRef } from 'features/delete-modal/types';
import { DeleteModal } from 'features/delete-modal';
import { TestProgram, TestProgramFeature, TestProgramResults } from 'entities/test-program/model';
import { useDeleteTestProgramFeatureMutation, useGetTestProgramFeaturesQuery, useGetTestProgramResultsQuery } from 'entities/test-program/api';
import { ResultView } from './primary';
import { useNavigate } from 'react-router-dom';
import { TestProgramResultModal, TestProgramResultModalRef } from './modal';
import { bgColorRule } from 'shared/ui/ui-table/types';
import { Colors } from 'app/constants';
//import { FeatureList } from './feature-list';
/*import { AccredStandartPrimaryModal } from './modal/standart-primary-modal';
import { AccredStandartPrimaryModalRef } from './modal/types';*/

interface Props {
  data: TestProgram;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
}

export const ResultList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<TestProgramResultModalRef>(null);

  const [deleteRec] = useDeleteTestProgramFeatureMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program });
    }
  };


  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      sort: 'order_nn, parent_method',
    },
  });

  const { data: records, isFetching } = useGetTestProgramResultsQuery({
    id_test_program: data ? data.id : 0,
    ...filterParams,
  });

  const { data: features, isFetching: isFeaturesFetching } = useGetTestProgramFeaturesQuery(
    { id_test_program: data ? data.id : 0 },
    { skip: !data }
  );

  const rules: bgColorRule[]  = [
    {
      field: 'parent_method',
      rule: [
        {value: null, color: '#b2d1b9'},
        {value: undefined, color: '#b2d1b9'},
      ]
    },
    /*{
      field: 'id',
      rule: [
        {value: 53434, color: '#b2d1b9'},
      ]
    },*/
  ];

  const headers = [
    {
      label: t('testProgram.result.specific'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.result.standard'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.result.standartTechParam'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('unit'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.result.formula'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('testProgram.result.confidenceProbability'),
      align: "center" as const,
      //sortable: true,
      //sortField: 'name',
    },
    {
      label: t('actions'),
      align: 'center' as const,
    },
  ];

  const columns = [
    {
      render: (row: TestProgramResults) => row.accredSpecific.name ?? '--',
    },
    {
      render: (row: TestProgramResults) => row.accredStandartData.name ?? '--',
    },
    {
      render: (row: TestProgramResults) => row.standart_tech_param ?? '--',
    },
    {
      render: (row: TestProgramResults) => row.unit ? row.unit.name : '--',
    },
    {
      render: (row: TestProgramResults) => row.formula ?? '--',
    },
    {
      render: (row: TestProgramResults) => row.confidence_probability ?? '--',
    },
    {
      render: (row: TestProgramResults) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='observe'
            onClick={() => navigate(`/test-program/view/${row.id_test_program}/results/${row.id}`)}
          />
          <UIActionButton
            icon='edit'
            tooltip={t('edit')}
            onClick={() => modalRef.current?.open(row, data.id)}
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, row.id_test_program)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('testProgram.result.title')}
            <IconButton
              onClick={() => modalRef.current?.open(undefined, data.id)} 
              aria-label="add"
            >
              <AddCircle />
            </IconButton>
          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={ headers }
          columns={ columns }
          //onSort={onChangeFilterParams}
          //orderDirection={'asc'}
          //orderByField={'name'}
          bgColorRules = {rules}
          rowCollapsed={true}
          renderRowAfter={(row: TestProgramResults, index: number) => {
            return (
              <ResultView data={row} />
            );
          }
          }
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <TestProgramResultModal testProgram={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormatedInput from 'shared/inputs/formated-input';
import { FormatedInputProps } from 'shared/inputs/formated-input/types';
import { BaseControllerInputProps } from 'shared/inputs/types';

const FormFormatedInput = <T extends FieldValues>(
  props: BaseControllerInputProps<T, FormatedInputProps>
) => {
  const { control, name, onChange, rules, InputProps, regExp, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      rules={{
        ...rules,
        required: rules?.required === true ? t('errors:required_field') : rules?.required,
      }}
      name={name}
      render={({ field: { ref, onChange: fieldOnChange, ...restField }, fieldState }) => {
        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (regExp && event.target.value && !regExp.test(event.target.value)) return;
          onChange?.(event);
          fieldOnChange(event);
        };

        return (
          <FormatedInput
            {...rest}
            {...restField}
            onChange={onChangeHandler}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            InputProps={{
              ref: ref,
              ...InputProps,
            }}
          />
        );
      }}
    />
  );
};

export default FormFormatedInput;

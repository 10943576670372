import { useAppSelector } from 'app/hooks/redux';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';


interface Props extends PropsWithChildren {
  checkIsLogged?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({ children, checkIsLogged = true }) => {
  const { authorized } = useAppSelector((state) => state.auth);

  const check = () => {
    if (checkIsLogged && !authorized) {
      return <Navigate to='/auth' replace />;
    }

    return children;
  };

  return <>{check()}</>;
};

import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartSampleModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  useGetStandartSamplesQuery,
} from 'entities/dicts/api';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState,
} from './initialState';
import { useCreateStandartSampleMutation, useUpdateStandartSampleMutation } from 'entities/accreditation/api';
import { AccredStandart, AccredStandartSample } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';

interface Props {
  accredStandart: AccredStandart;
}

export const AccredStandartSampleModal = forwardRef<AccredStandartSampleModalRef, Props>(({ accredStandart }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { 
    control, 
    handleSubmit, 
    reset, 
    //watch,
    //setValue, //formState, trigger 
  } = methods;
  const [createStandartSample, { isLoading: isLoadingCreate }] = useCreateStandartSampleMutation();
  const [updateStandartSample, { isLoading: isLoadingUpdate } ] = useUpdateStandartSampleMutation();

  const { data: samples, isFetching: isSampleFetching } = useGetStandartSamplesQuery({
    'per-page': 0,
  });
  
  

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandartSample | undefined) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        id_standart_sample: data.standartSample,
        usage_rate: data.usage_rate,
      });
    }else{
      reset({...initialState, 
        //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
        }
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({...initialState, 
      //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
    });
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.id_standart_sample) {
      return;
    }
    
    let body = {
      id_accred_standart: accredStandart.id,
      id_standart_sample: data.id_standart_sample.id,
      usage_rate: data.usage_rate,
    };
    
    try {
      if (isEdit && data.id) {
        await updateStandartSample({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandartSample(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      
      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else{
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '26vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '150px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isSampleFetching}
                options={samples?.items ?? []}
                control={control}
                name={`id_standart_sample`}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.tabs.standard.title')
                }}
                rules={{
                  required: true,
                }}
                onChange={(event, option) => {
                  
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormNumericInput
              control={control}
              name={'usage_rate'}
              label={t('accred.standart.tabs.standard.norm')}
              fullWidth
              //required
              rules={{
                required: true,
              }}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ whiteSpace: 'nowrap' }}>
                    { '-' }
                  </Typography>
                ),
              }}
            />
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

import { Tenant } from 'entities/dicts/model';
import { TestProgram } from 'entities/test-program/model';


export interface FormValues {
  id?:number;
  id_tenant: Tenant | null;
  id_test_program_lab: TestProgram | null;
}


export const initialState = {
  id_tenant: null,
  id_test_program_lab: null,
}
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import { ResponseWithPagination } from 'app/utils/types';
import { Staff } from '../model';

export const staffApi = createApi({
  reducerPath: 'staffApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getStaff: builder.query<ResponseWithPagination<Staff>, {}>({
      query: () => ({
        method: 'GET',
        url: '/staffs',
        params: {
          'per-page': 0,
        },
      }),
    }),
  }),
});

export const { useGetStaffQuery, useLazyGetStaffQuery } = staffApi;

import React from 'react';
import './index.sass';
import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import stringAvatar from 'helpers/stringAvatar';
import {
  HEADER_HEIGHT,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from 'app/constants';
import { useAppSelector } from 'app/hooks/redux';
import { useTranslation } from 'react-i18next';
import { SidebarNav } from './ui/sidebar-nav';

const ORG_NAME = 'I-TEST';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const { isSidebarOpen, theme: mode } = useAppSelector((state) => state.app);
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: isSidebarOpen ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
        backgroundColor:
          mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        transition: 'width 0.3s ease',
        px: 2,
      }}
    >
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='center'
        sx={{
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          height: HEADER_HEIGHT,
        }}
      >
        <Avatar>{stringAvatar(ORG_NAME)}</Avatar>
        {isSidebarOpen && (
          <Typography
            sx={{
              fontSize: '2.4rem',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              wordWrap: 'none',
            }}
          >
            {ORG_NAME}
          </Typography>
        )}
      </Stack>
      <SidebarNav sx={{ mt: 2 }} />
    </Box>
  );
};

export default Sidebar;

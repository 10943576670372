import { Box } from '@mui/material';
import React from 'react';
import Layout from 'shared/layout';
//import SingleRoom from './ui/single-room';
import UITabs from 'shared/ui/ui-tabs';
import EquipmentList from './list';
import MaintenanceSchedule from './schedule/';
import CalibrationProgram from './calibration/';

import { useTranslation } from 'react-i18next';

const Equipment = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <UITabs
          tabs={[
            {component: <EquipmentList />,
            label: t('techMeans'),
            href: 'list',},
            {component: <MaintenanceSchedule />,
            label: t('maintenanceSchedule'),
            href: 'schedule',},
            {component: <CalibrationProgram />,
            label: t('calibrationProgram'),
            href: 'calibration',},
          ]}
          name='room'
        />
      </Box>
    </Layout>
  );
};

export default Equipment;

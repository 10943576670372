import { baseApi } from '../base-api';
import { TestProgramPrimary } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramPrimaryBody } from '../../model/request-body';
import { createFormData } from 'app/utils';

const testProgramPrimaryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Primary
    getTestProgramPrimaries: builder.query<
      ResponseWithPagination<TestProgramPrimary>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/primaries`,
        params,
      }),
      providesTags: ['TestProgramPrimary'],
    }),
    createTestProgramPrimary: builder.mutation<
      DefaultResponse,
      TestProgramPrimaryBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/primaries`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),
    updateTestProgramPrimary: builder.mutation<DefaultResponse, TestProgramPrimaryBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/primaries/${id}`,
        body: createFormData(body),
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),
    deleteTestProgramPrimary: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/primaries/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramPrimary'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramPrimariesQuery,
  useCreateTestProgramPrimaryMutation,
  useUpdateTestProgramPrimaryMutation,
  useDeleteTestProgramPrimaryMutation, 
} = testProgramPrimaryApi;
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Grid, Typography } from '@mui/material';
import UITable from 'shared/ui/ui-table';
import { Equipment, EquipmentEnvironmentCondition } from 'entities/equipment/model';
import { useGetEnvironmentConditionsQuery } from 'entities/dicts/api';
import { EnvironmentCondition } from 'entities/dicts/model';

interface Props {
  equipment: Equipment;
}

export const Characteristic: React.FC<Props> = ({ equipment }) => {
  const { t } = useTranslation();

  const environmentConditions = useGetEnvironmentConditionsQuery({}).data;

  interface ScalesWithError {
    id_scale: string;
    name: string;
    unit: string;
    id_scale_mea_error: string;
    range_from: string;
    range_to: string;
    coef_a: string;
    coef_b: string;
  }

  const scales:ScalesWithError[] = (equipment && equipment?.scales.length > 0) ? equipment?.scales?.flatMap((item) => {
    //console.log(item);
    return (item.scaleMeaErrors.length>0) ? item.scaleMeaErrors.map((el, index) => {
      //console.log(index);
      return {
        id_scale: (index==0) ? String(item.id) : "",
        name: (index==0) ? item.name : "",
        unit: (index==0) ? item.unit.name : "",
        id_scale_mea_error: String(el.id),
        range_from: String(el.range_from),
        range_to: String(el.range_to),
        coef_a: String(el.coef_a),
        coef_b: String(el.coef_b),
      };
    }): {
      id_scale: String(item.id),
      name: item.name,
      unit: item.unit.name,
      id_scale_mea_error: "",
      range_from: "",
      range_to: "",
      coef_a: "",
      coef_b: "",
    };
  }) : [
    {
      id_scale: '',
      name: '',
      unit: '',
      id_scale_mea_error: '',
      range_from: '',
      range_to: '',
      coef_a: '',
      coef_b: ''
    }
  ];

  return (
    <CollapseCard title="Технические данные" collapsed={true}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography mb={'1rem'} variant='h3'>Шкала:</Typography>
            <UITable
              data={scales}
              headers={[
                //{ label: '№', align:"center"  as const, },
                { label: t('scale'), align: "center" as const, },
                //{ label: t('measurement'), align:"center"  as const, },
                { label: t('from'), align: "center" as const, },
                { label: t('to'), align: "center" as const, },
                { label: t('coef_a'), align: "center" as const, },
                { label: t('coef_b'), align: "center" as const, },
                //{ title: 'Погрешность', field: 'deviation' },
                //{ title: 'Расширенная неопределенность', field: 'expanded_uncertainty' },
              ]}
              columns={[
                /*{
                  render: (row: Scales) => row.id ?? '-',
                },*/
                {
                  render: (row: ScalesWithError) => row.name ?? '-',
                },
                /*{
                  render: (row: ScalesWithError) => row.unit ?? '-',
                },*/
                {
                  render: (row: ScalesWithError) => row.range_from ?? '-',
                },
                {
                  render: (row: ScalesWithError) => row.range_to ?? '-',
                },
                {
                  render: (row: ScalesWithError) => row.coef_a ?? '-',
                },
                {
                  render: (row: ScalesWithError) => row.coef_b ?? '-',
                },
              ]}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography mb={'1rem'} variant='h3'>Условия эксплуатации:</Typography>
            <UITable
              data={equipment?.equipmentEnvironmentConditions ?? []}
              headers={[
                { label: t('operatingConditions'), align: "center" as const, },
                { label: t('from'), align: "center" as const, },
                { label: t('to'), align: "center" as const, },
              ]}
              columns={[
                {
                  render: (row: EquipmentEnvironmentCondition) => environmentConditions?.items.find((item: EnvironmentCondition) => item.id == row.id_environment_condition)?.name ?? '-',
                },
                /*{
                  render: (row: ScalesWithError) => row.unit ?? '-',
                },*/
                {
                  render: (row: EquipmentEnvironmentCondition) => row.value_from ?? '-',
                },
                {
                  render: (row: EquipmentEnvironmentCondition) => row.value_to ?? '-',
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapseCard>
  );
}

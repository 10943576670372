import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartVideoModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetUnitsQuery,
} from 'entities/dicts/api';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState
} from './initialState';
import { useCreateStandartMutation, useCreateStandartPrimaryMutation, useCreateStandartVideoMutation, useGetGroupsQuery, useGetSpecificsQuery, useUpdateStandartMutation, useUpdateStandartPrimaryMutation, useUpdateStandartVideoMutation } from 'entities/accreditation/api';
import { AccredStandart, AccredStandartPrimary, AccredStandartVideoMaterial } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { FormFileInput } from 'shared/inputs/form/form-file-input';
import { useGetEquipmentQuery, useGetEquipmentScaleQuery } from 'entities/equipment/api';

interface Props {
  accredStandart: AccredStandart;
}

export const AccredStandartVideoModal = forwardRef<AccredStandartVideoModalRef, Props>(({ accredStandart }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { 
    control, 
    handleSubmit, 
    reset, 
    watch,
    setValue, //formState, trigger 
  } = methods;
  const [createStandartVideo, { isLoading: isLoadingCreate }] = useCreateStandartVideoMutation();
  const [updateStandartVideo, { isLoading: isLoadingUpdate } ] = useUpdateStandartVideoMutation();

  /*const { data: equipments, isFetching: isEquipmentFetching } = useGetEquipmentQuery({
    'per-page': 0,
  });
  
  //const { data: groups, isFetching: isGroupFetching } = useGetGroupsQuery({});
  const [selectedEq, setSelectedEq] = useState('0');
  const { data: scales, isFetching: isScalesFetching } = useGetEquipmentScaleQuery({
    'per-page': 0,
    id_equipment: selectedEq,
  });//, { skip: !selectedGroup || selectedGroup === '' });
  //const { data: laboratories, isFetching: isLaboratoryFetching } = useGetLaboratoriesQuery({});

  const handleEqChange = (id_equipment: string) => {
    console.log(id_equipment);
    setSelectedEq(id_equipment);
  };

  const [methodType, setMethodType] = useState(1);
  const [parallelShow, setParallelShow] = useState(0);

  const { data: units, isFetching: isUnitFetching } = useGetUnitsQuery({});

  const equipment = watch('id_equipment');*/

  /*const {
    //fields: unitFields,
    append: appendUnit,
    remove: removeUnit,
    //insert: insertUnit,
  } = useFieldArray({
    control,
    name: 'units',
    keyName: 'id',
  });*/

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandartVideoMaterial | undefined) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        name: data.name ? data.name : '',
        url: data.url ? data.url : '',
      });
    }else{
      reset({...initialState, 
        //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
        }
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({...initialState, 
      //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
    });
  };

  const onSubmit = async (data: FormValues) => {
    let body = {
      id_accred_standart: accredStandart.id,
      name: data.name,
      url: data.url,
    };
    
    try {
      if (isEdit && data.id) {
        await updateStandartVideo({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandartVideo(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      
      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else{
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '36vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '160px' }} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={12}>
            <FormControl>
              <FormTextInput
                control={control}
                name='name'
                label={t('accred.standart.tabs.video.name')}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormTextInput
                control={control}
                name='url'
                label={t('accred.standart.tabs.video.link')}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate || isLoadingUpdate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RequestAnalysis from './analysis';
import RequestSample from './sample';

interface Props {
  request: Request;
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export const Additional: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  const filesTestProg = ((request?.files !== undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;


  const files = (request?.files !== undefined) ? Object.keys(request.files).filter((el) => {
    let file = request.files[el as keyof Request['files']];
    return (file !== false && file !== "" && el !== "testProg");
  }).map(function (el) {
    let file = request.files[el as keyof Request['files']];
    return file as RequestFile;
  }) : [];
  //const filesTestProg = ((request?.files!==undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;
  /*const files = Object.keys(request.files).filter((el) => {
    let file = request.files[el as keyof Request['files']];
    return (file !== false && file !== "" && el !== "testProg");
  }).map(function (el) {
    let file = request.files[el as keyof Request['files']];
    return file as RequestFile;
  });*/

  return (
    <CollapseCard title="Дополнительная информация" collapsed={true}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RequestAnalysis request={request} />
        </Grid>
        <Grid item xs={6}>
          <RequestSample request={request} />
        </Grid>
      </Grid>
    </CollapseCard>
  );
}

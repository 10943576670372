import React, { useRef } from 'react';
import {
  Box,
  Typography,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Layout from 'shared/layout';
import Breadcrumb from 'shared/ui/breadcrumb';
import CollapseCard from 'shared/ui/collapse-card';
import DetailView from 'shared/ui/detail-view';
import {
  useGetTestProgramResultByIdQuery,
  useGetTestProgramByIdQuery
} from 'entities/test-program/api';
import { TestProgramResultModalRef } from '../modal';
import UIActionButton from 'shared/ui/ui-action-button';
import {
  binarityArray,
  decisionArray
} from 'entities/test-program/model';
import MaterialOutList from './material-out-list';
import SizeList from './size-list';

const TestProgramResultView: React.FC = () => {
  const { viewId, resultId } = useParams();
  const { t } = useTranslation();
  const resultModalRef = useRef<TestProgramResultModalRef>(null);

  const { data: result, isFetching: isFetchingResult } = useGetTestProgramResultByIdQuery({ id: resultId ?? '', id_test_program: viewId ?? '' });

  const { data: testProgram, isFetching: isFetchingTestProgram } = useGetTestProgramByIdQuery(viewId ?? '');

  const breadcrumbs = [
    { label: t('testProgram.title'), href: '/protocol?tab=test-prog' },
    { label: testProgram?.name ?? '', href: `/test-program/view/${result?.id_test_program}` },
    { label: result?.accredSpecific.name ?? '' },
  ];

  const handleEdit = () => {
    resultModalRef.current?.open(result);
  };

  if (isFetchingResult) {
    return <Typography>{t('loading')}</Typography>;
  }

  if (!result) {
    return <Typography>{t('not_found')}</Typography>;
  }

  return (
    <Layout>
      <Box display="flex" flexDirection="column" gap="1rem">
        <Breadcrumb items={breadcrumbs} />
        <Typography variant="h2">
          {result.accredSpecific.name}
          <UIActionButton icon="edit" tooltip={t('edit')} onClick={handleEdit} />
        </Typography>

        <CollapseCard title={t('testProgram.result.title')} collapsed={false}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <DetailView
                data={[
                  { label: t('testProgram.result.standard'), value: result.accredStandartData?.name },
                  { label: t('testProgram.result.standartTechParam'), value: result.standart_tech_param },
                  { label: t('unit'), value: result.unit?.name },
                ]}
              />
            </Grid>
            {result.accredStandartData 
                && (result.accredStandartData.type===0 || result.accredStandartData.type===1) 
                && (<Grid item xs={6}>
              <DetailView
                data={[
                  { label: t('testProgram.result.formula'), value: result.formula },
                  { label: t('testProgram.result.accuracy'), value: result.accuracy },
                  {
                    label: t('testProgram.result.resultRule'),
                    value: result.decision_rule && t(decisionArray.find((item) => item.id === result.decision_rule)?.name as string)
                  },
                  {
                    label: t('testProgram.result.binarity'),
                    value: result.binarity && t(binarityArray.find((item) => item.id === result.binarity)?.name as string)
                  },
                ]}
              />
            </Grid>)}
          </Grid>
        </CollapseCard>

        <SizeList result={result} />
        <MaterialOutList result={result} />
      </Box>
    </Layout>
  );
};

export default TestProgramResultView;
import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UITabProps } from './types';
import UITabPanel from './ui/ui-tab-panel';
import { useSearchParams } from 'react-router-dom';

const UITabs: React.FC<UITabProps> = ({ tabs, name = 'tabs' }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const tabName = searchParams.get('tab');
    const index = tabs.findIndex((tab) => tab.href === tabName);
    if (index !== -1) {
      setActiveTab(index);
    }
  }, []);
    
  const replaceUrlSearch = (index: number) => {
    const activeTabName = tabs.find((_tab, i) => i === index)?.href;
    if (activeTabName) {
      searchParams.set('tab', activeTabName);
      const res = searchParams.toString();
      setSearchParams(res, { replace: true });
    }
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    replaceUrlSearch(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs value={activeTab} onChange={handleChange} aria-label={`${name}`}>
          {tabs.map((tab, index) => (
            <Tab
              label={tab.label}
              id={`${name}-tab-${index}`}
              aria-controls={`${name}-panel-${index}`}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <UITabPanel value={activeTab} index={index} name={name} key={index}>
          {tab.component}
        </UITabPanel>
      ))}
    </Box>
  );
};

export default UITabs;

import React, { forwardRef } from 'react';
import { Box, ButtonBase, IconButton, ButtonBaseProps, IconButtonProps, Tooltip, Typography } from '@mui/material';

import { UIActionButtonProps } from './types';
import { Colors } from 'app/constants';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Edit from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Task from '@mui/icons-material/Task';
import GppBad from '@mui/icons-material/GppBad';

const ButtonComp = forwardRef<
  {},
  /*ButtonBaseProps & {
    btnColor: UIActionButtonProps['color'];
    noShadow: UIActionButtonProps['noShadow'];
  }*/
  IconButtonProps & {
    btnColor: UIActionButtonProps['color'];
    noShadow: UIActionButtonProps['noShadow'];
  }
>(({ btnColor, noShadow, sx, ...rest }) => {
  const chooseBtnColor = () => {
    switch (btnColor) {
      case 'yellow':
        return Colors.PRIMARY;
      case 'light-blue':
        return Colors.LIGHT_BLUE;
      default:
        return Colors.TEXT_WHITE;
    }
  };

  return (
    <IconButton
      {...rest}
      sx={{
        //height: '40px',
        //minWidth: '40px',
        marginLeft: '0 !important',
        borderRadius: '8px',
        backgroundColor: chooseBtnColor(),
        boxShadow: noShadow ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.15)',
        transition: 'transform 0.2s ease-in-out',
        ...(btnColor !== 'default' && {
          '& path': {
            stroke: Colors.TEXT_DARK,
          },
        }),
        '&:hover': {
          transform: 'scale(1.1)',
        },
        ...sx,
      }}
    />

  );
});

const UIActionButton: React.FC<UIActionButtonProps> = ({
  icon,
  tooltip,
  color = 'default',
  noShadow = true,
  text,
  sx,
  ...rest
}) => {
  const getIcon = () => {
    switch (icon) {
      case 'add':
        return AddOutlinedIcon;
      case 'edit':
        return Edit;
      case 'delete':
        return DeleteIcon;
      case 'search':
        return SearchIcon;
      case 'check':
        return CheckIcon;
      case 'upload':
        return UploadIcon;
      case 'download':
        return DownloadIcon;
      case 'share':
        return ReplyIcon;
      case 'refresh':
        return RefreshIcon;
      case 'observe':
        return RemoveRedEyeIcon;
      case 'sign':
        return Task;
      case 'cancel':
        return GppBad;
      default:
        return CloseIcon;
    }
  };

  const getColor = () => {
    switch (icon) {
      case 'delete':
        return Colors.ERROR;
      default:
        return Colors.TEXT_DARK;
    }
  };

  return (
    <ButtonComp
      {...rest}
      sx={{
        //color: getColor(),
        ...sx,
      }}
      btnColor={color}
      noShadow={noShadow}
    >
      <Tooltip title={tooltip ?? ''}>
        {icon ? (
          <Box
            component={getIcon()}
            sx={{
              // width: '100%',
              //height: '20px',
            }}
          />
        ) : (
          <Typography
            sx={{
              color: Colors.TEXT_SECONDARY,
              fontWeight: 500,
              px: 1.5,
            }}
          >
            {text}
          </Typography>
        )}
      </Tooltip>
    </ButtonComp>
  );
};

export default UIActionButton;

import { Staff } from 'entities/staff/model';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Laboratory } from 'entities/laboratories/model';
import { SelectObject } from 'app/utils/types';

/*export type CharProps = {
  scale: string,
  voltage: string,
  measurement:string,
  rangeOfUnit: 
    {
      from: string,
      to: string,
      accuracy: string,
    }[],
}[]

export type TechProps = {  
  name: string;
  id_unit: string;
  accuracy: string;  
}[]

export interface GeneralInfoProps {
  title:string,
  location:string,
  note:string,
  creator:string,
  zavodNum:string,
  inventoryNum:string,
  dateOfStartfUsage:string,
  metrolTrace:string
}


export interface JobConditionsProps {  
  id_environment_condition: string;
  value_from: string;
  value_to: string;
}*/

export const confirmationMethods = [
  {
    label: 'Калибровка',
    value: 1,
  },
  {
    label: 'In-house Калибровка',
    value: 2,
  },
  {
    label: 'Поверка',
    value: 3,
  },
  {
    label: 'Не имеет метрологических характеристик',
    value: 4,
  },
];

export const periodicyUnits = [
  {
    label: 'quantity',
    value: 1,
  },
  {
    label: 'month',
    value: 2,
  },
];

export interface FormValues {
  id?:number;
  //id_tenant: string;
  //nn: string;
  //date_create: string;
  name: string;
  manufactor: string;
  factory_number: string;
  inv_number: string;
  //type_system: string;
  date_begin: string;
  period_verify: number | null;
  description: string;
  scan: string;
  justification: string;
  justification_scan: string;
  confirmation_method: SelectObject;
  laboratory: Laboratory | null;
  software_version: string;
  software_url: string;
  scales: 
    {
      name: string;
      id_unit: Units | null;
      accuracy: number | null;
      scaleMeaErrors: {
        range_from: number | null;
        range_to: number | null;
        coef_a: number | null;
        coef_b: number | null;
      }[]
    }[]
  ;  
  equipmentEnvironmentConditions: 
    {
      checked: boolean;
      id_environment_condition: number;
      value_from: number;
      value_to: number;
    }[];
  equipmentServiceWorks: {
    name: string;
    periodicy: number | null;
    unit: SelectObject;
  }[];
  equipmentStaffs: {
    id_staff: number;
    staffModel: Staff;
  }[];
}


export const initialState = {
  //id_tenant: '',
  //nn: '',
  //date_create: '',
  name: '',
  manufactor: '',
  factory_number: '',
  inv_number: '',
  //type_system: '',
  date_begin: '',
  period_verify: null,
  description: '',
  scan: '',
  justification: '',
  justification_scan: '',
  confirmation_method: {label: '', value: ''},
  id_laboratory: null,
  software_version: '',
  software_url: '',
  scales: [
    {
      name: '',
      id_unit: null,
      accuracy: null,
      scaleMeaErrors: [
        {
          range_from: null,
          range_to: null,
          coef_a: null,
          coef_b: null,
        }
      ],
    }
  ],
  
  equipmentEnvironmentConditions: [
    /*{
      environmentCondition: null,
      value_from: '',
      value_to: '',
    }*/
  ],
  equipmentServiceWorks: [{
    name: '',
    periodicy: null,
    unit: {label: '', value: ''},
  }],
  equipmentStaffs: []
}
import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Link
} from '@mui/material';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import React, { useRef } from 'react';
import Layout from 'shared/layout';
//import EquipmentContainer from '../ui/equipment-container';

import './index.css';
import { useNavigate, useParams } from 'react-router-dom';
import { General } from './general';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'shared/ui/breadcrumb';
import { useGetStandartByIdQuery } from 'entities/accreditation/api';
import { TestProgram } from 'entities/test-program/model';
import { useGetTestProgramByIdQuery } from 'entities/test-program/api';
import { Feature } from './feature';
import { useAppSelector } from 'app/hooks/redux';
import { downloadFile } from 'app/utils/download';
import { ResultList } from './result';
import { ConnectedList } from './connected';
import { HsCodeList } from './hs-code';
import { StorageConditionList } from './storage-condition';
import { TestProgramModal, TestProgramModalRef } from '../modal';
import notify from 'app/utils/notify';
import { useCopyTestProgramMutation } from 'entities/test-program/api/endpoints/test-program';
/*import { Environment } from './environment';
import { Primary } from './primary';
import { Connected } from './connected';
import { Equipment } from './equipment';
import { Material } from './material';
import { Video } from './video';
import { Sample } from './sample';
import { Accuracy } from './accuracy';
import { AccredStandartModal } from '../modal/standart-modal';
import { AccredStandartModalRef } from '../modal/types';*/
//import {  } from 'pages/equipment/verification-modal/types';

const TestProgramView = () => {
  const navigate = useNavigate();
  const { viewId } = useParams();
  const { data, isFetching, isError } = useGetTestProgramByIdQuery(viewId ?? '');
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;

  const [copyTestProgram, { isLoading: isLoadingCopy }] = useCopyTestProgramMutation();

  const modalRef = useRef<TestProgramModalRef>(null);

  const handleEdit = () => {
    modalRef.current?.open(data)
  }

  const handleCopy = async () => {
    if (!data) {
      return;
    }
    try {
      const newTestProgram = await copyTestProgram({id: data.id }).unwrap();
      notify(t('copySuccess'), 'success');
      console.log(newTestProgram);
      navigate(`/test-program/view/${newTestProgram.id}`)
    } catch (error) {
      notify(t('copyError'), 'error');
    }
  };

  const breadcrumbs = [
    { label: t('testProgram.title'), href: '/protocol?tab=test-prog' },
    { label: data?.name as string },
  ];

  function handleDownload(id_test_program: number, id_test_program_results: number) {
    downloadFile(`test-programs/${id_test_program}/results/${id_test_program_results}/download-excel-template`, 'template1.xlsx');
  }

  return (
    <Layout>
      {isFetching ? (<Typography variant='h2'>{t('loading')}</Typography>) ? isError : (<Typography variant='h2'>{t('error')}</Typography>) ? data === null : (<Typography variant='h2'>{t('notFound')}</Typography>) :
        (<Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Breadcrumb items={breadcrumbs} />
          <Stack gap={'1rem'}>
            <Typography variant='h2'>
              {`${data?.name}`}
              <IconButton aria-label="edit" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="copy" onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Typography>
            {tenantType === 2 && (<Typography variant='h3'>
              {`${data?.accredGroup.name}`}
            </Typography>)}
          </Stack>
          {/**<Button 
          variant='contained' 
          onClick={() => handleDownload(data?.id as number, data?.id as number)}
        >
          {t('downloadExcel')}
        </Button>*/}
          <General data={data as TestProgram} />
          {tenantType === 2 && (<Feature data={data as TestProgram} />)}
          {tenantType === 2 && (<ResultList data={data as TestProgram} />)}
          {tenantType === 1 && (<HsCodeList data={data as TestProgram} />)}
          <ConnectedList data={data as TestProgram} />
          {tenantType === 1 && (<StorageConditionList data={data as TestProgram} />)}

          <TestProgramModal ref={modalRef} />
        </Box>)}
    </Layout>
  );
};

export default TestProgramView;

export enum Colors {
  PRIMARY = '#FFE600',
  SECONDARY = '#573973',
  PURPLE = '#563178',
  DARK = '#341F47',
  BG_DARK = '#242424',
  TEXT_DARK = '#353C47',
  TEXT_WHITE = '#FFFFFF',
  TEXT_SECONDARY = '#9BB5CC',
  BORDER = '#B4CBDB',
  BLUE = '#1A67B0',
  LIGHT_BLUE = '#ECF2F6',
  YELLOW = '#FFF622',
  SUCCESS = '#27AE60',
  ERROR = '#FF6B6B',
  WARNING = '#FFB800',
  WHITE = '#FFFFFF',
  MEDIUM_GREY = '#E5EFF5',
  DARKER_WHITE = '#F5FBFF',
  LIGHT_TURQUOISE = '#f0f8ff',
  ANOTHER_LIGHT_BLUE = '#F1F6FA',
  LIGHTER_BLUE = '#F4F8FB',
  PALE_BLUE = '#E5EFF5',
  BRIGHT_YELLOW = '#FFC700',
  PASTEL_ORANGE = '#FAC898',
  RED = '#ff0000',
  LIGHT_RED = '#ff8080',
  LIGHT_GREEN = '#77FF33',
  SILVER = '#C0C0C0',
}

export const HEADER_HEIGHT = 60;
export const SIDEBAR_EXPANDED_WIDTH = 280;
export const SIDEBAR_COLLAPSED_WIDTH = 80;

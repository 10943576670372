import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ModalHeader from 'shared/ui/modal-header';
import { FormFileInput } from 'shared/inputs/form/form-file-input';
import notify from 'app/utils/notify';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';

export interface FileUploadModalRef {
  open: <T extends Record<string, any>>(config: UploadConfig<T>) => void;
  close: () => void;
}

interface UploadConfig<T extends Record<string, any>> {
  uploadMutation: MutationTrigger<MutationDefinition<T, any, any, any>>;//(arg: T) => Promise<any>;
  additionalParams: Omit<T, 'file'>;
  fileParamName?: string;
  title?: string;
  acceptedFileTypes?: string;
  successMessage?: string;
  errorMessage?: string;
}

interface FormValues {
  file: File | null;
}

export const FileUploadModal = forwardRef<FileUploadModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentConfig, setCurrentConfig] = useState<UploadConfig<any> | null>(null);

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: { file: null },
  });

  useImperativeHandle(ref, () => ({
    open: (config) => {
      setCurrentConfig(config);
      setOpen(true);
    },
    close: handleClose,
  }));

  const handleClose = () => {
    setOpen(false);
    setIsUploading(false);
    setUploadProgress(0);
    setCurrentConfig(null);
    reset();
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.file || !currentConfig) {
      notify(t('error.invalidUploadData'), 'error');
      return;
    }

    setIsUploading(true);
    try {
      // Simulating upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 500);
      console.log('data', data);
      const fileParamName = currentConfig.fileParamName || 'file';
      /*const formData = new FormData();
      formData.append(fileParamName, data.file);
      console.log('currentConfig.additionalParams', currentConfig.additionalParams);
      // Append additional params to formData
      Object.entries(currentConfig.additionalParams).forEach(([key, value]) => {
        formData.append(key, value);
      });
      console.log('formData', formData);*/
      //await currentConfig.uploadMutation(formData as any);
      /*await currentConfig.uploadMutation({
        ...currentConfig.additionalParams,
        [fileParamName]: data.file,
      }).unwrap();*/

      const formData = new FormData();
      formData.append(fileParamName, data.file);

      await currentConfig.uploadMutation({
        ...currentConfig.additionalParams,
        [fileParamName]: formData.get(fileParamName) as File,
      }).unwrap();


      clearInterval(progressInterval);
      setUploadProgress(100);
      notify(currentConfig.successMessage || t('fileUpload.success'), 'success');
      handleClose();
    } catch (error) {
      notify(currentConfig.errorMessage || t('fileUpload.error'), 'error');
    } finally {
      setIsUploading(false);
    }
  };

  if (!currentConfig) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ModalHeader title={currentConfig.title || t('fileUpload.title')} handleClose={handleClose} />
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <FormFileInput
            control={control}
            name="file"
            label={t('fileUpload.chooseFile')}
            rules={{ required: true }}
            inputProps={{ accept: currentConfig.acceptedFileTypes }}
          />
        </Box>
        {isUploading && (
          <Box sx={{ width: '100%', my: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" color="text.secondary" align="center">
              {uploadProgress}%
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={isUploading}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={isUploading}
        >
          {isUploading ? t('fileUpload.uploading') : t('fileUpload.upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FileUploadModal;
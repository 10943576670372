import AddOutlined from '@mui/icons-material/AddOutlined';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteLaboratoryMutation, useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { useGetStaffQuery } from 'entities/staff/api';
import { AddLaboratoryModal } from 'features/add-laboratory-modal';
import { AddLaboratoryModalRef, EnvCondDeleteModalRef } from 'features/types';
import { WarningLabModal } from 'features/warning-lab-modal';
import React, {  useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';



export const DictsLaboratoriesPage = () => {
  const { t } = useTranslation();

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page':10,
      sort: '',
    },
  });
  const {theme} = useAppSelector(state=>state.app)
  const { data, isFetching } = useGetLaboratoriesQuery({ ...filterParams });
  const { data: staff, isLoading: staffLoading } = useGetStaffQuery({});

  const modalRef = useRef<AddLaboratoryModalRef>(null);
  const modalWarningRef = useRef<EnvCondDeleteModalRef>(null);


  const labHeaders = [
    {
      label:'№',
      align:"center"  as const,
    },
    {
      label:t('name'),
      align:'center'  as const,
      sortable: true,
      sortField: 'name',
      filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:  t('environment_conditions'),
      align:'center'  as const,
      filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:t('staff'),
      align:'center'  as const,
      filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:t('actions'),
      align:'center'  as const,
    },
    
  ];


  const handleDelete = (id:number) => {
    modalWarningRef.current?.open(id)
  }

  return (
    <Layout
      title={t('laboratories')}
      topContent={
        <Button startIcon={<AddOutlined />} onClick={() => modalRef.current?.open()}>
          {t('create')}
        </Button>
      }
    >
      <Box>
        <UITable
          isLoading={isFetching || staffLoading}
          data={data?.items ?? []}
          headers={labHeaders}
          onSort = {onChangeFilterParams}
          columns={[
            {
              render: (_row, idx) =>
                (filterParams.page - 1) * Number(data?._meta.perPage) + idx + 1,
              nowrap: true,
            },
            { key: 'name' },
            {
              render: (row) =>
                row.environmentConditions
                  .map((el) => el.name)
                  .join(', ')
                  .replace(/,/g, ', '),
            },
            {
              render: (row) =>
               {
                interface StaffTime {
                  time: string;
                  fio: string;
                }
                interface laboratoryConditionStaff {
                  id: number;
                  id_laboratory: number;
                  id_staff: number;
                  time: string;
                }
                let newNames = row.laboratoryConditionStaff
                .map((el:laboratoryConditionStaff) => {
                  let time_hhmm = el.time.split(":").slice(0,-1).join(":");
                  return {
                    time: time_hhmm,
                    fio:staff?.items.find((s) => s.id === el.id_staff)?.fio
                  } as StaffTime}
                );//console.log(newNames);
                let staffTimes = newNames.reduce((acc: { [key: string]: string[] }, el:StaffTime) => {
                  if (acc[el.fio]) {
                    acc[el.fio].push(el.time);
                  } else {
                    acc[el.fio] = [el.time];
                  }
                  return acc;
                }, {});
                return (
                  <Stack gap='1rem'>
                    {Object.keys(staffTimes).map((fio, index) => (
                      <Stack>
                        <Typography>
                          {fio}
                        </Typography>
                        {staffTimes[fio].map((time:string, timeIndex:number) => (
                          <Typography key={timeIndex}>
                            {'\u23F0'} {time}
                          </Typography>
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                );
                {/*return (
                  <Stack gap='1rem'>
                    {newNames.map((name, index) => (
                      
                        <Typography key={index}>
                          {index === 0 ? name.fio: (newNames[index - 1].fio !== newNames[index].fio && name.fio)}
                          {" "}
                          {index === 0}
                          
                          {'\u23F0'} {name.time}
                          
                          {(index === newNames.length - 1 || newNames[index].fio !== newNames[index + 1].fio)}
                        </Typography>
                      
                    ))}
                  </Stack>
                );*/}
               }
            },
            {
              render: (row) => (
                <Stack direction='row' spacing={2} justifyContent='center'>
                  <UIActionButton
                    icon='edit'
                    onClick={() => modalRef.current?.open(row)}
                  />
                  <UIActionButton
                    icon='delete'
                    onClick={() => handleDelete(row.id)}
                  />
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: data?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </Box>
      <AddLaboratoryModal ref={modalRef} />
      <WarningLabModal ref={modalWarningRef} />
    </Layout>
  );
};

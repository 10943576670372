import { Box, Button, Dialog, FormControl, Stack } from '@mui/material';
import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { AddEnvConditionModalRef } from './types';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { Colors } from 'app/constants';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import { useForm } from 'react-hook-form';
import { useCreateEnvironmentMutation, useGetUnitsQuery, useUpdateEnvironmentMutation } from 'entities/dicts/api';
import { handleResponse } from 'app/utils';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';

export interface FormValues {
  id:number
  name: string;
  id_unit: {
    id:number;
    name:string;
    name_en:string
  };
}

export const AddEnvModal = forwardRef<AddEnvConditionModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>();
  const [createEnv] = useCreateEnvironmentMutation();
  const [updateEnv] = useUpdateEnvironmentMutation()
  const {data: unitsData} = useGetUnitsQuery({})

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

    
  const handleOpen = (data: EnvironmentCondition | undefined, units:Array<Units> | undefined) => {
    if (data){
      const findUnit = units?.find(item => item.id === data?.id_unit)
      console.log({findUnit})
      console.log({units})
      setIsEdit(true)
      reset({
        id:data.id,
        id_unit: findUnit,
        name: data.name
      })
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      name:'',
      id_unit:{}
    });
  };

  const onSubmit = async (data: FormValues) => {
    const body = {
      name:data.name,
      id_unit: Number(data.id_unit.id),
    };
    try {
      setIsLoading(true);
      if (isEdit && data.id) {
        await handleResponse(await updateEnv({ ...body, id: data.id }));
      } else {
        await handleResponse(await createEnv(body));
      }
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <UIModalTitle>{t('add_environment_condition')}</UIModalTitle>

      <Stack spacing={4} mt={5}>
        <Box
          sx={{
            border: `1px solid ${Colors.BORDER}`,
            borderRadius: '8px',
            p: 2,
            height: '100%',
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'3rem'}>
            <FormControl>
              <FormTextInput
                control={control}
                name='name'
                label={t('name')}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <FormControl>
              <FormSelectInput
                options={unitsData?.items ?? []}
                control={control}
                getOptionLabel={(option) => option.name || "" }
                isOptionEqualToValue={(option,value) => option.id === value.id}
                name='id_unit'
                TextFieldProps={{label:t('measurement')}}
                disabled={isEdit}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={2} mt={3} direction='row' justifyContent='flex-end'>
        <Button color='secondary' onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>{t('save')}</Button>
      </Stack>
    </Dialog>
  );
});

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { EquipmentModalRef } from './types';
import { Equipment } from 'entities/equipment/model';
import UIModalTitle from 'shared/ui/ui-modal-title';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetEnvironmentConditionsQuery,
  useLazyGetEnvironmentConditionsQuery,
} from 'entities/dicts/api';
import { Colors } from 'app/constants';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { useGetStaffQuery, useLazyGetStaffQuery } from 'entities/staff/api';
import { Staff } from 'entities/staff/model';
import UISelect from 'shared/ui/ui-select';
import UIActionButton from 'shared/ui/ui-action-button';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { handleResponse } from 'app/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


import GeneralStep from './steps/general';
import SpecificationStep from './steps/specification';
import EnvironmentStep from './steps/environment';
import MaintenanceStep from './steps/maintenance';
import StaffStep from './steps/staff';


import ModalHeader from 'shared/ui/modal-header';

import { UseFormHandleSubmit } from 'react-hook-form';
import { FormValues, initialState, confirmationMethods, periodicyUnits } from './initialState';
import { useAppSelector } from 'app/hooks/redux';
import { CreateEquiment } from 'entities/equipment/model';
import {
  useCreateEquipmentMutation,
  useGetEquipmentIdQuery,
  useUpdateEquipmentMutation,
} from 'entities/equipment/api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
//import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Laboratory } from 'entities/laboratories/model';



export const AddEquipmentModal = forwardRef<EquipmentModalRef>((_, ref) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const { control, handleSubmit, reset, setValue, watch, formState, trigger } = methods;
  const [createEquipment] = useCreateEquipmentMutation();
  const [updateEquipment] = useUpdateEquipmentMutation();

  //const { data: laboratories } = useGetLaboratoriesQuery({});

  const {
    fields: charFields,
    append: charAppend,
    remove: charRemove,
    insert: charInsert,
  } = useFieldArray({
    control,
    name: 'scales',
  });
  const {
    fields: techFields,
    append: techAppend,
    remove: techRemove,
  } = useFieldArray({
    control,
    name: 'equipmentServiceWorks',
  });
  const {
    fields: envFields,
    append: envAppend,
    remove: envRemove,
  } = useFieldArray({
    control,
    name: 'equipmentEnvironmentConditions',
  });
  const {
    fields: staffFields,
    append: staffAppend,
    remove: staffRemove,
    insert: staffInsert,
  } = useFieldArray({
    control,
    name: 'equipmentStaffs',
  });

  const laboratory = watch('laboratory');

  useEffect(() => {
    envRemove();
  }
  , [laboratory]);

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: Equipment | undefined, laboratories?:Array<Laboratory> | undefined) => {
    if (data) {
      setIsEdit(true);
      /*reset({
        ...data,
        
        id: data.id,
      });*/

      const findLaboratory = laboratories?.find((item) => item.id === data.id_laboratory);
      
      const confirmationMethod = confirmationMethods.find((item)=>item.value==data.confirmation_method);
      console.log(data);
      console.log(confirmationMethod);
      reset({
        id:data.id,
        confirmation_method: confirmationMethod ? {...confirmationMethod, label: t(confirmationMethod.label)}:{label:'', value:''},
        date_begin: data.date_begin,
        //date_create: data.date_create, 
        description: data.description,
        factory_number: data.factory_number,
        inv_number: data.inv_number,
        laboratory: findLaboratory,
        //id_tenant: String(data.id_tenant),
        justification: data.justification,
        justification_scan: data.justification_url,
        manufactor: data.manufactor,
        name: data.name,
        //nn: data.nn,
        period_verify: data.period_verify,
        scan: data.scan_url,
        software_url: data.software_url,
        software_version: data.software_version,
        //type_system: String(data.type_system),
        equipmentEnvironmentConditions: data.equipmentEnvironmentConditions.length > 0 ? 
          data.equipmentEnvironmentConditions.map(
            (item) => ({
              checked: true,
              id_environment_condition: item.id_environment_condition,
              value_from: item.value_from,
              value_to: item.value_to,
            })
        ) : [],
        equipmentStaffs: data.equipmentStaffs.length > 0 ? 
          data.equipmentStaffs.map(
            (item) => ({
              id_staff: item.id_staff,
              staffModel: item.staff,
            })
        ) : [],
        equipmentServiceWorks: data.equipmentServiceWorks.length > 0 ? 
          data.equipmentServiceWorks.map((item) => ({
          ...item,
          periodicy: item.periodicy,
          unit: periodicyUnits.find((unit)=>unit.value===item.unit),
        })): [
          {
            name:'',
            periodicy:null,
            unit: {label:'', value:''},
          }
        ],
        scales: data.scales.length > 0 ? data.scales.map((item) => ({
          ...item,
          id: item.id,
          id_equipment: String(item.id_equipment),
          id_unit: item.unit,
          scaleMeaErrors: (item.scaleMeaErrors && item.scaleMeaErrors.length>0) ? 
            item.scaleMeaErrors.map((item) => ({
              range_from: item.range_from,
              range_to: item.range_to,
              coef_a: item.coef_a,
              coef_b: item.coef_b,
            })) : [{
              range_from: null,
              range_to: null,
              coef_a: null,
              coef_b: null,
            }],
          
          unit: item.unit,
        })) : [{
          accuracy:null,
          id_unit: null,
          name:'',
          scaleMeaErrors:[ 
            {
              coef_a: null,
              coef_b: null,
              range_from: null,
              range_to: null,
            }
          ]
        }],
      });
    }else{
      reset(initialState);
      //console.log('zero');
    }
    setActiveStep(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset(initialState);
  };

  const onSubmit = async (data: FormValues) => {
    //console.log(data.id_laboratory);
    console.log(data);

    //console.log(data.equipmentEnvironmentConditions.filter((item) => item.checked && item.checked!==undefined));

    const equipmentEnvironmentConditions = data.equipmentEnvironmentConditions.filter((item) => item.checked && item.checked!==undefined).map((item) => {
      return {
        id_environment_condition: item.id_environment_condition,
        value_from: Number(item.value_from),
        value_to: Number(item.value_to),
      }
    });

    //console.log(equipmentEnvironmentConditions);

    let body = {
      //...data,
      //id_tenant: Number(data.id_tenant),
      //type_system: Number(data.type_system),
      name: data.name,
      manufactor: data.manufactor,
      factory_number: data.factory_number,
      inv_number: data.inv_number,
      period_verify: Number(data.period_verify),
      //date_create: moment(data.date_create).format('YYYY-MM-DD'),
      date_begin: moment(data.date_begin).format('YYYY-MM-DD'),
      //nn: data.nn.length > 0 ? data.nn : '--',
      // @ts-ignore
      confirmation_method: Number(data.confirmation_method.value),
      id_laboratory: data.laboratory ? data.laboratory.id:null,
      description: data.description,
      scan: data.scan,
      justification: data.justification,
      justification_scan: data.justification_scan,
      software_version: data.software_version,
      software_url: data.software_url,
      scales: data.scales.map((item) => ({
        ...item,
        // @ts-ignore
        id_unit: Number(item.id_unit?.id),
        accuracy: Number(item.accuracy),
        scaleMeaErrors: item.scaleMeaErrors.map((meaError) => ({
          range_from: Number(meaError.range_from),
          range_to: Number(meaError.range_to),
          coef_a: Number(meaError.coef_a),
          coef_b: Number(meaError.coef_b),
        })),
      })),
      equipmentEnvironmentConditions: (equipmentEnvironmentConditions && equipmentEnvironmentConditions.length>0) ? equipmentEnvironmentConditions : [],
      equipmentServiceWorks: data.equipmentServiceWorks ? 
        data.equipmentServiceWorks.filter((item)=>item.name!='').map((item) => ({
          ...item,
          periodicy: Number(item.periodicy),
          unit: Number(item.unit.value),
        })) : [],
      equipmentStaffs: (data.equipmentStaffs && data.equipmentStaffs.length>0) ? data.equipmentStaffs.map((item) => ({
        id_staff: item.id_staff,
      })) : [],
    };
    console.log(body);
    console.log(data.id);
    try {
      setIsLoading(true);
      if (isEdit && data.id) {
        await handleResponse(await updateEquipment({ ...body, id: data.id }));
      } else {
        await handleResponse(await createEquipment(body));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
    /*try {
      setIsLoading(true);
      if (idEquip) { 
        const updateBody = {
          ...body,
          id: Number(idEquip)
        }
        await handleResponse(await updateEquipment(updateBody));
      } else {
        await handleResponse(await createEquipment(body));
      }
    } finally {
      setIsLoading(false);
      //handleClose();
    }*/
  };


  /*const onSubmit = async (data: FormValues) => {
    const body: UpdateLaboratoryBody = {
      name: data.name,
      laboratoryConditionStaff: data.laboratoryConditionStaff.map((el) => ({
        id_staff: el.value.id,
        time: el.value.time,
      })),
      laboratoryConditions: data.laboratoryConditions
        .map((el, index) => (el ? envConds?.items[index] : undefined))
        .filter((el) => el)
        .map((el) => ({
          id_environment_condition: el!.id,
        })),
    };

    try {
      setIsLoading(true);
      if (isEdit && data.id) {
        await handleResponse(await updateLab({ ...body, id: data.id }));
      } else {
        await handleResponse(await createLab(body));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };*/

  const steps = [t('generalInfo'), t('characteristics'), t('jobCondition'), t('maintenance'), t('staff')];
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    trigger().then((isValid) => {
      if (isValid) {
        setActiveStep(activeStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <GeneralStep 
          control={control} 
        />;
      case 1:
        return <SpecificationStep
          fields={charFields}
          remove={charRemove}
          append={charAppend}
          insert={charInsert}
          control={control}
        />;
      case 2:
        return <EnvironmentStep
          fields={envFields}
          remove={envRemove}
          append={envAppend}
          control={control}
        />;
      case 3:
        return <MaintenanceStep
          fields={techFields}
          remove={techRemove}
          append={techAppend}
          control={control}
        />;
      case 4:
        return <StaffStep
          staffFields={staffFields}
          staffRemove={staffRemove}
          staffAppend={staffAppend}
          staffInsert={staffInsert}
          setValue={setValue}
          control={control}
          open={open}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader 
        title={isEdit ? t('edit_equipment') : t('add_equipment')} 
        handleClose={handleClose} 
      />
      
      <DialogContent >
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
        
      <DialogContent style={{height:'400px'}} className='scrollbar'>
        <Stack spacing={1} mt={1}>
          <FormProvider {...methods} >
            <React.Fragment>
              {getStepContent(activeStep)}            
            </React.Fragment>
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
              {t('previous_step')}
            </Button>
          )}
          {activeStep === steps.length-1 ? (
            <Button
              variant="contained"
              autoFocus 
              onClick={handleSubmit(onSubmit)} 
              disabled={isLoading}
              sx={{ mt: 3, ml: 1 }}
            >
              {t('save')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {t('next_step')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
});

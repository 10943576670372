import { baseApi } from '../base-api';
import { TestProgramPrimary, TestProgramResults } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramResultChildBody, TestProgramResultsBody } from '../../model/request-body';

const testProgramResultApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Results
    getTestProgramResults: builder.query<
      ResponseWithPagination<TestProgramResults>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results`,
        params,
      }),
      providesTags: ['TestProgramResult'],
    }),
    getTestProgramResultById: builder.query<TestProgramResults, { id: string, id_test_program: string }>({
      query: ({ id, id_test_program }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['TestProgramResult'],
    }),
    createTestProgramResult: builder.mutation<
      DefaultResponse,
      TestProgramResultsBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/results`,
        body,
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    updateTestProgramResult: builder.mutation<DefaultResponse, TestProgramResultsBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/results/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    deleteTestProgramResult: builder.mutation({
      query: ({ id, id_test_program }) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/results/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    createTestProgramResultChild: builder.mutation<
      DefaultResponse,
      TestProgramResultChildBody & { id_test_program: number; id_test_program_results: number }
    >({
      query: ({ id_test_program, id_test_program_results, ...body }) => ({
        method: 'POST',
        url: `/test-programs/${id_test_program}/results/${id_test_program_results}/add-child`,
        body,
      }),
      invalidatesTags: ['TestProgramResult'],
    }),
    getTestProgramResultPrimaries: builder.query<
      ResponseWithPagination<TestProgramPrimary>,
      RequestWithPagination<{ id_test_program: number; id_test_program_result: number; }>
    >({
      query: ({ id_test_program, id_test_program_result, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/results/${id_test_program_result}/primaries`,
        params,
      }),
      providesTags: ['TestProgramResultPrimary'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramResultsQuery,
  useGetTestProgramResultByIdQuery,
  useCreateTestProgramResultMutation,
  useUpdateTestProgramResultMutation,
  useDeleteTestProgramResultMutation,
  useGetTestProgramResultPrimariesQuery,
} = testProgramResultApi;
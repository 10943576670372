import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { Box, Button, Stack, TextField, TextFieldProps, Typography, IconButton } from '@mui/material';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteLaboratoryMutation, useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { useGetStaffQuery } from 'entities/staff/api';
import { AddEquipmentModal } from 'pages/equipment/equipment-modal/equipment-modal';
import { EquipmentModalRef } from 'pages/equipment/equipment-modal/types';
import { WarningLabModal } from 'features/warning-lab-modal';
import Layout from 'shared/layout';
import UIActionButton from 'shared/ui/ui-action-button';
import UITable from 'shared/ui/ui-table';
import { AddCircle } from '@mui/icons-material';

import { useGetEquipmentQuery } from 'entities/equipment/api';

import { Equipment } from 'entities/equipment/model';
import { useNavigate } from 'react-router-dom';

import LongtextHide from 'shared/ui/long-text-hide';

import { bgColorRule } from 'shared/ui/ui-table/types';
import { Colors } from 'app/constants';
import { WarningEquipModal } from 'features/warning-equip-modal';
import { EnvCondDeleteModalRef } from 'features/types';

const EquipmentList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<EquipmentModalRef>(null);
  const modalWarningRef = useRef<EnvCondDeleteModalRef>(null);

  const { data: laboratories } = useGetLaboratoriesQuery({});

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      name: '',
      factory_number: '',
      inv_number: '',
      page: 1,
      fromDate: '',
      toDate: '',
      sort: '-date_create',
    },
  });

  const { data: records, isFetching } = useGetEquipmentQuery({
    //labId: data.id,
    //sort: '-date_create',
    //'per-page': 3,
    ...filterParams,
  });

  const handleDelete = (id: number) => {
    modalWarningRef.current?.open(id)
  }
  
  const equipHeaders = [
    {
      label:t('equipment'),
      align:"center"  as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}} />
    },
    {
      label:t('factoryNum'),
      align:'center'  as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:  t('inventoryNum'),
      align:'center'  as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:t('nextVerification'),
      align:'center'  as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label: t('methodTechDevice'),
      align:'center'  as const,
      //filter: <TextField InputProps={{sx:{backgroundColor:theme==='dark' ?'transparent': ''}}}/>
    },
    {
      label:t('actions'),
      align:'center'  as const,
    },
    
  ];

  const rules: bgColorRule[]  = [
    {
      field: 'date_end',
      rule: [
        {value:null, color: Colors.RED},
      ]
    },
    {
      field: 'sort',
      rule: [
        //{value:0, color: Colors.PURPLE},
        {value:1, color: Colors.LIGHT_RED},
        {value:2, color: Colors.PASTEL_ORANGE},
        //{value:3, color: Colors.PALE_BLUE},
      ]
    },
  ];


  return (
    <Box>
      <Stack direction={'row'} mb={'2rem'}>
        <Typography variant='h2'>{t('equipment')}</Typography>
        <IconButton onClick={() => modalRef.current?.open()} aria-label="add">
          <AddCircle />
        </IconButton>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          my: 2,
        }}
      >
        <TextField 
          label={t('equipment')} 
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ name: e.target.value })} 
        />
        <TextField 
          label={t('factoryNum')} 
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ factory_number: e.target.value })} 
        />
        <TextField 
          label={t('inventoryNum')} 
          variant='outlined'
          onChange={(e) => onChangeFilterParams({ inv_number: e.target.value })} 
        />
      </Stack>
      <UITable
        isLoading={isFetching}
        data={records?.items ?? []}
        onSort = {onChangeFilterParams}
        orderDirection = {'desc'}
        orderByField = {'date_create'}
        headers={equipHeaders}
        bgColorRules = {rules}
        columns={[
          {
            render: (row: Equipment) => row.name ?? '-',
          },
          {
            render: (row: Equipment) => row.factory_number ?? '-',
          },
          {
            render: (row: Equipment) => row.inv_number ?? '-',
          },
          {
            render: (row: Equipment) => {
              if(row.equipmentVerificationsOne.length === 0) return '-';
              return row.equipmentVerificationsOne[0].exp_date ?? '-'
            },
          },
          {
            render: (row: Equipment) => {
              const text = row.UsedAccredStandart.map((el) => el.name).join(', ') ?? '-';
              return (<LongtextHide text={text} />);
            },
          },
          {
            render: (row: Equipment) => (
              <Stack direction='row' spacing={2} justifyContent='center'>
                <UIActionButton
                  icon='observe'
                  onClick={() => navigate(`/equipment/view/${row.id}`)}
                />
                <UIActionButton icon='edit' onClick={() => modalRef.current?.open(row, laboratories?.items)} />
                <UIActionButton icon='delete'  onClick={() => handleDelete(row.id)}/>
              </Stack>
            ),
            nowrap: true,
          },
        ]}
        PaginationProps={{
          count: records?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
      <AddEquipmentModal ref={modalRef} />
      <WarningEquipModal ref={modalWarningRef} />
    </Box>
  );
};

export default EquipmentList;
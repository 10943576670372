import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { FormatedInputProps } from './types';

const FormatedInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FormatedInputProps
> = ({ format = '(##) ###-##-##', ...rest }, ref) => {
  return (
    <PatternFormat format={format} customInput={TextField} {...rest} inputRef={ref} />
  );
};

export default forwardRef(FormatedInput);

import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckView from 'shared/ui/check-view';

interface RequestAnalysisProps {
  request: Request;
}



const RequestAnalysis: React.FC<RequestAnalysisProps> = ({ request }) => {
  const { t } = useTranslation();

  const analysis = [
    {
      title: t('request.view.analysis.requirements_documented'),
      value: request?.requestAnalysis.requirements_documented,
      note: request?.requestAnalysis.requirements_note
    },
    {
      title: t('request.view.analysis.understandable'),
      value: request?.requestAnalysis.understandable,
      note: request?.requestAnalysis.understandable_note
    },
    {
      title: t('request.view.analysis.understandable'),
      value: request?.requestAnalysis.understandable,
      note: request?.requestAnalysis.understandable_note
    },
    {
      title: t('request.view.analysis.eq_ready'),
      value: request?.requestAnalysis.eq_ready,
      note: request?.requestAnalysis.eq_ready_note
    },
    {
      title: t('request.view.analysis.chemicals'),
      value: request?.requestAnalysis.chemicals,
      note: request?.requestAnalysis.chemicals_note
    },
    {
      title: t('request.view.analysis.chemicals_enough'),
      value: request?.requestAnalysis.chemicals_enough,
      visible: request?.requestAnalysis.chemicals==1
    },
    {
      title: t('request.view.analysis.chemicals_ready'),
      value: request?.requestAnalysis.chemicals_ready,
      visible: request?.requestAnalysis.chemicals==1
    },
    {
      title: t('request.view.analysis.staff_ready'),
      value: request?.requestAnalysis.staff_ready,
      note: request?.requestAnalysis.staff_note
    },
    {
      title: t('request.view.analysis.subcontract'),
      value: request?.requestAnalysis.subcontract,
      note: request?.requestAnalysis.subcontract_note
    },
    {
      title: t('request.view.analysis.agree_subcontract'),
      value: request?.requestAnalysis.agree_subcontract,
      visible: request?.requestAnalysis.subcontract==1
    },
    {
      title: t('request.view.analysis.methods_ready'),
      value: request?.requestAnalysis.methods_ready,
      note: request?.requestAnalysis.methods_note
    },
    {
      title: t('request.view.analysis.nd_uptodate'),
      value: request?.requestAnalysis.nd_uptodate,
      note: request?.requestAnalysis.nd_uptodate_note
    },
  ];

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        title={<Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body1" sx={{ fontSize: 24 }}>{t('request.view.analysis.title')}</Typography>
        {request.analysis_pdf_url && (
          <Link href={'https://prog.i-test.uz/uploads/' + request.analysis_pdf_url} target="_blank">
            <IconButton size="small">
              <FilePresentIcon />
            </IconButton>
          </Link>
        )}
      </Stack>}
        
      />
      <CardContent>
        <CheckView data={analysis} />
      </CardContent>
    </Card>
  );
};

export default RequestAnalysis;
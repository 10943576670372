import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import {
  DefaultResponse,
  RequestWithPagination,
  ResponseWithPagination,
} from 'app/utils/types';
import {
  AccredGroup,
  AccredSpecific,
  AccredStandart,
  AccredStandartAccuracyControl,
  AccredStandartConnect,
  AccredStandartEquipment,
  AccredStandartMaterial,
  AccredStandartPrimary,
  AccredStandartSample,
  AccredStandartVideoMaterial,
  CreateAccredGroupBody,
  CreateAccredSpecificBody,
  CreateAccredStandartBody, 
} from '../model';
import {
  AccredStandartConnectBody,
  AccredStandartEquipmentBody,
  AccredStandartMaterialBody,
  AccredStandartPrimaryBody,
  AccredStandartSampleBody,
  AccuracyControlBody,
  VideoMaterialBody
} from '../model/request-body';
import { createFormData } from 'app/utils';

/*const createFormData = (data: AccredStandartPrimaryBody): FormData => {
  const formData = new FormData();
  
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = (data as any)[key];
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
  
  return formData;
};*/

export const accreditationApi = createApi({
  reducerPath: 'accreditationApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'Group', 
    'Specific', 
    'Standart', 
    'StandartPrimary', 
    'StandartConnected', 
    'StandartEquipment', 
    'StandartMaterial',
    'StandartVideo',
    'StandartSample',
    'StandartAccuracy',

  ],
  endpoints: (builder) => ({
    getGroups: builder.query<ResponseWithPagination<AccredGroup>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/accred-groups',
        params
      }),
      providesTags: ['Group'],
    }),
    createGroup: builder.mutation<
      DefaultResponse,
      CreateAccredGroupBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-groups`,
        body,
      }),
      invalidatesTags: ['Group'],
    }),
    updateGroup: builder.mutation<
      DefaultResponse,
      CreateAccredGroupBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-groups/${id}`,
        body,
      }),
      invalidatesTags: ['Group'],
    }),
    deleteGroup: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/accred-groups/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Group'],
    }),
    // Specifics
    getSpecifics: builder.query<ResponseWithPagination<AccredSpecific>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/accred-specifics',
        params
      }),
      providesTags: ['Specific'],
    }),
    createSpecific: builder.mutation<
      DefaultResponse,
      CreateAccredSpecificBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-specifics`,
        body,
      }),
      invalidatesTags: ['Specific'],
    }),
    updateSpecific: builder.mutation<
      DefaultResponse,
      CreateAccredSpecificBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-specifics/${id}`,
        body,
      }),
      invalidatesTags: ['Specific'],
    }),
    deleteSpecific: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/accred-specifics/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Specific'],
    }),
    // Standarts
    getStandarts: builder.query<ResponseWithPagination<AccredStandart>, {}>({
      query: (params) => ({
        method: 'GET',
        url: '/accred-standarts',
        params
      }),
      providesTags: ['Standart'],
    }),
    getStandartById: builder.query<AccredStandart, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/accred-standarts/${id}`,
        params: {
          'per-page': 0,
        },
      }),
      providesTags: ['Standart'],
    }),
    createStandart: builder.mutation<
      DefaultResponse,
      CreateAccredStandartBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts`,
        body,
      }),
      invalidatesTags: ['Standart'],
    }),
    updateStandart: builder.mutation<
      DefaultResponse,
      CreateAccredStandartBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${id}`,
        body,
      }),
      invalidatesTags: ['Standart'],
    }),
    deleteStandart: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Standart'],
    }),

    // AccredStandartPrimary
    getStandartPrimaryWithConnected: builder.query<
      ResponseWithPagination<AccredStandartPrimary>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/primary-list`,
        params,
      }),
      providesTags: ['StandartPrimary'],
    }),
    getStandartPrimary: builder.query<
      ResponseWithPagination<AccredStandartPrimary>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/primaries`,
        params,
      }),
      providesTags: ['StandartPrimary'],
    }),
    createStandartPrimary: builder.mutation<
      DefaultResponse,
      AccredStandartPrimaryBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/primaries`,
        body: createFormData(body),
      }),
      invalidatesTags: ['StandartPrimary'],
    }),
    updateStandartPrimary: builder.mutation<DefaultResponse, AccredStandartPrimaryBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${body.id_accred_standart}/primaries/${id}`,
        body,
      }),
      invalidatesTags: ['StandartPrimary'],
    }),
    deleteStandartPrimary: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/primaries/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartPrimary'],
    }),

    // AccredStandartConnected
    getStandartConnected: builder.query<
      ResponseWithPagination<AccredStandartConnect>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/connects`,
        params,
      }),
      providesTags: ['StandartConnected'],
    }),
    createStandartConnected: builder.mutation<
      DefaultResponse,
      AccredStandartConnectBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/connects`,
        body,
      }),
      invalidatesTags: ['StandartConnected'],
    }),
    deleteStandartConnected: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/connects/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartConnected'],
    }),

    // AccredStandartEquipment
    getStandartEquipment: builder.query<
      ResponseWithPagination<AccredStandartEquipment>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/equipments`,
        params,
      }),
      providesTags: ['StandartEquipment'],
    }),
    createStandartEquipment: builder.mutation<
      DefaultResponse,
      AccredStandartEquipmentBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/equipments`,
        body,
      }),
      invalidatesTags: ['StandartEquipment'],
    }),
    deleteStandartEquipment: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/equipments/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartEquipment'],
    }),
    createStandartEqFromPrimary: builder.mutation({
      query: (id_accred_standart) => ({
        method: 'POST',
        url: `/accred-standarts/${id_accred_standart}/eq-from-primary`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartEquipment'],
    }),

    // AccredStandartMaterial
    getStandartMaterial: builder.query<
      ResponseWithPagination<AccredStandartMaterial>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/materials`,
        params,
      }),
      providesTags: ['StandartMaterial'],
    }),
    createStandartMaterial: builder.mutation<
      DefaultResponse,
      AccredStandartMaterialBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/materials`,
        body,
      }),
      invalidatesTags: ['StandartMaterial'],
    }),
    updateStandartMaterial: builder.mutation<
      DefaultResponse, 
      AccredStandartMaterialBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${body.id_accred_standart}/materials/${id}`,
        body,
      }),
      invalidatesTags: ['StandartMaterial'],
    }),
    deleteStandartMaterial: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/materials/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartMaterial'],
    }),

    // AccredStandartVideoMaterial
    getStandartVideo: builder.query<
      ResponseWithPagination<AccredStandartVideoMaterial>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/video-materials`,
        params,
      }),
      providesTags: ['StandartVideo'],
    }),
    createStandartVideo: builder.mutation<
      DefaultResponse,
      VideoMaterialBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/video-materials`,
        body,
      }),
      invalidatesTags: ['StandartVideo'],
    }),
    updateStandartVideo: builder.mutation<
      DefaultResponse, 
      VideoMaterialBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${body.id_accred_standart}/video-materials/${id}`,
        body,
      }),
      invalidatesTags: ['StandartVideo'],
    }),
    deleteStandartVideo: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/video-materials/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartVideo'],
    }),

    // AccredStandartSample
    getStandartSample: builder.query<
      ResponseWithPagination<AccredStandartSample>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/samples`,
        params,
      }),
      providesTags: ['StandartSample'],
    }),
    createStandartSample: builder.mutation<
      DefaultResponse,
      AccredStandartSampleBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/samples`,
        body,
      }),
      invalidatesTags: ['StandartSample'],
    }),
    updateStandartSample: builder.mutation<
      DefaultResponse, 
      AccredStandartSampleBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${body.id_accred_standart}/samples/${id}`,
        body,
      }),
      invalidatesTags: ['StandartSample'],
    }),
    deleteStandartSample: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/samples/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartSample'],
    }),

    // AccredStandartAccuracyControl
    getStandartAccuracy: builder.query<
      ResponseWithPagination<AccredStandartAccuracyControl>,
      RequestWithPagination<{ id_accred_standart: number }>
    >({
      query: ({ id_accred_standart, ...params }) => ({
        method: 'GET',
        url: `/accred-standarts/${id_accred_standart}/accuracy-controls`,
        params,
      }),
      providesTags: ['StandartAccuracy'],
    }),
    createStandartAccuracy: builder.mutation<
      DefaultResponse,
      AccuracyControlBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/accred-standarts/${body.id_accred_standart}/accuracy-controls`,
        body,
      }),
      invalidatesTags: ['StandartAccuracy'],
    }),
    updateStandartAccuracy: builder.mutation<
      DefaultResponse, 
      AccuracyControlBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/accred-standarts/${body.id_accred_standart}/accuracy-controls/${id}`,
        body,
      }),
      invalidatesTags: ['StandartAccuracy'],
    }),
    deleteStandartAccuracy: builder.mutation({
      query: ({id, id_accred_standart}) => ({
        method: 'DELETE',
        url: `/accred-standarts/${id_accred_standart}/accuracy-controls/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['StandartAccuracy'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useGetSpecificsQuery,
  useCreateSpecificMutation,
  useUpdateSpecificMutation,
  useDeleteSpecificMutation,
  useGetStandartsQuery,
  useGetStandartByIdQuery,
  useCreateStandartMutation,
  useUpdateStandartMutation,
  useDeleteStandartMutation,
  useGetStandartPrimaryWithConnectedQuery,
  useGetStandartPrimaryQuery,
  useCreateStandartPrimaryMutation,
  useUpdateStandartPrimaryMutation,
  useDeleteStandartPrimaryMutation,
  useGetStandartConnectedQuery,
  useCreateStandartConnectedMutation,
  useDeleteStandartConnectedMutation,
  useGetStandartEquipmentQuery,
  useCreateStandartEquipmentMutation,
  useDeleteStandartEquipmentMutation,
  useCreateStandartEqFromPrimaryMutation,
  useGetStandartMaterialQuery,
  useCreateStandartMaterialMutation,
  useUpdateStandartMaterialMutation,
  useDeleteStandartMaterialMutation,
  useGetStandartVideoQuery,
  useCreateStandartVideoMutation,
  useUpdateStandartVideoMutation,
  useDeleteStandartVideoMutation,
  useGetStandartSampleQuery,
  useCreateStandartSampleMutation,
  useUpdateStandartSampleMutation,
  useDeleteStandartSampleMutation,
  useGetStandartAccuracyQuery,
  useCreateStandartAccuracyMutation,
  useUpdateStandartAccuracyMutation,
  useDeleteStandartAccuracyMutation,  
} = accreditationApi;

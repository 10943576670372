import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Grid, Stack, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { Equipment, EquipmentGraphMethod, EquipmentOperatingCondition } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetEquipmentOperCondQuery } from 'entities/equipment/api';
import { skipToken } from '@reduxjs/toolkit/query/react'

interface Props {
  id_equipment?: number;
}

export const EnvironmentViolation: React.FC<Props> = ({ id_equipment }) => {
  const { t } = useTranslation();

  const { data: violations } = useGetEquipmentOperCondQuery(id_equipment ?? skipToken);
  console.log(violations);

  return (
    <CollapseCard title="Регистрация нарушений условий эксплуатации" collapsed={true}>
      <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Дата регистрации</TableCell>
            <TableCell>
              <Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
                Условие эксплуатации
                <Button variant='contained' size='small' sx={{ padding: '1rem' }}>
                  Зафиксировать риск
                </Button>
              </Stack>
            </TableCell>
            <TableCell>Норма согласно паспорта</TableCell>
            <TableCell>Фактическое значение</TableCell>
            <TableCell colSpan={4} style={{ padding: '0' }}>
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                  Повторяемость нарушения
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Повторяемость нарушения</TableCell>
                <TableCell> Повторяемость нарушения</TableCell>
                <TableCell> Повторяемость нарушения</TableCell>
                <TableCell> Повторяемость нарушения</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </TableHead>
        {
          violations?.result.map((violation, index: number) => (
            <TableRow key={index}>
              <TableCell>-</TableCell>
              <TableCell>{violation.environmentCondition.name}</TableCell>
              <TableCell>{`${violation.equipmentEnvironmentCondition.value_from}-${violation.equipmentEnvironmentCondition.value_to}`}</TableCell>
              <TableCell>{violation.value}</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          ))
        }
        <TableRow>
          <TableCell width={'10%'}>2023</TableCell>
          <TableCell width={'25%'}>тут должен быть график</TableCell>
          <TableCell width={'15%'}>тут должен быть график</TableCell>
          <TableCell>тут должен быть график</TableCell>

          <TableCell>h</TableCell>
          <TableCell>1</TableCell>
          <TableCell>2</TableCell>
          <TableCell>3</TableCell>
        </TableRow>
      </Table>

    </CollapseCard>
  );
}

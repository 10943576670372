import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  title?: string;
  children?: React.ReactNode;
}

const UIModalTitle: React.FC<Props> = ({ title, children = title }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Typography
        variant='h3'
        sx={{
          position: 'absolute',
          top: '-20px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: theme.palette.common.white,
          width: '90%',
          textAlign: 'center',
          backgroundColor: theme.palette.primary.main,
          padding: 3,
          borderRadius: '10px',
        }}
      >
        {children ?? title}
      </Typography>
    </Box>
  );
};

export default UIModalTitle;

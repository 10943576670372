import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ErrorStatus, ClearSizeModalRef } from './types';
import { useTranslation } from 'react-i18next';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useClearCombinedSizesMutation } from 'entities/test-program/api';

export const ClearSizeModal = forwardRef<ClearSizeModalRef>((_, ref) => {
    const { t } = useTranslation();
    const [id_test_program, setIdTestProgram] = useState<number | null>(null);
    const [id_test_program_results, setIdTestProgramResults] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [errorStatus, setErrorStatus] = useState<ErrorStatus | null>(null);
    const [clearCombinedSizes, { isLoading: isLoadingClearCombinedSizes }] = useClearCombinedSizesMutation();

    useImperativeHandle(
      ref,
      () => ({
        open: handleOpen,
        close: handleClose,
      }),
      []
    );

    const handleOpen = (id_test_program: number, id_test_program_results: number) => {
      setIdTestProgram(id_test_program);
      setIdTestProgramResults(id_test_program_results);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setErrorStatus(null);
    };

    const handleSubmit = async () => {
      if (id_test_program === null || id_test_program_results === null) return;
    
      try {
        const body = {
          id_test_program: id_test_program,
          id_test_program_results: id_test_program_results
        }
        const res = await clearCombinedSizes(body).unwrap();
    
        notify(t('operationSuccess'), 'success');
        setOpen(false);
        setErrorStatus(null);
      } catch (error) {
        notify(t('operationError'), 'error');
        setOpen(false);
        setErrorStatus(null);
      }
    };
    
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '1600px',
            width: '26vw',
            //p: 3,
            //overflowY: 'visible',
          },
        }}
      >
        <ModalHeader
          title={t('toast.warning')}
          handleClose={handleClose}
        />
        <DialogContent style={{ height: '100px' }} className='scrollbar'>
          <Stack spacing={4} mt={3}>
            <Typography variant='h3' textAlign={'center'}>
              {t('warningClear')}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' onClick={handleSubmit}>
            {t('clear')}
          </Button>
        </DialogActions>
      </Dialog >
    );
  });

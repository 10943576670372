import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell
} from '@mui/material';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

import React from 'react';
import Layout from 'shared/layout';
//import EquipmentContainer from '../ui/equipment-container';
import CollapseCard from '../../../shared/ui/collapse-card';
//import './index.css';
import {
  useGetEquipmentIdQuery,
} from 'entities/equipment/api';
import { useParams } from 'react-router-dom';
import { Equipment } from 'entities/equipment/model';
import { useGetRequestByIdQuery } from 'entities/request/api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { General } from './general';
import { Request } from 'entities/request/model';
/*import { ServiceRegistration } from './service-reg';
import { Verification } from './verification';

import { Characteristic } from './characteristic';
import { Shipping } from './shipping';
import { MethodGraphic } from './method-graphic';
import { EnvironmentViolation } from './environment-violation';*/
//import {  } from 'pages/equipment/verification-modal/types';


import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Breadcrumb from 'shared/ui/breadcrumb';
import DropdownButton from 'shared/ui/dropdown-button';
import { Additional } from './additional';
import { Production } from './production';



const RequestViewPage = () => {
  const { t } = useTranslation();
  const { viewId } = useParams();
  const { data } = useGetRequestByIdQuery(viewId ?? '');

  const editEquipment = () => {
    console.log(1);
  }

  const title = `${t('request.title')} ${moment(data?.date_create).format('DD.MM.YYYY hh:mm')} ${t('from')} ${data?.client_name}`;

  const breadcrumbs = [
    { label: t('request.list.title'), href: '/request' },
    { label: title },
  ];

  const actions = [
    {
      icon: <EditIcon />,
      text: t('edit'),
      onClick: () => {console.log('Изменить')},
      visible: data?.date_samples === null,
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.analysis'),
      onClick: () => {console.log('Анализ заявки')},
      visible: (data?.id_staff !== null) 
        && (data?.date_samples === null) 
        && (data?.requestAnalysis === null),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.getSamples'),
      onClick: () => {console.log('Образцы получил')},
      visible: ((data?.id_staff !== null) 
        && (data?.date_samples === null) 
        && (data?.requestAnalysis !== null)),
    },
    {
      //icon: <EditIcon />,
      text: t('cancel'),
      onClick: () => {console.log('Отменить')},
      visible: ((data?.id_staff !== null) 
        && (data?.date_samples !== null) 
        && (!data?.date_cancel) 
        && (!data?.has_any_next_steps)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.returnSamples'),
      onClick: () => {console.log('Вернуть образцы')},
      visible: ((data?.id_staff !== null) 
        && (data?.date_samples !== null) 
        && (data?.date_cancel === null) 
        && (data?.protocol !== null) 
        && (data?.return_date === null)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.copyCanceled'),
      onClick: () => {console.log('Создать копию отмененной')},
      visible: ((data?.date_samples !== null) 
        && (data?.date_cancel !== null)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.createProtocol'),
      onClick: () => {console.log('Сделать протокол')},
      visible: ((!data?.has_null_group) 
        && (!data?.has_next_steps) 
        && (data?.date_samples !== null) 
        && (!data?.date_cancel)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.createContract'),
      onClick: () => {console.log('Сделать договор')},
      visible: ((!data?.has_null_group) 
        && (!data?.has_next_steps) 
        && (data?.date_samples !== null) 
        && (data?.date_cancel === null)),
    },
/*
    {
      icon: <DeleteIcon />,
      text: t('delete'),
      onClick: () => {console.log(2)},
    },
    {
      icon: <PrintIcon />,
      text: t('print'),
      onClick: () => {console.log(3)},
    },*/
  ];

  return (
    <Layout>
      <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
        <Breadcrumb items={breadcrumbs} />
        <Stack
          direction='row'
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Typography variant='h2'>
            {title}
          </Typography>
          <DropdownButton items={actions} />
        </Stack>
            

        <General request={data as Request} />
        <Additional request={data as Request} />
        <Production request={data as Request} />
          {/**<Characteristic equipment={data as Equipment} />
          <Verification equipment={data as Equipment} />
          <ServiceRegistration equipment={data as Equipment} />
          <Shipping equipment={data as Equipment} />
          <MethodGraphic id_equipment={data?.id as number} />
          <EnvironmentViolation id_equipment={data?.id as number} />*/}
      </Box>
    </Layout>
  );
};

export default RequestViewPage;

import { baseApi } from '../base-api';
import { TestProgramFeature } from '../../model';
import { ResponseWithPagination, DefaultResponse, RequestWithPagination } from 'app/utils/types';
import { TestProgramFeatureBody } from '../../model/request-body';

const testProgramFeatureApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Test Program Features
    getTestProgramFeatures: builder.query<
      ResponseWithPagination<TestProgramFeature>,
      RequestWithPagination<{ id_test_program: number }>
    >({
      query: ({ id_test_program, ...params }) => ({
        method: 'GET',
        url: `/test-programs/${id_test_program}/features`,
        params,
      }),
      providesTags: ['TestProgramFeature'],
    }),
    createTestProgramFeature: builder.mutation<
      DefaultResponse,
      TestProgramFeatureBody
    >({
      query: (body) => ({
        method: 'POST',
        url: `/test-programs/${body.id_test_program}/features`,
        body,
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),
    updateTestProgramFeature: builder.mutation<DefaultResponse, TestProgramFeatureBody & { id: number }>({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/test-programs/${body.id_test_program}/features/${id}`,
        body,
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),
    deleteTestProgramFeature: builder.mutation({
      query: ({id, id_test_program}) => ({
        method: 'DELETE',
        url: `/test-programs/${id_test_program}/features/${id}`,
        credentials: 'include'
      }),
      invalidatesTags: ['TestProgramFeature'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestProgramFeaturesQuery,
  useCreateTestProgramFeatureMutation,
  useUpdateTestProgramFeatureMutation,
  useDeleteTestProgramFeatureMutation,  
} = testProgramFeatureApi;
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  FormControl,
  IconButton
} from '@mui/material';
import { FormValues } from 'pages/equipment/equipment-modal/initialState';
import React, { useState, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import UIActionButton from 'shared/ui/ui-action-button';
import { Staff } from 'entities/staff/model';
import { useGetStaffQuery, useLazyGetStaffQuery } from 'entities/staff/api';
import UISelect from 'shared/ui/ui-select';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';


interface Props {  
  staffFields: FieldArrayWithId<FormValues, 'equipmentStaffs'>[];
  staffRemove: UseFieldArrayRemove;
  staffAppend: UseFieldArrayAppend<FormValues, 'equipmentStaffs'>;
  staffInsert: UseFieldArrayInsert<FormValues, 'equipmentStaffs'>;
  setValue: UseFormSetValue<FormValues>;
  control: Control<FormValues, any>;
  open: boolean;
}

const StaffStep: React.FC<Props> = ({ staffFields, staffRemove, staffAppend, staffInsert, setValue, control, open }) => {
  const { t } = useTranslation();

  const { data: staff } = useGetStaffQuery({}, { skip: !open });
  //const [getStaff] = useLazyGetStaffQuery();
  const [staffData, setStaffData] = useState(staff?.items);

  useEffect(() => {
    setStaffData(staff?.items);
  }, [staff]);

  const onStaffAppend = (item: Staff) => {
    staffAppend({
      id_staff: item.id,
      staffModel: {
        ...item,
      },
    });
  };

  const onStaffInsert = (index:number, item:Staff) => {
    staffInsert(index + 1, {
      id_staff: item.id,
      staffModel: {
        ...item,
      },
    })
  }

  const onStaffRemove = (index: number) => {
    staffRemove(index);
  };

  /*const addHandle = () => {
    append({
      id_staff: '',
    });
  };

  const deleteHandle = (techIndex: number) => {
    remove(techIndex);
  };*/
  return (
    <Box>
      <Typography mb={'2rem'} variant='h4'>
        {t('staff')}
      </Typography>
      <FormControl sx={{ mb: 3 }}>
        <UISelect
          options={staffData ?? []}
          getOptionLabel={(option) => option.fio}
          value={undefined}
          onChange={(_, option) => {
            onStaffAppend(option!);
          }}
          renderOption={(props, option) => (
            <li
              {...props}
              onClick={() => {
                if (!!staffFields.some((item) => item.id_staff === option.id)) {
                  setValue(
                    'equipmentStaffs',
                    staffFields.filter((item) => item.id_staff !== option.id)
                  );
                } else {
                  onStaffAppend(option!);
                }
              }}
            >
              {!!staffFields.some((item) => item.id_staff === option.id) ? (
                <Stack gap='1rem' flexDirection={'row'}>
                  <CheckBoxIcon fontSize='small' />
                  <li style={{ fontWeight: '700' }}>{option.fio}</li>
                </Stack>
              ) : (
                <li style={{ marginLeft: '2rem' }}>{option.fio}</li>
              )}
            </li>
          )}
          disableClearable
          disableCloseOnSelect
          inputValue=''
          TextFieldProps={{
            label: t('choose_staff_from_list'),
          }}
        />
      </FormControl>
      <Stack
        spacing={2}
        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
        className='scrollbar'
        height={'17rem'}
        pb={'2rem'}
      >
        {staffFields?.map((item, index) => (
          <Stack key={item.id} direction='row' sx={{ alignItems:'center', display: 'flex' }}>
            <Typography variant='h4'>{item.staffModel.fio}</Typography>
            <IconButton aria-label="delete" onClick={() => onStaffRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      {/**<Stack
        direction={'row'}
        width={'100%'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        gap={'1.5rem'}
      >
        {fields.map((item, index) => (
          <Stack
            sx={{ flex: '0 0 40%' }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            key={item.id}
          >
            <FormControl>
              <FormCheckboxInput
                control={control}
                name={`equipmentEnvironmentConditions.${index}.id_environment_condition`}
                label={t('envCondition')}
              />
            </FormControl>

            <FormControl>
              <FormTextInput
                control={control}
                type='number'
                name={`equipmentEnvironmentConditions.${index}.value_from`}
                label={t('from')}
                variant='standard'
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <FormControl>
              <FormTextInput
                control={control}
                type='number'
                name={`equipmentEnvironmentConditions.${index}.value_to`}
                label={t('to')}
                variant='standard'
                rules={{
                  required: true,
                }}
              />
            </FormControl>
            <Stack direction={'row'} gap={'1rem'} mx={'2rem'}>
              {fields.length > 1 && (
                <UIActionButton icon='delete' onClick={() => deleteHandle(index)} />
              )}
              <UIActionButton icon='add' onClick={() => addHandle()} />
              
            </Stack>
          </Stack>
        ))}
      </Stack>*/}
    </Box>
  );
};

export default StaffStep;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  //FormLabel,
  Grid,
  Stack,
  //Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  useFieldArray, 
  useForm 
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//import UIModalTitle from 'shared/ui/ui-modal-title';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
/*import { CreateEnvRecordBody, Laboratory } from 'entities/laboratories/model';
import { BaseModalRef } from 'app/utils/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormFormatedInput from 'shared/inputs/form/form-formatted-input';*/
import dayjs from 'dayjs';
import {
  useCreateEquipmentServRegMutation,
  useGetEquipmentTodayServiceWorkQuery,
  //useUpdateEquipmentServRegMutation,
  useSignEquipmentServRegByIdMutation
} from 'entities/equipment/api';
//import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { handleResponse } from 'app/utils';
import eimzo from 'app/vendors/eimzo';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import { CreateEquipmentServReg, Equipment, EquipmentServReg, EquipmentServiceWork } from 'entities/equipment/model';
import moment from 'moment';
import FormDatePicker from 'shared/inputs/form/form-date-picker';
import { Colors } from 'app/constants';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import { ServiceRegModalRef } from './types';

interface FormValues {
  id?: number;
  date_service: string;
  equipmentServiceRegWorks: {
    checked: boolean;
    id_equipment_service_work: number;
    order_nn: number;
    note: string;
  }[];
}

interface Props {
  equipment: Equipment;
}

const ServiceRegModal: React.ForwardRefRenderFunction<ServiceRegModalRef, Props> = (
  { equipment },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dateService, setDateService] = useState<string | undefined>();
  const { t } = useTranslation();
  const { control, reset, register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      date_service: '',
      equipmentServiceRegWorks: [],
    },
  });

  const [createServiceReg] = useCreateEquipmentServRegMutation();
  //const [updateServiceReg] = useUpdateEquipmentServRegMutation();
  const [signServiceReg] = useSignEquipmentServRegByIdMutation();

  const { data: todayServiceWork } = useGetEquipmentTodayServiceWorkQuery({id_equipment: (equipment ? String(equipment.id) : ""), date_service: dateService});
  
  const { fields: serviceRegWorks } = useFieldArray({
    control,
    name: 'equipmentServiceRegWorks',
  });

  useImperativeHandle(ref, () => ({
    open: onOpenHandler,
    close: onCloseHandler,
  }));

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    const equipmentServiceRegWorks = todayServiceWork?.map((el, index) => ({
      checked: false,
      id_equipment_service_work: el.id,
      order_nn: index + 1,
      note: '',
    }));
    reset({
      date_service: dayjs().format('YYYY-MM-DD'),
      equipmentServiceRegWorks: equipmentServiceRegWorks && [],
    });
  };

  const onOpenHandler = (data?: EquipmentServReg | undefined) => {
    if (data) {
      setDateService(data.date_service);
      reset({
        id: data.id,
        date_service: data.date_service,
        equipmentServiceRegWorks: todayServiceWork && todayServiceWork.map((el, index) => {
          const work = data.equipmentServiceRegWorks.find((work) => work.id_equipment_service_work === el.id);
          return {
            checked: work ? true : false,
            id_equipment_service_work: el.id,
            order_nn: work ? work.order_nn : index + 1,
            note: work ? work.note : '',
          };
        }),
      });
      setIsEdit(true);

    } else {
      const equipmentServiceRegWorks = todayServiceWork?.map((el, index) => ({
        checked: false,
        id_equipment_service_work: el.id,
        order_nn: index + 1,
        note: '',
      }));
      reset({
        date_service: dayjs().format('YYYY-MM-DD'),
        equipmentServiceRegWorks: equipmentServiceRegWorks && [],
      });
      setIsEdit(false);
    }
    setOpen(true);
    
  };

  const onSubmit = async (data: FormValues) => {
    //const [hrs, mins] = data.date_create.split(':');
    const equipmentServiceRegWorks = data.equipmentServiceRegWorks.filter((el) => el.id_equipment_service_work && el.checked==true);
    console.log('data', data);
    console.log('equipmentServiceRegWorks', equipmentServiceRegWorks);
    try {
      setIsLoading(true);
      const body: CreateEquipmentServReg = {
        id_equipment: equipment.id,
        date_service: moment(data.date_service).format('YYYY-MM-DD'),
        equipmentServiceRegWorks: (equipmentServiceRegWorks && equipmentServiceRegWorks.length>0) ? equipmentServiceRegWorks.map((el) => ({
          id_equipment_service_work: el.id_equipment_service_work as number,
          order_nn: el.order_nn,
          note: el.note,
        })) : [],
      };

      /*const res = await handleResponse(await create(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json.replace(/\\\//g, "")));
      await handleResponse(
        await signEnvRecord({ id: res.id, labId: room.id, signature, note: data.note })
      );*/
      const res = await handleResponse(await createServiceReg(body));
      // @ts-ignore
      const signature = await eimzo.sign((res.doc_json.replace(/\\\//g, "")));
      await handleResponse(
        await signServiceReg({ id: res.id, id_equipment: equipment.id, signature, note: '' })
      );
      /*if (isEdit && data.id) {
        await handleResponse(await updateServiceReg({ ...body, id: data.id }));
      } else {
        await handleResponse(await createServiceReg(body));
      }*/

    } finally {
      onCloseHandler();
      notify('', 'success');
      setIsLoading(false);
    }
  };

  /*const validate = (value: any, values: any) => {
    if (values.checked && !value) {
      return t('required_fields');
    }
    return true;
  };*/

  return (
    <Dialog
      open={open}
      onClose = {onCloseHandler}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '40vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader 
        title={isEdit ? t('edit') : t('add')} 
        handleClose={onCloseHandler} 
      />
      <DialogContent style={{height:'400px'}} className='scrollbar'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <FormControl>
              <FormDatePicker
                control={control}
                name={`date_service`}
                dateFormat='dd-MM-yyyy'
                TextFieldProps={{ label: t('eqServiceReg.date'), variant: 'standard' }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{
            border: `1px solid ${Colors.BORDER}`,
            borderRadius: '8px',
            p: 2,
            height: '40vh',
            mt: 2,
          }}
        >
          <Stack
            gap={'0.5rem'}
            sx={{ overflowY: 'scroll', overflowX: 'hidden', height: '35vh' }}
            className='scrollbar'
          >
            {
              todayServiceWork?.map((work: EquipmentServiceWork, index) => (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  key={work.id}
                >
                  <input type="hidden" value={index} {...register(`equipmentServiceRegWorks.${index}.order_nn`)} />
                  <input type="hidden" value={work.id} {...register(`equipmentServiceRegWorks.${index}.id_equipment_service_work`)} />
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <FormControl>
                        <FormCheckboxInput
                          control={control}
                          name={`equipmentServiceRegWorks.${index}.checked`}
                          label={(work ? work.name : "")}
                          sx={{ mt: 1 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextInput
                        control={control}
                        name={`equipmentServiceRegWorks.${index}.note`}
                        label={t('notion')}
                        variant='standard'
                        rules={{
                          //required: true,
                          //validate: (value, values) => validate(value, values.equipmentServiceRegWorks[index]),
                        }}
                      />
                    </Grid>
                  </Grid>
                      
                  
                </Stack>
              ))
            }
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onCloseHandler}>
          {t('close')}
        </Button>
        <Button color='success' onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ServiceRegModal);

import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TFunction } from 'i18next';
import SpaIcon from '@mui/icons-material/Spa';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { NavItem } from '../types';
import { useAppSelector } from 'app/hooks/redux';

export const NavData = (t: TFunction): NavItem[] => {
  const { userInfo } = useAppSelector((state) => state.auth);

  const items = [
    {
      name: t('backToVersion1'),
      to: process.env.REACT_APP_V1_URL ?? 'https://prog.i-test.uz/',
      icon: <ArrowBackIcon />,
      absolute: true,
      temp: true,
      tenantType: [1, 2],
    },
    {
      name: t('personal'),
      to: '/',
      icon: <PeopleAltIcon />,
      dev: true,
      tenantType: [2],
    },
    {
      name: t('spaces'),
      to: '/rooms',
      icon: <MeetingRoomIcon />,
      //dev: true,
      tenantType: [2],
    },
    {
      name: t('environment'),
      to: '/',
      icon: <SpaIcon />,
      //dev: true,
      tenantType: [2],
    },
    {
      name: t('dicts'),
      icon: <LibraryBooksIcon />,
      items: [
        {
          name: t('environment_conditions'),
          to: '/dicts/environment-conditions',
          tenantType: [2],
          temp: false,
          dev: false
        },
        {
          name: t('laboratories'),
          to: '/dicts/laboratories',
          tenantType: [2],
          temp: false,
          dev: false
        },
      ],
      //dev: true,
      tenantType: [1, 2],
    },
    {
      name: t('equipment'),
      to: '/equipment',
      icon: <PeopleAltIcon />,
      //dev: true,
      tenantType: [2],
    },
    {
      name: t('request.title'),
      to: '/request',
      icon: <PeopleAltIcon />,
      dev: true,
      tenantType: [2],
    },
    {
      name: t('protocol.common.menuLabel'),
      to: '/protocol',
      icon: <AssignmentIcon />,
      dev: true,
      tenantType: [2],
    },
    {
      name: t('accreditation'),
      to: '/accreditation',
      icon: <MenuBookIcon />,
      dev: true,
      tenantType: [2],
    },
    {
      name: t('cert.title'),
      to: '/certification',
      icon: <ArticleIcon />,
      //dev: true,
      tenantType: [1],
    },

  ];

  if (userInfo && userInfo.tenant) {
    return items.filter((item) => item.tenantType.includes(userInfo.tenant.type)
      && (!process.env.REACT_APP_TEMP_GUI || (process.env.REACT_APP_TEMP_GUI === "1" && item.temp) || !item.temp)
      && ((process.env.REACT_APP_ALL_GUI && process.env.REACT_APP_ALL_GUI === "1") || ((!process.env.REACT_APP_ALL_GUI || process.env.REACT_APP_ALL_GUI === "0") && !item.dev))
    ).map((item) => {
      if (item.items) {
        return {
          ...item,
          items: item.items.filter((subItem) => subItem.tenantType.includes(userInfo.tenant.type)
            && (!process.env.REACT_APP_TEMP_GUI || (process.env.REACT_APP_TEMP_GUI === "1" && subItem.temp) || !subItem.temp)
            && ((process.env.REACT_APP_ALL_GUI && process.env.REACT_APP_ALL_GUI === "1") || ((!process.env.REACT_APP_ALL_GUI || process.env.REACT_APP_ALL_GUI === "0") && !subItem.dev))
          ),
        };
      }
      return item;
    }
    );
  }
  
  return [
    {
      name: t('personal'),
      to: '/',
      icon: <PeopleAltIcon />,
    },
  ] as NavItem[];
}



import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, TextField, Typography, IconButton, Autocomplete } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks/redux';
import { useFilters } from 'app/hooks/use-filters';
import UITable from 'shared/ui/ui-table';
import UIActionButton from 'shared/ui/ui-action-button';
import { UITableColumn, UITableProps } from 'shared/ui/ui-table/types';
import { useGetCertRequestsQuery } from 'entities/certification/api';
import { CertRequest } from 'entities/certification/model';
import moment from 'moment';
import LongTextHide from 'shared/ui/long-text-hide';
import Breadcrumb from 'shared/ui/breadcrumb';

const CertRequestList: React.FC = () => {
  const { t } = useTranslation(['certification', 'common', 'errors']);
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);
  const tenantType = userInfo?.tenant?.type;

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      request_nn: '',
      reg_nn: '',
      id_scheme: '',
      page: 1,
      sort: '',
      'per-page': 10,
    },
  });

  const { data: certRequests, isFetching } = useGetCertRequestsQuery(filterParams);

  const headers: UITableProps<CertRequest>['headers'] = [
    { label: t('certRequest.requestNumber'), sortable: true, sortField: 'request_nn', align: 'center' },
    { label: t('certRequest.applicant'), sortable: true, sortField: 'clientInfo.name', align: 'center' },
    { label: t('certRequest.scheme'), sortable: true, sortField: 'scheme', align: 'center' },
    { label: t('certRequest.product'), align: 'center' },
    { label: t('certRequest.attachments'), align: 'center' },
    { label: t('actions', { ns: 'common' }), align: 'center' },
  ];

  const columns: UITableColumn<CertRequest>[] = [
    {
      render: (row) => (
        <a href={`/certification/${row.id}`} style={{ color: 'blue', textDecoration: 'underline' }}>
          {row.request_nn} от {moment(row.request_date).format('DD.MM.YYYY')}
        </a>
      ),
    },
    {
      render: (row) => `${row.clientInfo.name}, ${row.clientInfo.inn}`,
    },
    {
      render: (row: CertRequest) => {
        if (row.scheme.file_url) {
          return (
            <Button variant="text" onClick={() => console.log(`Show scheme: ${row.scheme.file_url}`)}>
              {row.scheme.name}
            </Button>
          );
        } else {
          return row.scheme.name;
        }
      },
    },
    {
      render: (row) => {
        const text = row.products.map(product =>
          `${product.name}, ${product.quantity} ${product.unit.name}`
        ).join('; ');
        return (<LongTextHide text={text} />);
      },
    },
    {
      render: (row) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button size="small" variant="outlined" title={t('certRequest.downloadCertificate')}>
            <DownloadIcon fontSize="small" />
          </Button>
          <Button size="small" variant="outlined" title={t('certRequest.downloadArchive')}>
            <DownloadIcon fontSize="small" />
          </Button>
        </Stack>
      ),
    },
    {
      render: (row) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <UIActionButton
            icon="observe"
            onClick={() => navigate(`/certification/${row.id}`)}
          />
          <UIActionButton icon="edit" onClick={() => console.log('Edit', row.id)} />
          <UIActionButton icon="delete" onClick={() => console.log('Delete', row.id)} />
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <Box>
      <Breadcrumb items={[{ label: t('certRequest.plural') }]} />
      <Stack direction="row" mb="2rem" alignItems="center">
        <Typography variant="h2">{t('certRequest.title')}</Typography>
        <IconButton onClick={() => console.log('Add new cert request')} aria-label="add">
          <AddCircle sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ my: 2 }}>
        <TextField
          label={t('certRequest.requestNumber')}
          variant="outlined"
          value={filterParams.request_nn}
          onChange={(e) => onChangeFilterParams({ request_nn: e.target.value })}
        />
        {/**<TextField
          label={t('certRequest.regNumber')}
          variant="outlined"
          value={filterParams.reg_nn}
          onChange={(e) => onChangeFilterParams({ reg_nn: e.target.value })}
        />*/}
        <Autocomplete
          options={[
            { name: t('certRequest.scheme') + ' 1', id: '1' },
            { name: t('certRequest.scheme') + ' 2', id: '2' },
            { name: t('certRequest.scheme') + ' 3', id: '3' },
            { name: t('certRequest.scheme') + ' 4', id: '4' },
            { name: t('certRequest.scheme') + ' 5', id: '5' },
            { name: t('certRequest.scheme') + ' 6', id: '6' },
            { name: t('certRequest.scheme') + ' 7', id: '7' },
            { name: t('certRequest.scheme') + ' 7A', id: '8' },
          ]}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('certRequest.scheme')}
              variant="outlined"
            />
          )}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}
          onChange={(event, option) => onChangeFilterParams({ id_scheme: (option && option.id) ? option.id : '' })}

          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              m: 'auto',
            }, width: 300
          }}
        />
      </Stack>

      <UITable
        isLoading={isFetching}
        data={certRequests?.items || []}
        onSort={onChangeFilterParams}
        headers={headers}
        columns={columns}
        PaginationProps={{
          count: certRequests?._meta.pageCount,
          page: filterParams.page,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
    </Box>
  );
};

export default CertRequestList;
import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RoomIcon from '@mui/icons-material/Room';
import CheckView from 'shared/ui/check-view';

interface RequestAnalysisProps {
  request: Request;
}



const RequestSample: React.FC<RequestAnalysisProps> = ({ request }) => {
  const { t } = useTranslation();

  const analysis = [
    {
      title: t('request.view.sample.is_test_ready'),
      value: request?.is_test_ready,
      note: request?.test_ready_note
    },
    {
      title: t('request.view.sample.is_description_correct'),
      value: request?.is_description_correct,
      note: request?.description_correct_note
    },
    {
      title: t('request.view.sample.is_quantity_correct'),
      value: request?.is_quantity_correct,
      note: request?.quantity_correct_note
    },
    {
      title: t('request.view.sample.is_require_correct'),
      value: request?.is_require_correct,
      note: request?.require_correct_note
    },
  ];

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        title={t('request.view.sample.title')}
      />
      <CardContent>
        <Stack spacing={1}>
          <Stack justifyContent='center'>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t('request.list.dateGetRejectSamples')}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1}>
              <CalendarMonth />
              <Typography variant="body1" sx={{ fontSize: 16 }}>{request.date_samples ? moment(request.date_samples).format('DD.MM.YYYY') : '-'}</Typography>
              {request.acceptance_pdf_url && (
                <Link href={'https://prog.i-test.uz/uploads/' + request.acceptance_pdf_url} target="_blank">
                  <IconButton size="small">
                    <FilePresentIcon />
                  </IconButton>
                </Link>
              )}
            </Stack>
          </Stack>

          <Divider variant="middle" />
          <CheckView data={analysis} />
          <Divider variant="middle" />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.view.sample.sample_place')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <RoomIcon />
                  <Typography variant="body1" sx={{ fontSize: 16 }}>{request.sample_place}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.view.sample.storageDates')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CalendarMonth />
                  <Typography variant="body1" sx={{ fontSize: 16 }}>
                    {`${request.date_samples ? moment(request.date_samples).format('DD.MM.YYYY') : ''} - 
                ${request.return_date ? moment(request.return_date).format('DD.MM.YYYY') : ''}`}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Stack justifyContent='center'>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t('request.view.sample.returnInfo')}
            </Typography>
            <Typography sx={{ fontSize: 16 }} variant="body1">
              {request.return_client_fio ? request.return_client_fio : ''}
            </Typography>
            <Typography sx={{ fontSize: 16 }} variant="body1">
              {request.return_client_phone ? request.return_client_phone : ''}
            </Typography>
            <Typography sx={{ fontSize: 16 }} variant="body1">
              {(request.protocol && request.protocol.writeoff_pdf_url) ?
                <Link href={'https://prog.i-test.uz/protocol/view-act-writeoff?id=' + request.protocol.id + '&pdf_file=1'} target="_blank">
                  <IconButton size="small">
                    <FilePresentIcon />
                  </IconButton>
                </Link> : ''}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {request.return_note ? request.return_note : ''}
            </Typography>


          </Stack>

        </Stack>
      </CardContent>
    </Card>
  );
};

export default RequestSample;
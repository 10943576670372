import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState, UserTheme } from './types';
import i18n from 'app/i18n';

const initialState: AppState = {
  isSidebarOpen: !!localStorage.getItem('isSidebarOpen'),
  theme: (localStorage.getItem('theme') as UserTheme) || 'light',
  lang: localStorage.getItem('lang') || 'ru',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openSidebar: (state) => {
      localStorage.setItem('isSidebarOpen', 'true');
      state.isSidebarOpen = true;
    },
    hideSidebar: (state) => {
      localStorage.removeItem('isSidebarOpen');
      state.isSidebarOpen = false;
    },
    toggleSidebar: (state) => {
      const { isSidebarOpen } = state;
      if (isSidebarOpen) {
        localStorage.removeItem('isSidebarOpen');
        state.isSidebarOpen = false;
      } else {
        localStorage.setItem('isSidebarOpen', 'true');
        state.isSidebarOpen = true;
      }
    },
    toggleTheme: (state) => {
      const { theme } = state;
      if (theme === 'dark') {
        state.theme = 'light';
      } else {
        state.theme = 'dark';
      }
      localStorage.setItem('theme', state.theme);
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
      localStorage.setItem('lang', state.lang);
      i18n.changeLanguage(state.lang);
    },
  },
});

export const { openSidebar, hideSidebar, toggleSidebar, toggleTheme, setLang } =
  appSlice.actions;

import React, { useRef } from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import UITable from 'shared/ui/ui-table';
import CollapseCard from 'shared/ui/collapse-card';
import {
  useGetTestProgramSizesQuery,
  useCombineFromParentMutation,
  useUploadTestProgramSizeExcelMutation,
  useDeleteTestProgramSizeMutation
} from 'entities/test-program/api';
import UIActionButton from 'shared/ui/ui-action-button';
import { useFilters } from 'app/hooks/use-filters';
import {
  TestProgramResults,
  TestProgramSize,
} from 'entities/test-program/model';
import { AddCircle } from '@mui/icons-material';
import DropdownButton from 'shared/ui/dropdown-button';
import Download from '@mui/icons-material/Download';
import Upload from '@mui/icons-material/Upload';
import Delete from '@mui/icons-material/Delete';
import { SizeCombineModal, SizeCombineModalRef } from './size-combine-modal';
import notify from 'app/utils/notify';
import { DeleteModalRef } from 'features/delete-modal/types';
import { ClearSizeModalRef } from './clear-size-modal/types';
import { ClearSizeModal } from './clear-size-modal';
import { downloadFile } from 'app/utils/download';
import SizeModal, { TestProgramSizeModalRef } from './size-modal';
import FileUploadModal, { FileUploadModalRef } from 'features/upload-modal';
import { DeleteModal } from 'features/delete-modal';

interface Props {
  result: TestProgramResults;
}

interface DeleteRequestBody {
  id: number;
  id_test_program: number;
  id_test_program_results: number;
}

const SizeList: React.FC<Props> = ({ result }) => {
  const { t } = useTranslation();
  const combineModalRef = useRef<SizeCombineModalRef>(null);
  const clearModalRef = useRef<ClearSizeModalRef>(null);
  const sizeModalRef = useRef<TestProgramSizeModalRef>(null);
  const uploadModalRef = useRef<FileUploadModalRef>(null);

  const [deleteRec] = useDeleteTestProgramSizeMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_test_program: additionalData?.id_test_program as number,
    id_test_program_results: additionalData?.id_test_program_results as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_test_program: number, id_test_program_results: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_test_program, id_test_program_results });
    }
  };

  const { filterParams: sizeFilterParams, onChangeFilterParams: onChangeSizeFilterParams } = useFilters({
    initialFilterParams: { page: 1, 'per-page': 10 },
  });

  const { data: sizes, isFetching: isFetchingSizes } = useGetTestProgramSizesQuery({
    id_test_program: result.id_test_program,
    id_test_program_results: result.id,
    ...sizeFilterParams,
  });

  const [combineFromParent, { isLoading: isLoadingFromParent }] = useCombineFromParentMutation();
  const [uploadTestProgramSizeExcel, { isLoading: isLoadingUpload }] = useUploadTestProgramSizeExcelMutation();

  const handleCombineFromParent = async () => {
    if (!result) {
      return
    }
    try {
      await combineFromParent({
        id_test_program: result.id_test_program,
        id_test_program_results: result.id
      }).unwrap();
      notify(t('addSuccess'), 'success');
    } catch (error) {
      notify(t('addError'), 'error');
    }
  }

  const handleClearCombinedSizes = async () => {
    if (!result) {
      return
    }
    clearModalRef.current?.open(result.id_test_program, result.id)
  }

  const handleDownloadExcel = () => {
    if (!result) {
      return
    }
    downloadFile(`test-programs/${result.id_test_program}/results/${result.id}/download-excel-template`, `template${result.id}.xlsx`);
  }

  const handleUploadExcel = () => {
    if (!result) {
      return
    }
    uploadModalRef.current?.open({
      uploadMutation: uploadTestProgramSizeExcel,
      additionalParams: {
        id_test_program: result.id_test_program,
        id_test_program_results: result.id,
      },
      fileParamName: 'file',
      title: t('testProgram.size.uploadExcel'),
      acceptedFileTypes: '.xlsx, .xls',
      successMessage: t('fileUpload.success'),
      errorMessage: t('fileUpload.error'),
    });
  };

  const sizeHeaders = [
    {
      label: t('testProgram.size.feature'),
      align: 'center' as const
    },
    {
      label: t('testProgram.size.tech_param_plan'),
      align: 'center' as const
    },
    {
      label: t('testProgram.size.value_from'),
      align: 'center' as const,
      visible: result.accredStandartData?.type == 0 || result.accredStandartData?.type == 1
    },
    {
      label: t('testProgram.size.value_to'),
      align: 'center' as const,
      visible: result.accredStandartData?.type == 0 || result.accredStandartData?.type == 1
    },
    {
      label: t('actions'),
      align: 'center' as const
    },
  ];

  const sizeColumns = [
    { render: (row: TestProgramSize) => row.features ?? '--' },
    { render: (row: TestProgramSize) => row.tech_param_plan ?? '--' },
    {
      render: (row: TestProgramSize) => row.value_from ?? '--',
      visible: result.accredStandartData?.type == 0 || result.accredStandartData?.type == 1
    },
    {
      render: (row: TestProgramSize) => row.value_to ?? '--',
      visible: result.accredStandartData?.type == 0 || result.accredStandartData?.type == 1
    },
    {
      render: (row: TestProgramSize) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <UIActionButton
            icon="edit"
            tooltip={t('edit')}
            onClick={() => sizeModalRef.current?.open(row, result.id_test_program, result.id)}
          />
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, result.id_test_program as number, row.id_test_program_results)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
        </Stack>
      ),
    },
  ];

  return (
    <>
      <CollapseCard
        title={t('testProgram.size.title')}
      >
        <Box display="flex" justifyContent="flex-begin" sx={{ mb: 1 }}>
          <DropdownButton
            label={t('actions')}
            items={
              [
                {
                  icon: <AddCircle />,
                  text: t('testProgram.size.combine'),
                  visible: true,
                  onClick: () => {
                    combineModalRef.current?.open();
                  }
                },
                {
                  icon: <AddCircle />,
                  text: t('testProgram.size.fromParent'),
                  visible: result.parent_method !== null && result.parent_method !== undefined,
                  onClick: () => {
                    handleCombineFromParent();
                  }
                },
                {
                  icon: <Delete />,
                  text: t('testProgram.size.clear'),
                  visible: sizes?.items && sizes.items.length > 0,
                  onClick: () => {
                    handleClearCombinedSizes();
                  }
                },
                {
                  icon: <Download />,
                  text: t('testProgram.size.downloadExcel'),
                  visible: sizes?.items && sizes.items.length > 0,
                  onClick: () => {
                    handleDownloadExcel();
                  }
                },
                {
                  icon: <Upload />,
                  text: t('testProgram.size.uploadExcel'),
                  visible: sizes?.items && sizes.items.length > 0,
                  onClick: () => {
                    handleUploadExcel();
                  }
                }
              ]
            }
          />
        </Box>
        <UITable
          isLoading={isFetchingSizes}
          data={sizes?.items ?? []}
          headers={sizeHeaders.filter((header) => header.visible === undefined || header.visible)}
          columns={sizeColumns.filter((column) => column.visible === undefined || column.visible)}
          PaginationProps={{
            count: sizes?._meta.pageCount,
            page: sizeFilterParams.page,
            onChange: (_, page) => onChangeSizeFilterParams({ page }),
          }}
        />
      </CollapseCard>


      <SizeCombineModal ref={combineModalRef} testProgramResult={result as TestProgramResults} />
      <ClearSizeModal ref={clearModalRef} />
      <SizeModal ref={sizeModalRef} />
      <FileUploadModal ref={uploadModalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
};

export default SizeList;
import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

interface AttentionProps {
  width?: number | string;
  description: string | React.ReactNode;
}

const Attention: React.FC<AttentionProps> = ({ width, description }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ maxWidth: width ?? 400, border: 'none', boxShadow: 'none' }}>
      <CardContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={2}>
            <WarningIcon style={{ color: 'red', fontSize: 48 }} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h4" color="text.secondary">
              { t('attention') + '!' }
            </Typography>
            <Typography variant="h4" color="text.secondary">
              { description }
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Attention;
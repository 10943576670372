import React, { useRef } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { AccredStandart, AccredStandartConnect } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';
import UITable from 'shared/ui/ui-table';
import { useFilters } from 'app/hooks/use-filters';
import { useDeleteStandartConnectedMutation, useGetStandartConnectedQuery } from 'entities/accreditation/api';
import { AddCircle } from '@mui/icons-material';
import UIActionButton from 'shared/ui/ui-action-button';
import { AccredStandartConnectModalRef } from './modal/types';
import { DeleteModalRef } from 'features/delete-modal/types';
import { AccredStandartConnectModal } from './modal/standart-connect-modal';
import { DeleteModal } from 'features/delete-modal';

interface Props {
  data: AccredStandart;
}

interface DeleteRequestBody {
  id: number;
  id_accred_standart: number;
}

export const Connected: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const modalRef = useRef<AccredStandartConnectModalRef>(null);

  const [deleteRec] = useDeleteStandartConnectedMutation();

  const prepareRequestBody = (id: number, additionalData?: Record<string, any>): DeleteRequestBody => ({
    id,
    id_accred_standart: additionalData?.id_accred_standart as number,
  });

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const openDeleteModal = (id: number, id_accred_standart: number) => {
    if (deleteModalRef.current) {
      deleteModalRef.current.open(id, { id_accred_standart });
    }
  };

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      'per-page': 10,
      //sort: '-date_service',
    },
  });

  const { data: records, isFetching } = useGetStandartConnectedQuery({
    id_accred_standart: data ? data.id : 0,
    ...filterParams,
  });



  const getResultRuleName = (resultRule: number) => {
    switch (resultRule) {
      case 1:
        return t('accred.standart.view.rule1');
      case 2:
        return t('accred.standart.view.rule2');
      case 3:
        return t('accred.standart.view.rule3');
      case 4:
        return t('accred.standart.view.rule4');
      case 5:
        return t('accred.standart.view.rule5');
      case 6:
        return t('accred.standart.view.rule6');
      case 7:
        return t('accred.standart.view.rule7');
      default:
        return "-";
    }
  }

  const headers = [
    {
      label: t('accred.standart.tabs.connected.group'),
      align: "center" as const,
    },
    {
      label: t('accred.standart.tabs.connected.specific'),
      align: "center" as const,
      //visible: data.type !== 2,
    },
    {
      label: t('accred.standart.tabs.connected.standart'),
      align: "center" as const,
    },
    {
      label: t('accred.standart.tabs.connected.unit'),
      align: "center" as const,
    },
    {
      label: t('accred.standart.tabs.connected.formula'),
      align: "center" as const,
    },
    {
      label: t('accred.standart.tabs.connected.resultRule'),
      align: "center" as const,
    },
    {
      label: t('accred.standart.tabs.connected.confidenceProbability'),
      align: "center" as const,
    },
    {
      label: t('actions'),
      align: 'center' as const,
    },
  ];

  const columns = [
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.accredSpecific.accredGroup.name ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.accredSpecific.name ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.name ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.unit?.name ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.formula ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => getResultRuleName(row.accredStandartChild.result_rule),
    },
    {
      render: (row: AccredStandartConnect) => row.accredStandartChild.confidence_probability ?? '--',
    },
    {
      render: (row: AccredStandartConnect) => (
        <Stack direction='row' spacing={2} justifyContent='center'>
          <UIActionButton
            icon='delete'
            tooltip={t('delete')}
            onClick={() => openDeleteModal(row.id, row.id_accred_standart_main)}//{() => modalDeleteRef.current?.open(row.id, row.id_equipment)}
          />
        </Stack>
      ),
      nowrap: true,
    },
  ];

  return (
    <>
      <CollapseCard
        title={
          <>
            {t('accred.standart.tabs.connected.title')}
            <IconButton
              onClick={() => modalRef.current?.open()} 
              aria-label="add"
            >
              <AddCircle />
            </IconButton>
          </>
        }
        collapsed={true}
      >
        <UITable
          isLoading={isFetching}
          data={records?.items ?? []}
          headers={headers}//.filter(h => h.visible !== false)}
          columns={columns}//.filter(c => c.visible !== false)}
          PaginationProps={{
            count: records?._meta.pageCount,
            page: filterParams.page,
            onChange: (_, page) => onChangeFilterParams({ page }),
          }}
        />
      </CollapseCard>
      <AccredStandartConnectModal accredStandart={data} ref={modalRef} />
      <DeleteModal
        ref={deleteModalRef}
        mutationFunction={deleteRec}
        prepareRequestBody={prepareRequestBody}
      />
    </>
  );
}

import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { CustomError } from './types';
import { setToken, signOut } from 'entities/auth/model/slice';
import { RootState } from 'app/store';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
  credentials: 'include',
  responseHandler: async (response) => {
    if (response.status === 204) {
      return { data: null };
    }
    const result = (await response.json()) as
      | {
          error: { code: number; msg: string };
          success: boolean;
        }
      | {
          data: unknown;
          success: boolean;
        };

    return result;
  },
});

// @ts-ignore
export const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  CustomError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  //console.log(result.error?.status)
  // Handle 204 No Content response
  /*if (result.error?.status === 204) {
    result = { ...result, data: null, error: undefined };
  }*/

  //@ts-ignore
  //if (result?.data && 'reason' in result.data) return Promise.reject();

  // Handle other errors or success responses
  if (result?.error && 'status' in result.error && result.error.status === 401) {
    const refreshResult = (await baseQuery(
      {
        credentials: 'include',
        url: '/site/refresh-token',
        method: 'POST',
      },
      api,
      extraOptions
    )) as { data: { token: string } };

    if (refreshResult.data) {
      api.dispatch(setToken(refreshResult.data?.token));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(signOut());
    }
  } else if (result?.data && typeof result.data === 'object' && 'reason' in result.data) {
    return Promise.reject(result.error);
  }
  return result;
};

import React from 'react';
import { Grid } from '@mui/material';
import { Protocol } from 'entities/protocol/model';
import{ MainInfoCard } from './main-info-card';
import{ FileCard } from './file-card';

interface Props {
  protocol: Protocol;
}

export const General: React.FC<Props> = ({ protocol }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MainInfoCard protocol={protocol} />
      </Grid>
      <Grid item xs={6}>
        <FileCard protocol={protocol} />
      </Grid>
    </Grid>

  );
}

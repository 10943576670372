import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface BreadcrumbsProps {
  items: { label: string; href?: string }[];
}

const Breadcrumb: React.FC<BreadcrumbsProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/">
        {t('home')}
      </Link>
      {items.map((item, index) => (
        <Link underline="hover" color="inherit" key={index} href={item.href}>
          {item.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
import React from 'react';
import { Box } from '@mui/material';
import { AccredStandart } from 'entities/accreditation/model';
import CollapseCard from 'shared/ui/collapse-card';
import { useTranslation } from 'react-i18next';

interface Props {
  data: AccredStandart;
}

export const Environment: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <CollapseCard title={t('accred.standart.tabs.environment.title')} collapsed={true}>
      <Box sx={{ flexGrow: 1 }}>
        environment
      </Box>
    </CollapseCard>
  );
}

import { Staff } from 'entities/staff/model';
import { EnvironmentCondition, Units } from 'entities/dicts/model';
import { Scales } from 'entities/equipment/model';
import { SelectObject } from 'app/utils/types';

export interface FormValues {
  id?:number;
  id_equipment: number;
  verify_date: string;
  verify_nn: string;
  verify_result: SelectObject;
  exp_date: string;
  scan: string;
  confirmation_method: SelectObject;
  note: string;
  scalePoints: 
    {
      id_scale: Scales | null;
      point: number | null;
      expanded_uncertainty: number | null;
      deviation: number | null;
    }[]
}

export const initialState = {
    verify_date: '',
    verify_nn: '',
    verify_result: {label: '', value: ''},
    exp_date: '',
    scan: '',
    confirmation_method: {label: '', value: ''},
    note: '',
    scalePoints: [
      {
        id_scale: null,
        point: null,
        expanded_uncertainty: null,
        deviation: null,
      }
    ]
};




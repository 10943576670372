import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';

interface Props {
  request: Request;
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export const General: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  /*const filesTestProg = (request.files.testProg !== false) ? request.files.testProg as RequestFile[] : null;
  const files = Object.keys(request.files).filter((el) => {
    let file = request.files[el as keyof Request['files']];
    return (file !== false && file !== "" && el !== "testProg");
  }).map(function (el) {
    let file = request.files[el as keyof Request['files']];
    return file as RequestFile;
  });*/
  //const filesTestProg = (request: Request) => {
    const filesTestProg= ((request?.files !== undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;

  //};
  //const files = (request: Request) => {
    const files =  (request?.files !== undefined) ? Object.keys(request.files).filter((el) => {
      let file = request.files[el as keyof Request['files']];
      return (file !== false && file !== "" && el !== "testProg");
    }).map(function (el) {
      let file = request.files[el as keyof Request['files']];
      return file as RequestFile;
    }) : [];

 // }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card sx={{ minWidth: 275 }}>
          {/**<CardHeader
            title="Общие сведения"
          />*/}
          <CardContent>
            <Stack spacing={1}>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.staffFio')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Person />
                  <Typography variant="body1" sx={{ fontSize: 16 }}>{request?.staff.fio}</Typography>
                </Stack>
              </Stack>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.requester')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Science />
                  <Typography variant="body1" sx={{ fontSize: 16 }}>{request?.from_tenant}</Typography>
                </Stack>
              </Stack>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.dateCreate')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CalendarMonth />
                  <Typography variant="body1" sx={{ fontSize: 16 }}>{moment(request?.date_create).format('DD.MM.YYYY')}</Typography>
                </Stack>
              </Stack>
              <Stack justifyContent='center'>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('request.client.title')}
                </Typography>
                <Stack justifyContent='center'>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <NextWeek />
                    <Typography variant="body1" sx={{ fontSize: 16 }}>{`${request?.client.name} (${t("request.client.inn")} ${request?.client.inn})`}</Typography>
                  </Stack>
                  <Typography>{request?.client.address}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ minWidth: 275 }}>
          {/*<CardHeader
            title="Прикрепленные файлы"
          />*/}
          <CardContent>

            <Stack spacing={1} justifyContent='center'>
              {files.map((file: RequestFile, index: number) => {
                return (<Stack direction="row" alignItems="center" gap={1}><FilePresentIcon /><Typography key={index}>{file.url ? (<Link target="_blank" href={"https://prog.i-test.uz" + file.url as string}>{file.label}</Link>) : file.label}</Typography></Stack>);
              })}
              {filesTestProg && filesTestProg.map((file: RequestFile, index: number) => {
                return (<Stack direction="row" alignItems="center" gap={1}><FilePresentIcon /><Typography key={index}>{file.url ? (<Link target="_blank" href={"https://prog.i-test.uz" + file.url as string}>{file.label}</Link>) : file.label}</Typography></Stack>);
              })}
            </Stack>

          </CardContent>
        </Card>
      </Grid>
    </Grid>

  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Grid, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { Equipment, EquipmentGraphMethod } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetEquipmentGraphMethodsQuery } from 'entities/equipment/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { ScatterChart } from '@mui/x-charts/ScatterChart'

interface Props {
  id_equipment?: number;
}

export const MethodGraphic: React.FC<Props> = ({ id_equipment }) => {
  const { t } = useTranslation();

  const { data: graphMethods } = useGetEquipmentGraphMethodsQuery(id_equipment ?? skipToken);
  console.log(graphMethods);

  const chartSetting = {
    width: 400,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
      [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
        transform: 'rotateZ(-45deg) translate(-50px, 0)',
        fontSize: '6px',
      },
    },
  };

  return (
    <CollapseCard title="Сведения о верификации оборудования" collapsed={true}>
      <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Наименование метода испытаний</TableCell>
            <TableCell>
              График верификации с методом и точностью по паспорту
            </TableCell>
            <TableCell>График изменения точностных характеристик по годам</TableCell>
          </TableRow>
        </TableHead>
        {graphMethods?.map((graphMethod: EquipmentGraphMethod, index: number) => (
          <TableRow key={index}>
            <TableCell>{graphMethod.title}</TableCell>
            <TableCell>
              <BarChart
                dataset={graphMethod.data.map((data) => {
                    return { min: data.data[0][0],
                      max: data.data[0][1],
                      title: data.name, 
                    };
                  }
                )}
                series={[
                  { dataKey: 'min', label: 'Минимум' },
                  { dataKey: 'max', label: 'Максимум' },
                ]}
                {...chartSetting}
                xAxis={[{ scaleType: 'band', dataKey: 'title' }]}
                bottomAxis={null}
              />
            </TableCell>
            <TableCell>тут должен быть график</TableCell>
          </TableRow>
        ))}
      </Table>

    </CollapseCard>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'shared/ui/collapse-card';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
import { Equipment } from 'entities/equipment/model';
import DetailView from 'shared/ui/detail-view';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import { Request, RequestFile } from 'entities/request/model';
import moment from 'moment';
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Person, Science, CalendarMonth, NextWeek, LocationOn, LocalPhone, Check, AssignmentTurnedIn, DownloadDoneOutlined } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Protocol } from 'entities/protocol/model';
import Download from '@mui/icons-material/Download';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { InfoGroup } from 'shared/ui/info-group';

import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import{ MainInfoCard } from './main-info-card';
//import{ FileCard } from './file-card';
import { mockProtocolsEnvironment, ProtocolEnvironment } from '../../types';


interface Props {
  protocol: Protocol;
}


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



export const Environment: React.FC<Props> = ({ protocol }) => {
  const { t } = useTranslation();

  const return_destroy_str = (return_destroy?: number) => {
    switch (return_destroy) {
      case 1:
        return t('protocol.view.destroyed');
      case 2:
        return t('protocol.view.returned');
      default:
        return '-';
    }
  }

  const strMinMax = (min?: number | null, max?: number | null) => {
    if(!min && !max) return '';
    if(min && !max) return `${min}`;
    if(!min && max) return `${max}`;
    if(min === max) return `${min}`;
    return `${min}-${max}`;
  }

  const data: ProtocolEnvironment[] = mockProtocolsEnvironment;

  /*const { data: laboratories } = useGetLaboratoriesQuery({});

  const confirmation_method_str = (confirmation_method: number) => {
    switch (confirmation_method) {
      case 1:
        return t('confirmationMethod.calibration');
      case 2:
        return t('confirmationMethod.attestation');
      case 3:
        return t('confirmationMethod.notMeaInstrument');
      default:
        return t('confirmationMethod.unknown');
    }
  }

  
    const filesTestProg= ((request?.files !== undefined) && (request?.files.testProg !== false)) ? request.files.testProg as RequestFile[] : null;

    const files =  (request?.files !== undefined) ? Object.keys(request.files).filter((el) => {
      let file = request.files[el as keyof Request['files']];
      return (file !== false && file !== "" && el !== "testProg");
    }).map(function (el) {
      let file = request.files[el as keyof Request['files']];
      return file as RequestFile;
    }) : [];
*/
  

  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card>
          <CardHeader
            title={item.laboratory.name}
          />
          <CardContent>
            <InfoGroup 
              title={t('protocol.view.environment.title')}
              items={item.data.map((el) => ({
                text: `${el.environmentCondition.name}: ${strMinMax(el.min, el.max)} ${el.unit.name}`,
              }))
              }
            />
          </CardContent>
        </Card>
      </Grid>
      ))}
    </Grid>

  );
}

import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AccredStandartConnectModalRef } from './types';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormTextInput } from 'shared/inputs/form/form-text-input';
import {
  useGetUnitsQuery,
} from 'entities/dicts/api';
import FormCheckboxInput from 'shared/inputs/form/form-checkbox-input';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import notify from 'app/utils/notify';
import ModalHeader from 'shared/ui/modal-header';
import {
  FormValues,
  initialState,
} from './initialState';
import { useCreateStandartConnectedMutation, useCreateStandartMutation, useCreateStandartPrimaryMutation, useGetGroupsQuery, useGetSpecificsQuery, useGetStandartsQuery, useUpdateStandartMutation, useUpdateStandartPrimaryMutation } from 'entities/accreditation/api';
import { AccredStandart, AccredStandartConnect, AccredStandartPrimary } from 'entities/accreditation/model';
import FormSelectInput from 'shared/inputs/form/form-select-input';
import { useGetLaboratoriesQuery } from 'entities/laboratories/api';
import FormNumericInput from 'shared/inputs/form/form-numeric-input';
import { FormFileInput } from 'shared/inputs/form/form-file-input';
import { useGetEquipmentQuery } from 'entities/equipment/api';

interface Props {
  accredStandart: AccredStandart;
}

export const AccredStandartConnectModal = forwardRef<AccredStandartConnectModalRef, Props>(({ accredStandart }, ref) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: initialState,
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue, //formState, trigger 
  } = methods;
  const [createStandartConnected, { isLoading: isLoadingCreate }] = useCreateStandartConnectedMutation();
  //const [updateStandartConnected, { isLoading: isLoadingUpdate }] = useUpdateStandartConnectedMutation();

  /*const { data: equipments, isFetching: isEquipmentFetching } = useGetEquipmentQuery({
    'per-page': 0,
  });*/

  const { data: specifics, isFetching: isSpecificFetching } = useGetSpecificsQuery({
    'per-page': 0,
    id_accred_group: accredStandart.accredSpecific.id_accred_group,
  });
  const [selectedSpecific, setSelectedSpecific] = useState('0');
  const { data: standarts, isFetching: isStandartFetching } = useGetStandartsQuery({
    'per-page': 0,
    id_accred_specific: selectedSpecific,
  });//, { skip: !selectedGroup || selectedGroup === '' });
  //const { data: laboratories, isFetching: isLaboratoryFetching } = useGetLaboratoriesQuery({});

  const handleSpecificChange = (id_accred_specific: string) => {
    //console.log(id_accred_specific);
    setSelectedSpecific(id_accred_specific);
  };

  //const [methodType, setMethodType] = useState(1);
  //const [parallelShow, setParallelShow] = useState(0);

  //const { data: units, isFetching: isUnitFetching } = useGetUnitsQuery({});

  //const equipment = watch('id_equipment');

  /*const {
    //fields: unitFields,
    append: appendUnit,
    remove: removeUnit,
    //insert: insertUnit,
  } = useFieldArray({
    control,
    name: 'units',
    keyName: 'id',
  });*/

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    []
  );

  const handleOpen = async (data?: AccredStandartConnect | undefined) => {
    if (data) {
      setIsEdit(true);
      reset({
        id: data.id,
        id_accred_specific: data.accredStandartChild.accredSpecific,
        id_accred_standart_child: data.accredStandartChild

      });
      
    } else {
      reset({
        ...initialState,
        //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
      }
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset({
      ...initialState,
      //type: {label: t(methodTypes[0].label), value: methodTypes[0].value}
    });
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.id_accred_standart_child) {
      return;
    }

    let body = {
      id_accred_standart: accredStandart.id,
      id_accred_standart_child: data.id_accred_standart_child?.id,
    };

    try {
      if (isEdit && data.id) {
        //await updateStandartPrimary({ ...body, id: data.id }).unwrap();
        notify(t('editSuccess'), 'success');
      } else {
        console.log(body);
        await createStandartConnected(body).unwrap();
        notify(t('addSuccess'), 'success');
      }
      
      handleClose();
    } catch (error) {
      if (isEdit && data.id) {
        notify(t('editError'), 'error');
      } else{
        notify(t('addError'), 'error');
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1600px',
          width: '28vw',
          //p: 3,
          //overflowY: 'visible',
        },
      }}
    >
      <ModalHeader
        title={isEdit ? t('edit') : t('add')}
        handleClose={handleClose}
      />
      <DialogContent style={{ height: '150px' }} className='scrollbar'>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} flex={'1'}>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isSpecificFetching}
                options={specifics?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_accred_specific`}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.tabs.connected.specific')
                }}
                rules={{
                  required: true,
                }}
                onChange={(event, option) => {
                  //@ts-ignore
                  handleSpecificChange((option && option.id) ? option.id.toString() : '0');

                  //onChangeFilterParams({ id_test_type: option && (option.id.toString) })
                  //field.onChange(option);
                  //@ts-ignore
                  //onChange?.(event, option);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormSelectInput
                loading={isStandartFetching}
                options={standarts?.items ?? []}//{ locationList ?? [] }//{ laboratories?.items ?? []}
                control={control}
                name={`id_accred_standart_child`}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id || ""}
                isOptionEqualToValue={(option, value) => value.id === undefined || String(value.id) === "" || option.id === value.id}

                TextFieldProps={{
                  //variant: 'standard',
                  //placeholder: t('location'),
                  size: 'small',
                  label: t('accred.standart.tabs.connected.standart')
                }}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='secondary' sx={{ mt: 3, ml: 1 }} onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate}
            sx={{ mt: 3, ml: 1 }}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

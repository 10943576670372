import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell
} from '@mui/material';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

import React, { useRef } from 'react';
import Layout from 'shared/layout';
//import EquipmentContainer from '../ui/equipment-container';

import './index.css';
import {
  useGetEquipmentIdQuery,
} from 'entities/equipment/api';
import { useParams } from 'react-router-dom';
import { General } from './general';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'shared/ui/breadcrumb';
import { useDeleteStandartMutation, useGetStandartByIdQuery } from 'entities/accreditation/api';
import { AccredStandart } from 'entities/accreditation/model';
import UITabs from 'shared/ui/ui-tabs';
import { Environment } from './environment';
import { Primary } from './primary';
import { Connected } from './connected';
import { Equipment } from './equipment';
import { Material } from './material';
import { Video } from './video';
import { Sample } from './sample';
import { Accuracy } from './accuracy';
import { AccredStandartModal } from '../modal/standart-modal';
import { AccredStandartModalRef } from '../modal/types';
//import {  } from 'pages/equipment/verification-modal/types';

const AccredStandartView = () => {
  const { viewId } = useParams();
  const { data, isFetching, isError } = useGetStandartByIdQuery(viewId ?? '');
  const { t } = useTranslation();

  const modalRef = useRef<AccredStandartModalRef>(null);

  const handleEdit = () => {
    modalRef.current?.open(data)
  }

  const breadcrumbs = [
    { label: t('accred.standart.title'), href: '/accreditation?tab=standart' },
    { label: data?.name as string },
  ];


  return (
    <Layout>
      {isFetching ? (<Typography variant='h2'>{t('loading')}</Typography>) ? isError : (<Typography variant='h2'>{t('error')}</Typography>) ? data === null : (<Typography variant='h2'>{t('notFound')}</Typography>) :
      (<Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
        <Breadcrumb items={breadcrumbs} />
        <Stack gap={'1rem'}>
          <Typography variant='h2'>
            {`${data?.name}`}
            <IconButton aria-label="edit" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Typography>
          <Typography variant='h3'>
            {`${data?.accredSpecific.name}`}
          </Typography>
          
          {/**<Button variant='contained' color='info' sx={{ width: 'fit-content' }}>
            Печать
          </Button>*/}
        </Stack>
        <General data={data as AccredStandart} />
        <Environment data={data as AccredStandart} />
        <Primary data={data as AccredStandart} />
        <Connected data={data as AccredStandart} />
        <Equipment data={data as AccredStandart} />
        <Material data={data as AccredStandart} />
        <Video data={data as AccredStandart} />
        <Sample data={data as AccredStandart} />
        <Accuracy data={data as AccredStandart} />
        <AccredStandartModal ref={modalRef} />
      </Box>)}
    </Layout>
  );
};

export default AccredStandartView;

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import { Guid, LoginFormData, LoginReturnData } from '../model/types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    login: builder.mutation<LoginReturnData, LoginFormData>({
      query: (body) => ({
        method: 'post',
        url: '/site/login',
        body,
      }),
    }),
    generateGuid: builder.query<Guid, {}>({
      query: () => ({
        method: 'GET',
        url: '/site/generate-guid',
      }),
    }),
    loginWithToken: builder.query<LoginReturnData, string>({
      query: (token) => ({
        method: 'GET',
        url: `/site/login-by-token?token=${token}`,
      }),
    }),
    /*loginWithToken: builder.mutation<LoginReturnData, string>({
      query: (token) => ({
        method: 'post',
        url: '/site/login-by-token',
        params: {
          token,
        },
      }),
    }),*/
  }),
});

export const { useLazyGenerateGuidQuery, useLazyLoginWithTokenQuery, useLoginMutation } = authApi;

import {
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell
} from '@mui/material';

import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

import React from 'react';
import Layout from 'shared/layout';
//import EquipmentContainer from '../ui/equipment-container';
import CollapseCard from '../../../shared/ui/collapse-card';
//import './index.css';
import {
  useGetEquipmentIdQuery,
} from 'entities/equipment/api';
import { useParams } from 'react-router-dom';
import { Equipment } from 'entities/equipment/model';
import { useGetProtocolByIdQuery } from 'entities/protocol/api';
import { Protocol } from 'entities/protocol/model';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { General } from './general';
import { Environment } from './environment';
import { Request } from 'entities/request/model';
/*import { ServiceRegistration } from './service-reg';
import { Verification } from './verification';

import { Characteristic } from './characteristic';
import { Shipping } from './shipping';
import { MethodGraphic } from './method-graphic';
import { EnvironmentViolation } from './environment-violation';*/
//import {  } from 'pages/equipment/verification-modal/types';


import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Breadcrumb from 'shared/ui/breadcrumb';
import DropdownButton from 'shared/ui/dropdown-button';
import { Additional } from './additional';
import { Production } from './production';
//import { Protocol, mockProtocol } from '../types'
import UIActionButton from 'shared/ui/ui-action-button';
import UITabs from 'shared/ui/ui-tabs';
import { dataURLtoFile } from 'app/utils';



const ProtocolViewPage = () => {
  const { t } = useTranslation();
  const { viewId } = useParams();
  const { data, isFetching, isError } = useGetProtocolByIdQuery(viewId ?? '');

  const editEquipment = () => {
    console.log(1);
  }

  //const data = mockProtocol;
  //console.log(data);

  const title = `${t('protocol.view.title')} №${data?.protocol_nn}`;

  const breadcrumbs = [
    { label: t('protocol.common.protocolTitle'), href: '/protocol' },
    { label: title },
  ];




  const actions = [
    { 
      icon: <PrintIcon />, 
      text: t('protocol.view.button.btnCreatePdf'), 
      onClick: () => {alert('Сделать PDF')},
    },
    { 
      icon: <PrintIcon />, 
      text: t('protocol.view.button.btnCreatePdfWithSign'), 
      onClick: () => {alert('Сделать PDF с подписями')},
    },
    { 
      icon: <ArchiveIcon />, 
      text: t('protocol.view.button.btnViewPdf'), 
      onClick: () => {alert('Посмотреть PDF')},
    },
    { 
      icon: <ArchiveIcon />, 
      text: t('protocol.view.button.btnActReturn'), 
      onClick: () => {alert('Акт о списании (возврате)')},
    },
    { 
      icon: <PrintIcon />, 
      text: t('protocol.view.button.btnCreateWriteOff'), 
      onClick: () => {alert('Сделать PDF (Первичные данные)')},
    },
    { 
      icon: <ArchiveIcon />, 
      text: t('protocol.view.button.btnViewWriteOff'), 
      onClick: () => {alert('Посмотреть первичные данные')},
    },
    { 
      icon: <ArchiveIcon />, 
      text: t('protocol.view.button.btnQualityCerificate'), 
      onClick: () => {alert('Сертификат качества')},
    },/*
    {
      icon: <EditIcon />,
      text: t('edit'),
      onClick: () => { console.log('Изменить') },
      visible: data?.date_samples === null,
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.analysis'),
      onClick: () => { console.log('Анализ заявки') },
      visible: (data?.id_staff !== null)
        && (data?.date_samples === null)
        && (data?.requestAnalysis === null),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.getSamples'),
      onClick: () => { console.log('Образцы получил') },
      visible: ((data?.id_staff !== null)
        && (data?.date_samples === null)
        && (data?.requestAnalysis !== null)),
    },
    {
      //icon: <EditIcon />,
      text: t('cancel'),
      onClick: () => { console.log('Отменить') },
      visible: ((data?.id_staff !== null)
        && (data?.date_samples !== null)
        && (!data?.date_cancel)
        && (!data?.has_any_next_steps)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.returnSamples'),
      onClick: () => { console.log('Вернуть образцы') },
      visible: ((data?.id_staff !== null)
        && (data?.date_samples !== null)
        && (data?.date_cancel === null)
        && (data?.protocol !== null)
        && (data?.return_date === null)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.copyCanceled'),
      onClick: () => { console.log('Создать копию отмененной') },
      visible: ((data?.date_samples !== null)
        && (data?.date_cancel !== null)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.createProtocol'),
      onClick: () => { console.log('Сделать протокол') },
      visible: ((!data?.has_null_group)
        && (!data?.has_next_steps)
        && (data?.date_samples !== null)
        && (!data?.date_cancel)),
    },
    {
      //icon: <EditIcon />,
      text: t('request.view.button.createContract'),
      onClick: () => { console.log('Сделать договор') },
      visible: ((!data?.has_null_group)
        && (!data?.has_next_steps)
        && (data?.date_samples !== null)
        && (data?.date_cancel === null)),
    },*/
  ];

  return (
    <Layout>
      {isFetching ? (<Typography variant='h2'>{t('loading')}</Typography>) ? isError : (<Typography variant='h2'>{t('error')}</Typography>) ? data === null : (<Typography variant='h2'>{t('notFound')}</Typography>) :
      (<Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
        <Breadcrumb items={breadcrumbs} />
        <Stack
          direction='row'
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Typography variant='h2'>
            {title}
          </Typography>
          {(!data?.anySigned) && (<UIActionButton icon='edit' onClick={() => alert('edit')} />)}
          {(!data?.anySigned) && (<UIActionButton icon='delete' onClick={() => alert('delete')} />)}
          <Button color='error' onClick={() => alert('Отменить')}>
            {t('cancel')}
          </Button>
          <Button color='warning' onClick={() => alert('Копия отмененного')}>
            {t('protocol.view.button.btnCopyCancelled')}
          </Button>
          <Button color='success' onClick={() => alert('Копия')}>
            {t('protocol.view.button.btnCopy')}
          </Button>
          <DropdownButton label={t('protocol.view.button.btnPdf')} items={actions} />
          
        </Stack>
        <Box sx={{ width: '100%' }}>          
          <UITabs
            tabs={[
              {
                component: <General protocol={data as Protocol} />,
                label: t('protocol.view.generalTitle'),
                href: 'general',
              },
              {
                component: <Production protocol={data as Protocol} />,
                label: t('protocol.view.objectTitle'),
                href: 'objects',
              },
              {
                component: <Environment protocol={data as Protocol} />,
                label: t('protocol.view.environmentTitle'),
                href: 'environment',
              },
              {
                component: <Typography variant='h2'>d</Typography>,
                label: t('protocol.view.importTitle'),
                href: 'import',
              },
              {
                component: <Typography variant='h2'>d</Typography>,
                label: t('protocol.view.eqTitle'),
                href: 'equipment',
              },
              {
                component: <Typography variant='h2'>d</Typography>,
                label: t('protocol.view.peopleTitle'),
                href: 'people',
              },
              {
                component: <Typography variant='h2'>d</Typography>,
                label: t('protocol.view.additionalTitle'),
                href: 'additional',
              },
              {
                component: <Typography variant='h2'>review and rating</Typography>,
                label: t('protocol.view.reviewTitle'),
                href: 'review',
              },
            ]}
            name='protocol'
          />
        </Box>
      </Box>)}
    </Layout>
  );
};

export default ProtocolViewPage;

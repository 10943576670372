import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" rel="noopener" target="_blank" href="https://i-test.uz">
        I-Test
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}<br/>{'\u00A0'}
    </Typography>
  );
}
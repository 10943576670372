import { CustomError } from './types';
import { SerializedError } from '@reduxjs/toolkit';
import notify from './notify';
import i18n from 'app/i18n';
import { FieldPath, FieldPathValue } from 'react-hook-form';
import { ReactNode } from 'react';
import _ from 'lodash';
import { Cert } from 'app/vendors/eimzo';

export const getUidBuCertificate = (cert: Cert): string => {
  let uid = cert.inn;
  if (!cert.inn || Number(cert.inn[0]) > 3) {
    uid = cert.parsedAlias?.['1.2.860.3.16.1.2'] || cert.inn;
  }
  return uid;
};

type Response<T = unknown> =
  | {
      data: T;
    }
  | {
      error: CustomError | SerializedError;
    };

export const getLang = (): string => localStorage.getItem('lang') || 'en';

export const getFetchEror = (error: CustomError | SerializedError) => {
  // if ('status' in error && error.status === 'PARSING_ERROR') {
  //   return '';
  // }
  if ('data' in error) {
    return error.data.error.msg;
  }

  return '';
};

export const handleResponse = <T>(res: Response<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    if ('error' in res) {
      const error = getFetchEror(res.error);
      notify(error, 'error');
      return reject(error);
    }
    resolve(res?.data);
  });
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], filename, { type: mime });
  return file;
};

export const getBooleanLabel = (value: boolean): string => {
  const t = i18n.t.bind(i18n);

  return value ? t('yes') : t('no');
};

export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

interface GetLabelByValueArgs<T extends {}> {
  data: T[];
  key: FieldPath<T>;
  value: FieldPathValue<T, FieldPath<T>>;
  label: FieldPath<T>;
}

export function getLabelByValue<T extends {}>({
  data,
  key,
  value,
  label,
}: GetLabelByValueArgs<T>) {
  const found = data.find((el) => _.get(el, key) === value);
  return _.get(found, label) ?? (value as ReactNode);
}

export const createFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else {
          formData.append(key, value);
        }
      }
    }
  }
  
  return formData;
};